import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Box from '@mui/material/Box';

export default function AddMemoDatePicker({ reminderDate, setReminderDate }) {
    return (
        <Box mt={-1}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                    <DatePicker
                        label="Remind me from"
                        value={reminderDate}
                        onChange={(newValue) => setReminderDate(newValue)}
                    />
                </DemoContainer>
            </LocalizationProvider>
        </Box>
    );
}