import React from 'react'
import { tokens } from "../../theme";
import { Box, useTheme, Button, Typography, IconButton, useMediaQuery } from "@mui/material";
import Loading from '../../components/Loading';
import Header from "../../components/Header";
import Axios from 'axios';
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Editor } from '@tinymce/tinymce-react';
import BackdropComp from '../../components/Backdrop';
import ConsultationCardReadOnly from './ConsultationCardReadOnly';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import Alert from '../../components/alert/Alert';
import SemesterChipWithDialog from './SemesterChipWithDialog';
import { FileUploadOutlined } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import PrevEmailContentModal from './PrevEmailContentModal';
import StudentInfoCardModal from './StudentInfoCardModal';

const endOfEmail = `
    <br />
    <p>Best Regards,</p>
    <p>A ONE Institute</p><br /><br />

    <img src="https://aone-universe.s3.amazonaws.com/aone-images/logo(no-background).png" alt="A ONE Institute" width="200" height="39">

    <div>
        <font color="#666666"><font face="Arial, sans-serif">T.&nbsp;&nbsp;</font></font>
        <font face="Arial, sans-serif" style="color:rgb(102,102,102)">201-266-8882</font>
        <span style="color:rgb(102,102,102);font-family:바탕,serif"><font size="1">┃</font></span>
        <font face="Arial, sans-serif" style="color:rgb(102,102,102)">201-346-<wbr>5689</font>
        <span style="color:rgb(102,102,102);font-family:바탕,serif"><font size="1">┃</font></span>
        <span style="color:rgb(102,102,102);font-family:Arial,sans-serif">&nbsp;E.
        <a href="mailto:aoneinstitutesat@gmail.com" target="_blank">aoneinstitutesat@gmail.com</a></span>
    </div>

    <div>
        <font color="#666666">
        <span style="font-family:Arial,sans-serif">65 Challenger Rd Suite 201, Ridgefield Park, NJ 07660</span>
        </font>
        </div>

    <div><font color="#666666"><span style="font-family:Arial,sans-serif">
        <a href="http://www.aoneinstitutesat.com" target="_blank" data-saferedirecturl="https://www.google.com/url?q=http://www.aoneinstitutesat.com&amp;source=gmail&amp;ust=1693706048365000&amp;usg=AOvVaw20fPhTzBitxyJxCvPo0AsO">www.aoneinstitutesat.com</a>
        </span></font></div>

    <div><font color="#666666"><span style="font-family:Arial,sans-serif"><br></span></font></div>
    `;

const options = {
    height: 800,
    menubar: false,
    plugins: [
        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
        'anchor', 'searchreplace', 'visualblocks', 'fullscreen',
        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
    ],
    toolbar: [
        ' undo redo | blocks | bold italic forecolor backcolor fontsize | fontselect |' +
        'alignleft aligncenter alignright alignjustify | lineheight |' +
        'bullist numlist outdent indent | removeformat | help'
    ],
    font_formats: 'Arial=arial,helvetica,sans-serif;' +
                 'Arial Black=arial black,avant garde;' +
                 'Courier New=courier new,courier;' +
                 'Georgia=georgia,palatino;' +
                 'Tahoma=tahoma,arial,helvetica,sans-serif;' +
                 'Times New Roman=times new roman,times;' +
                 'Verdana=verdana,geneva;' +
                 'Impact=impact,chicago;' +
                 'Comic Sans MS=comic sans ms,sans-serif;' +
                 'Trebuchet MS=trebuchet ms,geneva;',
    htmlAllowedTags:  ['.*'],
    htmlAllowedAttrs: ['.*'],
    extended_valid_elements: '*[.*]',
    content_style: "p { margin: 0; }",
};

const season = {0: 'All', 1: 'Spring', 2: 'Summer', 3: 'Fall'};

const EmailGenerator = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const minWidth90Rem = useMediaQuery('(min-width:90rem)');

    // for 401 error
    const signOut = useSignOut();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut();
        localStorage.removeItem("userName");
        localStorage.removeItem("who");
        navigate("/signin");
    }

    const [prevModalOpen, setPrevModalOpen] = React.useState(false);

    const [draftId, setDraftId] = React.useState(new URLSearchParams(window.location.search).get('draft'));
    let first_render = true;

    // for alert
    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = React.useState("");
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = React.useState("");

    const student_uuid = window.location.pathname.split('/')[3];

    const [loading, setLoading] = React.useState(true);
    const [backdropOpen, setBackdropOpen] = React.useState(false);

    const [consultationCardToggle, setConsultationCardToggle] = React.useState(false);
  
    const [semesters, setSemesters] = React.useState([]);
    const [selectedSemester, setSelectedSemester] = React.useState(0);

    const [categoryWithTemplates, setCategoryWithTemplates] = React.useState([]);
    const [courseTitleCheckedMap, setCourseTitleCheckedMap] = React.useState({});
    const [selectedCourses, setSelectedCourses] = React.useState([]);

    const [student, setStudent] = React.useState({});
    const [senderEmail, setSenderEmail] = React.useState('aoneinstituteoffice@gmail.com');
    const [recipientEmail, setRecipientEmail] = React.useState('');

    const [author, setAuthor] = React.useState('');
    const [title, setTitle] = React.useState('');
    const [date, setDate] = React.useState(dayjs());

    const [emailContent, setEmailContent] = React.useState(`
        <div>
            안녕하세요.
            <br />
            A ONE 실장 Hailey Hong 입니다.
            <br />
            귀한 시간내어 연락주셔서 감사합니다.
            <br />
            <br />
        </div>
        <div>문의하신 수업 안내 입니다.</div>
        <div>
            <br />
            자세하게 써드리고 싶어서 메일이 길어졌습니다.
            <br />
            한번 살펴보신후에 언제든지 궁금하신점 있으시면 연락주세요.
            <br />
            감사합니다.
        </div>
    `);

    const [draft, setDraft] = React.useState(null);

    // const [eachCategoryTrueNum, setEachCategoryTrueNum] = React.useState({
    //     'English': 0,
    //     'Math': 0,
    //     'Science': 0,
    //     'Social': 0,
    //     'Competition': 0,
    //     'Test Prep': 0,
    // });

    // for file upload
    const [files, setFiles] = React.useState([]);
    const handleFileRemove = (event, index) => {
        event.preventDefault();
        const newFiles = [...files];
        newFiles.splice(index, 1);
        setFiles(newFiles);
    };
    const handleUpload = (event) => {
        setFiles([...files, ...Array.from(event.target.files)]);

        const formData = new FormData();
        for (let i = 0; i < event.target.files.length; i++) {
            formData.append("file", event.target.files[i]);
        }
    };
    
    const handleChange = (event, category, course) => {
        // setEachCategoryTrueNum(
        //     event.target.checked ? eachCategoryTrueNum[course.course_title] + 1 : eachCategoryTrueNum[course.course_title] - 1
        // );

        if (course.course_id === undefined) return;
        if (course.course_id !== null) {
            setCourseTitleCheckedMap({
                ...courseTitleCheckedMap,
                [course.course_title + "|" + course.course_id]: event.target.checked,
            });

            if (event.target.checked && (course.email_template !== undefined && course.email_template !== null && course.email_template !== "")) {
                setEmailContent(
                    emailContent + `\n\n${course.email_template}\n\n`
                )
                let is_already_selected = false;
                for (let i = 0; i < selectedCourses.length; i++) {
                    if (selectedCourses[i] === course.course_id) {
                        is_already_selected = true;
                        break;
                    }
                }
                if (!is_already_selected) {
                    setSelectedCourses([...selectedCourses, course.course_id]);
                }
            } else {
                setEmailContent(
                    emailContent.replace(
                        course.email_template, ''
                    )
                )
                setSelectedCourses(selectedCourses.filter((selectedCourse) => selectedCourse !== course.course_id));
            }
        } else {
            // dictionary with course_id is null, meaning that it is for combined courses
            const course_ids = course.course_ids;
            let checkMap = {
                [course.course_title + "|" + course.course_id]: event.target.checked,
            };

            // check all the courses in the combined courses
            for (let i = 0; i < course.course_titles.length; i++) {
                checkMap[course.course_titles[i] + "|" + course.course_ids[i]] = event.target.checked;
            }

            setCourseTitleCheckedMap({
                ...courseTitleCheckedMap,
                ...checkMap,
            });

            if (event.target.checked) {
                if (course.email_template !== undefined && course.email_template !== null && course.email_template !== "") {
                    setEmailContent(
                        emailContent + `\n\n${course.email_template}\n\n`
                    )
                }
                let not_in_selected_courses = course_ids.filter((course_id) => !selectedCourses.includes(course_id));
                setSelectedCourses([...selectedCourses, ...not_in_selected_courses]);
            } else {
                setEmailContent(
                    emailContent.replace(
                        course.email_template, ''
                    )
                )
                setSelectedCourses(selectedCourses.filter((selectedCourse) => !course_ids.includes(selectedCourse)));
            }
        }
    };

    async function handleSaveDraft() {
        setBackdropOpen(true);
        const courseTitleAndId = Object.keys(courseTitleCheckedMap).filter((courseTitleAndId) => courseTitleCheckedMap[courseTitleAndId] === true);
        const course_titles = courseTitleAndId.map((courseTitleAndId) => {
            // remove course_id from courseTitleAndId
            if (!courseTitleAndId) return null;
            return courseTitleAndId.split("|")?.slice(0, courseTitleAndId.split("|").length - 1).join("|");
        });
        const selectedCourses = courseTitleAndId.map((courseTitleAndId) => {
            if (!courseTitleAndId) return null;
            return courseTitleAndId.split("|")[courseTitleAndId.split("|").length - 1];
        });

        if (recipientEmail === null || recipientEmail.trim() === '') {
            setErrorAlertMessage("Recipient Email is required! Please check the student's parent email.");
            setErrorAlertOpen(true);
            setBackdropOpen(false);
            return;
        }

        if (title === null || title.trim() === '') {
            setErrorAlertMessage("Subject is required!");
            setErrorAlertOpen(true);
            setBackdropOpen(false);
            return;
        }

        // save it to local storage
        const prevEmailContents = JSON.parse(localStorage.getItem('prevEmailContents')) ?? [];
        if (prevEmailContents.length >= 5) {
            prevEmailContents.shift();
        }
        prevEmailContents.push(emailContent);
        localStorage.setItem('prevEmailContents', JSON.stringify(prevEmailContents));

        if (draftId === null) {
            // save draft
            const res = await Axios.post(`${process.env.REACT_APP_URL}/api/v1/info-email-drafts`, {
                student_uuid,
                course_ids: selectedCourses,
                course_titles: course_titles,
                email_author: author,
                sender_email: senderEmail,
                recipient_email: recipientEmail.trim(),
                subject: title.trim(),
                content: emailContent,
            }, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            });

            if (res.status !== 200) {
                if (res.status === 401) {
                    handleLogout();
                    return;
                }

                setErrorAlertMessage(res.data.message || res.data.error);
                setErrorAlertOpen(true);
                return;
            }

            // status 201 here

            const draft_id = res.data.draft_id;

            if (files.length > 0) {
                const result = await saveAttachedFiles(draft_id);

                if (result.status !== 200) {
                    setErrorAlertMessage("Error occurs while saving attached files!");
                    setErrorAlertOpen(true);
                    return;
                }
            }
            
            setSuccessAlertMessage("Draft saved successfully");
            setSuccessAlertOpen(true);

            setTimeout(() => {
                setDraftId(draft_id);
            }, 300);

        } else {
            // update draft
            const res = await Axios.put(`${process.env.REACT_APP_URL}/api/v1/info-email-drafts/${draftId}`, {
                student_uuid,
                course_ids: selectedCourses,
                course_titles: course_titles,
                email_author: author,
                sender_email: senderEmail,
                recipient_email: recipientEmail.trim(),
                subject: title.trim(),
                content: emailContent,
            }, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            
            });


            if (res.status !== 200) {
                if (res.status === 401) {
                    handleLogout();
                    return;
                }
                
                setErrorAlertMessage(res.data.message ? res.data.message : res.data.error ? res.data.error : "Something went wrong!");
                setErrorAlertOpen(true);
                return;
            }

            // status 200 here

            if (files.length > 0) {
                const result = await saveAttachedFiles(draftId);
                if (result.status !== 200) {
                    setErrorAlertMessage("Error occurs while saving attached files!");
                    setErrorAlertOpen(true);
                    return;
                }
            }

            setSuccessAlertMessage("Draft updated successfully");
            setSuccessAlertOpen(true);
        }
        setBackdropOpen(false);
    }

    function saveAttachedFiles(draft_id) {
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append("file", files[i]);
        }

        return Axios.post(`${process.env.REACT_APP_URL}/api/v1/info-email-drafts/${draft_id}/draft-attached-files`, formData, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                'Content-Type': 'multipart/form-data',
            },
        });
    }
    
    async function handleSendEmail() {
        setBackdropOpen(true);

        if (recipientEmail === null || recipientEmail.trim() === '') {
            setErrorAlertMessage("Recipient Email is required! Please check the student's parent email.");
            setErrorAlertOpen(true);
            setBackdropOpen(false);
            return;
        }

        if (title === null || title.trim() === '') {
            setErrorAlertMessage("Subject is required!");
            setErrorAlertOpen(true);
            setBackdropOpen(false);
            return;
        }

        // save it to local storage
        const prevEmailContents = JSON.parse(localStorage.getItem('prevEmailContents')) ?? [];
        if (prevEmailContents.length >= 5) {
            prevEmailContents.shift();
        }
        prevEmailContents.push(emailContent);
        localStorage.setItem('prevEmailContents', JSON.stringify(prevEmailContents));

        const email_content = pToDiv(emailContent) + endOfEmail;

        if (files.length > 0) {
            const formData = new FormData();
            for (let i = 0; i < files.length; i++) {
                formData.append("file", files[i]);
            }

            formData.append("senderEmail", senderEmail);
            formData.append("recipientEmail", recipientEmail.trim());
            formData.append("author", author);
            formData.append("date", date.hour(12).toISOString());
            formData.append("subject", title.trim());
            formData.append("content", email_content);
            formData.append("draft_id", draftId === null ? null : parseInt(draftId));

            await Axios.post(`${process.env.REACT_APP_URL}/api/v1/mails/students/${student_uuid}/email-generator/with-attachments`, formData, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                    'Content-Type': 'multipart/form-data',
                },
            }).then(async (response) => {
                if (selectedCourses.length !== 0) {
                    // need to add student to the selected courses
                    await Axios.post(`${process.env.REACT_APP_URL}/api/v1/students/${student_uuid}/potential-courses`, {
                        course_ids: selectedCourses,
                        date: date.hour(12).toISOString(),
                        author,
                    }, {
                        headers: {
                            Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                        },
                    }).then((response) => {
                        setSuccessAlertMessage("Email sent successfully and student added to the selected courses");
                        setSuccessAlertOpen(true);
                    }).catch((error) => {
                        if (error.response.status === 401) {
                            handleLogout();
                            return;
                        }
                        setErrorAlertMessage(error.response.data.message || error.response.data.error);
                        setErrorAlertOpen(true);
                    });
                } else {
                    setSuccessAlertMessage("Email sent successfully");
                    setSuccessAlertOpen(true);
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    handleLogout();
                    return;
                }
                setErrorAlertMessage(error.response.data.message || error.response.data.error);
                setErrorAlertOpen(true);
            });
        } else {
            await Axios.post(`${process.env.REACT_APP_URL}/api/v1/mails/students/${student_uuid}/email-generator`, {
                senderEmail,
                recipientEmail: recipientEmail.trim(),
                author,
                date: date.hour(12).toISOString(),
                subject: title.trim(),
                content: email_content,
                draft_id: draftId === null ? null : parseInt(draftId),
            }, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then(async (response) => {
                if (selectedCourses.length !== 0) {
                    // need to add student to the selected courses
                    await Axios.post(`${process.env.REACT_APP_URL}/api/v1/students/${student_uuid}/potential-courses`, {
                        course_ids: selectedCourses,
                        date: date.hour(12).toISOString(),
                        author,
                    }, {
                        headers: {
                            Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                        },
                    }).then((response) => {
                        setSuccessAlertMessage("Email sent successfully and student added to the selected courses");
                        setSuccessAlertOpen(true);    
                    }).catch((error) => {
                        if (error.response.status === 401) {
                            handleLogout();
                            return;
                        }
                        setErrorAlertMessage(error.response.data.message || error.response.data.error);
                        setErrorAlertOpen(true);
                    });
                } else {
                    setSuccessAlertMessage("Email sent successfully");
                    setSuccessAlertOpen(true);
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    handleLogout();
                    return;
                }
                setErrorAlertMessage(error.response.data.message || error.response.data.error);
                setErrorAlertOpen(true);
            });    
        }

        setBackdropOpen(false);
    };

    React.useEffect(() => {
        localStorage.setItem('selectedMenu', '상담 진행 상황');

        function base64ToBlob(base64, contentType = '') {
            const byteCharacters = atob(base64);
            const byteArrays = [];
          
            for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                const slice = byteCharacters.slice(offset, offset + 512);
                const byteNumbers = new Array(slice.length);
            
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }
            
                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }
          
            return new Blob(byteArrays, { type: contentType });
        }          

        function createAttachedFiles(draft_attached_files) {
            let files = [];
            for (let i = 0; i < draft_attached_files.length; i++) {
                try {
                    // Convert Base64 to Blob
                    const blob = base64ToBlob(draft_attached_files[i].base64_content, draft_attached_files[i].file_type);

                    // Create a File object from the Blob
                    const newFile = new File([blob], draft_attached_files[i].file_name, { type: draft_attached_files[i].file_type });

                    files.push(newFile);
                } catch (error) {
                    console.error(error);
                }
            }

            setFiles(files);
        }

        async function fetchDraft() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/info-email-drafts/${draftId}`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                setSenderEmail(response.data.sender_email);
                setAuthor(response.data.email_author);
                setTitle(response.data.subject);
                setDraft(response.data);
                setEmailContent(response.data.content);

                if (response.data.draft_attached_files && response.data.draft_attached_files.length > 0) {
                    createAttachedFiles(response.data.draft_attached_files);
                }

            }).catch((error) => {
                if (error.response.status === 401) {
                    handleLogout();
                    return;
                }
                setErrorAlertMessage(error.response.data.message || error.response.data.error || "Something went wrong!");
                setErrorAlertOpen(true);
            });
        }

        async function fetchStudent() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/students/${student_uuid}`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                setStudent(response.data);
                setRecipientEmail(response.data.parent_email);
            }).catch((error) => {
                if (error.response.status === 401) {
                    handleLogout();
                    return;
                }
                setErrorAlertMessage(error.response.data.message || error.response.data.error || "Something went wrong!");
                setErrorAlertOpen(true);
            });
        }

        async function fetchSemesters() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/semesters`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                setSemesters(response.data);
                setSelectedSemester(response.data[0].id);
            }).catch((error) => {
                if (error.response.status === 404) {
                    setSemesters([]);
                    return;
                } else if (error.response.status === 401) {
                    handleLogout();
                    return;
                }
                setErrorAlertMessage(error.response.data.message || error.response.data.error || "Something went wrong!");
                setErrorAlertOpen(true);
            });
        }

        if (draftId !== null) {
            fetchDraft();
        }
        fetchStudent();
        fetchSemesters();
        setLoading(false);
    }, [draftId]);

    React.useEffect(() => {
        async function fetchCategoryWithEmailTemplates() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/potential-courses/semesters/${selectedSemester}/category-with-templates`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                let courseTitleCheckedMap = response.data.courseTitleCheckedMap;
                if (first_render && draftId !== null && draft !== null) {
                    first_render = false;

                    for (let i = 0; i < draft.course_titles.length; i++) {
                        if (draft.course_titles[i].includes("Combined")) {
                            courseTitleCheckedMap[draft.course_titles[i] + "|" + null] = true;
                            continue;
                        }
                        courseTitleCheckedMap[draft.course_titles[i] + "|" + draft.course_ids[i]] = true;
                    }

                    setSelectedCourses(draft.course_ids || []);
                }

                setCategoryWithTemplates(response.data.categoryWithTemplates);
                setCourseTitleCheckedMap(courseTitleCheckedMap);
            }).catch((error) => {
                if (error.response?.status === 401) {
                    handleLogout();
                    return;
                } else if (error.response?.status === 404) {
                    setCategoryWithTemplates([]);
                    setCourseTitleCheckedMap({});
                    return;
                }
                setErrorAlertMessage(error.response.data.message || error.response.data.error || "Something went wrong!");
                setErrorAlertOpen(true);
            });
            setLoading(false);
        }

        if (selectedSemester === 0) return;

        setLoading(true);
        fetchCategoryWithEmailTemplates();
    }, [selectedSemester, draft]);

    const handleChipClick = (semester_id) => {
        setSelectedSemester(semester_id);
    };

    function pToDiv(emailContent) {
        return emailContent.replace(/<p>/g, '<div>').replace(/<\/p>/g, '</div>').replace(/<p /g, '<div ');
    }

    return (
        <Box 
            m="20px" 
            marginX={3} 
            marginY={3} 
            sx={{
                minHeight: "88%",
                position: 'relative',
            }}
        >
            <Header title="Email Generator" subtitle="A ONE Institute" />
            { loading ? 
                <Loading />
            :
                <Box 
                    display="flex" 
                    flexDirection="column" 
                    justifyContent="left" 
                    gap={2} 
                    p={0} 
                    maxWidth={"90%"}
                    minHeight={70} 
                    mb={2}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            border: `1px solid ${colors.grey[700]}`,
                            padding: 2,
                        }}
                    >
                        <Typography
                            color={colors.redAccent[500]}
                            sx={{
                                fontWeight: 700,
                                fontSize: 14,
                            }}
                        >
                            Those two buttons below are only for saving the email content on this local device. (Not save draft)
                        </Typography>
                        <Box>
                            <Button
                                variant='outlined'
                                color='info'
                                onClick={() => {
                                    try {
                                        const prevEmailContents = JSON.parse(localStorage.getItem('prevEmailContents')) ?? [];
                                        prevEmailContents.push(emailContent);
                                        localStorage.setItem('prevEmailContents', JSON.stringify(prevEmailContents));
                                    } catch (error) {
                                        console.error(error);
                                        alert("Please try again!");
                                    }
                                }}
                                sx={{
                                    mb: 2,
                                    mr: 2,
                                }}
                            >
                                Save Email Content (only on this local device)
                            </Button>
                            <Button
                                variant='outlined'
                                color='warning'
                                onClick={() => {setPrevModalOpen(true);}}
                                sx={{
                                    mb: 2,
                                }}
                            >
                                Previous Email Contents (only on this local device)
                            </Button>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 2,
                            alignItems: 'center',
                        }}
                    >
                        <TextField
                            variant="outlined"
                            type="text"
                            label="Sender Email"
                            onChange={(e) => setSenderEmail(e.target.value)}
                            value={senderEmail}
                            sx={{
                                minWidth: 300,
                            }}
                        />
                        <TextField
                            variant="outlined"
                            type="text"
                            label="Author"
                            onChange={(e) => setAuthor(e.target.value)}
                            value={author}
                        />
                        <Box mt={-1}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker
                                        label="Date"
                                        value={date}
                                        onChange={(newValue) => setDate(newValue)}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 2,
                            alignItems: 'center',
                        }}
                    >
                        <TextField
                            variant="outlined"
                            type="text"
                            label="Recipient Email"
                            onChange={(e) => setRecipientEmail(e.target.value)}
                            value={recipientEmail}
                            sx={{
                                minWidth: 300,
                            }}
                        />
                    </Box>
                    <TextField
                        variant="outlined"
                        type="text"
                        label="Subject"
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                        sx={{
                            maxWidth: 1000,
                        }}
                    />
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 2,
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                gap: 2,
                            }}
                        >
                            {semesters.map((semester, index) => {
                                return (
                                    <SemesterChipWithDialog
                                        semester={semester}
                                        handleChipClick={handleChipClick}
                                        season={season}
                                        selectedSemester={selectedSemester}
                                        key={index}
                                    />
                                )
                            })}
                        </Box>
                    </Box>
                    <Box 
                        sx={{ 
                            display: 'flex', 
                            flexDirection: 'column',
                            marginBottom: 2,
                        }}
                    >
                        {categoryWithTemplates.map((course_type, i) => {
                            return (
                                <Box 
                                    key={i}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        marginBottom: 2,
                                        border: `1px solid ${colors.grey[700]}`,
                                        borderRadius: 1,
                                        padding: 2,
                                    }}
                                >
                                    <Typography
                                        variant="h3"
                                        fontWeight={700}
                                        color={colors.primary[100]}
                                    >
                                        {course_type.course_type}
                                    </Typography>
                                    {course_type.categories.map((category, index) => {
                                        if (category.courses.length === 0) return null;

                                        return (
                                            <FormControl sx={{ mt: 2, marginX: 3 }} component="fieldset" variant="standard" key={index}>
                                                <FormLabel component="legend" sx={{ fontSize: "20px", color: colors.primary[100] }}>
                                                    {category.category} ({category.courses.length})
                                                </FormLabel>
                                                <FormGroup row={true}>
                                                    {category.courses.map((course, index) => {
                                                        return (
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox 
                                                                        checked={courseTitleCheckedMap[course.course_title + "|" + course.course_id] || false}
                                                                        onChange={(e) => handleChange(e, category.category, course)}
                                                                        name={course.course_title}
                                                                    />
                                                                }
                                                                label={course.course_title}
                                                                key={index}
                                                            />
                                                        )
                                                    })}
                                                </FormGroup>
                                            </FormControl>
                                        )
                                    })}
                                </Box>
                            )
                        })}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                        }}
                    >
                        <Editor
                            apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                            init={options}
                            value={emailContent}
                            onEditorChange={
                                (newValue, editor) => {
                                    setEmailContent(newValue);
                                }
                            }
                        />
                    </Box>

                    {/* Student Info Card Generator */}
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-start"
                    >
                        <StudentInfoCardModal
                            student_uuid={student_uuid}
                            setErrorAlertMessage={setErrorAlertMessage}
                            setErrorAlertOpen={setErrorAlertOpen}
                            setSuccessAlertMessage={setSuccessAlertMessage}
                            setSuccessAlertOpen={setSuccessAlertOpen}
                        />
                    </Box>

                    {/* File Attach */}
                    <Box
                        display="flex"
                        minWidth="100%"
                        flexDirection="column"
                        justifyContent="flex-start"
                        sx={{
                            gap: "1rem",
                            ...minWidth90Rem && {
                                justifyContent: "space-between",
                                flexDirection: "row",
                            },
                        }}
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            gap={2}
                        >
                            <Box
                                display="flex"
                                alignItems="center"
                                mb={-2}
                            >
                                <Typography variant="h6" fontWeight="bold" color={colors.primary[450]}>
                                    Attach Files
                                </Typography>
                                <IconButton component="label">
                                    <FileUploadOutlined />
                                    <input
                                        multiple={true}
                                        styles={{display:"none"}}
                                        type="file"
                                        hidden
                                        onChange={handleUpload}
                                        name="[licenseFile]"
                                    />
                                </IconButton>
                            </Box>
                            <Box
                                display="flex"
                                minWidth="100%"
                                flexDirection="column"
                            >
                                {files.length > 0 && files.map((file, index) => {
                                    return (
                                        <Box key={index} display="flex" alignItems="center">
                                            <Typography variant="body1" color={colors.primary[450]}>
                                                {file.name}
                                            </Typography>
                                            <IconButton onClick={(e) => handleFileRemove(e, index)}>
                                                <ClearIcon />
                                            </IconButton>
                                        </Box>
                                    )
                                })}
                            </Box>
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            mt: 4,
                        }}
                    >
                        <Typography 
                            variant="h3" 
                            fontWeight={700}
                            color={colors.primary[100]}
                        >
                            Email Preview
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                                padding: 2,
                                borderRadius: 1,
                                border: `1px solid ${colors.primary[100]}`,
                                minHeight: 1000,
                            }}
                        >
                            <Typography variant="h5" color={colors.primary[100]}>From: <span style={{ padding: "6px 40px", backgroundColor: colors.primary[600] }}>{senderEmail}</span></Typography>
                            <Typography variant="h5" color={colors.primary[100]}>Author: <span style={{ padding: "6px 40px", backgroundColor: colors.primary[600] }}>{author}</span></Typography>
                            <Typography variant="h5" color={colors.primary[100]}>Date: <span style={{ padding: "6px 40px", backgroundColor: colors.primary[600] }}>{date.format('YYYY-MM-DD')}</span></Typography>
                            <Typography variant="h5" color={colors.primary[100]}>Subject: <span style={{ padding: "6px 40px", backgroundColor: colors.primary[600] }}>{title}</span></Typography>
                            <div 
                                dangerouslySetInnerHTML={{ __html: pToDiv(emailContent) + endOfEmail }} 
                                style={{
                                    minHeight: 800,
                                    padding: "10px 20px",
                                    backgroundColor: colors.primary[600],
                                }}
                            />
                            <Typography variant="h5" color={colors.primary[100]}>Attached Files{files.length > 0 ? `(${files.length})` : null}: </Typography>
                            {files.length > 0 && files.map((file, index) => {
                                return (
                                    <Typography variant="h5" color={colors.primary[100]} key={index}><span style={{ padding: "6px 40px", backgroundColor: colors.primary[600] }}>{file.name}</span></Typography>
                                )
                            })}
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 4,
                            mt: 4,
                            mb: 20,
                        }}
                    >
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: colors.blueAccent[600],
                                color: colors.primary[450],
                                width: 200,
                                minHeight: 50,
                                '&:hover': {
                                    backgroundColor: colors.blueAccent[300],
                                },
                            }}
                            onClick={handleSaveDraft}
                        >
                            {draftId === null ? "Save Draft" : "Update Draft"}
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: colors.greenAccent[500],
                                color: colors.primary[450],
                                width: 200,
                                minHeight: 50,
                                '&:hover': {
                                    backgroundColor: colors.greenAccent[400],
                                },
                            }}
                            onClick={handleSendEmail}
                        >
                            Send Email
                        </Button>
                    </Box>
                    <BackdropComp backdropOpen={backdropOpen} handleBackdropClose={() => setBackdropOpen(false)} />
                </Box>
            }
            <Box
                sx={{
                    position: 'fixed',
                    top: '13%', 
                    // right: !consultationCardToggle ? 10 : -320,
                    right: !consultationCardToggle ? 30 : -300,
                    transform: 'translateY(-50%)',
                    zIndex: 101,
                    transition: 'right 0.5s',
                    minWidth: 400,
                    maxWidth: 500,
                }}
            >
                <IconButton
                    onClick={() => setConsultationCardToggle(!consultationCardToggle)}
                >
                    {consultationCardToggle ?
                    <h4>Show</h4> : <h4>Hide</h4>
                    }
                    {consultationCardToggle ? 
                    <KeyboardDoubleArrowLeftIcon fontSize='large' /> :
                    <KeyboardDoubleArrowRightIcon fontSize='large' />}
                </IconButton>
            </Box>
            <Box
                sx={{
                    position: 'fixed',
                    top: '50%',
                    right: !consultationCardToggle ? 40 : -300,
                    transform: 'translateY(-50%)',
                    backgroundColor: colors.primary[600],
                    border: `5px solid ${colors.grey[800]}`,
                    boxShadow: 10,
                    zIndex: 100,
                    padding: 2,
                    transition: 'right 0.5s',
                    height: "70%",
                    // height: "45%",
                    // maxHeight: "70%",
                    // minWidth: 400,
                    // maxWidth: 500,
                    width: 400,                    
                }}            
            >
                <ConsultationCardReadOnly student={student} />
            </Box>
            <Alert
                successAlertOpen={successAlertOpen}
                setSuccessAlertOpen={setSuccessAlertOpen}
                errorAlertOpen={errorAlertOpen}
                setErrorAlertOpen={setErrorAlertOpen}
                successMsg={successAlertMessage}
                errorMsg={errorAlertMessage}
                vertical='bottom'
                horizontal='left'
            />
            <PrevEmailContentModal
                setModalOpen={setPrevModalOpen}
                modalOpen={prevModalOpen}
                pToDiv={pToDiv}
                endOfEmail={endOfEmail}
                setEmailContent={setEmailContent}
            />
        </Box>
    )
}

export default EmailGenerator;