import React from 'react';
import { Box, Typography, useTheme, TextField, Divider } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import { tokens } from "../../theme";
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Axios from 'axios';

const languages_for_day = [
    { value: "en", label: "English" },
    { value: "ko", label: "Korean" },
];

function WeeklySettingsModal({
    adminWeeklySettings,
    times,
    admin_uuid,
    enqueueSnackbar,
    setWeeklySettingsUpdated,
    open,
    handleClose,
}) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: colors.primary[600],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const [languageForDay, setLanguageForDay] = React.useState(adminWeeklySettings?.language_for_day || "en");
    const [dayVisibility, setDayVisibility] = React.useState(adminWeeklySettings?.day_visibility_arr || [true, true, true, true, true, true, true]);
    const [timesFromToByDay, setTimesFromToByDay] = React.useState(null);
    const [tableRowHeight, setTableRowHeight] = React.useState(adminWeeklySettings?.table_row_height || 40);
    const [isNoZoomVisible, setIsNoZoomVisible] = React.useState(adminWeeklySettings?.is_no_zoom_visible || true);
    const [eachZoomColumnWidth, setEachZoomColumnWidth] = React.useState(adminWeeklySettings?.each_zoom_column_width || 600);
    
    React.useEffect(() => {

        if (!adminWeeklySettings) {
            return;
        }

        const visibleDays = adminWeeklySettings.day_visibility_arr;

        const mon_times_from_to = [adminWeeklySettings.mon_times[0], adminWeeklySettings.mon_times[adminWeeklySettings.mon_times.length - 1]];
        const tue_times_from_to = [adminWeeklySettings.tue_times[0], adminWeeklySettings.tue_times[adminWeeklySettings.tue_times.length - 1]];
        const wed_times_from_to = [adminWeeklySettings.wed_times[0], adminWeeklySettings.wed_times[adminWeeklySettings.wed_times.length - 1]];
        const thu_times_from_to = [adminWeeklySettings.thu_times[0], adminWeeklySettings.thu_times[adminWeeklySettings.thu_times.length - 1]];
        const fri_times_from_to = [adminWeeklySettings.fri_times[0], adminWeeklySettings.fri_times[adminWeeklySettings.fri_times.length - 1]];
        const sat_times_from_to = [adminWeeklySettings.sat_times[0], adminWeeklySettings.sat_times[adminWeeklySettings.sat_times.length - 1]];
        const sun_times_from_to = [adminWeeklySettings.sun_times[0], adminWeeklySettings.sun_times[adminWeeklySettings.sun_times.length - 1]];


        setDayVisibility(visibleDays);

        setTimesFromToByDay([
            mon_times_from_to,
            tue_times_from_to,
            wed_times_from_to,
            thu_times_from_to,
            fri_times_from_to,
            sat_times_from_to,
            sun_times_from_to
        ]);

        setTableRowHeight(adminWeeklySettings.table_row_height);
        setIsNoZoomVisible(adminWeeklySettings.is_no_zoom_visible);
        setEachZoomColumnWidth(adminWeeklySettings.each_zoom_column_width);

    }, [adminWeeklySettings]);

    const handleSave = async (e) => {
        e.preventDefault();

        // check if at least one day is visible
        if (!dayVisibility.includes(true)) {
            enqueueSnackbar("At least one day must be visible", { variant: "error" });
            // setErrorAlertMessage("At least one day must be visible");
            // setErrorAlertOpen(true);
            return;
        }

        // check if the times are valid (from < to)
        for (let i = 0; i < timesFromToByDay.length; i++) {
            const fromIdx = times.indexOf(timesFromToByDay[i][0]);
            const toIdx = times.indexOf(timesFromToByDay[i][1]);
            if (fromIdx >= toIdx) {
                enqueueSnackbar("Invalid time range! From must be less than To", { variant: "error" });
                // setErrorAlertMessage("Invalid time range! From must be less than To");
                // setErrorAlertOpen(true);
                return;
            }
        }

        const mon_times = [];
        const tue_times = [];
        const wed_times = [];
        const thu_times = [];
        const fri_times = [];
        const sat_times = [];
        const sun_times = [];

        for (let i = 0; i < timesFromToByDay.length; i++) {
            const from = timesFromToByDay[i][0];
            const to = timesFromToByDay[i][1];

            const fromIdx = times.indexOf(from);
            const toIdx = times.indexOf(to);

            for (let j = fromIdx; j <= toIdx; j++) {
                switch (i) {
                    case 0:
                        mon_times.push(times[j]);
                        break;
                    case 1:
                        tue_times.push(times[j]);
                        break;
                    case 2:
                        wed_times.push(times[j]);
                        break;
                    case 3:
                        thu_times.push(times[j]);
                        break;
                    case 4:
                        fri_times.push(times[j]);
                        break;
                    case 5:
                        sat_times.push(times[j]);
                        break;
                    case 6:
                        sun_times.push(times[j]);
                        break;
                    default:
                        break;
                }
            }
        }

        if (isNaN(tableRowHeight)) {
            enqueueSnackbar("Table Row Height must be a number", { variant: "error" });
            return;
        }

        if (tableRowHeight < 20 || tableRowHeight > 100) {
            enqueueSnackbar("Table Row Height must be between 20 and 100", { variant: "error" });
            return;
        }

        if (!languageForDay || (languageForDay !== "en" && languageForDay !== "ko")) {
            enqueueSnackbar("Invalid language for day. Please refresh the page and try again", { variant: "error" });
            return;
        }

        await Axios.put(`${process.env.REACT_APP_URL}/api/v1/admins/${admin_uuid}/weekly-settings`, {
            day_visibility_arr: dayVisibility,
            mon_times,
            tue_times,
            wed_times,
            thu_times,
            fri_times,
            sat_times,
            sun_times,
            is_no_zoom_visible: isNoZoomVisible ? true : false,
            table_row_height: tableRowHeight,
            each_zoom_column_width: eachZoomColumnWidth,
            language_for_day: languageForDay,
        }, {
            headers: {
                Authorization: `Bearer ${document?.cookie?.split("=")[1]?.split(";")[0]}`,
            }
        }).then((res) => {
            setWeeklySettingsUpdated(true);
            enqueueSnackbar("Weekly settings saved successfully", { variant: "success" });
            // setSuccessAlertMessage("Weekly settings saved successfully");
            // setSuccessAlertOpen(true);
            handleClose();
        }).catch((err) => {
            console.log("Error: ", err);
            enqueueSnackbar("Failed to save weekly settings", { variant: "error" });
            // setErrorAlertMessage("Failed to save weekly settings");
            // setErrorAlertOpen(true);
        });
    };

    const handleFromToChange = (e, index, fromToIdx) => {
        e.preventDefault();
        const newTimesFromToByDay = [...timesFromToByDay];
        newTimesFromToByDay[index][fromToIdx] = e.target.value;
        setTimesFromToByDay(newTimesFromToByDay);
    };

    const handleVisibilityChange = (e, index) => {
        e.preventDefault();
        const newDayVisibility = [...dayVisibility];
        newDayVisibility[index] = e.target.checked;
        setDayVisibility(newDayVisibility);
    };

    const handleLanguageForDayChange = (e, value) => {
        e.preventDefault();
        setLanguageForDay(value);
    };

    function getDayLabel(index) {
        switch (index) {
            case 0:
                return "Monday";
            case 1:
                return "Tuesday";
            case 2:
                return "Wednesday";
            case 3:
                return "Thursday";
            case 4:
                return "Friday";
            case 5:
                return "Saturday";
            case 6:
                return "Sunday";
            default:
                return "Unknown";
        }
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <Typography variant="h4" color={colors.primary[450]}>
                        Weekly Settings
                    </Typography>
                    <Typography variant="h6" color={colors.grey[600]}>
                        (These settings are not global and will only affect your account)
                    </Typography>
                    <CloseIcon sx={{ position: "absolute", right: 10, top: 10, cursor: "pointer" }} onClick={handleClose} />
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", mt: 2, ml: 2, }}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Day Visibility</FormLabel>
                            <FormGroup row>
                                {dayVisibility && dayVisibility.length > 0 && dayVisibility.map((bool, index) => {
                                    return (
                                        <FormControlLabel 
                                            key={index}
                                            control={
                                                <Checkbox
                                                    checked={bool}
                                                    onChange={(e) => handleVisibilityChange(e, index)}
                                                />
                                            }
                                            label={getDayLabel(index)} 
                                        />
                                    )
                                })}
                            </FormGroup>
                        </FormControl>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">
                                Language for Day
                            </FormLabel>
                            <FormGroup row>
                                {languages_for_day.map((obj, index) => {
                                    return (
                                        <FormControlLabel 
                                            key={index}
                                            control={
                                                <Checkbox
                                                    checked={languageForDay === obj.value}
                                                    onChange={(e) => handleLanguageForDayChange(e, obj.value)}
                                                />
                                            }
                                            label={obj.label}
                                        />
                                    )
                                })}
                            </FormGroup>
                        </FormControl>
                    </Box>
                    <Divider sx={{ mt: 2 }} />
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", mt: 2 }}>
                        {timesFromToByDay && timesFromToByDay.map((FromTo, index) => {
                            return (
                                <Box 
                                    key={index}
                                    sx={{ 
                                        width: 400, 
                                        display: "flex", 
                                        flexDirection: "row", 
                                        alignItems: "center", 
                                        justifyContent: "center", 
                                        mt: 2
                                    }}
                                >
                                    <Typography variant="h6" color={colors.primary[450]} flex={1}>
                                        {getDayLabel(index)}:
                                    </Typography>
                                    <FormControl sx={{ m: 1, minWidth: 120, flex: 2 }} size="small">
                                        <InputLabel id="demo-select-small-label">From</InputLabel>
                                        <Select
                                            labelId="demo-select-small-label"
                                            id="demo-select-small"
                                            value={FromTo[0]}
                                            label="From"
                                            onChange={(e) => handleFromToChange(e, index, 0)}
                                        >
                                            {times && times.map((time, index) => {
                                                return (
                                                    <MenuItem key={index} value={time}>{time}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                    <FormControl sx={{ m: 1, minWidth: 120, flex: 2 }} size="small">
                                        <InputLabel id="demo-select-small-label">To</InputLabel>
                                        <Select
                                            labelId="demo-select-small-label"
                                            id="demo-select-small"
                                            value={FromTo[1]}
                                            label="To"
                                            onChange={(e) => handleFromToChange(e, index, 1)}
                                        >
                                            {times && times.map((time, index) => {
                                                return (
                                                    <MenuItem key={index} value={time}>{time}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </Box>
                            )
                        })}
                    </Box>
                    <Divider sx={{ mt: 2 }} />
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 2 }}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: 2,
                            }}
                        >
                            <Typography variant="h6" color={colors.primary[450]}>
                                Table Row Height
                            </Typography>
                            <TextField
                                id="table-row-height"
                                label="Default: 40"
                                type="number"
                                value={tableRowHeight}
                                onChange={(e) => setTableRowHeight(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            /
                            <Typography variant="h6" color={colors.primary[450]}>
                                Each Zoom Column's Width
                            </Typography>
                            <TextField
                                id="each-zoom-column-width"
                                label="Default: 600"
                                type="number"
                                value={eachZoomColumnWidth}
                                onChange={(e) => setEachZoomColumnWidth(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                        <Divider sx={{ mt: 2 }} />
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: 2,
                                mt: 2,
                            }}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isNoZoomVisible}
                                        onChange={(e) => setIsNoZoomVisible(e.target.checked)}
                                    />
                                }
                                label="Show No-Zoom Column"
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: 2,
                            mt: 2,
                        }}
                    >
                        <Button
                            variant="contained"
                            color="secondary"
                            sx={{ width: 200, mt: 2 }}
                            onClick={handleSave}
                        >
                            Save
                        </Button>
                        <Button
                            variant="contained"
                            color="error"
                            sx={{ width: 200, mt: 2 }}
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    )
}

export default WeeklySettingsModal