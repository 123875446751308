import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function Dropdown({ maxWidth, maxHeight, reactUseState, setReactUseState, inputLabel, data }) {
    const handleChange = (event) => {
        setReactUseState(event.target.value);
    };

    return (
        <Box sx={{ minWidth: 100, maxWidth: maxWidth, maxHeight: maxHeight, height: 50 }}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{inputLabel}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={reactUseState}
                    label={inputLabel}
                    onChange={handleChange}
                >
                    {(data === null || data === undefined || data.length === 0 || data === "none")
                    ? <MenuItem value={null}>None</MenuItem>
                    : data.map((item, index) => {
                        return (
                            <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </Box>
    );
}