import * as React from 'react';
import { tokens } from "../../theme";
import { useTheme, TextField } from "@mui/material";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);


export default function EventModal({
    openAddEventModal,
    handleCloseAddEventModal,
    openUpdateEventModal,
    handleCloseUpdateEventModal,
    eventDetails,
    setEventDetails,
    handleColorSelect,
    handleEventSubmit,
    handleEventUpdate,
}) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [state, setState] = React.useState({
        registration_deadline: false,
        submission_deadline: false,
    });

    React.useEffect(() => {
        if (openUpdateEventModal && eventDetails.fixed_event_name === 'deadline') {
            const newSelection = {
                open: eventDetails.color === '#00b050',                
                registration_deadline: eventDetails.color === '#e97132',
                submission_deadline: eventDetails.color === '#ff0000',
                test: eventDetails.color === '#0070c0',
                winner_announcement: eventDetails.color === '#a02b93',
            };
            setState(newSelection);

            // Set the color based on the fixed event name
            if (newSelection.open) {
                setEventDetails((prev) => ({ ...prev, color: '#00b050'}));
            } else if (newSelection.registration_deadline) {
                setEventDetails((prev) => ({ ...prev, color: '#e97132' }));
            } else if (newSelection.submission_deadline) {
                setEventDetails((prev) => ({ ...prev, color: '#ff0000' }));
            } else if (newSelection.test) {
                setEventDetails((prev) => ({ ...prev, color: '#0070c0' }));
            } else if (newSelection.winner_announcement) {
                setEventDetails((prev) => ({ ...prev, color: '#a02b93' }));
            }
        }
    }, [openUpdateEventModal]);

    // Handle checkbox change and allow reset on double click
    const handleChange = (event) => {
        const { name, checked } = event.target;

        // If the checkbox is double-clicked (already checked), reset the state
        if (checked && state[name]) {
            setState({ registration_deadline: false, submission_deadline: false });
            setEventDetails({ ...eventDetails, color: '' });
        } else {
            // Reset other checkboxes and enable only the clicked one
            const newState = { registration_deadline: false, submission_deadline: false, [name]: checked };
            setState(newState);

            // Set event details color based on the checkbox selection
            if (checked) {
                const colorMapping = {
                    registration_deadline: '#e97132',
                    submission_deadline: '#ff0000',
                };
                setEventDetails({ ...eventDetails, color: colorMapping[name] });
            } else {
                // Reset event details if unchecked
                setEventDetails({ ...eventDetails, color: '' });
            }
        }
    };


    const { registration_deadline, submission_deadline } = state;

    // Disable other buttons if one is selected
    const disableOthers = registration_deadline || submission_deadline;

    return (
        <>       
        {/* Add Open Event Modal */}
        <Modal
            open={openAddEventModal}
            onClose={handleCloseAddEventModal}
            aria-labelledby="add-event-modal-title"
            aria-describedby="add-event-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 500,
                    bgcolor: colors.primary[600],
                    border: '2px solid grey',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: '8px',
                }}
            >
                <Typography variant="h6" component="div">
                    Add 
                    { eventDetails.fixed_event_name === 'open' ? ' Open ' 
                        : eventDetails.fixed_event_name === 'deadline' ? ' Deadline '
                        : eventDetails.fixed_event_name === 'test' ? ' Test '
                        : eventDetails.fixed_event_name === 'winner_announcement' ? ' Winner Announcement '
                        : ' '
                    }
                    Event
                </Typography>
                
                {/* Checkboxes for deadline events*/}
                { eventDetails.fixed_event_name === 'deadline' && (
                <Box sx={{ display: 'flex' }}>
                    <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
                        <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={registration_deadline}
                                        onChange={handleChange}
                                        name="registration_deadline"
                                        disabled={disableOthers && !registration_deadline}
                                    />
                                }
                                label="Registration Deadline"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={submission_deadline}
                                        onChange={handleChange}
                                        name="submission_deadline"
                                        disabled={disableOthers && !submission_deadline}
                                    />
                                }
                                label="Submission Deadline"
                            />
                        </FormGroup>
                    </FormControl>
                </Box>
                )}
                
                {/* Event Name */}
                <TextField
                    label="Event Name"
                    fullWidth
                    margin="normal"
                    value={eventDetails.name}
                    onChange={(e) => setEventDetails({ ...eventDetails, name: e.target.value })}
                />
                {/* Date Picker */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="Date"
                        fullWidth
                        margin="normal"
                        onChange={(newValue) => {
                            setEventDetails({ ...eventDetails, date: newValue ? newValue.set('hour', 12).toISOString() : '' });
                        }}
                    />
                </LocalizationProvider>
            
                <Button
                    fullWidth
                    variant="contained"
                    sx={{ mt: 2 }}
                    onClick={handleEventSubmit}
                >
                    Save Event
                </Button>
            </Box>
        </Modal>

        {/* Update Event Modal */}
        <Modal
            open={openUpdateEventModal}
            onClose={handleCloseUpdateEventModal}
            aria-labelledby="add-event-modal-title"
            aria-describedby="add-event-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 500,
                    bgcolor: colors.primary[600],
                    border: '2px solid grey',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: '8px',
                }}
            >
                <Typography variant="h6">Update Event</Typography>

                {/* Checkboxes for deadline events */}
                { eventDetails.fixed_event_name === 'deadline' && (
                    <Box sx={{ display: 'flex' }}>
                        <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
                            <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={registration_deadline}
                                            onChange={handleChange}
                                            name="registration_deadline"
                                            disabled={disableOthers && !registration_deadline}
                                        />
                                    }
                                    label="Registration Deadline"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={submission_deadline}
                                            onChange={handleChange}
                                            name="submission_deadline"
                                            disabled={disableOthers && !submission_deadline}
                                        />
                                    }
                                    label="Submission Deadline"
                                />
                            </FormGroup>
                        </FormControl>
                    </Box>
                )}

                {/* Event Name */}
                <TextField
                    label="Event Name"
                    fullWidth
                    margin="normal"
                    value={eventDetails.name}
                    onChange={(e) => setEventDetails({ ...eventDetails, name: e.target.value })}
                />

                {/* Date Picker */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="Date"
                        fullWidth
                        margin="normal"
                        value={dayjs(eventDetails.date)}
                        onChange={(newValue) => {
                            setEventDetails({ ...eventDetails, date: newValue ? newValue.set('hour', 12).toISOString() : '' });
                        }}
                    />
                </LocalizationProvider>

                <Button
                    fullWidth
                    variant="contained"
                    sx={{ mt: 2 }}
                    onClick={handleEventUpdate}
                >
                    Save Event
                </Button>
            </Box>
        </Modal>
        </>
    );
};