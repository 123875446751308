import React from 'react'
import { tokens } from "../../../theme";
import { Box, useTheme, Typography } from "@mui/material";
import Loading from '../../../components/Loading';
import Avatar from '@mui/material/Avatar';
import Header from "../../../components/Header";
import Axios from 'axios';
import { useOutletContext } from "react-router-dom";
import dayjs from 'dayjs';
import Alert from '../../../components/alert/Alert';
import BackdropComp from '../../../components/Backdrop';
import AdminActions from './AdminActions';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import getStudentName from '../../../utils/getStudentName';

function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}
  
function stringAvatar(name) {
    return {
        sx: {
        bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

const ClassRoomPeople = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const matches = useMediaQuery('(min-width:1500px)');
    const matches2 = useMediaQuery('(min-width:1200px)');

    // for 401 error
    const signOut = useSignOut();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut();
        localStorage.removeItem("userName");
        localStorage.removeItem("who");
        navigate("/signin");
    }

    const [loading, setLoading] = React.useState(true);
    const courseData = useOutletContext().courseData;
    const [courseStudents, setCourseStudents] = React.useState([]);
    const [otherStudents, setOtherStudents] = React.useState([]);
    const [courseId, setCourseId] = React.useState(null);
    const [courseTeachers, setCourseTeachers] = React.useState(null);
    const [allTeachers, setAllTeachers] = React.useState([]);

    // for alert
    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = React.useState("");
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = React.useState("");

    // for backdrop
    const [backdropOpen, setBackdropOpen] = React.useState(false);
    const handleBackdropClose = () => {
        setBackdropOpen(false);
    };

    // for sorting semesters
    const [sortSemesters, setSortSemesters] = React.useState([]);

    React.useEffect(() => {
        localStorage.setItem('selectedMenu', 'People');
        setCourseId(window.location.pathname.split('/')[2]);

        async function getOtherStudents() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/courses/${window.location.pathname.split('/')[2]}/other-students`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                setOtherStudents(response.data);
            }).catch((error) => {
                if (error.response.status === 401) {
                    handleLogout();
                    return;
                }
                console.log(error.response.data.message);
            });
        }

        async function getCourseStudents() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/courses/${window.location.pathname.split('/')[2]}/students`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                setCourseStudents(response.data);
            }).catch((error) => {
                if (error.response.status === 404) {
                    // do nothing
                } else if (error.response.status === 401) {
                    handleLogout();
                    return;
                } else
                    console.log(error.response.data.message);
            });
        }

        async function getAllTeachers() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/teachers`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                setAllTeachers(response.data);
            }).catch((error) => {
                if (error.response.status === 401) {
                    handleLogout();
                    return;
                }
                console.log(error.response.data.message);
            });
        }

        getAllTeachers();
        getOtherStudents();
        getCourseStudents().then(() => {
            setLoading(false);
        });

        // set teachers to state and
        // sort semesters by start date and set it to state
        if (courseData !== null) {
            const teacher_list = [];
            courseData.teacher_courses.map((teacher_course) => {
                teacher_list.push(teacher_course.teacher);
            });
            setCourseTeachers(teacher_list);

            console.log(courseData);

            const sortSemesters = [...courseData.course_semesters].sort((a, b) => {
                return dayjs(a.semester.start) - dayjs(b.semester.start);
            });

            setSortSemesters(sortSemesters);
        }

    }, [courseData]);

    return (
        <Box p={4} key={courseId}>
            { loading || courseData === null ? 
                <Loading />
                : <>
                    <Box 
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            px: "25%",
                            ...!matches && {
                                px: "15%",
                            },
                            ...!matches2 && {
                                px: "10%",
                            },
                            }}
                    >
                        <Header 
                            title={
                                courseData.code !== null 
                                ? courseData.code + ". " + courseData.course_title 
                                : courseData.course_title
                            } 
                            subtitle="A ONE Institute"
                        />
                        {/* Admin Actions */}
                        <AdminActions 
                            courseData={courseData}
                            courseId={courseId}
                            allTeachers={allTeachers}
                            courseTeachers={courseTeachers}
                            courseStudents={courseStudents}
                            otherStudents={otherStudents}
                            setBackdropOpen={setBackdropOpen}
                            setSuccessAlertOpen={setSuccessAlertOpen}
                            setSuccessAlertMessage={setSuccessAlertMessage}
                            setErrorAlertOpen={setErrorAlertOpen}
                            setErrorAlertMessage={setErrorAlertMessage}
                            sortSemesters={sortSemesters}
                        />

                        {/* For students and teachers */}
                        <Box
                            minWidth="20rem"
                            width="100%"
                            minHeight="32rem"
                            borderRadius="3px"
                            padding={3}
                            boxShadow="0px 0px 10px 10px rgba(0, 0, 0, 0.10)"
                            backgroundColor={colors.primary[600]}
                            // display="flex"
                            // flexDirection="column"
                            alignItems="center"
                            gap={3}
                        >
                            <Box
                                sx={{
                                    padding: ".5rem",
                                    minHeight: "10rem",
                                }}
                            >
                                <Typography variant="h3" color={colors.primary[450]} borderBottom={.5}>
                                    Teacher
                                </Typography>
                                { courseTeachers === null || courseTeachers.length === 0 ? (
                                    <Typography variant="h5" color={colors.primary[450]} py={2}>
                                        A teacher is not assigned yet.
                                    </Typography>
                                ) : (
                                    courseTeachers.map((teacher) => {
                                        return (
                                            <Box key={teacher.id} display="flex" flexDirection="row" pt={2} alignItems="center">
                                                <Avatar {...stringAvatar(teacher.first_name + ' ' + teacher.last_name)} />
                                                <Typography variant="h5" color={colors.primary[450]} ml={2}>
                                                    {teacher.first_name} {teacher.last_name}
                                                </Typography>
                                            </Box>
                                        )
                                    })
                                )}
                            </Box>
                            <Box
                                sx={{
                                    padding: ".5rem",
                                    minHeight: "10rem",
                                }}
                            >
                                <Typography variant="h3" color={colors.primary[450]} borderBottom={.5}>
                                    Students
                                </Typography>
                                { courseStudents.length === 0 ? (
                                    <Typography variant="h5" color={colors.primary[450]} py={2}>
                                        No students are enrolled in this course.
                                    </Typography>
                                ) : (
                                    courseStudents.map((data, i) => (
                                        <Box key={i} display="flex" flexDirection="row" pt={2} alignItems="center">
                                            <Avatar {...stringAvatar(data.student.first_name + ' ' + data.student.last_name)} />
                                            {data.end_date !== null && dayjs(data.end_date).isBefore(dayjs()) ? (
                                                <Typography key={data.student.id} variant="h5" color={colors.grey[800]} ml={2} sx={{ textDecoration: 'line-through' }}>
                                                    {getStudentName(data.student)}
                                                </Typography>
                                            ) : (
                                                <Typography key={data.student.id} variant="h5" color={colors.primary[450]} ml={2}>
                                                    {getStudentName(data.student)}
                                                </Typography>
                                            )}
                                        </Box>
                                    ))
                                )}
                            </Box>
                        </Box>
                    </Box>
                    <Alert
                        successAlertOpen={successAlertOpen}
                        setSuccessAlertOpen={setSuccessAlertOpen}
                        errorAlertOpen={errorAlertOpen}
                        setErrorAlertOpen={setErrorAlertOpen}
                        successMsg={successAlertMessage}
                        errorMsg={errorAlertMessage}
                        vertical="bottom"
                        horizontal="left"
                    />
                    <BackdropComp backdropOpen={backdropOpen} handleBackdropClose={handleBackdropClose} />
                </>
            }
        </Box>
    )
}

export default ClassRoomPeople;