import React from 'react';
import { Box } from "@mui/material";

const sampleColors = [
    '#000000', // black
    '#757575', // grey
    '#E13019', // red
    "#D63200", // deep orange
    "#67160E", // burdundy
    "#D51555", // pink
    "#953755", // fuchsia
    "#9024A3", // violet
    "#673ab7", // purple
    "#3E50B1", // blue
    "#002CDB", // indigo
    "#455D63", // blue grey
    "#007E8F", // cyan
    "#0B78D0", // skyblue
    "#007E8F", // turquoise
    "#008577", // teal
    "#3A833C", // green
    "#707A15", // lime 
    "#A26A2A", // amber
    "#735045", // brown - 20
];

const ColorPicker = ({ 
    colors = sampleColors, 
    selectedColor, 
    onColorSelect, 
    circleSize = 28, 
    circleSpacing = 14,
    allowColor,
}) => {
    
    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: `${circleSpacing}px` }}>
            {colors.map((color, index) => (
                <Box
                    component={'button'}
                    disabled={allowColor}
                    key={`${color}-${index}`}
                    sx={{
                        width: `${circleSize}px`,
                        height: `${circleSize}px`,
                        borderRadius: '50%',
                        backgroundColor: color,
                        cursor: 'pointer',
                        border: color === selectedColor ? '3px solid white' : '1px solid #ccc',
                        boxShadow: color === selectedColor ? '0px 0px 5px 2px rgba(0,0,0,0.3)' : 'none',
                        transition: 'all 0.2s ease',
                    }}
                    onClick={() => onColorSelect(color)}
                />
            ))}
        </Box>
    );
};

export default ColorPicker;