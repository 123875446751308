import React from 'react';
import Axios from 'axios';
import { tokens } from '../theme';
import { Box, Typography, Button, useTheme, TextField, Autocomplete, Modal, Backdrop, Fade, IconButton } from '@mui/material';
import { Formik } from "formik";
import * as yup from "yup";
import { countryPhoneCodes } from "../adminDashboard/weekly/studentButtonMenu/countryPhoneCode";
import useMediaQuery from "@mui/material/useMediaQuery";
import Alert from '../components/alert/Alert';
import Loading from '../components/Loading';
import InstructionModal from './InstructionModal';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';


function StudentCards() {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        gap: 2,
        bgcolor: colors.primary[600],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const [loading, setLoading] = React.useState(false);
    const [tinyLoading, setTinyLoading] = React.useState(false);

    const [areYouSureModalOpen, setAreYouSureModalOpen] = React.useState(false);

    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = React.useState("");
    const [errorStatus, setErrorStatus] = React.useState(null);
    const isNonMobile = useMediaQuery("(min-width:600px)");

    const [instructionModalOpen, setInstructionModalOpen] = React.useState(true);

    // get uuid, which is right before the last slash
    const student_uuid = window.location.pathname.split('/')[window.location.pathname.split('/').length - 2];
    const [studentInfoLink, setStudentInfoLink] = React.useState(null);

    // for student and parents country phone code
    const [studentCountryPhoneCode, setStudentCountryPhoneCode] = React.useState(countryPhoneCodes.find((option) => option.name === "United States"));
    const [parentCountryPhoneCode, setParentCountryPhoneCode] = React.useState(countryPhoneCodes.find((option) => option.name === "United States"));
    const [parent2CountryPhoneCode, setParent2CountryPhoneCode] = React.useState(countryPhoneCodes.find((option) => option.name === "United States"));

    React.useEffect(() => {

        async function getStudentInfoLink() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/student_info_links/students/${student_uuid}`).then((response) => {
                setStudentInfoLink(response.data);
                if (errorStatus === null && response.data.is_complete !== true) {
                    setInstructionModalOpen(true);
                }
                setLoading(false);
            }).catch((error) => {
                console.log(error);
                setLoading(false);
                setErrorStatus(error.response.status);
                let error_msg = "";
                if (error && error.response && error.response.data) {
                    if (error.response.data.message) {
                        error_msg = error.response.data.message;
                    } else if (error.response.data.error) {
                        error_msg = error.response.data.error;
                    } else {
                        error_msg = "Internal Server Error. Please try again later.";
                    }
                }
                setErrorAlertMessage(error_msg);
                setErrorAlertOpen(true);
            });
        }

        setLoading(true);
        getStudentInfoLink();
    }, [student_uuid]);

    const handleFormSubmit = async (values) => {
        setTinyLoading(true);

        // console.log(values);
        let email = values.email;
        let parent_email = values.parent_email;
        let parent_email_2 = values.parent_email_2;

        let korean_name = values.korean_name;
        let first_name = values.first_name;
        let last_name = values.last_name;

        let phone = values.phone;
        let parent_phone = values.parent_phone;
        let parent_phone_2 = values.parent_phone_2;

        let school = values.school;
        let grade = values.grade;

        let street = values.street;
        let city = values.city;
        let state = values.state;
        let zip = values.zip;
        let country = values.country;

        let break_date_answer_1 = values.break_date_answer_1;
        let break_date_answer_2 = values.break_date_answer_2;

        try {
            email = email.toLowerCase().trim();
            parent_email = parent_email.toLowerCase().trim();
            parent_email_2 = parent_email_2.toLowerCase().trim();
            first_name = first_name.toLowerCase().trim();
            last_name = last_name.toLowerCase().trim();
            korean_name = korean_name ? korean_name.trim() : "";
            phone = phone.trim();
            parent_phone = parent_phone.trim();
            parent_phone_2 = parent_phone_2.trim();
            school = school.toLowerCase().trim();
            grade = grade.trim();
            street = street.trim();
            city = city.trim();
            state = state.trim();
            zip = zip.trim();
            country = country.trim();
            break_date_answer_1 = break_date_answer_1.trim();
            break_date_answer_2 = break_date_answer_2.trim();

            first_name = first_name.replace(/\b\w/g, (c) => c.toUpperCase());
            last_name = last_name.replace(/\b\w/g, (c) => c.toUpperCase());
            school = school.replace(/\b\w/g, (c) => c.toUpperCase());
            street = street.replace(/\b\w/g, (c) => c.toUpperCase());
            city = city.replace(/\b\w/g, (c) => c.toUpperCase());

            if (country.length > 2) {
                country = country.replace(/\b\w/g, (c) => c.toUpperCase());
            } else {
                country = country.toUpperCase();
            }

            if (state.length > 2) {
                state = state.replace(/\b\w/g, (c) => c.toUpperCase());
            } else {
                state = state.toUpperCase();
            }

        } catch (error) {
            console.log(error);
            setTinyLoading(false);
            return;
        }

        if (first_name === "" || last_name === "" || phone === "" || email === "" || grade === "" || school === "" || street === "" || city === "" || state === "" || country === "" || parent_phone === "" || parent_email === "") {
            setErrorAlertMessage("Please fill out all required fields! (Fields with * are required)");
            setErrorAlertOpen(true);
            setTinyLoading(false);
            return;
        }

        // check if country phone codes are selected
        if (values.phone !== "" && values.phone !== null && studentCountryPhoneCode === null) {
            setErrorAlertMessage("Please select the country phone code for student's phone number!");
            setErrorAlertOpen(true);
            setTinyLoading(false);
            return;
        }

        if (values.parent_phone !== "" && values.parent_phone !== null && parentCountryPhoneCode === null) {
            setErrorAlertMessage("Please select the country phone code for parent's phone number!");
            setErrorAlertOpen(true);
            setTinyLoading(false);
            return;
        }

        if (values.parent_phone_2 !== "" && values.parent_phone_2 !== null && parent2CountryPhoneCode === null) {
            setErrorAlertMessage("Please select the country phone code for parent's phone number!");
            setErrorAlertOpen(true);
            setTinyLoading(false);
            return;
        }

        if ((korean_name && korean_name.length > 30) || (first_name && first_name.length > 30) || (last_name && last_name.length > 30)) {
            setErrorAlertMessage("Name fields can't be more than 30 characters!");
            setErrorAlertOpen(true);
            setTinyLoading(false);
            return;
        }

        if (email.length > 250 || parent_email.length > 250 || (parent_email_2 && parent_email_2.length > 250)) {
            setErrorAlertMessage("Email fields can't be more than 250 characters!");
            setErrorAlertOpen(true);
            setTinyLoading(false);
            return;
        }

        if (phone.length > 30 || parent_phone.length > 30 || (parent_phone_2 && parent_phone_2.length > 30)) {
            setErrorAlertMessage("Phone number fields can't be more than 30 characters!");
            setErrorAlertOpen(true);
            setTinyLoading(false);
            return;
        }

        if (school.length > 100 || street.length > 100 || city.length > 100 || state.length > 100 || country.length > 100) {
            setErrorAlertMessage("School, Street, City, State, and Country fields can't be more than 100 characters!");
            setErrorAlertOpen(true);
            setTinyLoading(false);
            return;
        }

        if (zip && zip.length > 20) {
            setErrorAlertMessage("Zip field can't be more than 20 characters!");
            setErrorAlertOpen(true);
            setTinyLoading(false);
            return;
        }

        if ((break_date_answer_1 && break_date_answer_1.length > 250) || (break_date_answer_2 && break_date_answer_2.length > 250)) {
            setErrorAlertMessage("Break Date fields can't be more than 250 characters!");
            setErrorAlertOpen(true);
            setTinyLoading(false);
            return;
        }

        await Axios.post(`${process.env.REACT_APP_URL}/api/v1/student_info_links/${studentInfoLink.id}/submission`, {
            first_name: first_name,
            last_name: last_name,
            korean_name: korean_name,
            country_phone_code: studentCountryPhoneCode === null ? "+1 United States" : studentCountryPhoneCode.code + " " + studentCountryPhoneCode.name,
            student_phone: phone,
            student_email: email,
            grade: grade,
            school: school,
            street: street,
            city: city,
            state: state,
            zip: zip && zip !== "" ? zip : null,
            country: country,
            parent_email: parent_email,
            parent_email_2: parent_email_2 && parent_email_2 !== "" ? parent_email_2 : null,
            parent_country_phone_code: parentCountryPhoneCode === null ? "+1 United States" : parentCountryPhoneCode.code + " " + parentCountryPhoneCode.name,
            parent_phone: parent_phone,
            parent_country_phone_code_2: parent2CountryPhoneCode === null ? "+1 United States" : parent2CountryPhoneCode.code + " " + parent2CountryPhoneCode.name,
            parent_phone_2: parent_phone_2 && parent_phone_2 !== "" ? parent_phone_2 : null,
            break_date_answer_1: break_date_answer_1,
            break_date_answer_2: break_date_answer_2,
        }, {
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(async (response) => {
            const newStudentInfoLink = response.data;

            // send email to office (admin)
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/mails/student_info_links/${studentInfoLink.id}/submission_email`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then((response) => {
                setStudentInfoLink(newStudentInfoLink);
                setTinyLoading(false);
                setAreYouSureModalOpen(false);
            }).catch((error) => {
                console.log(error);
                setErrorStatus(500);
                setErrorAlertMessage("Student Card is submitted successfully, but there was an error sending the email to the office (admin). Please contact us.");
                setErrorAlertOpen(true);
                setTinyLoading(false);
                setAreYouSureModalOpen(false);
            });
        }).catch((error) => {
            if (error && error.response && error.response.status === 400) {
                setErrorStatus(400);
                setErrorAlertMessage(error.response.data.message || "This student card is already submitted.");
                setErrorAlertOpen(true);
                setAreYouSureModalOpen(false);
                setTinyLoading(false);
                return;
            } else if (error && error.response && error.response.status === 404) {
                setErrorStatus(404);
                setErrorAlertMessage(error.response.data.message || "Student data is not found. Sorry for the inconvenience. Please contact us.");
                setErrorAlertOpen(true);
                setAreYouSureModalOpen(false);
                setTinyLoading(false);
                return;
            } else if (error && error.response && error.response.status === 500) {
                setErrorStatus(500);
                setErrorAlertMessage(error.response.data.message || "Internal Server Error. Please refresh the page and try again. If the problem persists, please contact us.");
                setErrorAlertOpen(true);
                setAreYouSureModalOpen(false);
                setTinyLoading(false);
                return;
            }

            setErrorAlertMessage(error.response.data.message);
            setErrorAlertOpen(true);
            setTinyLoading(false);
            setAreYouSureModalOpen(false);
        });
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                minHeight: '100vh',
                // backgroundColor: colors.primary[600],
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                    height: '100px',
                    px: 2,
                    mt: 2,
                }}
            >
                <a 
                    href="https://www.aoneinstitutesat.com" 
                    target="_blank" 
                    rel="noreferrer"
                    style={{
                        textDecoration: 'none',
                    }}
                >
                    <img src="/imgs/aone-logo.png" alt="logo" style={{height: "60px"}} />
                </a>
            </Box>
            {(loading || !studentInfoLink) && errorStatus === null ? (
                <Loading />
            ) : errorStatus !== null ? (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '20px',
                        width: '100%',
                        mt: '40px',
                    }}
                >
                    <Typography variant="h2" fontWeight={800}>
                        Error: {errorStatus}
                    </Typography>
                    <Typography variant="h4" fontWeight={800}>
                        {errorAlertMessage}
                    </Typography>
                    <Typography variant="h4" fontWeight={800}>
                        Please refresh the page and try again. If the problem persists, please contact us.
                    </Typography>
                </Box>
            ) : !loading && studentInfoLink && studentInfoLink.is_complete ? (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '20px',
                        width: '100%',
                        mt: '40px',
                    }}
                >
                    <Typography variant="h2" fontWeight={800}>
                        Thank you for submitting student card!
                    </Typography>
                    <Typography variant="h4" fontWeight={800}>
                        If you need to make changes, please contact us.
                    </Typography>
                </Box>
            ) : (
                <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '20px',
                        width: '100%',
                        mt: '40px',
                    }}
                >
                    <Typography variant="h2" fontWeight={800}>
                        A ONE Student Card
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '20px',
                            width: '100%',
                            px: 2,
                        }}
                    >
                        <Formik
                            onSubmit={handleFormSubmit}
                            initialValues={initialValues}
                            validationSchema={checkoutSchema}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                            }) => (
                            <form id='student-card-form' onSubmit={handleSubmit}>
                                <Box
                                    position={"relative"}
                                    padding={4}
                                    boxShadow={3}
                                    borderRadius={3}
                                    m={1}
                                    mb={2}
                                    bgcolor={colors.primary[600]}
                                >
                                    <IconButton
                                        color="primary"
                                        sx={{
                                            ml: 1,
                                            position: "absolute",
                                            top: "10px",
                                            right: "10px",
                                        }}
                                        onClick={() => setInstructionModalOpen(true)}
                                    >
                                        <HelpOutlineIcon />
                                    </IconButton>
                                    <Typography
                                        variant="h4"
                                        fontWeight="bold"
                                        mb={studentInfoLink && studentInfoLink.korean_name_included ? 1 : 3}
                                    >
                                        Student Information <span style={{ fontWeight: 500, fontSize: "14px" }}>(*Required)</span>
                                    </Typography>
                                    {studentInfoLink && studentInfoLink.korean_name_included && (
                                        <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                            color={colors.grey[600]}
                                            mb={2}
                                        >
                                            한글 이름 (Korean Name) 칸에는 성을 빼고 이름만 적어주세요. 감사합니다.
                                        </Typography>
                                    )}
                                    <Box
                                        display="grid"
                                        gap="30px"
                                        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                        sx={{
                                            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                                        }}
                                    >
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="*First Name"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.first_name}
                                            name="first_name"
                                            error={!!touched.first_name && !!errors.first_name}
                                            helperText={touched.first_name && errors.first_name}
                                            sx={{ gridColumn: "span 2" }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="*Last Name"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.last_name}
                                            name="last_name"
                                            error={!!touched.last_name && !!errors.last_name}
                                            helperText={touched.last_name && errors.last_name}
                                            sx={{ gridColumn: "span 1" }}
                                        />
                                        {studentInfoLink && studentInfoLink.korean_name_included && (
                                            <TextField
                                                fullWidth
                                                variant="filled"
                                                type="text"
                                                label="Korean Name (Optional)"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.korean_name}
                                                name="korean_name"
                                                error={!!touched.korean_name && !!errors.korean_name}
                                                helperText={touched.korean_name && errors.korean_name}
                                                sx={{ gridColumn: "span 1" }}
                                            />
                                        )}
                                        <Autocomplete
                                            id="country-phone-code"
                                            value={studentCountryPhoneCode}
                                            options={countryPhoneCodes}
                                            getOptionLabel={(option) => option.code + " " + option.name}
                                            sx={{ gridColumn: "span 2" }}
                                            renderInput={(params) => <TextField {...params} label="*Country Phone Code" />}
                                            onChange={(e, value) => {
                                                setStudentCountryPhoneCode(value);
                                            }}
                                            isOptionEqualToValue={(option, value) => option.code === value.code}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="*Phone (only number)"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.phone}
                                            name="phone"
                                            error={!!touched.phone && !!errors.phone}
                                            helperText={touched.phone && errors.phone}
                                            sx={{ gridColumn: "span 2" }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="*Email"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.email}
                                            name="email"
                                            error={!!touched.email && !!errors.email}
                                            helperText={touched.email && errors.email}
                                            sx={{ gridColumn: "span 4" }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="*Grade (only number)"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.grade}
                                            name="grade"
                                            error={!!touched.grade && !!errors.grade}
                                            helperText={touched.grade && errors.grade}
                                            sx={{ gridColumn: "span 1" }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="*School"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.school}
                                            name="school"
                                            error={!!touched.school && !!errors.school}
                                            helperText={touched.school && errors.school}
                                            sx={{ gridColumn: "span 3" }}
                                        />
                                        <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                            sx={{ gridColumn: "span 4" }}
                                        >
                                            Home Address
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="*Street"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.street}
                                            name="street"
                                            error={!!touched.street && !!errors.street}
                                            helperText={touched.street && errors.street}
                                            sx={{ gridColumn: "span 4" }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="*City"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.city}
                                            name="city"
                                            error={!!touched.city && !!errors.city}
                                            helperText={touched.city && errors.city}
                                            sx={{ gridColumn: "span 1" }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="*State"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.state}
                                            name="state"
                                            error={!!touched.state && !!errors.state}
                                            helperText={touched.state && errors.state}
                                            sx={{ gridColumn: "span 1" }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="*Country"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.country}
                                            name="country"
                                            error={!!touched.country && !!errors.country}
                                            helperText={touched.country && errors.country}
                                            sx={{ gridColumn: "span 1" }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Zip (Optional)"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.zip}
                                            name="zip"
                                            error={!!touched.zip && !!errors.zip}
                                            helperText={touched.zip && errors.zip}
                                            sx={{ gridColumn: "span 1" }}
                                        />
                                    </Box>
                                </Box>
                                <Box
                                    padding={4}
                                    boxShadow={3}
                                    borderRadius={3}
                                    m={1}
                                    bgcolor={colors.primary[600]}
                                >
                                    <Typography
                                        variant="h4"
                                        fontWeight="bold"
                                        mb={3}
                                    >
                                        Parents Information
                                    </Typography>
                                    <Box
                                        display="grid"
                                        gap="30px"
                                        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                        sx={{
                                            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                                        }}
                                    >
                                        <Autocomplete
                                            id="country-phone-code"
                                            value={parentCountryPhoneCode}
                                            options={countryPhoneCodes}
                                            getOptionLabel={(option) => option.code + " " + option.name}
                                            sx={{ gridColumn: "span 2" }}
                                            renderInput={(params) => <TextField {...params} label="*Country Phone Code" />}
                                            onChange={(e, value) => {
                                                setParentCountryPhoneCode(value);
                                            }}
                                            isOptionEqualToValue={(option, value) => option.code === value.code}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="*Parent Phone 1"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.parent_phone}
                                            name="parent_phone"
                                            error={!!touched.parent_phone && !!errors.parent_phone}
                                            helperText={touched.parent_phone && errors.parent_phone}
                                            sx={{ gridColumn: "span 2" }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="*Parent Email 1"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.parent_email}
                                            name="parent_email"
                                            error={!!touched.parent_email && !!errors.parent_email}
                                            helperText={touched.parent_email && errors.parent_email}
                                            sx={{ gridColumn: "span 4" }}
                                        />
                                        <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                            sx={{ gridColumn: "span 4" }}
                                        >
                                            Optional
                                        </Typography>
                                        <Autocomplete
                                            id="country-phone-code"
                                            value={parent2CountryPhoneCode}
                                            options={countryPhoneCodes}
                                            getOptionLabel={(option) => option.code + " " + option.name}
                                            sx={{ gridColumn: "span 2" }}
                                            renderInput={(params) => <TextField {...params} label="Country Phone Code" />}
                                            onChange={(e, value) => {
                                                setParent2CountryPhoneCode(value);
                                            }}
                                            isOptionEqualToValue={(option, value) => option.code === value.code}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Parent Phone 2"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.parent_phone_2}
                                            name="parent_phone_2"
                                            error={!!touched.parent_phone_2 && !!errors.parent_phone_2}
                                            helperText={touched.parent_phone_2 && errors.parent_phone_2}
                                            sx={{ gridColumn: "span 2" }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Parent Email 2"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.parent_email_2}
                                            name="parent_email_2"
                                            error={!!touched.parent_email_2 && !!errors.parent_email_2}
                                            helperText={touched.parent_email_2 && errors.parent_email_2}
                                            sx={{ gridColumn: "span 4" }}
                                        />
                                    </Box>
                                </Box>
                                <Box
                                    padding={4}
                                    boxShadow={3}
                                    borderRadius={3}
                                    m={1}
                                    bgcolor={colors.primary[600]}
                                >
                                    <Typography
                                        variant="h4"
                                        fontWeight="bold"
                                        mb={1}
                                    >
                                        School Breaks <span style={{ fontWeight: 500, fontSize: "14px" }}>(Optional)</span>
                                    </Typography>
                                    <Typography variant="body1" mb={3} color={colors.grey[600]}>
                                        Please Type in the School Break Dates with the following format: YYYY/MM/DD-YYYY/MM/DD <br />
                                        (e.g. 2024/12/20-2025/01/05)
                                    </Typography>
                                    <Box
                                        display="grid"
                                        gap="30px"
                                        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                        sx={{
                                            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                                        }}
                                    >
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label={studentInfoLink.current_year + " School " + studentInfoLink.current_year_season + " Break Date"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.break_date_answer_1}
                                            name="break_date_answer_1"
                                            error={!!touched.break_date_answer_1 && !!errors.break_date_answer_1}
                                            helperText={touched.break_date_answer_1 && errors.break_date_answer_1}
                                            sx={{ gridColumn: "span 2" }}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label={studentInfoLink.next_year + " School " + studentInfoLink.next_year_season + " Break Date"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.break_date_answer_2}
                                            name="break_date_answer_2"
                                            error={!!touched.break_date_answer_2 && !!errors.break_date_answer_2}
                                            helperText={touched.break_date_answer_2 && errors.break_date_answer_2}
                                            sx={{ gridColumn: "span 2" }}
                                        />
                                    </Box>
                                </Box>
                                <Box display="flex" justifyContent="center" mt="20px" mb="100px">
                                    <Button 
                                        color="secondary" 
                                        variant="contained"
                                        onClick={() => setAreYouSureModalOpen(true)}
                                        sx={{ width: "200px" }}
                                    >
                                        Submit
                                    </Button>
                                    <Modal
                                        aria-labelledby="are-you-sure-modal-title"
                                        aria-describedby="are-you-sure-modal-description"
                                        open={areYouSureModalOpen}
                                        onClose={() => setAreYouSureModalOpen(false)}
                                        closeAfterTransition
                                        slots={{ backdrop: Backdrop }}
                                        slotProps={{
                                            backdrop: {
                                                timeout: 500,
                                            },
                                        }}
                                    >
                                        <Fade in={areYouSureModalOpen}>
                                            <Box sx={style}>
                                                <Typography variant="h4" color={colors.primary[450]}>
                                                    Are you sure you want to submit?
                                                </Typography>
                                                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", mt: 2 }}>
                                                    <Typography variant="body1" color={colors.primary[450]}>
                                                        Please double check all the information before submitting. <br />
                                                        <span style={{ color: "red" }}>If you don't fill out all required fields, it won't allow you to submit.</span> <br /><br />
                                                        <span style={{ fontWeight: "bold" }}>Once you submit, you will see a success message on a new page.</span>
                                                    </Typography>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        ml: "auto",
                                                        gap: 2,
                                                        mt: 2,
                                                    }}
                                                >
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        sx={{ width: 200, mt: 2 }}
                                                        type="submit"
                                                        form="student-card-form"
                                                        disabled={tinyLoading}
                                                    >
                                                        Yes
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        color="error"
                                                        sx={{ width: 200, mt: 2 }}
                                                        onClick={() => setAreYouSureModalOpen(false)}
                                                    >
                                                        No
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Fade>
                                    </Modal>
                                </Box>
                            </form>
                            )}
                        </Formik>
                        <Alert
                            successAlertOpen={successAlertOpen}
                            setSuccessAlertOpen={setSuccessAlertOpen}
                            errorAlertOpen={errorAlertOpen}
                            setErrorAlertOpen={setErrorAlertOpen}
                            successMsg="Student information has been successfully updated!"
                            errorMsg={errorAlertMessage}
                            vertical="bottom"
                            horizontal="left"
                        />
                    </Box>
                    <InstructionModal instructionModalOpen={instructionModalOpen} setInstructionModalOpen={setInstructionModalOpen} colors={colors} />
                </Box>
            )}
        </Box>
    )
}

const phoneRegExp =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
const gradeRegExp =
    /^([0-9]{1,2})$/;


const checkoutSchema = yup.object().shape({
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    korean_name: yup.string().optional(),
    phone: yup
        .string()
        .matches(phoneRegExp, "Phone number is not valid")
        .required(),
    email: yup.string().email("invalid email").required(),
    grade: yup
        .string()
        .matches(gradeRegExp, "Grade is not valid")
        .required(),
    school: yup.string().required(),
    parent_phone: yup
        .string()
        .matches(phoneRegExp, "Phone number is not valid")
        .required(),
    parent_phone_2: yup
        .string()
        .matches(phoneRegExp, "Phone number is not valid")
        .optional(),
    parent_email: yup.string().email("invalid email").required(),
    parent_email_2: yup.string().email("invalid email").optional(),
    street: yup.string().required(),
    city: yup.string().required(),
    state: yup.string().required(),
    zip: yup.string().optional(),
    country: yup.string().required(),
    break_date_answer_1: yup.string().optional(),
    break_date_answer_2: yup.string().optional(),
});

const initialValues = {
    first_name: "",
    last_name: "",
    korean_name: "",
    phone: "",
    email: "",
    grade: "",
    school: "",
    parent_phone: "",
    parent_phone_2: "",
    parent_email: "",
    parent_email_2: "",
    street: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    break_date_answer_1: "",
    break_date_answer_2: "",
};

export default StudentCards