import * as React from 'react';
import { tokens } from "../../theme";
import { useTheme, TextField } from "@mui/material";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Axios from 'axios';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Tooltip from '@mui/material/Tooltip';


var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone');

dayjs.extend(utc)
dayjs.extend(timezone)


const VacationModal = ({
    vacations,
    open,
    onClose,
    setUpdated,
    setSuccessAlertMessage,
    setSuccessAlertOpen,
    setErrorAlertMessage,
    setErrorAlertOpen,
}) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [vacationInfo, setVacationInfo] = React.useState({
        from: dayjs().day(1).tz("America/New_York"),
        to: dayjs().day(6).tz("America/New_York")
    });

    const [openAddVacationModal, setOpenAddVacationModal] = React.useState(false);
    const [openUpdateVacationModal, setOpenUpdateVacationModal] = React.useState(false);

    const handleOpenAddVacationModal = () => {
        setOpenAddVacationModal(true);
    };

    const handleCloseAddVacationModal = () => {
        setOpenAddVacationModal(false);
    };

    const handleOpenUpdateVacationModal = (event) => {
        setOpenUpdateVacationModal(true);
        setVacationInfo(event);
    };

    const handleCloseUpdateVacationModal = () => {
        setVacationInfo({ from: dayjs().tz("America/New_York"), to: dayjs().tz("America/New_York") });
        setOpenUpdateVacationModal(false);
    };

    const handleFromChange = (newValue) => {
        // Check if the selected day is a Monday
        if (dayjs(newValue).day() !== 1) {
            setErrorAlertMessage('The first day of vacation must be a Monday');
            setErrorAlertOpen(true);
            setVacationInfo({ ...vacationInfo, from: null });
        } else {
            // Set the selected Monday as the "from" date
            setVacationInfo({
                ...vacationInfo,
                from: dayjs(newValue).tz("America/New_York"),
                to: dayjs(newValue).day(6).tz("America/New_York")
            });
        }
    };

    const handleAddVacation = async () => {

        if (!vacationInfo.from) {
            setErrorAlertMessage('Please select a valid vacation range.');
            setErrorAlertOpen(true);
            return;
        }
        
        // check if the selected vacation is already in the list
        if (vacations.some(vacation => dayjs(vacation.from).isSame(vacationInfo.from, 'day'))) {
            setErrorAlertMessage('The selected vacation is already in the list.');
            setErrorAlertOpen(true);
            return;
        };

        await Axios.post(`${process.env.REACT_APP_URL}/api/v1/calendar-events/vacations`, {
            from: vacationInfo.from,
            to: vacationInfo.to,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setSuccessAlertMessage("Vacation has been added!");
            setSuccessAlertOpen(true);
            setUpdated(true);
            setVacationInfo({ from: dayjs().tz("America/New_York"), to: dayjs().tz("America/New_York") });
            handleCloseAddVacationModal();
        }).catch((error) => {
            console.log(error);
            setErrorAlertMessage(error.response.data.message);
            setErrorAlertOpen(true);
        });
    };

    const handleUpdateVacation = async () => {

        // check if the selected vacation is already in the list
        if (vacations.some(vacation => dayjs(vacation.from).isSame(vacationInfo.from, 'day'))) {
            setErrorAlertMessage('The selected vacation is already in the list.');
            setErrorAlertOpen(true);
            return;
        };

        await Axios.put(`${process.env.REACT_APP_URL}/api/v1/calendar-events/vacations/${vacationInfo.id}`, {
            from: vacationInfo.from,
            to: vacationInfo.to,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setSuccessAlertMessage("Vacation has been updated!");
            setSuccessAlertOpen(true);
            setUpdated(true);
            setVacationInfo({ from: dayjs().tz("America/New_York"), to: dayjs().tz("America/New_York") });
            handleCloseUpdateVacationModal();
        }).catch((error) => {
            console.log(error);
            setErrorAlertMessage(error.response.data.message);
            setErrorAlertOpen(true);
        });
    };

    const handleDeleteVacation = async (e, id) => {
        e.stopPropagation();

        await Axios.delete(`${process.env.REACT_APP_URL}/api/v1/calendar-events/vacations/${id}`, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setSuccessAlertMessage("Event has been deleted!");
            setSuccessAlertOpen(true);
            setUpdated(true);
        }).catch((error) => {
            console.log(error);
            setErrorAlertMessage(error.response.data.message);
            setErrorAlertOpen(true);
        });
    };        

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '30%',
        height: '30%',
        bgcolor: colors.primary[600],
        border: '2px solid grey',
        boxShadow: 24,
        p: 4,
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
    };

    const eventStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: colors.primary[600],
        border: '2px solid grey',
        boxShadow: 24,
        p: 4,
        borderRadius: '8px',
    };


    return (
        <>
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 2,
                        borderBottom: 1,
                    }}
                >
                    <Typography variant="h4">
                        Vacation Schedules
                    </Typography>

                    {/* Add Vacation Button */}
                    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>                    
                        <Tooltip title="Add Vacation" placement="top">
                            <IconButton
                                onClick={handleOpenAddVacationModal}
                                sx={{ borderRadius: '50%', bgcolor: "lightgrey", color: '#fff' }}
                            >
                                <AddIcon fontSize='small'/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>

                {/* Column Headers */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mb: 2,
                        fontWeight: 'bold',
                        textAlign: 'left',
                    }}
                >
                    <Box sx={{ width: '15%' }}>From</Box>
                    <Box sx={{ width: '15%' }}>To</Box>
                    <Box sx={{ width: '18%' }}>Actions</Box>
                </Box>

                {/* Vacation Schedules */}
                <Box
                    sx={{
                        overflowY: 'auto',
                        borderRadius: '4px',
                        border: `1px solid ${colors.primary[700]}`,                        
                    }}
                >
                    {vacations && vacations.length > 0 ? (
                        vacations.map((vacation) => (
                            <Box
                                key={vacation.id}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    borderBottom: `1px solid ${colors.primary[700]}`,
                                }}
                            >
                                <Box sx={{ width: '15%' }}>
                                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                                        {dayjs(vacation.from).tz("America/New_York").format('YYYY/MM/DD')}
                                    </Typography>
                                </Box>
                                <Box sx={{ width: '15%' }}>
                                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                                        {dayjs(vacation.to).tz("America/New_York").format('YYYY/MM/DD')}
                                    </Typography>
                                </Box>                                
                                <Box sx={{ width: '20%', display: 'flex' }}>
                                    <IconButton sx={{ color: 'primary.main' }} onClick={() => handleOpenUpdateVacationModal(vacation)}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton sx={{ color: 'error.main' }} onClick={(e) => handleDeleteVacation(e, vacation.id)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        ))
                    ) : (
                        <Typography variant="body2" align="center">
                            No vacation has been posted yet
                        </Typography>
                    )}
                </Box>
            </Box>
        </Modal>

        {/* Add Vacation Modal */}
        <Modal
            open={openAddVacationModal}
            onClose={handleCloseAddVacationModal}
            aria-labelledby="add-event-modal-title"
            aria-describedby="add-event-modal-description"
        >
            <Box sx={eventStyle}>
                <Typography variant="h6">
                    Add New Vacation
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', mt: 1 }}>
                    {/* Date Picker (From) */}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="From"
                            value={vacationInfo.from}
                            onChange={handleFromChange}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>

                    {/* Date Picker (To) */}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="To"
                            value={vacationInfo.to}
                            disabled // Disable direct selection for "To"
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </Box>
                <Button
                    fullWidth
                    variant="contained"
                    sx={{ mt: 2 }}
                    onClick={handleAddVacation}
                >
                    Save
                </Button>
            </Box>
        </Modal>

        {/* Update Vacation Modal */}
        <Modal
            open={openUpdateVacationModal}
            onClose={handleCloseUpdateVacationModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={eventStyle}>
                <Box sx={{ display: 'flex', flexDirection: 'row', mt: 1 }}>
                    
                    {/* Date Picker (From) */}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="From"
                            value={dayjs(vacationInfo.from)}
                            onChange={handleFromChange}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>

                    {/* Date Picker (To) */}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="To"
                            value={dayjs(vacationInfo.to)}
                            disabled // Disable direct selection for "To"
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </Box>
                <Button
                    fullWidth
                    variant="contained"
                    sx={{ mt: 2 }}
                    onClick={handleUpdateVacation}
                >
                    Update
                </Button>                                    
            </Box>
        </Modal>
        </>
    );
};

export default VacationModal;