import React from 'react'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import HomeIcon from '@mui/icons-material/Home';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import Face5OutlinedIcon from '@mui/icons-material/Face5Outlined';
import Face4OutlinedIcon from '@mui/icons-material/Face4Outlined';
import ThreePIcon from '@mui/icons-material/ThreeP';
import { Button } from '@mui/material';
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import Tooltip from '@mui/material/Tooltip';


export default function InfoTab({ student }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const season = {1: 'Spring', 2: 'Summer', 3: 'Fall', 4: 'Winter'};

    const sortSemesterHashMap = new Map();
    if (student.student_courses !== null && student.student_courses !== undefined) {
        student.student_courses.map((student_course) => {
            // 여기서 이미 title이 순서대로 안 나오니까, 여기서 start date로 sorting을 해줘야 함
            const sortSemesters = [...student_course.course.course_semesters].sort((a, b) => {                
                return dayjs(a.semester.start) - dayjs(b.semester.start);
            });
            return sortSemesterHashMap.set(student_course.course.id, sortSemesters);
        });
    }

    const courseDuration = (course) => {
        const sortSemesters = sortSemesterHashMap.get(course.id) || [];
        const start_year = sortSemesters[0]?.semester?.year || "";
        const start_season = season[sortSemesters[0]?.semester?.season] || "";
        const end_year = sortSemesters[sortSemesters?.length - 1]?.semester?.year || "";
        const end_season = season[sortSemesters[sortSemesters?.length - 1]?.semester?.season] || "";

        if (sortSemesters.length === 1)
            return `${start_year} ${start_season}`;
        else
            return `${start_year} ${start_season} - ${end_year} ${end_season}`;
    };

    return (
        <>
        <Grid container direction="row" alignItems="center" ml={1} mb={0.5}>
            <Grid item>
                <BubbleChartIcon />
            </Grid>
            <Grid item>
                <Typography component='span' mb={1} ml={1} variant='h3'> Student</Typography>
            </Grid>
        </Grid>
        <Grid container direction="row" alignItems="center" ml={3}>
            <Grid item>
                <Tooltip title="Email" placement="top" arrow>
                    <AlternateEmailIcon />
                </Tooltip>
            </Grid>
            <Grid item>
                <Typography component='span' mb={1} ml={1} variant='h5'> {student.email ? student.email : "N/A"}</Typography>
            </Grid>
        </Grid>
        <Grid container direction="row" alignItems="center" ml={3}>
            <Grid item>
                <Tooltip title="Phone" placement="top" arrow>
                    <PhoneIphoneIcon />
                </Tooltip>
            </Grid>
            <Grid item>
                <Typography component='span' mb={1} ml={1} variant='h5'>{student.phone ? student.phone : "N/A"}</Typography>
            </Grid>
        </Grid>
        {student.address === null || student.address === undefined ? null : (
            <Grid container direction="row" alignItems="center" ml={3}>
                <Grid item>
                    <Tooltip title="Address" placement="top" arrow>
                        <HomeIcon />
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Typography component='span' mb={1} ml={1} variant='h5'>
                        {student.address.street}, {student.address.city}, {student.address.state} {student.address.zip}, {student.address.country}
                    </Typography>
                </Grid>
            </Grid>
        )}
        <Grid container direction="row" alignItems="center" ml={3}>
            <Grid item>
                <Tooltip title="Remark (특이사항)" placement="top" arrow>
                    <ThreePIcon />
                </Tooltip>
            </Grid>
            <Grid item>
                <Typography component='span' mb={1} ml={1} variant='h5'>
                    {student.remark ? student.remark : "N/A"}
                </Typography>
            </Grid>
        </Grid>
        <br />
        <Grid container direction="row" alignItems="center" ml={1} mb={0.5}>
            <Grid item>
                <BubbleChartIcon />
            </Grid>
            <Grid item>
                <Typography component='span' mb={1} ml={1} variant='h3'> Parent</Typography>
            </Grid>
        </Grid>
        <Grid container direction="row" alignItems="center" ml={3}>
            <Grid item>
                <Tooltip title="Email" placement="top" arrow>
                    <AlternateEmailIcon />
                </Tooltip>
            </Grid>
            <Grid item>
                <Typography component='span' mb={1} ml={1} variant='h5'>{student.parent_email ? student.parent_email : "N/A"}</Typography>
            </Grid>
        </Grid>
        <Grid container direction="row" alignItems="center" ml={3}>
            <Grid item>
                <Tooltip title="Phone" placement="top" arrow>
                    <PhoneAndroidIcon />
                </Tooltip>
            </Grid>
            <Grid item>
                <Typography component='span' mb={1} ml={1} variant='h5'>{student.parent_phone ? student.parent_phone : "N/A"}</Typography>
            </Grid>
        </Grid>
        <Grid container direction="row" alignItems="center" ml={3}>
            <Grid item>
                <Tooltip title="Gender" placement="top" arrow>
                    {student.parent_gender === null || student.parent_gender === "" ? <Groups2OutlinedIcon /> 
                        : student.parent_gender === "Male" ? <Face5OutlinedIcon />
                        : <Face4OutlinedIcon />
                    }
                </Tooltip>
            </Grid>
            <Grid item>
                <Typography component='span' mb={1} ml={1} variant='h5'>
                    {student.parent_gender === null || student.parent_gender === "" ? "Gender not specified" : student.parent_gender}
                </Typography>
            </Grid>
        </Grid>
        <br />
        <Grid container direction="row" alignItems="center" ml={1} mb={0.5}>
            <Grid item>
                <BubbleChartIcon />
            </Grid>
            <Grid item>
                <Typography component='span' mb={1} ml={1} variant='h3'> Courses</Typography>
            </Grid>
        </Grid>
        {student.student_courses && student.student_courses.length > 0 ? (
            student.student_courses.map((element, index) => {
                if ((element.is_confirmed === false && element.course.is_potential_course === true)
                    || (element.course.is_potential_course === true))
                    return null;

                // if code starts with 'Z', it is for final exam, so we don't show it
                if (element.course.code && element.course.code.startsWith('Z-'))
                    return null;
                
                return (
                    <Grid key={index} container direction="row" alignItems="center" ml={3}>
                        <Grid item>
                            <ArrowRightIcon />
                        </Grid>
                        <Grid item>
                            <Typography component='a' mb={1} ml={1} variant='h5' href={`/classrooms/${element.course.uuid}/home`} target="_blank">
                                ({element.course.code ?? 'n/a'}) {element.course.course_title}, {courseDuration(element.course)} 
                                {/* {element.course.is_potential_course ? "(Confirmed)" : ""} */}
                            </Typography>
                        </Grid>
                    </Grid>
                );
            })) : (
                <Box sx={{ mb: "1rem" }}>
                    <Typography component='span' ml={1} variant='h5'>No courses yet.</Typography>
                </Box>
            )
        }
        <br />
        {!student.is_potential_student && 
            <Button
                type="submit"
                variant="contained"
                href={`/admin/students/${student.uuid}/class-logs`}
                style={{
                    backgroundColor: colors.primary[300],
                    color: "white",
                    width: "10rem",
                }}
            >
                Student Logs
            </Button>
        }
        </>
    )
}