import dayjs from 'dayjs';

export const getCourseDuration = ( course ) => {
    const season = {1: 'Spring', 2: 'Summer', 3: 'Fall', 4: 'Winter'};
    const sortSemesters = [...course.course_semesters].sort((a, b) => {
        return dayjs(a.semester.start) - dayjs(b.semester.start);
    });

    const start_year = sortSemesters[0].semester.year;
    const start_season = season[sortSemesters[0].semester.season];
    const end_year = sortSemesters[sortSemesters.length - 1].semester.year;
    const end_season = season[sortSemesters[sortSemesters.length - 1].semester.season];

    if (sortSemesters.length === 1)
        return `${start_year} ${start_season}`;
    else
        return `${start_year} ${start_season} - ${end_year} ${end_season}`;

};

export const getCourseYear = (course) => {
    const years = [...course.course_semesters].map((course_semester) => {
        console.log("course semester: ", course_semester)
        return course_semester.semester.year;
    });

    const uniqueYears = [...new Set(years)];
    return uniqueYears;
};

export const getCourseSemester = ( course, year ) => {
    const semesters = [];
    course.course_semesters.map((course_semester) => {
        if (course_semester.semester.year === year || year === 0)
            semesters.push(parseInt(course_semester.semester.season));

        return null;
    });

    return semesters;
};