import * as React from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import Axios from 'axios';
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Editor } from '@tinymce/tinymce-react';

const options = {
    height: 400,
    menubar: false,
    plugins: [
        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
        'anchor', 'searchreplace', 'visualblocks', 'fullscreen',
        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
    ],
    toolbar: [
        ' undo redo | blocks | bold italic forecolor backcolor fontsize | fontselect |' +
        'alignleft aligncenter alignright alignjustify | lineheight |' +
        'bullist numlist outdent indent | removeformat | help'
    ],
    font_formats: 'Arial=arial,helvetica,sans-serif;' +
                 'Arial Black=arial black,avant garde;' +
                 'Courier New=courier new,courier;' +
                 'Georgia=georgia,palatino;' +
                 'Tahoma=tahoma,arial,helvetica,sans-serif;' +
                 'Times New Roman=times new roman,times;' +
                 'Verdana=verdana,geneva;' +
                 'Impact=impact,chicago;' +
                 'Comic Sans MS=comic sans ms,sans-serif;' +
                 'Trebuchet MS=trebuchet ms,geneva;',
    htmlAllowedTags:  ['.*'],
    htmlAllowedAttrs: ['.*'],
    extended_valid_elements: '*[.*]',
    content_style: "p { margin: 0; }",
};

const categoryData = [
    "English",
    "Math",
    "Science",
    "Social",
    "Competition",
    "Test Prep",
    "Other",
]

const AddPrivatePotentialCourseOrOther = ({ 
    setSuccessAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertOpen,
    setErrorAlertMessage,
    selectedSemester,
    potentialCourseType,
    buttonName,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: 800,
        minHeight: 600,
        maxHeight: 900,
        overflowY: 'auto',
        bgcolor: colors.primary[600],
        border: '2px solid grey',
        boxShadow: 24,
        p: 4,
    };

    // for modal open and close
    const [addPotentialCourseModalOpen, setAddPotentialCourseModalOpen] = React.useState(false);
    const handleAddPotentialCourseModalClose = () => setAddPotentialCourseModalOpen(false);    

    // for course info
    const [course_title, setCourseTitle] = React.useState("");
    const [course_memo, setCourseMemo] = React.useState("");
    const [emailContent, setEmailContent] = React.useState('');
    const [category, setCategory] = React.useState("");

    async function handleAddPotentialCourse(e) {
        e.preventDefault();
        const code = "n/a";
        const subject = "n/a";

        if (potentialCourseType === undefined || potentialCourseType === null || selectedSemester === undefined || selectedSemester === null) {
            setErrorAlertOpen(true);
            setErrorAlertMessage("Something went wrong!");
            return;
        }

        if (course_title === "" || category === "") {
            setErrorAlertOpen(true);
            setErrorAlertMessage("Please fill course title and category!");
            return;
        }

        await Axios.post(`${process.env.REACT_APP_URL}/api/v1/potential-schedules/potential-courses`, {
            code,
            course_title,
            course_memo,
            subject,
            category,
            email_template: emailContent !== "" ? emailContent : null,
            semester_id: selectedSemester,
            potential_course_type: potentialCourseType,
            is_private_course: true,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setSuccessAlertOpen(true);
            setSuccessAlertMessage(response.data.message || "Potential course created successfully!");
            window.location.reload();
        }).catch((error) => {
            console.log(error);
            setErrorAlertOpen(true);
            setErrorAlertMessage("Error occurs while creating potential course!");
        });
    }

    return (<>
        <Button
            variant="contained"
            color="primary"
            sx={{
                width: 300,
            }}
            onClick={() => setAddPotentialCourseModalOpen(true)}
        >
            {buttonName}
        </Button>
        <Modal
            open={addPotentialCourseModalOpen}
            onClose={handleAddPotentialCourseModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Fade in={addPotentialCourseModalOpen}>
                <Box sx={style}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '1.5rem' }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '1rem',
                                alignItems: 'center',
                            }}
                        >
                            <TextField
                                fullWidth
                                label="Title"
                                variant="outlined"
                                value={course_title}
                                onChange={(event) => setCourseTitle(event.target.value)}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '1rem',
                                alignItems: 'center',
                            }}
                        >
                            <FormControl sx={{ m: 1, width: 300 }}>
                                <InputLabel id="demo-multiple-chip-label">
                                    Category
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={category}
                                    label="Category"
                                    onChange={(event) => setCategory(event.target.value)}
                                >
                                    {categoryData.map((category, index) => (
                                        <MenuItem key={index} value={category}>
                                            {category}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <TextField
                            fullWidth
                            multiline
                            minRows={3}
                            maxRows={10}
                            label="Memo (Optional)"
                            variant="outlined"
                            value={course_memo}
                            onChange={(event) => setCourseMemo(event.target.value)}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '1rem',
                            }}
                        >
                            <Typography
                                variant="h5"
                                fontWeight={700}
                            >
                                Email Template (Optional)
                            </Typography>
                            <Editor
                                apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                                init={options}
                                value={emailContent}
                                onEditorChange={
                                    (newValue, editor) => {
                                        setEmailContent(newValue);
                                    }
                                }
                            />
                            {emailContent !== "" && (<>
                                <h3 style={{ marginBottom: 0 }}>Preview</h3>
                                <div 
                                    dangerouslySetInnerHTML={{ __html: emailContent }} 
                                    style={{
                                        padding: "10px",
                                        backgroundColor: colors.primary[600],
                                    }}
                                />
                            </>)}
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '1rem',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                            }}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                    width: 100,
                                }}
                                onClick={(e) => handleAddPotentialCourse(e)}
                            >
                                Add
                            </Button>
                            <Button
                                variant="contained"
                                color="error"
                                sx={{
                                    width: 100,
                                }}
                                onClick={handleAddPotentialCourseModalClose}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    </>);
};

export default AddPrivatePotentialCourseOrOther