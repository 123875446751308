import React from 'react'
import { Box, useTheme, Typography } from "@mui/material";
import { tokens } from "../../../theme";
import AssignmentIcon from '@mui/icons-material/Assignment';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Popover from '@mui/material/Popover';
import MoreVertIcon from "../../../components/MoreVertIcon"
import UpdateModal from './UpdateModal';
import Chip from '@mui/material/Chip';
import DeleteDialog from '../../../components/DeleteDialog';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import CircleIcon from '@mui/icons-material/Circle';
import dayjs from 'dayjs';
import getStudentName from '../../../utils/getStudentName';

var options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: '2-digit',
    minute: '2-digit'
};

var options_without_time = {
    year: "numeric",
    month: "short",
    day: "numeric"
};

const TestBox = ({
    test,
    courseId,
    students,
    studentsStartEndDateMap,
    authorDisabledForAdminActions, 
    setBackdropOpen, 
    setSuccessAlertOpen, 
    setErrorAlertOpen,
    userRole,
    userId,
    setSuccessAlertMessage,
    setErrorAlertMessage,   
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);        

    // for popover
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleMoreVertIconClick = (event) => {
        if (userRole === "student")
            return;
        setAnchorEl(event.currentTarget);
    };
    const handlePopOverClose = () => {
        setAnchorEl(null);
    };
    const popoverOpen = Boolean(anchorEl);
    const popoverId = popoverOpen ? 'popover' : undefined;

    const testId = test.test_id;
    const [testTakerInfo, setTestTakerInfo] = React.useState(null);

    const handleDeleteTest = () => {
        setAnchorEl(null);
        setBackdropOpen(true);
        Axios.delete(`${process.env.REACT_APP_URL}/api/v1/courses/${courseId}/tests/${testId}`, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((res) => {
            setBackdropOpen(false);
            setSuccessAlertMessage("Test deleted successfully!");
            setSuccessAlertOpen(true);
            window.location.reload();
        }).catch((err) => {
            setBackdropOpen(false);
            setErrorAlertMessage(err.response.data.message);
            setErrorAlertOpen(true);
            console.log(err);
        });
    };

    // for due date logic
    const dueDateRender = () => {
        if (test.due_date === null) {
            // no due date assigned
            return (
                <Box></Box>
            );
        }
        else {
            // within due_date
            return (
                <Typography variant="h7" color="error" sx={{my: "auto", ml: "auto"}}>
                    <span style={{ fontWeight: "bold" }}>Due</span> {new Date(test.due_date).toLocaleDateString("en", options)}
                </Typography>
            )
        }
    };
    
    // test taker info
    React.useEffect(() => {
        if (students === null || students === undefined || students.length === 0 || userRole !== "student")
            return;

        async function getTestResultInfo() {
            // userId = student's uuid
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/courses/${courseId}/test-taking/${testId}/students/${userId}/student-answers`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((res) => {
                if (res.data === null || res.data === undefined || res.data.length === 0)
                    return;
                else 
                    setTestTakerInfo(res.data);        
            }).catch((err) => {
                console.log(err);
            });
        }
        getTestResultInfo();   
    }, [students]);
    // }, [testId, userId]);


    // for assigned students render logic
    const assignedStudentsRender = () => {
        if (students === null || students === undefined || students.length === 0)
            return <Box></Box>;

        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    flexWrap: "wrap",
                    width: "100%",
                    py: "0.5rem",
                    px: "1rem",
                    gap: "0.5rem",
                }}
            >
                {students !== null && students.length > 0 &&                
                    students.map((student, index) => {
                        let exclude_students = [];
                        let unexclude_students = [];

                        const end_date = dayjs(studentsStartEndDateMap[student.id].end_date);
                        const start_date = dayjs(studentsStartEndDateMap[student.id].start_date);
                        
                        if (dayjs().isAfter(end_date) || dayjs().isBefore(start_date)) {
                            return null;
                        }

                        if (test.exclude_student !== null) {
                            const exclude_student = test.exclude_student?.split('|');
                            exclude_students = exclude_student.map(Number);
                        }
                        if (exclude_students.includes(student.id))
                            return null;

                        if (test.unexclude_student !== null) {
                            const unexclude_student = test.unexclude_student?.split('|');
                            unexclude_students = unexclude_student.map(Number);
                        }
                        
                        let studentsWhoSubmitted = [];
                        let studentsWhoTaking = [];

                        if (test.student_test_answers !== null) {
                            test.student_test_answers.forEach((student_test_answer) => {

                                if (student_test_answer.is_completed === true) {
                                    studentsWhoSubmitted.push(student_test_answer.student_id);
                                }
                                else {
                                    studentsWhoTaking.push(student_test_answer.student_id);
                                }
                            });
                        }

                        let not_started_students = unexclude_students.filter((student_id) => !(studentsWhoSubmitted.includes(student_id) || studentsWhoTaking.includes(student_id)));
                        const student_name = getStudentName(student);

                        if (studentsWhoSubmitted.includes(student.id)) {
                            return (
                                <Chip key={index} color="secondary" label={student_name} sx={{ mr: "0.3rem", mb: "0.3rem" }} />
                            )
                        }
                        else if (studentsWhoTaking.includes(student.id)) {
                            return (
                                <Chip key={index} color="warning" label={student_name} sx={{ mr: "0.3rem", mb: "0.3rem" }} />
                            )
                        }
                        else if (not_started_students.includes(student.id)) {
                            return (
                                <Chip key={index} label={student_name} sx={{ mr: "0.3rem", mb: "0.3rem"}} />
                            )
                        }
                    })
                }
            </Box>
        );
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: colors.primary[600],
                borderRadius: "5px",
                border: "0.1px solid #a3a3a3",
                mb: "1.8rem",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    borderBottom: "0.1px solid #a3a3a3",
                    py: "0.5rem",
                    px: "1rem",
                    alignItems: "center",
                }}
            >
                <Avatar sx={{ width: 30, height: 30 }} >
                    <AssignmentIcon />
                </Avatar>
                <Typography variant="h5" sx={{my: "auto", ml: "1rem"}}>
                    {test.title}
                </Typography>
                {dueDateRender()}
                {userRole !== "student" &&
                    <>
                        <MoreVertIcon handleClick={handleMoreVertIconClick} />
                        <Popover
                            id={popoverId}
                            open={popoverOpen}
                            anchorEl={anchorEl}
                            onClose={handlePopOverClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <UpdateModal
                                courseId={courseId} 
                                test={test}
                                students={students}
                                userRole={userRole}
                                studentsStartEndDateMap={studentsStartEndDateMap} 
                                authorDisabledForAdminActions={authorDisabledForAdminActions}
                                setBackdropOpen={setBackdropOpen}
                                setSuccessAlertOpen={setSuccessAlertOpen}
                                setErrorAlertOpen={setErrorAlertOpen}
                                setSuccessAlertMessage={setSuccessAlertMessage}
                                setErrorAlertMessage={setErrorAlertMessage}
                            />
                            <DeleteDialog
                                handleDelete={handleDeleteTest}
                                setAnchorEl={setAnchorEl}
                                dialogTitle={"Are you sure you want to delete the test?"} 
                            />
                        </Popover>
                    </>
                }
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    borderBottom: "0.1px solid #a3a3a3",
                    py: "1rem",
                    px: "0.3rem",
                }}
            >
                <Typography variant="h7" color={colors.grey[700]} sx={{my: "auto", ml: "1rem"}}>
                    Posted on: {new Date(test.createdAt).toLocaleDateString("en", options_without_time)}
                </Typography>
                <Typography variant='h7' sx={{mt: "1rem", ml: "1rem"}}>
                    {test.content && test.content.split("\n").map((line, index) => {
                        return (
                            <span key={index}>
                                {line}
                                <br />
                            </span>
                        )
                    })}
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        mt: "1rem",
                        mx: "1rem"
                    }}
                >
                    <Box sx={{ display: "flex", flexDirection: "column" }}/>

                    {userRole !== "student" ? (
                        <Link
                            to={`/classrooms/${courseId}/test/${test.test_id}/submissions`}
                            style={{ color: "white", textDecoration: "none" }}
                        >
                            <Box mt="auto" display="flex" flexDirection="row">   
                                <Button
                                    type="button"
                                    variant="contained"
                                    style={{
                                        backgroundColor: userRole !== "student" ? colors.blueAccent[600] : testTakerInfo && testTakerInfo.is_completed === true ? "purple" : testTakerInfo ? colors.blueAccent[600] : "#51BE90",
                                        // backgroundColor: colors.primary[300],
                                        width: "11rem",
                                        fontSize: "9px",
                                    }}
                                >
                                    View the Test
                                </Button>
                            </Box>
                        </Link>                    
                    ) : !testTakerInfo || testTakerInfo.is_completed === false ? (
                        <Link
                            to={`/classrooms/${courseId}/test/${test.test_id}/submissions`}
                            style={{ color: "white", textDecoration: "none" }}
                        >
                            <Box mt="auto" display="flex" flexDirection="row">   
                                <Button
                                    type="button"
                                    variant="contained"
                                    style={{
                                        backgroundColor: testTakerInfo ? colors.blueAccent[600] : "#51BE90",
                                        width: "11rem",
                                        fontSize: "9px",
                                    }}
                                >
                                    {testTakerInfo ? "Resume" : "Take the Test"}
                                </Button>
                            </Box>
                        </Link>
                    ) : (
                        <Link
                             to={`/classrooms/${courseId}/test/${test.test_id}/student/${testTakerInfo.student_uuid}/submission`}
                             style={{ color: "white", textDecoration: "none" }}
                         >
                             <Box mt="auto" display="flex" flexDirection="row">   
                                 <Button
                                     type="button"
                                     variant="contained"
                                     style={{
                                         backgroundColor: "purple",
                                         width: "11rem",
                                         fontSize: "9px",
                                     }}
                                 >
                                     See the Result
                                 </Button>
                             </Box>
                         </Link>
                    )}
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    width: "100%",
                    py: "0.5rem",
                    px: "1rem",
                }}
            >
                <CircleIcon fontSize='10px' color='secondary' />
                <Typography variant='h9' sx={{ml: "0.3rem"}}>
                    Complete Test
                </Typography>
                <CircleIcon fontSize='10px' color='warning' sx={{ml: "1rem"}} />
                <Typography variant='h9' sx={{ml: "0.3rem"}}>
                    During Test
                </Typography>
                <CircleIcon fontSize='10px' sx={{ml: "1rem", color: colors.grey[950]}} />
                <Typography variant='h9' sx={{ml: "0.3rem"}}>
                    Not Start Yet
                </Typography>
            </Box>
            {assignedStudentsRender()}
        </Box>
    )
}

export default TestBox;