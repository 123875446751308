import React from 'react'
import { tokens } from "../../theme";
import { Box, IconButton, useTheme, Button } from "@mui/material";
import Loading from '../../components/Loading';
import Header from "../../components/Header";
import Axios from 'axios';
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import PotentialStudentsHorizView from './PotentialStudentsHorizView';
import Alert from '../../components/alert/Alert';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';

const ConsultationProgress = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // for 401 error
    const signOut = useSignOut();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut();
        localStorage.removeItem("userName");
        localStorage.removeItem("who");
        navigate("/signin");
    }

    // for alert
    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = React.useState("");
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = React.useState("");

    const [loading, setLoading] = React.useState(true);
    const [search, setSearch] = React.useState(''); // search by consultation number
    const [parentPhoneNumberSearch, setParentPhoneNumberSearch] = React.useState(''); // search by parent phone number
    const [emailSearch, setEmailSearch] = React.useState(''); // search by parent email
    const [studentEmailSearch, setStudentEmailSearch] = React.useState(''); // search by student email
    const [studentNameSearch, setStudentNameSearch] = React.useState(''); // search by student name
    const [studentPhoneNumberSearch, setStudentPhoneNumberSearch] = React.useState(''); // search by student phone number
    const [memoSearch, setMemoSearch] = React.useState(''); // search by memo

    const [studentUpdated, setStudentUpdated] = React.useState(false);
    const [students, setStudents] = React.useState([]);

    // store previous actions for horizontal view
    const [prevActions, setPrevActions] = React.useState([]);

    React.useEffect(() => {
        const consultationNumElement = document.getElementById('consultation_number');
        if (!loading && consultationNumElement) {
            consultationNumElement.focus();
        }
    }, [loading]);

    React.useEffect(() => {
        document.addEventListener('keydown', (e) => {
            if (e.key === 'Enter') {
                // find the element with the focus
                const focusedElement = document.activeElement;
                if (focusedElement.id === 'consultation_number') {
                    fetchStudents('enter', 'consultation_number', focusedElement.value);
                } else if (focusedElement.id === 'parent_phone_number') {
                    fetchStudents('enter', 'parent_phone_number', focusedElement.value);
                } else if (focusedElement.id === 'parent_email') {
                    fetchStudents('enter', 'parent_email', focusedElement.value);
                } else if (focusedElement.id === 'student_email') {
                    fetchStudents('enter', 'student_email', focusedElement.value);
                } else if (focusedElement.id === 'student_name') {
                    fetchStudents('enter', 'student_name', focusedElement.value?.toLowerCase());
                } else if (focusedElement.id === 'student_phone_number') {
                    fetchStudents('enter', 'student_phone_number', focusedElement.value);
                } else if (focusedElement.id === 'student_memo') {
                    fetchStudents('enter', 'student_memo', focusedElement.value);
                }
            }
        });

        return () => {
            document.removeEventListener('keydown', () => { });
        }
    }, []);

    React.useEffect(() => {
        localStorage.setItem('selectedMenu', '상담 진행 상황');

        // get searchparams
        const search = new URLSearchParams(window.location.search);
        const consultation_number = search.get('consultation_number');

        if (consultation_number) {
            fetchStudents('enter', 'consultation_number', consultation_number);
            setLoading(false);
            return;
        }

        const search_method = localStorage.getItem('cp_search_method');
        const search_value = localStorage.getItem('cp_search_value');

        if (search_method && search_value) {
            fetchStudents('enter', search_method, search_value);
        } else {
            fetchStudents('enter', 'num_of_people', 15);
        }

        setLoading(false);
    }, [studentUpdated]);

    function updatePotentialStudentsInfo(student_uuid, updatedInfo) {
        Axios.put(`${process.env.REACT_APP_URL}/api/v1/students/${student_uuid}`, {
            ...updatedInfo,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).catch((error) => {
            if (error.response.status === 401) {
                handleLogout();
                return;
            }
            alert(error.response.data.message);
        });

    }

    function updateInfoMail(student_uuid, info_mail_id, updatedInfo) {
        Axios.put(`${process.env.REACT_APP_URL}/api/v1/students/${student_uuid}/info-mails/${info_mail_id}`, {
            ...updatedInfo,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).catch((error) => {
            if (error.response.status === 401) {
                handleLogout();
                return;
            }
            alert(error.response.data.message);
        });

    }

    function updateMemo(student_uuid, memo_id, updatedInfo) {
        Axios.put(`${process.env.REACT_APP_URL}/api/v1/students/${student_uuid}/memos/${memo_id}`, {
            ...updatedInfo,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).catch((error) => {
            if (error.response.status === 401) {
                handleLogout();
                return;
            }
            alert(error.response.data.message);
        });
    }

    async function fetchStudents(method, param, value) {
        if (method === 'enter' && (value === '' || value === undefined || value === null)) {
            setErrorAlertMessage("Please enter a search value");
            setErrorAlertOpen(true);
            return;
        }
        if (method === 'click'
            && (search === '' || search === undefined || search === null)
            && (parentPhoneNumberSearch === '' || parentPhoneNumberSearch === undefined || parentPhoneNumberSearch === null)
            && (emailSearch === '' || emailSearch === undefined || emailSearch === null)
            && (studentEmailSearch === '' || studentEmailSearch === undefined || studentEmailSearch === null)
            && (studentNameSearch === '' || studentNameSearch === undefined || studentNameSearch === null)
            && (studentPhoneNumberSearch === '' || studentPhoneNumberSearch === undefined || studentPhoneNumberSearch === null)
            && (memoSearch === '' || memoSearch === undefined || memoSearch === null)) {
            setErrorAlertMessage("Please enter a search value");
            setErrorAlertOpen(true);
            return;
        }

        let search_method = '';
        let url = `${process.env.REACT_APP_URL}/api/v1/students/consultation-progress`;
        if (param === 'consultation_number') {
            url = `${process.env.REACT_APP_URL}/api/v1/students/consultation-progress?consultation_number=`;
            url += method === 'enter' ? value : search;
            search_method = 'consultation_number';
        } else if (param === 'parent_phone_number') {
            url = `${process.env.REACT_APP_URL}/api/v1/students/consultation-progress?parent_phone_number=`;
            url += method === 'enter' ? value : parentPhoneNumberSearch;
            search_method = 'parent_phone_number';
        } else if (param === 'parent_email') {
            url = `${process.env.REACT_APP_URL}/api/v1/students/consultation-progress?parent_email=`;
            url += method === 'enter' ? value : emailSearch;
            search_method = 'parent_email';
        } else if (param === 'student_email') {
            url = `${process.env.REACT_APP_URL}/api/v1/students/consultation-progress?student_email=`;
            url += method === 'enter' ? value : studentEmailSearch;
            search_method = 'student_email';
        } else if (param === 'student_phone_number') {
            url = `${process.env.REACT_APP_URL}/api/v1/students/consultation-progress?student_phone_number=`;
            url += method === 'enter' ? value : studentPhoneNumberSearch;
            search_method = 'student_phone_number';
        } else if (param === 'num_of_people') {
            url = `${process.env.REACT_APP_URL}/api/v1/students/consultation-progress?num_of_people=${value}`;
            search_method = 'num_of_people';
        } else if (param === 'student_name') {
            url = `${process.env.REACT_APP_URL}/api/v1/students/consultation-progress?student_name=`;
            url += method === 'enter' ? value : studentNameSearch?.toLowerCase();
            search_method = 'student_name';
        } else if (param === 'student_memo') {
            url = `${process.env.REACT_APP_URL}/api/v1/students/consultation-progress?student_memo=`;
            url += method === 'enter' ? value : memoSearch;
            search_method = 'student_memo';
        } else if (param === 'is_requested') {
            url = `${process.env.REACT_APP_URL}/api/v1/students/consultation-progress?is_requested=${value}`;
            search_method = 'is_requested';
        } else if (param === 'is_sent') {
            url = `${process.env.REACT_APP_URL}/api/v1/students/consultation-progress?is_sent=${value}`;
            search_method = 'is_sent';
        }

        // set a search method and value on a local storage
        localStorage.setItem('cp_search_method', search_method);
        localStorage.setItem('cp_search_value', method === 'enter' ? value : search);
        localStorage.setItem('cp_use_search', 'false');

        await Axios.get(url, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setStudents(response.data);
            // set searches to empty
            emptySearch();
        }).catch((error) => {
            if (error.response.status === 404) {
                setStudents([]);
                setErrorAlertMessage("No student found");
                setErrorAlertOpen(true);
                return;
            } else if (error.response.status === 401) {
                handleLogout();
                return;
            }
            setErrorAlertMessage(error.response.data.message || "Something went wrong");
            setErrorAlertOpen(true);
        });
        setStudentUpdated(false);
    }

    function emptySearch() {
        setSearch('');
        setParentPhoneNumberSearch('');
        setEmailSearch('');
        setStudentEmailSearch('');
        setStudentNameSearch('');

        const consultationNumElement = document.getElementById('consultation_number');
        const parentPhoneNumElement = document.getElementById('parent_phone_number');
        const parentEmailElement = document.getElementById('parent_email');
        const studentEmailElement = document.getElementById('student_email');
        const studentNameElement = document.getElementById('student_name');
        if (consultationNumElement) {
            consultationNumElement.value = '';
        }
        if (parentPhoneNumElement) {
            parentPhoneNumElement.value = '';
        }
        if (parentEmailElement) {
            parentEmailElement.value = '';
        }
        if (studentEmailElement) {
            studentEmailElement.value = '';
        }
        if (studentNameElement) {
            studentNameElement.value = '';
        }
    }

    return (
        <Box
            m="20px"
            marginX={3}
            marginY={3}
            sx={{
                minHeight: "88%",
            }}
        >
            {/* <Header title="Potential Students" subtitle="A ONE Institute" /> */}
            {loading ?
                <Loading />
                : <>
                    <Box
                        display="flex"
                        justifyContent="left"
                        gap={1}
                        p={0}
                        minHeight={70}
                        mb={2}
                        flexWrap={'wrap'}
                    >
                        <Box
                            display="flex"
                            backgroundColor={colors.primary[600]}
                            borderRadius="3px"
                            boxShadow={10}
                            mb={1}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => fetchStudents('loading', 'num_of_people', 15)}
                            >
                                Show First 15
                            </Button>
                        </Box>
                        {/* SEARCH BAR */}
                        <Box
                            display="flex"
                            backgroundColor={colors.primary[600]}
                            borderRadius="3px"
                            boxShadow={10}
                            mb={1}
                            maxWidth={240}
                            minWidth={240}
                        >
                            <InputBase
                                sx={{ ml: 2, flex: 1 }}
                                onChange={(e) => setSearch(e.target.value)}
                                placeholder="Search by consultation number"
                                id='consultation_number'
                            />
                            <IconButton
                                type="button"
                                sx={{ p: 1 }}
                                onClick={() => fetchStudents('click', 'consultation_number')}
                            >
                                <SearchIcon />
                            </IconButton>
                        </Box>

                        <Box
                            display="flex"
                            backgroundColor={colors.primary[600]}
                            borderRadius="3px"
                            boxShadow={10}
                            mb={1}
                            maxWidth={240}
                            minWidth={240}
                        >
                            <InputBase
                                sx={{ ml: 2, flex: 1 }}
                                onChange={(e) => setParentPhoneNumberSearch(e.target.value)}
                                placeholder="Search by parent phone"
                                id='parent_phone_number'
                            />
                            <IconButton
                                type="button"
                                sx={{ p: 1 }}
                                onClick={() => fetchStudents('click', 'parent_phone_number')}
                            >
                                <SearchIcon />
                            </IconButton>
                        </Box>

                        <Box
                            display="flex"
                            backgroundColor={colors.primary[600]}
                            borderRadius="3px"
                            boxShadow={10}
                            mb={1}
                            maxWidth={240}
                            minWidth={240}
                        >
                            <InputBase
                                sx={{ ml: 2, flex: 1 }}
                                onChange={(e) => setEmailSearch(e.target.value)}
                                placeholder="Search by parent email"
                                id='parent_email'
                            />
                            <IconButton
                                type="button"
                                sx={{ p: 1 }}
                                onClick={() => fetchStudents('click', 'parent_email')}
                            >
                                <SearchIcon />
                            </IconButton>
                        </Box>
                        <Box
                            display="flex"
                            backgroundColor={colors.primary[600]}
                            borderRadius="3px"
                            boxShadow={10}
                            mb={1}
                            maxWidth={240}
                            minWidth={240}
                        >
                            <InputBase
                                sx={{ ml: 2, flex: 1 }}
                                onChange={(e) => setStudentEmailSearch(e.target.value)}
                                placeholder="Search by student email"
                                id='student_email'
                            />
                            <IconButton
                                type="button"
                                sx={{ p: 1 }}
                                onClick={() => fetchStudents('click', 'student_email')}
                            >
                                <SearchIcon />
                            </IconButton>
                        </Box>
                        <Box
                            display="flex"
                            backgroundColor={colors.primary[600]}
                            borderRadius="3px"
                            boxShadow={10}
                            mb={1}
                            maxWidth={240}
                            minWidth={240}
                        >
                            <InputBase
                                sx={{ ml: 2, flex: 1 }}
                                onChange={(e) => setStudentNameSearch(e.target.value)}
                                placeholder="Search by student name"
                                id='student_name'
                            />
                            <IconButton
                                type="button"
                                sx={{ p: 1 }}
                                onClick={() => fetchStudents('click', 'student_name')}
                            >
                                <SearchIcon />
                            </IconButton>
                        </Box>

                        <Box
                            display="flex"
                            backgroundColor={colors.primary[600]}
                            borderRadius="3px"
                            boxShadow={10}
                            mb={1}
                            maxWidth={240}
                            minWidth={240}
                        >
                            <InputBase
                                sx={{ ml: 2, flex: 1 }}
                                onChange={(e) => setStudentPhoneNumberSearch(e.target.value)}
                                placeholder="Search by student phone"
                                id='student_phone_number'
                            />
                            <IconButton
                                type="button"
                                sx={{ p: 1 }}
                                onClick={() => fetchStudents('click', 'student_phone_number')}
                            >
                                <SearchIcon />
                            </IconButton>
                        </Box>

                        <Box
                            display="flex"
                            backgroundColor={colors.primary[600]}
                            borderRadius="3px"
                            boxShadow={10}
                            mb={1}
                            maxWidth={240}
                            minWidth={240}
                        >
                            <InputBase
                                sx={{ ml: 2, flex: 1 }}
                                onChange={(e) => setMemoSearch(e.target.value)}
                                placeholder="Search by memo"
                                id='student_memo'
                            />
                            <IconButton
                                type="button"
                                sx={{ p: 1 }}
                                onClick={() => fetchStudents('click', 'student_memo')}
                            >
                                <SearchIcon />
                            </IconButton>
                        </Box>
                        <Box
                            display="flex"
                            backgroundColor={colors.primary[600]}
                            borderRadius="3px"
                            boxShadow={10}
                            mb={1}
                        >
                            <Button
                                variant="contained"
                                sx={{ backgroundColor: '#ffffff', '&:hover': { backgroundColor: '#ffffff' } }}
                                onClick={() => fetchStudents('loading', 'is_requested', true)}
                            >
                                <CancelScheduleSendIcon fontSize='small' color='warning' sx={{ cursor: "pointer" }} />
                            </Button>
                        </Box>
                        <Box
                            display="flex"
                            backgroundColor={colors.primary[600]}
                            borderRadius="3px"
                            boxShadow={10}
                            mb={1}
                        >
                            <Button
                                variant="contained"
                                sx={{ backgroundColor: '#ffffff', '&:hover': { backgroundColor: '#ffffff' } }}
                                onClick={() => fetchStudents('loading', 'is_sent', false)}
                            >
                                <CancelScheduleSendIcon fontSize='small' color='error' sx={{ cursor: "pointer" }} />
                            </Button>
                        </Box>
                    </Box>
                    <PotentialStudentsHorizView
                        students={students}
                        search={search}
                        emailSearch={emailSearch}
                        setStudentUpdated={setStudentUpdated}
                        setPrevActions={setPrevActions}
                        prevActions={prevActions}
                        updatePotentialStudentsInfo={updatePotentialStudentsInfo}
                        updateInfoMail={updateInfoMail}
                        updateMemo={updateMemo}
                    />
                </>}
            <Alert
                successAlertOpen={successAlertOpen}
                setSuccessAlertOpen={setSuccessAlertOpen}
                errorAlertOpen={errorAlertOpen}
                setErrorAlertOpen={setErrorAlertOpen}
                successMsg={successAlertMessage}
                errorMsg={errorAlertMessage}
                vertical='bottom'
                horizontal='left'
            />
        </Box>
    )
}

export default ConsultationProgress