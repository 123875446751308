import * as React from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import Axios from 'axios';
import Alert from '../../components/alert/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTime, Settings } from 'luxon';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

Settings.defaultZone = "system";

const AddCourse = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // for 401 error
    const signOut = useSignOut();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut();
        localStorage.removeItem("userName");
        localStorage.removeItem("who");
        navigate("/signin");
    }

    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = React.useState("");
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = React.useState("");
    const isNonMobile = useMediaQuery("(min-width:600px)");
    // const matches = useMediaQuery('(max-width:1600px)');

    const seasons = ['Spring', 'Summer', 'Fall', 'Winter'];
    const [semesters, setSemesters] = React.useState(null);
    const [semesterForCourse, setSemesterForCourse] = React.useState(null);
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);

    const [isPrivateCourse, setIsPrivateCourse] = React.useState(null);

    // for days of week for couese schedule
    const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    // const daysValue = {'Moday' : 1, 'Tuesday' : 2, 'Wednesday' : 3, 'Thursday' : 4, 'Friday' : 5, 'Saturday' : 6}
    const [days, setDays] = React.useState(null);

    // for time schedule in each day of class session
    const [fromValue, setFromValue] = React.useState(DateTime.now());
    const [toValue, setToValue] = React.useState(DateTime.now());

    // const reinitiailize = () => {
    //     setSemesterForCourse(null);
    //     setDays(null);
    //     setFromValue(DateTime.now());
    //     setToValue(DateTime.now());
    // };
    
    const handleFormSubmit = async (values) => {

        if (isPrivateCourse === null) {
            setErrorAlertMessage("Please select course type!");
            setErrorAlertOpen(true);
            return;
        }

        if (semesterForCourse === null || semesterForCourse.length === 0) {
            setErrorAlertMessage("Please select at least one semester for this course!");
            setErrorAlertOpen(true);
            return;
        }

        const semesterIdList = semesterForCourse !== null ? semesterForCourse.map((semester) => semester.id) : [];
        let courseUUID = null;

        if (startDate === null || endDate === null) {
            setErrorAlertMessage("Please select start and end date for this course!");
            setErrorAlertOpen(true);
            return;
        }

        if (startDate > endDate) {
            setErrorAlertMessage("Start date cannot be greater than end date!");
            setErrorAlertOpen(true);
            return;
        }

        const utcStartDate = startDate.toUTC();
        const utcEndDate = endDate.toUTC();

        if (days === null || days.length === 0) {
            setErrorAlertMessage("Please select at least one day for this course!");
            setErrorAlertOpen(true);
            return;
        }

        if (fromValue === null || toValue === null) {
            setErrorAlertMessage("Please select start and end time for this course!");
            setErrorAlertOpen(true);
            return;
        }

        // check if start and end time are valid
        if (fromValue > toValue) {
            setErrorAlertMessage("\"From time\" cannot be greater than or equal to \"To time\"!");
            setErrorAlertOpen(true);
            return;
        }


        // generate class schedule for the course using the days, fromValue, toValue, startDate, endDate
        // loop through from startDate to endDate and check if the day is in the days list
        const courseSchedule = [];

        for (let date = startDate; date <= endDate; date = date.plus({days: 1})) {
            if (days.includes(date.weekdayLong.toLowerCase())) {
                const sessionTime = {
                    start: date.set({hour: fromValue.hour, minute: fromValue.minute, second: 0}),
                    end: date.set({hour: toValue.hour, minute: toValue.minute, second: 0}),
                };

                courseSchedule.push(sessionTime);
            }
        }

        await Axios.post(`${process.env.REACT_APP_URL}/api/v1/courses`, {
            code: values.code ? values.code.trim() : null,
            course_title: values.course_title.trim(),
            subject: values.subject.trim(),
            textbook: values.textbook ? values.textbook.trim() : null,
            textbook_total_page: values.textbook_total_page ? values.textbook_total_page : 0,
            price: values.price ? values.price : null,
            semesterIdList: semesterIdList,
            start_date: utcStartDate,
            end_date: utcEndDate,
            allDay: false,
            courseSchedule: courseSchedule,
            is_private_course: isPrivateCourse,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setSuccessAlertMessage("Course created successfully!");
            setSuccessAlertOpen(true);
            // reinitiailize();
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }).catch((error) => {
            console.log(error.response.data);
            setErrorAlertMessage(error.response.data.message || error.response.data.error || "Failed to create the course!");
            setErrorAlertOpen(true);
        });
    };

    React.useEffect(() => {
        localStorage.setItem('selectedMenu', 'Add Course');

        async function fetchSemesters() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/semesters`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                    setSemesters(response.data);
                }).catch((error) => {
                    if (error.response.status === 404) {
                        setSemesters([]);
                        return;
                    } else if (error.response.status === 401) {
                        handleLogout();
                        return;
                    }
                    console.log(error.response.data.message);
                    setErrorAlertOpen(true);
                    setErrorAlertMessage(error.response.data.message);
                });
        };

        fetchSemesters();
    }, []);

    return (
        <Box m="20px" marginX={3} marginY={3} maxWidth={"70rem"}>
            <Header title="Add Course" subtitle="A ONE Institute" />
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                }) => (
                <form onSubmit={handleSubmit}>
                    <Box
                        padding={4}
                        boxShadow={3}
                        borderRadius={3}
                        m={1}
                        bgcolor={colors.primary[600]}
                    >
                        <Typography
                            variant="h4"
                            fontWeight="bold"
                            mb={3}
                        >
                            Course Information
                        </Typography>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                        >
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Course Code"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.code}
                                name="code"
                                error={!!touched.code && !!errors.code}
                                helperText={touched.code && errors.code}
                                sx={{ gridColumn: "span 1" }}
                            />
                            <FormControl>
                                <InputLabel id="demo-simple-select-label">
                                    Course Type
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={isPrivateCourse}
                                    label="Course Type"
                                    onChange={(event) => setIsPrivateCourse(event.target.value)}
                                >
                                    <MenuItem value={null}>None</MenuItem>
                                    <MenuItem value={true}>Private</MenuItem>
                                    <MenuItem value={false}>Group</MenuItem>
                                </Select>
                            </FormControl>
                            <Box sx={{ gridColumn: "span 2" }}></Box>
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Course Title"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.course_title}
                                name="course_title"
                                error={!!touched.course_title && !!errors.course_title}
                                helperText={touched.course_title && errors.course_title}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Subject"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.subject}
                                name="subject"
                                error={!!touched.subject && !!errors.subject}
                                helperText={touched.subject && errors.subject}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Textbook"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.textbook}
                                name="textbook"
                                error={!!touched.textbook && !!errors.textbook}
                                helperText={touched.textbook && errors.textbook}
                                sx={{ gridColumn: "span 1" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="number"
                                label="Total Pages of Textbook"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.textbook_total_page}
                                name="textbook_total_page"
                                error={!!touched.textbook_total_page && !!errors.textbook_total_page}
                                helperText={touched.textbook_total_page && errors.textbook_total_page}
                                sx={{ gridColumn: "span 1" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Price"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.price}
                                name="price"
                                error={!!touched.price && !!errors.price}
                                helperText={touched.price && errors.price}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <Autocomplete
                                multiple
                                id="tags-outlined-1"
                                options={semesters !== null ? semesters : []}
                                getOptionLabel={(semester) => semester.year + " " + seasons[semester.season - 1]}
                                filterSelectedOptions
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Semester"
                                        placeholder="Select Semester"
                                    />
                                )}
                                sx={{ gridColumn: "span 2" }}
                                onChange={(event, value) => setSemesterForCourse(value)}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                            />

                            <LocalizationProvider dateAdapter={AdapterLuxon}>
                                <DatePicker
                                    label="Start Date"
                                    value={startDate}
                                    onChange={(newValue) => {
                                        const startOfDate = newValue.startOf('day');
                                        setStartDate(startOfDate);
                                    }}
                                    sx={{ gridColumn: "span 1" }}
                                />
                                <DatePicker
                                    label="End Date"
                                    value={endDate}
                                    onChange={(newValue) => {
                                        const endOfDate = newValue.endOf('day');
                                        setEndDate(endOfDate);
                                    }}
                                    sx={{ gridColumn: "span 1" }}
                                />
                            </LocalizationProvider>

                            <Autocomplete
                                multiple
                                id="tags-outlined-1"
                                options={daysOfWeek}
                                getOptionLabel={(day) => day}
                                filterSelectedOptions
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Days"
                                        placeholder="Select days if you also want to create schedules for this course"
                                    />
                                )}
                                sx={{ gridColumn: "span 2" }}
                                onChange={(event, value) => setDays(value)}
                            />
                            {days !== null && days.length > 0 && (
                                <LocalizationProvider dateAdapter={AdapterLuxon}>
                                    <Box sx={{ gridColumn: "span 2" }}></Box>
                                    <TimePicker
                                        label="From"
                                        value={fromValue}
                                        onChange={(newValue) => setFromValue(newValue)}
                                        format="HH:mm"
                                        sx={{ gridColumn: "span 1" }}
                                    />
                                    <TimePicker
                                        label="To"
                                        value={toValue}
                                        onChange={(newValue) => setToValue(newValue)}
                                        format="HH:mm"
                                        sx={{ gridColumn: "span 1" }}
                                    />
                                </LocalizationProvider>
                            )}
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="start" mt="20px">
                        <Button type="submit" color="secondary" variant="contained">
                            Create New Course
                        </Button>
                    </Box>
                </form>
                )}
            </Formik>
            <Alert
                successAlertOpen={successAlertOpen}
                setSuccessAlertOpen={setSuccessAlertOpen}
                errorAlertOpen={errorAlertOpen}
                setErrorAlertOpen={setErrorAlertOpen}
                successMsg={successAlertMessage}
                errorMsg={errorAlertMessage}
                vertical="bottom"
                horizontal="left"
            />
        </Box>
    );
};

const checkoutSchema = yup.object().shape({
    code: yup.string().required(),
    course_title: yup.string().required("required"),
    subject: yup.string().required("required"),
    textbook: yup.string().optional(),
    textbook_total_page: yup.number().optional(),
    price: yup.number().optional(),
});

const initialValues = {
    code: "",
    course_title: "",
    subject: "",
    textbook: "",
    textbook_total_page: 0,
    price: "",
};

export default AddCourse;