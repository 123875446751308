import React from 'react';
import Button from '@mui/material/Button';
import { Box, TextField, Typography } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CloseIcon from '@mui/icons-material/Close';
// import AreYouSureModal from '../../../components/AreYouSureModal';

function ModuleTotalNumButton({
    course,
    colors, 
    handlePopOverClose,
    enqueueSnackbar,
    socketConnected,
    socket,
}) {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: colors.primary[600],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    // for modal
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => { setOpen(false); handlePopOverClose(); };

    // const [areYouSureModalOpen, setAreYouSureModalOpen] = React.useState(false);

    const [totalNumber, setTotalNumber] = React.useState(course.private_total_classes ? course.private_total_classes : 0);

    const handleOnChange = (e) => {
        const newValue = e.target.value;
        setTotalNumber(newValue);
    };

    React.useEffect(() => {
        if (course && course.private_total_classes) {
            setTotalNumber(course.private_total_classes);
        }
    }, [course]);

    const handleSave = async () => {
        if (!socketConnected) {
            enqueueSnackbar("Socket is not connected. Please refresh the page.", { variant: "error" });
            return;
        }

        if (isNaN(totalNumber)) {
            enqueueSnackbar("Total Number of Class Sessions must be a number.", { variant: "error" });
            return;
        }

        if (totalNumber < 0) {
            enqueueSnackbar("Total Number of Class Sessions cannot be less than 0.", { variant: "error" });
            return;
        }

        try {
            // emit to server
            socket.emit("put.TotalNumberOfClassSessions", {
                course_id: course.id,
                total_number: totalNumber,
            });
        } catch (error) {
            console.error(error);
            enqueueSnackbar("Error: Cannot save Total Number of Class Sessions at the moment.", { variant: "error" });
            return;
        }

        handleClose();
    };

    return (
        <>
            <Button 
                onClick={handleOpen} 
                sx={{ 
                    p: 1, 
                    width: "100%",
                    bgcolor: colors.blueAccent[900],
                    borderRadius: "0px",
                    '&:hover': {
                        bgcolor: colors.blueAccent[800],
                    },
                }}
            >
                Total class session count
            </Button>
            <Modal
                aria-labelledby="one-drive-setup-modal"
                aria-describedby="one-drive-setup-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography variant="h4" color={colors.primary[450]}>
                            Set up Total Number of Class Sessions
                        </Typography>
                        <CloseIcon sx={{ position: "absolute", right: 10, top: 10, cursor: "pointer" }} onClick={handleClose} />
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", mt: 2 }}>
                            <TextField
                                type='number'
                                label="Total Number of Class Sessions"
                                variant="outlined"
                                value={totalNumber}
                                onChange={(e) => handleOnChange(e)}
                                sx={{ width: 600, mt: 2 }}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: 2,
                                mt: 2,
                            }}
                        >
                            <Button
                                variant="contained"
                                color="secondary"
                                sx={{ width: 200, mt: 2 }}
                                onClick={() => handleSave()}
                            >
                                Save
                            </Button>
                            {/* <AreYouSureModal
                                colors={colors}
                                open={areYouSureModalOpen}
                                setOpen={setAreYouSureModalOpen}
                                title="Are you sure?"
                                message="Are you sure you want to save the changes?"
                                onConfirm={handleSave}
                            /> */}
                            <Button
                                variant="contained"
                                color="error"
                                sx={{ width: 200, mt: 2 }}
                                onClick={() => handleClose()}
                            >
                                Close
                            </Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </>
    )
}

export default ModuleTotalNumButton