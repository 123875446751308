import React from 'react'
import Button from '@mui/material/Button';
import { Box, TextField, Typography } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CloseIcon from '@mui/icons-material/Close';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
// import AreYouSureModal from '../../../components/AreYouSureModal';

const ModuleCancelButton = ({ 
    userId,
    zoom_id,
    date,
    _time,
    event_idx,
    event,
    colors, 
    handlePopOverClose,
    enqueueSnackbar,
    socketConnected,
    socket,
}) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: colors.primary[600],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    // for modal
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => { setOpen(false); handlePopOverClose(); };

    // const [areYouSureModalOpen, setAreYouSureModalOpen] = React.useState(false);

    const [note, setNote] = React.useState("");
    const [initialIsCanceled, setInitialIsCanceled] = React.useState(event.is_canceled_int || 0);
    const [isCanceled, setIsCanceled] = React.useState(event.is_canceled_int || 0);

    const handleIsCanceledChange = (event, cancel_num) => {
        if (cancel_num === isCanceled) {
            setIsCanceled(0);
            return;
        }

        setIsCanceled(cancel_num);
    };

    React.useEffect(() => {
        if (event) {
            setIsCanceled(event.is_canceled_int || 0);
        }
    }, [event]);

    const handleSave = async () => {
        if (!event) {
            enqueueSnackbar("Error: unexpected error occurred. Please refresh the page.", { variant: "error" });
            return;
        }

        if (!socketConnected) {
            enqueueSnackbar("Socket is not connected. Please refresh the page.", { variant: "error" });
            return;
        }

        if (initialIsCanceled === isCanceled) {
            handleClose();
            return;
        }

        try {
            setTimeout(() => {
                // emit to server
                socket.emit("put.EventCancel", {
                    admin_uuid: userId,
                    zoom_id: zoom_id,
                    date: date,
                    _time: _time,
                    event_idx: event_idx,
                    event: event,
                    is_canceled_int: isCanceled,
                    note: note,
                });
            }, 300);
        } catch (error) {
            console.error(error);
            enqueueSnackbar("Error: Cannot save the changes. Please try again.", { variant: "error" });
            return;
        }

        handleClose();
    };

    return (
        <>
            <Button 
                onClick={handleOpen} 
                sx={{ 
                    p: 1, 
                    width: "100%",
                    bgcolor: colors.redAccent[700],
                    borderRadius: "0px",
                    '&:hover': {
                        bgcolor: colors.redAccent[600],
                    },
                }}
            >
                Cancel Class
            </Button>
            <Modal
                aria-labelledby="one-drive-setup-modal"
                aria-describedby="one-drive-setup-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography variant="h4" color={colors.primary[450]}>
                            Cancel Class
                        </Typography>
                        <CloseIcon sx={{ position: "absolute", right: 10, top: 10, cursor: "pointer" }} onClick={handleClose} />
                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", mb: -2 }}>
                            {event.course && event.course.is_private_course ? (
                                <Typography variant="h6" color={colors.primary[450]}>
                                    Student Cancel: {event.course.num_of_student_cancel} / Teacher Cancel: {event.course.num_of_teacher_cancel}
                                </Typography>
                            ) : (
                                <Typography variant="h6" color={colors.primary[450]}>
                                    Teacher Cancel: {event.course.num_of_teacher_cancel}
                                </Typography>
                            )}
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", mt: 2 }}>
                            <FormGroup>
                                {event.course.is_private_course && (
                                    <FormControlLabel 
                                        control={
                                            <Checkbox
                                                checked={isCanceled === 1}
                                                onChange={(e) => handleIsCanceledChange(e, 1)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        } 
                                        label="Canceled by a student" 
                                    />
                                )}
                                <FormControlLabel 
                                    control={
                                        <Checkbox
                                            checked={isCanceled === 2}
                                            onChange={(e) => handleIsCanceledChange(e, 2)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    } 
                                    label="Canceled by a teacher"
                                />
                                <FormControlLabel 
                                    control={
                                        <Checkbox
                                            checked={isCanceled === 3}
                                            onChange={(e) => handleIsCanceledChange(e, 3)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    } 
                                    label="Canceled by other reasons"
                                />
                            </FormGroup>
                            <TextField
                                label="Note"
                                multiline
                                rows={5}
                                variant="outlined"
                                value={note}
                                onChange={(e) => setNote(e.target.value)}
                                sx={{ width: 400, mt: 2 }}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: 2,
                                mt: 2,
                            }}
                        >
                            <Button
                                variant="contained"
                                color="secondary"
                                sx={{ width: 200, mt: 2 }}
                                onClick={() => handleSave()}
                            >
                                Save
                            </Button>
                            {/* <AreYouSureModal
                                colors={colors}
                                open={areYouSureModalOpen}
                                setOpen={setAreYouSureModalOpen}
                                title="Are you sure?"
                                message="Are you sure you want to save the changes?"
                                onConfirm={handleSave}
                            /> */}
                            <Button
                                variant="contained"
                                color="error"
                                sx={{ width: 200, mt: 2 }}
                                onClick={() => handleClose()}
                            >
                                Close
                            </Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </>
    )
}

export default ModuleCancelButton