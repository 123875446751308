import React from 'react'
import Chip from '@mui/material/Chip';
import FaceIcon from '@mui/icons-material/Face';
import IndividualSchedule from './IndividualSchedule';

const TeacherChip = ({ teacher, time, isDarkMode }) => {
    const [openModal, setModalOpen] = React.useState(false);
    const handleAvailabilityOpen = () => setModalOpen(true);
    const handleAvailabilityClose = () => setModalOpen(false);
    
    return (<>
        <Chip
            icon={<FaceIcon />} 
            label={teacher.first_name + " " + teacher.last_name}
            onClick={handleAvailabilityOpen}
            size='small'
            sx={{backgroundColor: isDarkMode ? '#28292c' : 'lightgrey', zIndex: 1, fontWeight: 'bold', marginBottom: '4px'}}
        />
        <IndividualSchedule userId={teacher.uuid} teacher={teacher} open={openModal} handleClose={handleAvailabilityClose} time={time} isDarkMode={isDarkMode}/>
    </>)
};

export default TeacherChip;