import React from 'react';
import { tokens } from "../../theme";
import { Box, useTheme, Typography, Button, TextField } from "@mui/material";
import Loading from '../../components/Loading';
import Header from "../../components/Header";
import Axios from 'axios';
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import Alert from '../../components/alert/Alert';
import Checkbox from '@mui/material/Checkbox';
import Backdrop from '../../components/Backdrop';


function EmailAI() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // for 401 error
    const signOut = useSignOut();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut();
        localStorage.removeItem("userName");
        localStorage.removeItem("who");
        navigate("/signin");
    }

    const [backdropOpen, setBackdropOpen] = React.useState(false);

    // for alert
    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = React.useState("");
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = React.useState("");

    const [loading, setLoading] = React.useState(true);

    const [emailText, setEmailText] = React.useState("");
    const [similarEmails, setSimilarEmails] = React.useState({});
    const [selectedSampleEmails, setSelectedSampleEmails] = React.useState([]);
    const [addtionalRequests, setAdditionalRequests] = React.useState("");
    const [generatedEmail, setGeneratedEmail] = React.useState("");
    
    React.useEffect(() => {
        localStorage.setItem('selectedMenu', 'Email AI');
        setLoading(false);
    }, []);

    const handleSearch = async (e) => {
        e.preventDefault();

        setSimilarEmails({});
        setGeneratedEmail("");
        setSelectedSampleEmails([]);
        setAdditionalRequests("");

        if (emailText === "") {
            setErrorAlertMessage("Please enter email text!");
            setErrorAlertOpen(true);
            return;
        }

        setBackdropOpen(true);

        const data = {
            email_text: emailText,
        };

        try {
            const response = await Axios.post(`${process.env.REACT_APP_URL}/api/v1/vector-emails`, data, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            });
            setSimilarEmails(response.data);
            setSuccessAlertMessage("Successfully searched similar emails!");
            setSuccessAlertOpen(true);
            setBackdropOpen(false);
        } catch (error) {
            console.log("Error: ", error);
            if (error && error.response && error.response.status === 401) {
                handleLogout();
            } else {
                setErrorAlertMessage("Error occurs while searching similar emails!");
                setErrorAlertOpen(true);
            }

            setBackdropOpen(false);
        }
    };

    const handleRecommendedEmails = async (e) => {
        e.preventDefault();

        if (emailText === "") {
            setErrorAlertMessage("Please enter email text!");
            setErrorAlertOpen(true);
            return;
        }

        if (selectedSampleEmails.length === 0) {
            setErrorAlertMessage("Please select sample emails (up to 3)!");
            setErrorAlertOpen(true);
            return;
        }

        setBackdropOpen(true);

        const data = {
            sample_emails: selectedSampleEmails.map((email) => email.body),
            email_text: emailText,
        };

        try {
            const response = await Axios.post(`${process.env.REACT_APP_URL}/api/v1/email-ai/recommended-emails`, data, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            });
            setGeneratedEmail(response.data);
            setSuccessAlertMessage("Successfully generated email!");
            setSuccessAlertOpen(true);
            setBackdropOpen(false);
        } catch (error) {
            console.log("Error: ", error);
            if (error && error.response && error.response.status === 401) {
                handleLogout();
            } else {
                setErrorAlertMessage("Error occurs while generating email!");
                setErrorAlertOpen(true);
            }

            setBackdropOpen(false);
        }
    };

    const handleGenerateEmail = async (e) => {
        e.preventDefault();

        if (emailText === "") {
            setErrorAlertMessage("Please enter email text!");
            setErrorAlertOpen(true);
            return;
        }

        if (selectedSampleEmails.length === 0) {
            setErrorAlertMessage("Please select sample emails (up to 3)!");
            setErrorAlertOpen(true);
            return;
        }

        setBackdropOpen(true);

        const data = {
            sample_emails: selectedSampleEmails.map((email) => email.body),
            email_text: emailText,
            additional_requests: addtionalRequests,
        };

        try {
            const response = await Axios.post(`${process.env.REACT_APP_URL}/api/v1/email-ai/generate-email`, data, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            });
            setGeneratedEmail(response.data);
            setSuccessAlertMessage("Successfully generated email!");
            setSuccessAlertOpen(true);
            setBackdropOpen(false);
        } catch (error) {
            console.log("Error: ", error);
            if (error && error.response && error.response.status === 401) {
                handleLogout();
            } else {
                setErrorAlertMessage("Error occurs while generating email!");
                setErrorAlertOpen(true);
            }

            setBackdropOpen(false);
        }
    };

    const handleCheckBoxChange = (e, obj) => {
        e.preventDefault();

        if (selectedSampleEmails.length >= 3 && !checked(obj)) {
            setErrorAlertMessage("You can only select 3 sample emails!");
            setErrorAlertOpen(true);
            return;
        }

        let emailIndex = -1;
        for (let i = 0; i < selectedSampleEmails.length; i++) {
            const emailId = selectedSampleEmails[i].id;
            if (emailId === obj.id) {
                emailIndex = i;
                break;
            }
        }

        if (emailIndex === -1) {
            setSelectedSampleEmails([...selectedSampleEmails, obj]);
        } else {
            setSelectedSampleEmails(selectedSampleEmails.filter((email) => email.id !== obj.id));
        }
    };

    const checked = (obj) => {
        for (let i = 0; i < selectedSampleEmails.length; i++) {
            const emailId = selectedSampleEmails[i].id;
            if (emailId === obj.id) {
                return true;
            }
        }
        return false;
    }

    return (
        <Box 
            m="20px" 
            marginX={3} 
            marginY={3} 
            sx={{
                minHeight: "88%",
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Header title="Email AI" subtitle="A ONE Institute" />
            { loading ? 
                <Loading />
            :
                <Box 
                    display="flex" 
                    flexDirection="column" 
                    justifyContent="left" 
                    gap={2} 
                    p={0} 
                    mb={2}
                    sx={{
                        borderRadius: 1,
                        backgroundColor: colors.background,
                        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                        height: '100%',
                        flexGrow: 1,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 2,
                            p: 2,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                                flex: 1,
                            }}
                        >
                            <Typography variant="h5" color={colors.text} sx={{ fontWeight: 600 }}>
                                Paste email text here to find similar emails
                            </Typography>
                            <TextField
                                id="outlined-multiline-static"
                                label="Email Text"
                                multiline
                                rows={20}
                                fullWidth
                                variant="outlined"
                                value={emailText}
                                onChange={(e) => setEmailText(e.target.value)}
                            />
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: 2,
                                }}
                            >
                                <Button 
                                    variant="contained" 
                                    color="primary" 
                                    onClick={handleSearch}
                                    sx={{ width: 200, alignSelf: 'flex-start' }}
                                >
                                    Search
                                </Button>
                                <Typography variant="h6" color={colors.text} sx={{ fontWeight: 600 }}>
                                    Found {Object.keys(similarEmails).length} similar threads with their replies (if any)
                                </Typography>
                            </Box>
                            {Object.keys(similarEmails).length > 0 && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 2,
                                        p: 2,
                                        borderRadius: 1,
                                        border: `1px solid ${colors.grey[900]}`,
                                        backgroundColor: colors.background,
                                        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                                        mt: 2,
                                    }}
                                >
                                    <Typography variant="h6" color={colors.text} sx={{ fontWeight: 600 }}>
                                        Ask AI for recommended emails to respond to the email you pasted above
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        color="info"
                                        onClick={(e) => handleRecommendedEmails(e)}
                                        sx={{ width: 200, alignSelf: 'flex-start' }}
                                    >
                                        Ask AI for Recommended Emails
                                    </Button>
                                    <Typography variant="h6" color={colors.text} sx={{ fontWeight: 600, mt: 2 }}>
                                        Select 3 sample emails to generate a new email
                                    </Typography>
                                    <TextField
                                        id="outlined-multiline-static"
                                        label="Additional Requests (Optional)"
                                        placeholder='Please enter additional requests here'
                                        multiline
                                        rows={4}
                                        fullWidth
                                        variant="outlined"
                                        value={addtionalRequests}
                                        onChange={(e) => setAdditionalRequests(e.target.value)}
                                    />
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={(e) => handleGenerateEmail(e)}
                                        sx={{ width: 200, alignSelf: 'flex-start' }}
                                    >
                                        Generate Email
                                    </Button>
                                    {generatedEmail && generatedEmail !== "" && (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: 2,
                                                p: 2,
                                                borderRadius: 1,
                                                border: `1px solid ${colors.grey[900]}`,
                                                backgroundColor: colors.blueAccent[900],
                                                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                                                mt: 2,
                                            }}
                                        >
                                            <Typography variant="h6" color={colors.text} sx={{ fontWeight: 600 }}>
                                                Generated Email
                                            </Typography>
                                            {/* <Typography variant="body1" color={colors.text}>
                                                <div dangerouslySetInnerHTML={{ __html: generatedEmail }} />
                                            </Typography> */}
                                            {generatedEmail.split('\n').map((line, index) => {
                                                return (
                                                    <Typography key={index} variant='body1' color={colors.text}>{line}</Typography>
                                                );
                                            })}
                                        </Box>
                                    )}
                                </Box>
                            )}
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                                flex: 1,
                            }}
                        >
                            <Typography variant="h5" color={colors.text} sx={{ fontWeight: 600 }}>
                                The 5 most similar emails
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 2,
                                    flex: 1,
                                    overflowY: 'auto',
                                    p: 2,
                                    borderRadius: 1,
                                    border: `1px solid ${colors.grey[900]}`,
                                    minHeight: '70vh',
                                }}
                            >
                                {Object.values(similarEmails).map((thread, index) => {
                                    return (
                                        <Box 
                                            key={index} 
                                            sx={{ 
                                                display: 'flex', 
                                                flexDirection: 'column', 
                                                gap: 2, 
                                                border: 3,
                                                borderRadius: 1,
                                                backgroundColor: colors.background,
                                                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                                                p: 1,
                                            }}>
                                            <Typography variant="h6" color={colors.text} sx={{ fontWeight: 600 }}>
                                                Thread ID: {thread[0].thread_id}
                                            </Typography>
                                            {thread.map((email, index) => {
                                                return (
                                                    <Box
                                                        key={index}
                                                        sx={{
                                                            position: 'relative',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            gap: 1,
                                                            p: 2,
                                                            borderBottom: index === thread.length - 1 ? 0 : '1px solid ' + colors.grey[900],
                                                            mb: index === thread.length - 1 ? 2 : 0,
                                                            backgroundColor: email.thread_id !== thread[0].thread_id && colors.redAccent[900],
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                position: 'absolute',
                                                                top: 0,
                                                                right: 0,
                                                                p: 1,
                                                            }}
                                                        >
                                                            <Checkbox
                                                                checked={checked(email)}
                                                                onChange={(e) => handleCheckBoxChange(e, email)}
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                            />
                                                        </Box>
                                                        {email.thread_id !== thread[0].thread_id && (
                                                            <Typography variant="h6" color={colors.text} sx={{ fontWeight: 600 }}>
                                                                Thread ID: {email.thread_id}
                                                            </Typography>
                                                        )}
                                                        <Typography variant="h6" color={colors.text} sx={{ fontWeight: 600 }}>
                                                            Subject: {email.subject}
                                                        </Typography>
                                                        <Typography variant="body1" color={colors.text}>
                                                            From: {email.from}
                                                        </Typography>
                                                        <Typography variant="body1" color={colors.text}>
                                                            To: {email.to}
                                                        </Typography>
                                                        <Typography variant="body1" color={colors.text}>
                                                            Date: {email.date}
                                                        </Typography>
                                                        <br />
                                                        <Typography variant="body1" color={colors.text}>
                                                            {email.body}
                                                        </Typography>
                                                    </Box>
                                                );
                                            })}
                                        </Box>
                                    );
                                })}
                            </Box>
                        </Box>
                    </Box>
                    
                </Box>
            }
            <Alert
                successAlertOpen={successAlertOpen}
                setSuccessAlertOpen={setSuccessAlertOpen}
                errorAlertOpen={errorAlertOpen}
                setErrorAlertOpen={setErrorAlertOpen}
                successMsg={successAlertMessage}
                errorMsg={errorAlertMessage}
                vertical='bottom'
                horizontal='left'
            />
            <Backdrop
                backdropOpen={backdropOpen}
                handleBackdropClose={() => setBackdropOpen(false)}
            />
        </Box>
    );
}

export default EmailAI