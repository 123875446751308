import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { motion } from "framer-motion";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import Axios from 'axios';
import AreYouSureModal from '../../components/AreYouSureModal';
import MoreInfoModal from './MoreInfoModal';
import dayjs from 'dayjs';

var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone');

dayjs.extend(utc)
dayjs.extend(timezone)

export default function CompetitionCard({ 
    competition, 
    competitionUpdated,
    setCompetitionUpdated,
    setSuccessAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertOpen,
    setErrorAlertMessage,
}) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // for are you sure modal
    const [areYouSureOpen, setAreYouSureOpen] = React.useState(false);

    const [open, setOpen] = React.useState(false);
    const [openEvents, setOpenEvents] = React.useState([]);
    const [deadlineEvents, setDeadlineEvents] = React.useState([]);
    const [testEvents, setTestEvents] = React.useState([]);
    const [winnerEvents, setWinnerEvents] = React.useState([]);
    const [earliestFutureEvents, setEarliestFutureEvents] = React.useState([]);

    const handleOpen = () => {
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    }
    
    const getEvents = async () => {
        await Axios.get(`${process.env.REACT_APP_URL}/api/v1/competitions/${competition.id}/events`, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setOpenEvents(response.data.open_events);
            setDeadlineEvents(response.data.deadline_events);
            setTestEvents(response.data.test_events);
            setWinnerEvents(response.data.winner_events);
            getEarliestFutureEvents(response.data.open_events, response.data.deadline_events, response.data.test_events, response.data.winner_events);
        }).catch((error) => {
            console.log(error);
        });
    };

    const getEarliestFutureEvents = (openEvents, DeadlineEvents, TestEvents, WinnerEvents) => {
        let earliestFutureEvents = [];
        let allEvents = openEvents.concat(DeadlineEvents, TestEvents, WinnerEvents);
        allEvents.forEach(event => {
            if (dayjs(event.date).isAfter(dayjs())) {
                earliestFutureEvents.push(event);
            }
        });
        earliestFutureEvents.sort((a, b) => {
            return dayjs(a.date).diff(dayjs(b.date));
        });

        // only keep the first 5 events
        setEarliestFutureEvents(earliestFutureEvents.slice(0, 5));
    }

    const handleDeleteCompetition = async (event) => {

        await Axios.delete(`${process.env.REACT_APP_URL}/api/v1/competitions/${competition.id}`, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setSuccessAlertMessage("Competition has been deleted!");
            setSuccessAlertOpen(true);
            setCompetitionUpdated(true);
            setAreYouSureOpen(false);            
        }).catch((error) => {
            console.log(error);
            setErrorAlertMessage(error.response.data.message);
            setErrorAlertOpen(true);
        });
    }

    React.useEffect(() => {
        getEvents();
    }, [competitionUpdated]);
    
    return (
        <motion.div
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            layout
        >
            <Card
                key={competition.id}
                sx={{
                    minWidth: 200,
                    backgroundColor: colors.primary[600],
                    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.5)",
                    border: `1px solid ${colors.primary[600]}`,
                    ":hover": {
                        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.5)",
                        border: "1px solid grey",
                    }
                }}
            >
                <CardContent
                    sx={{
                        minHeight: 160,
                        maxHeight: 160,
                        overflow: "hidden",
                    }}
                >
                    <Typography 
                        variant="h5" 
                        mt={1} 
                        display='flex' 
                        justifyContent='center' 
                        marginBottom={1} 
                        component="div"
                        textAlign="center"
                    >
                        {/* {competition.name.length > 20 ? competition.name.substring(0, 20) + "..." : competition.name} */}
                        {competition.name}
                    </Typography>

                    {/* Event List */}
                    <Typography sx={{ mb: 1.5 }} color="text.secondary" display='flex' flexDirection='column' align='center' component="div">
                        {earliestFutureEvents.length > 0 ? (
                            <>
                            
                            {earliestFutureEvents.map((event) => {
                                return (
                                    <Box
                                        key={event.id}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography 
                                            variant="body1"
                                            color={event.color}
                                        >
                                            {event.name.length > 20
                                                ? event.name.substring(0, 20) + "..."
                                                : event.name
                                            }
                                        </Typography>
                                        <Typography variant="body1">
                                            {dayjs(event.date).tz("America/New_York").format('YYYY/MM/DD')}
                                        </Typography>
                                    </Box>
                                );
                            })}

                            
                            {/* {events.length > 4 && (
                                <>
                                <Typography
                                    variant="body1"
                                    sx={{ color: 'blue', cursor: 'pointer', mt: 1 }}
                                    onClick={handleMoreClick}
                                >
                                    + {events.length - 4} more
                                </Typography>

                                <Popover
                                    id={id}
                                    open={openPopover}
                                    anchorEl={anchorEl}
                                    onClose={handlePopoverClose}
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                                    PaperProps={{
                                        sx: {
                                            maxHeight: '300px',
                                            overflowY: 'auto',
                                            width: '300px',
                                            padding: 2,
                                        }
                                    }}
                                >
                                    
                                    {events.map((event) => (
                                        <Box 
                                            key={event.id} 
                                            sx={{ 
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between', 
                                                mb: 2,
                                            }}
                                        >
                                            <Typography variant="body2" sx={{width: '30%'}}>{event.name}</Typography>
                                            <Box
                                                sx={{
                                                    width: 20,
                                                    height: 20,
                                                    backgroundColor: event.color,
                                                    borderRadius: '50%',
                                                    display: 'flex',
                                                }}
                                            />
                                            <Typography variant="body2" color="text.secondary">
                                                {dayjs(event.date).format('YYYY/MM/DD')}
                                            </Typography>
                                        </Box>
                                    ))}
                                </Popover>
                            </>
                            )} */}
                            </>                    
                        ) : (
                            <Typography variant="body1" align='center' marginTop={5}>
                                No upcoming events
                            </Typography>
                        )}
                    </Typography>
                </CardContent>
                <CardActions
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >         
                    <Button
                        size="small"
                        style={{
                            "backgroundColor": colors.primary[800],
                            "color": colors.primary[400]
                        }}
                        onClick={handleOpen}
                    >
                        More Info
                    </Button>
                    <Button
                        size="small"
                        style={{
                            "backgroundColor": colors.primary[800],
                            "color": colors.primary[400]
                        }}
                        onClick={() => setAreYouSureOpen(true)}
                    >
                        Delete
                    </Button>
                     
                </CardActions>
            </Card>

            {/* For MoreInfo Modal */}
            <MoreInfoModal
                open={open}
                handleClose={handleClose}
                competition={competition} 
                setCompetitionUpdated={setCompetitionUpdated}
                openEvents={openEvents}
                deadlineEvents={deadlineEvents}
                testEvents={testEvents}
                winnerEvents={winnerEvents}
                setSuccessAlertOpen={setSuccessAlertOpen}
                setSuccessAlertMessage={setSuccessAlertMessage}
                setErrorAlertOpen={setErrorAlertOpen}
                setErrorAlertMessage={setErrorAlertMessage}
            />
            {/* For Delete Modal */}
            <AreYouSureModal
                colors={colors}
                title="Delete Competition"
                message="Please confirm to delete this competition. This action cannot be undone."
                onConfirm={handleDeleteCompetition}
                setOpen={setAreYouSureOpen}
                open={areYouSureOpen}
            />
        </motion.div>
    );
}