import * as React from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import Axios from 'axios';
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { Editor } from '@tinymce/tinymce-react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const options = {
    height: 400,
    menubar: false,
    plugins: [
        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
        'anchor', 'searchreplace', 'visualblocks', 'fullscreen',
        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
    ],
    toolbar: [
        ' undo redo | blocks | bold italic forecolor backcolor fontsize | fontselect |' +
        'alignleft aligncenter alignright alignjustify | lineheight |' +
        'bullist numlist outdent indent | removeformat | help'
    ],
    font_formats: 'Arial=arial,helvetica,sans-serif;' +
                 'Arial Black=arial black,avant garde;' +
                 'Courier New=courier new,courier;' +
                 'Georgia=georgia,palatino;' +
                 'Tahoma=tahoma,arial,helvetica,sans-serif;' +
                 'Times New Roman=times new roman,times;' +
                 'Verdana=verdana,geneva;' +
                 'Impact=impact,chicago;' +
                 'Comic Sans MS=comic sans ms,sans-serif;' +
                 'Trebuchet MS=trebuchet ms,geneva;',
    htmlAllowedTags:  ['.*'],
    htmlAllowedAttrs: ['.*'],
    extended_valid_elements: '*[.*]',
    content_style: "p { margin: 0; }",
};

const daysMapForSort = {
    "월": 1,
    "화": 2,
    "수": 3,
    "목": 4,
    "금": 5,
    "토": 6,
    "일": 7,
}

const categoryData =[
    "English",
    "Math",
    "Science",
    "Social",
    "Competition",
    "Test Prep",
]

function sortDays(days) {
    return days.sort((a, b) => {
        return daysMapForSort[a] - daysMapForSort[b];
    });
}

function getStyles(day, days, theme) {
    return {
        fontWeight:
            days.indexOf(day) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}  

const AddPotentialCourseModal = ({ 
    potential_schedule_id,
    time,
    subject,
    order,
    day,
    daysData,    
    setSuccessAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertOpen,
    setErrorAlertMessage,
    selectedSemester,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: 800,
        minHeight: 500,
        maxHeight: 800,
        overflowY: 'auto',
        bgcolor: colors.primary[600],
        border: '2px solid grey',
        boxShadow: 24,
        p: 4,
    };

    // for modal open and close
    const [addPotentialCourseModalOpen, setAddPotentialCourseModalOpen] = React.useState(false);
    const handleAddPotentialCourseModalClose = () => setAddPotentialCourseModalOpen(false);    

    // for course info
    const [isPrivateCourse, setIsPrivateCourse] = React.useState(null);
    const [code, setCode] = React.useState("");
    const [course_title, setCourseTitle] = React.useState("");
    const [course_memo, setCourseMemo] = React.useState("");
    const [emailContent, setEmailContent] = React.useState('');
    const [category, setCategory] = React.useState("");
    const [days, setDays] = React.useState([day]);
    const [teacher_name, setTeacherName] = React.useState("");

    async function handleAddPotentialCourse(e) {
        e.preventDefault();

        if (isPrivateCourse === null) {
            setErrorAlertMessage("Please select course type!");
            setErrorAlertOpen(true);
            return;
        }

        if (code === "" || course_title === "" || category === "") {
            setErrorAlertOpen(true);
            setErrorAlertMessage("Please fill code, course title, and category!");
            return;
        }

        if (days.length === 0) {
            setErrorAlertOpen(true);
            setErrorAlertMessage("Please select at least one day!");
            return;
        }

        await Axios.post(`${process.env.REACT_APP_URL}/api/v1/potential-schedules/${potential_schedule_id}/potential-courses`, {
            code,
            course_title,
            course_memo,
            days: days.join("|"),
            subject,
            teacher_name,
            category,
            order,
            email_template: emailContent !== "" ? emailContent : null,
            semester_id: selectedSemester,
            is_private_course: isPrivateCourse,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setSuccessAlertOpen(true);
            setSuccessAlertMessage(response.data.message || "Potential course created successfully!");
            window.location.reload();
        }).catch((error) => {
            console.log(error);
            setErrorAlertOpen(true);
            setErrorAlertMessage("Error occurs while creating potential course!");
        });
    }

    const handleChange = (event) => {
        const { target: { value }, } = event;
        setDays(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (<>
        <Box
            sx={{
                height: 30,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                color: "darkgray",
                opacity: 0.2,
                cursor: "pointer",
                "&:hover": {
                    opacity: 1,
                    bgcolor: colors.greenAccent[600],
                }
            }}
            onClick={() => setAddPotentialCourseModalOpen(true)}
        >
            +
        </Box>
        <Modal
            open={addPotentialCourseModalOpen}
            onClose={handleAddPotentialCourseModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Fade in={addPotentialCourseModalOpen}>
                <Box sx={style}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '1.5rem' }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Typography
                                variant="body1"
                            >
                                Time: <span style={{ fontWeight: "bold", fontSize: "1rem", }}>{time}</span>
                            </Typography>
                            <Typography
                                variant="body1"
                            >
                                Subject: <span style={{ fontWeight: "bold", fontSize: "1rem", }}>{subject}</span>
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '1rem',
                                alignItems: 'center',
                            }}
                        >
                            <TextField
                                fullWidth
                                label="Code"
                                variant="outlined"
                                value={code}
                                onChange={(event) => setCode(event.target.value)}
                            />
                            <TextField
                                fullWidth
                                label="Course Title"
                                variant="outlined"
                                value={course_title}
                                onChange={(event) => setCourseTitle(event.target.value)}
                            />
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                    Course Type
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={isPrivateCourse}
                                    label="Course Type"
                                    onChange={(event) => setIsPrivateCourse(event.target.value)}
                                >
                                    <MenuItem value={null}>None</MenuItem>
                                    <MenuItem value={true}>Private</MenuItem>
                                    <MenuItem value={false}>Group</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '1rem',
                                alignItems: 'center',
                            }}
                        >
                            <FormControl sx={{ m: 1, width: 250 }}>
                                <InputLabel id="demo-multiple-chip-label">
                                    Days
                                </InputLabel>
                                <Select
                                    labelId="demo-multiple-chip-label"
                                    id="demo-multiple-chip"
                                    multiple
                                    value={days}
                                    onChange={handleChange}
                                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((value) => (
                                                <Chip key={value} label={value} />
                                            ))}
                                        </Box>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {daysData.map((day) => (
                                        <MenuItem
                                            key={day}
                                            value={day}
                                            style={getStyles(day, days, theme)}
                                        >
                                            {day}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl sx={{ m: 1, width: 200 }}>
                                <InputLabel id="demo-multiple-chip-label">
                                    Category
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={category}
                                    label="Category"
                                    onChange={(event) => setCategory(event.target.value)}
                                >
                                    {categoryData.map((category, index) => (
                                        <MenuItem key={index} value={category}>
                                            {category}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <TextField                                    
                                sx={{ width: 210 }}
                                label="Teacher(s) for Memo"
                                variant="outlined"
                                value={teacher_name}
                                onChange={(event) => setTeacherName(event.target.value)}
                            />  
                        </Box>
                        <TextField
                            fullWidth
                            multiline
                            minRows={3}
                            maxRows={10}
                            label="Course Memo (Optional)"
                            variant="outlined"
                            value={course_memo}
                            onChange={(event) => setCourseMemo(event.target.value)}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '1rem',
                            }}
                        >
                            <Typography
                                variant="h5"
                                fontWeight={700}
                            >
                                Email Template (Optional)
                            </Typography>
                            <Editor
                                apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                                init={options}
                                value={emailContent}
                                onEditorChange={
                                    (newValue, editor) => {
                                        setEmailContent(newValue);
                                    }
                                }
                            />
                            {emailContent !== "" && (<>
                                <h3 style={{ marginBottom: 0 }}>Preview</h3>
                                <div 
                                    dangerouslySetInnerHTML={{ __html: emailContent }} 
                                    style={{
                                        padding: "10px",
                                        backgroundColor: colors.primary[600],
                                    }}
                                />
                            </>)}
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '1rem',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                            }}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                    width: 100,
                                }}
                                onClick={(e) => handleAddPotentialCourse(e)}
                            >
                                Add
                            </Button>
                            <Button
                                variant="contained"
                                color="error"
                                sx={{
                                    width: 100,
                                }}
                                onClick={handleAddPotentialCourseModalClose}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    </>);
};

export default AddPotentialCourseModal