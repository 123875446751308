import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MemoTableBody from './MemoTableBody';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

export default function MemoTable({ 
    memos, 
    student_uuid, 
    setStudentUpdated,
    setSuccessAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertOpen,
    setErrorAlertMessage, 
    socket = null,
    socketConnected = false,
    from_weekly = false,
    getStudentName,
}) {

    return (      
            <TableContainer component={Paper} sx={{ overflow: "auto" }}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell style={{ width: "7rem" }}>Date</StyledTableCell>
                            <StyledTableCell style={{ width: "7rem" }}>Title</StyledTableCell>
                            <StyledTableCell>Content</StyledTableCell>
                            <StyledTableCell style={{ width: "5rem" }}>Reminder</StyledTableCell>
                            <StyledTableCell style={{ width: "7rem" }}>Date</StyledTableCell>
                            <StyledTableCell align='center' style={{ width: "4rem" }}>Edit</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <MemoTableBody
                        memos={memos} 
                        student_uuid={student_uuid} 
                        setStudentUpdated={setStudentUpdated} 
                        setSuccessAlertOpen={setSuccessAlertOpen} 
                        setSuccessAlertMessage={setSuccessAlertMessage} 
                        setErrorAlertOpen={setErrorAlertOpen} 
                        setErrorAlertMessage={setErrorAlertMessage}
                        socket={socket}
                        socketConnected={socketConnected}
                        from_weekly={from_weekly}
                        getStudentName={getStudentName}
                    />
                </Table>
            </TableContainer>       
    );
}