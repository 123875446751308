import React from 'react';
import './Calendar.css';
import { tokens } from "../../theme";
import {
    Box,
    ListItem,
    ListItemText,
    Typography,
    useTheme,
    InputBase,
    IconButton,
    Button,
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import Axios from 'axios';
import { motion, AnimatePresence } from "framer-motion";
import Popover from '@mui/material/Popover';
import MoreInfoModal from '../competitions/MoreInfoModal';


const CalendarSidebar = ({ 
    updated, 
    onEventClick,
    setSuccessAlertMessage,
    setSuccessAlertOpen,
    setErrorAlertMessage,
    setErrorAlertOpen,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [events, setEvents] = React.useState([]);
    const [eventSearch, setEventSearch] = React.useState('');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedEvent, setSelectedEvent] = React.useState(null);
    const [competitionInfo, setCompetitionInfo] = React.useState(null);
    const [openEvents, setOpenEvents] = React.useState([]);
    const [deadlineEvents, setDeadlineEvents] = React.useState([]);
    const [testEvents, setTestEvents] = React.useState([]);
    const [winnerEvents, setWinnerEvents] = React.useState([]);
    const [openCompetitionModal, setOpenCompetitionModal] = React.useState(false);
    const [competitionUpdated, setCompetitionUpdated] = React.useState(false);


    React.useEffect(() => {
        const eventListup = () => {
            Axios.get(`${process.env.REACT_APP_URL}/api/v1/sidebar-events`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                setEvents(response.data);
            }).catch((error) => {
                if (error.response.status === 404) {
                console.log("No events found");
                }
            });
        };

        eventListup();
    }, [updated]);

    // Function to handle popover open
    const handlePopoverOpen = (event, selectedEvent) => {
        if (selectedEvent.competition_id) {
            setAnchorEl(event.currentTarget);
            setSelectedEvent(selectedEvent);
        } else {
            onEventClick(selectedEvent.date);
        }
    };

    // Function to handle popover close
    const handlePopoverClose = () => {
        setAnchorEl(null);
        setSelectedEvent(null);
    };

    // Function to fetch competition info
    const handleSeeCompetitionInfo = async (competitionId) => {
        try {
            const response = await Axios.get(`${process.env.REACT_APP_URL}/api/v1/competitions/${competitionId}`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            });
            setCompetitionInfo(response.data);

            // Fetch events info
            const eventsResponse = await Axios.get(`${process.env.REACT_APP_URL}/api/v1/competitions/${competitionId}/events`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            });
            setOpenEvents(eventsResponse.data.open_events);
            setDeadlineEvents(eventsResponse.data.deadline_events);
            setTestEvents(eventsResponse.data.test_events);
            setWinnerEvents(eventsResponse.data.winner_events);
            setCompetitionUpdated(false);
            setOpenCompetitionModal(true);            
        } catch (error) {
            setErrorAlertMessage("Failed to fetch competition info");
            setErrorAlertOpen(true);
        } finally {
            handlePopoverClose();
        }
    };

    // Function to handle closing the competition modal
    const handleCompetitionModalClose = () => {
        setOpenCompetitionModal(false);
    };    

    React.useEffect(() => {
        document.addEventListener('keydown', (e) => {
            if (e.key === 'Enter') {
                // find the element with the focus
                const focusedElement = document.activeElement;
                if (focusedElement.id === 'event_title') {
                    fetchEvents('enter', 'event_title', focusedElement.value);
                }
            }
        });

        return () => {
            document.removeEventListener('keydown', () => {});
        }
    }, []);

    async function fetchEvents(method, param, value) {
        if (method === 'enter' && (value === '' || value === undefined || value === null)) {
            setErrorAlertMessage("Please enter a search value");
            setErrorAlertOpen(true);
            return;
        }
        if (method === 'click' 
            && (eventSearch === '' || eventSearch === undefined || eventSearch === null)) {
            setErrorAlertMessage("Please enter a search value");
            setErrorAlertOpen(true);
            return;
        }

        let search_method = '';
        let url = `${process.env.REACT_APP_URL}/api/v1/students/consultation-progress`;
        if (param === 'event_title') {
            url = `${process.env.REACT_APP_URL}/api/v1/students/consultation-progress?event_title=`;
            url += method === 'enter' ? value : eventSearch;
            search_method = 'event_title';
        }

        // set a search method and value on a local storage
        localStorage.setItem('cp_search_method', search_method);
        localStorage.setItem('cp_search_value', method === 'enter' ? value : eventSearch);
        localStorage.setItem('cp_use_search', 'false');

        await Axios.get(url, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            // set searches to empty
            emptySearch();
        }).catch((error) => {
            if (error.response.status === 404) {
                setErrorAlertMessage("No student found");
                setErrorAlertOpen(true);
                return;
            }
            setErrorAlertMessage(error.response.data.message || "Something went wrong");
            setErrorAlertOpen(true);
        });
    };

    function emptySearch() {
        setEventSearch('');

        const eventNumElement = document.getElementById('event_title');
    
        if (eventNumElement) 
            eventNumElement.value = '';
    };

    const formatDate = (date) => {
        return new Intl.DateTimeFormat("en-US", {
            year: "numeric", 
            month: "short",
            day: "numeric",
        }).format(date);
    };

    
    return (
        <Box
            flex="1 1 20%"
            sx={{
                height: "80vh",
                p: '15px',
                paddingTop: '0',
                borderRadius: "4px",
                backgroundColor: colors.primary[400],
                overflowY: "scroll",
                "&::-webkit-scrollbar": {
                    width: "0.4em",
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "rgba(0,0,0,.1)",
                },
                overflowx: "hidden",
            }}
        >            
            {/* Search bar */}
            <Box
                sx={{
                    position: 'sticky',
                    top: 0, 
                    zIndex: 1,
                    backgroundColor: colors.primary[400], 
                    p: '10px 0',
                }}
            >

                <Typography variant="h5" color={colors.greenAccent[500]}>Events</Typography>

                <Box sx={{ display: 'flex', backgroundColor: colors.primary[600], mt: 2, mb: 2 }}>
                    <InputBase
                        sx={{ ml: 2, flex: 1 }}
                        value={eventSearch}
                        onChange={(e) => setEventSearch(e.target.value)}
                        placeholder="Search events"
                    />
                    <IconButton sx={{ p: 1 }}>
                        <SearchIcon />
                    </IconButton>
                </Box>
            </Box>
    
            {/* Events List */}
            <motion.div
                style={{
                    display: "grid",
                }}
                layout
            >
                <AnimatePresence>
                    {events
                        .filter(event => event.name && event.name.toLowerCase().includes(eventSearch.toLowerCase()))
                        .map(event => (
                            <ListItem
                                key={event.id}
                                sx={{
                                    backgroundColor: colors.greenAccent[500],
                                    // backgroundColor: "#90add4",
                                    // backgroundColor: "#e7ded1",
                                    margin: "10px 0",
                                    borderRadius: "2px",
                                    ":hover": {
                                        // backgroundColor: "#ab8b75",
                                        backgroundColor: "#f0cd5f",
                                        cursor: "pointer",
                                    },
                                }}
                                // onClick={() => onEventClick(event.date)}
                                onClick={(e) => handlePopoverOpen(e, event)}
                            >
                                <ListItemText
                                    primary={event.name}
                                    secondary={<Typography>{formatDate(new Date(event.date))}</Typography>}
                                />
                            </ListItem>
                        ))
                    }
                </AnimatePresence>
            </motion.div>

            {/* Popover for Event Actions */}
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Button onClick={() => {
                        onEventClick(selectedEvent.date);
                        handlePopoverClose();
                    }}>
                        Go to Event
                    </Button>
                    {selectedEvent?.competition_id && (
                        <Button onClick={() => handleSeeCompetitionInfo(selectedEvent.competition_id)}>See Competition Info</Button>
                    )}
                </Box>
            </Popover>

            {/* Modal for Competition Info */}
            <MoreInfoModal
                open={openCompetitionModal}
                handleClose={handleCompetitionModalClose}
                competition={competitionInfo} 
                setCompetitionUpdated={setCompetitionUpdated}
                openEvents={openEvents}
                deadlineEvents={deadlineEvents}
                testEvents={testEvents}
                winnerEvents={winnerEvents}
                setSuccessAlertOpen={setSuccessAlertOpen}
                setSuccessAlertMessage={setSuccessAlertMessage}
                setErrorAlertOpen={setErrorAlertOpen}
                setErrorAlertMessage={setErrorAlertMessage}
            />
        </Box>
    );
  };

export default CalendarSidebar;