import React from 'react';
import { tokens } from '../../theme';
import { Box, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { generateWeekDatesForTeacherScheduler } from '../../utils/weekDates';
import { useTheme } from "@mui/material";
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import Loading from '../../components/Loading';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Axios from 'axios';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import Alert from '../../components/alert/Alert';
import Tooltip from '@mui/material/Tooltip';

const IndividualSchedule = ({ userId, teacher, open, handleClose, time, isDarkMode }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        // width: 1540,
        // height: 800,
        width: '90%',
        maxWidth: 1520,
        height: '80%',
        bgcolor: colors.primary[600],
        border: '2px solid grey',
        boxShadow: 24,
        p: 4,
        overflow: 'auto',
        borderRadius: '8px',
    };
    
    const [date, setDate] = React.useState(time);
    const [weekDates, setWeekDates] = React.useState(generateWeekDatesForTeacherScheduler(date, 0.5));
    const [loading, setLoading] = React.useState(false);
    
    const [selectedSlots, setSelectedSlots] = React.useState([]);
    const [classSlots, setClassSlots] = React.useState([]);

    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = React.useState('');
    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const ROWHEIGHT = 40;
    
    // Fetch teacher's availability
    React.useEffect(() => {
        const fetchTeacherAvailability = async () => {
            setLoading(true); // Set loading at the beginning
            try {
                const response = await Axios.get(`${process.env.REACT_APP_URL}/api/v1/teachers/${userId}/weekly-schedule/availability`, {
                    params: {
                        week_start: weekDates[1].date.set({ hour: 0, minute: 0, second: 0 }).toISO(),
                        week_end: weekDates[7].date.set({ hour: 23, minute: 59, second: 59 }).toISO(),
                    },
                    headers: {
                        Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                    },
                });
    
                // Process availability data
                const weeklyAvailability = response.data.teacherAvailabilities || [];
    
                const availabilitySlots = weeklyAvailability.map(slot => {
                    const date = DateTime.fromISO(slot.date);
                    const dateIndex = weekDates.findIndex(weekDate => weekDate.date?.toISODate() === date.toISODate());
                    const slotIndex = weekDates[dateIndex].hours.findIndex(hour => hour === date.toFormat('h:mm a'));
                    return [dateIndex, slotIndex, date, date.toFormat('h:mm a')];
                });
    
                setSelectedSlots(availabilitySlots);

                // Process class data separately
                const classSlots = response.data.modules.flatMap(slot => {
                    const start = DateTime.fromISO(slot.start);
                    const end = DateTime.fromISO(slot.end);
                    const dateIndex = weekDates.findIndex(weekDate => weekDate.date?.toISODate() === start.toISODate());
                    
                    const startSlotIndex = weekDates[dateIndex].hours.findIndex(hour => hour === start.toFormat('h:mm a'));
                    const endSlotIndex = weekDates[dateIndex].hours.findIndex(hour => hour === end.toFormat('h:mm a'));

                    const title = slot.course.course_title;
                    const code = slot.course.code;
                    const is_canceled = slot.is_canceled;
    
                    // Create slots for the entire class duration
                    const slots = [];
                    for (let i = startSlotIndex; i < endSlotIndex; i++) {
                        slots.push([dateIndex, i, start, start.toFormat('h:mm a'), end, end.toFormat('h:mm a'), title, code, is_canceled]);
                    }
                    return slots;
                });
                setClassSlots(classSlots);
            } catch (error) {
                console.error(error);
                setErrorAlertMessage(error.response.data.message || 'Error loading data');
                setErrorAlertOpen(true);
            } finally {
                setLoading(false); // Ensure loading is false no matter what
            }
        };
    
        fetchTeacherAvailability();
    }, [weekDates]);

    const isNoClassSlot = (dateObj, hour) => {
        if (!dateObj || !dateObj.date) return false; // Ensure dateObj and date exist
    
        const noClassStartTime = 8; // 8:00 AM
        const noClassEndTime = 15.5; // 3:30 PM (inclusive)
    
        const hourValue = DateTime.fromFormat(hour, 'h:mm a').hour + (DateTime.fromFormat(hour, 'h:mm a').minute === 30 ? 0.5 : 0);
    
        // Check if it's a Sunday or if the hour falls between the no-class range for Monday-Friday
        const weekday = dateObj.date.weekday; // Safely access weekday
        if (weekday === 7 || (weekday >= 1 && weekday <= 5 && hourValue >= noClassStartTime && hourValue <= noClassEndTime)) {
            return true;
        };
        return false;
    };
    
    function handleDateChange(lOrR) {
        if (lOrR === -1) {
            setDate(time);
            setWeekDates(generateWeekDatesForTeacherScheduler(time, 0.5));
        } else if (lOrR === 0) {
            setDate(date.minus({ days: 7 }));
            setWeekDates(generateWeekDatesForTeacherScheduler(date.minus({ days: 7 }), 0.5));
        } else if (lOrR === 1) {
            setDate(date.plus({ days: 7 }));
            setWeekDates(generateWeekDatesForTeacherScheduler(date.plus({ days: 7 }), 0.5));
        }
    }

    // Cancel > class > availability > no class
    const getSlotBackgroundColor = (isAvailability, isClass, classInfo, hourIndex) => {
        if (isAvailability) {
            return '#FFA500';
        }
        if (classInfo !== undefined && classInfo !== null && classInfo[8] === true) {
            return '#db4f4a';
        }
        if (isClass) {
            return 'skyblue';
        }
        if (hourIndex % 2 === 0) {
            return isDarkMode ? '#121212' : 'Azure';
        }
        return isDarkMode ? '#373737' : 'Beige';
    };

    // Cancel > overlappingClasses > class > availability > no class
    const getSlotInfo = (overlappingClasses, isClass, isAvailability, isNoClass, classInfo) => {
        if (isAvailability) {
            return 'Available';
        }
        if (classInfo !== undefined && classInfo !== null && classInfo[8] === true) {
            return `${classInfo[7]}. ${classInfo[6]} → Canceled`;
        }
        if (isAvailability && overlappingClasses.length > 0) {
            return `${classInfo[7]}. ${classInfo[6]} this is overlap`;
        }
        if (isClass) {
            return `${classInfo[7]}. ${classInfo[6]}`;
        }      
        if (isNoClass) {
            return 'No Class';
        }
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{ backdrop: { timeout: 500 } }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <Box borderBottom={1} sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                        <Typography 
                            variant="h2" 
                            color="primary"
                            sx={{
                                '@media screen and (max-width: 1495px)': {
                                    fontSize: 20,
                                },
                            }}
                        >
                            {teacher.first_name} {teacher.last_name}'s Weekly Schedule
                        </Typography>
                        <Box display="flex" alignItems="center" gap={1}>
                            <AccessTimeFilledIcon 
                                color="error" 
                                sx={{
                                    '@media screen and (max-width: 1495px)': {
                                        fontSize: 'small',                                   
                                    },
                                }}    
                            />
                            <Typography 
                                variant="h5" 
                                color="error"
                                sx={{
                                    '@media screen and (max-width: 1495px)': {
                                        fontSize: 12,
                                    },
                                }}
                            >
                                Based on EDT
                            </Typography>
                            <AccessTimeFilledIcon 
                                color="error" 
                                sx={{
                                    '@media screen and (max-width: 1495px)': {
                                        fontSize: 'small',                                   
                                    },
                                }}
                            />
                        </Box>
                        <Box display="flex" gap={2} sx={{mb: 1}}>
                            <Button                                 
                                variant="contained" 
                                color="primary" 
                                onClick={() => handleDateChange(-1)}
                                sx={{  
                                    '@media screen and (max-width: 1495px)': {
                                        size: 'small',
                                        fontSize: 10,
                                    },
                                }}
                            >
                                First Page
                            </Button>
                            <Button 
                                variant="contained" 
                                color="primary" 
                                onClick={() => handleDateChange(0)}
                                sx={{  
                                    '@media screen and (max-width: 1495px)': {
                                        size: 'small',
                                    },
                                }}
                            >
                                <KeyboardArrowLeftIcon />
                            </Button>
                            <Button 
                                variant="contained" 
                                color="primary" 
                                onClick={() => handleDateChange(1)}
                                sx={{  
                                    '@media screen and (max-width: 1495px)': {
                                        size: 'small',
                                    },
                                }}
                            >
                                <KeyboardArrowRightIcon />
                            </Button>
                            <Button 
                                variant="contained" 
                                color="secondary" 
                                onClick={handleClose}
                                sx={{
                                    '&:hover': { backgroundColor: colors.blueAccent[600] },
                                    '@media screen and (max-width: 1495px)': {
                                        size: 'small',
                                        fontSize: 10,
                                    },
                                }}
                            >
                                Close
                            </Button>
                        </Box>
                    </Box>
                    {loading ? <Loading /> : 
                        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', minHeight: 600 }}>
                            {weekDates.map((dateObj, dateIndex) => (
                                <Box key={`week-${dateIndex}`} sx={{ display: 'flex', flexDirection: 'column', pb: 1, minWidth: dateIndex === 0 ? 100 : 190 }}>
                                    <Box
                                        key={`sticky-${dateIndex}`}
                                        sx={{
                                            position: 'sticky',
                                            top: '-32px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            bgcolor: dateObj.date?.toISODate() === DateTime.now().toISODate() ? 'green' : isDarkMode ? '#28292c' : 'lightgrey',
                                            color: isDarkMode ? 'white' : 'black',
                                            minHeight: ROWHEIGHT,
                                            border: '1px solid lightgrey',
                                            borderRadius: 1,
                                            zIndex: 1,
                                        }}
                                    >
                                        {dateIndex === 0 ? "Time" :     
                                            <Typography variant="h6" key={`weekly-${dateIndex}`}>
                                                {dateObj.date.toLocaleString({ weekday: "short" })}, {dateObj.date.toLocaleString({ month: "short" })} {dateObj.date.day}, {dateObj.date.year}
                                            </Typography>
                                        }
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, borderRight: '1px solid lightgrey' }}>
                                        {dateObj.hours.map((hour, hourIndex) => {
                                            const isAvailability = selectedSlots.some(slot => slot[0] === dateIndex && slot[1] === hourIndex);
                                            const isClass = classSlots.some(slot => slot[0] === dateIndex && slot[1] === hourIndex);
                                            const classInfo = classSlots.find(slot => slot[0] === dateIndex && slot[1] === hourIndex);
                                            const overlappingClasses = classSlots.filter(slot => slot[0] === dateIndex && slot[1] === hourIndex);
                                            const isNoClass = isNoClassSlot(dateObj, hour);
                                        
                                            return (
                                                <Tooltip title={`${hour} ~ ${DateTime.fromFormat(hour, 'h:mm a').plus({ minutes: 30 }).toFormat('h:mm a')}`} placement="right">
                                                    <Box
                                                        key={hourIndex}
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            bgcolor: getSlotBackgroundColor(isAvailability, isClass, classInfo, hourIndex),
                                                            height: ROWHEIGHT,
                                                            border: '1px solid lightgrey',
                                                            borderRadius: 1,
                                                        }}
                                                    >
                                                        {dateIndex === 0 ? (
                                                            <Typography variant="h6">
                                                                {hour}
                                                            </Typography>
                                                        ) : (
                                                            <Typography variant="body2">
                                                                {getSlotInfo(overlappingClasses, isClass, isAvailability, isNoClass, classInfo)}
                                                            </Typography>
                                                            )
                                                        }
                                                    </Box>
                                                </Tooltip>

                                            );
                                        })}
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    }
                    <Alert
                        successAlertOpen={successAlertOpen}
                        setSuccessAlertOpen={setSuccessAlertOpen}
                        errorAlertOpen={errorAlertOpen}
                        setErrorAlertOpen={setErrorAlertOpen}
                        successMsg="Schedule Updated!"
                        errorMsg={errorAlertMessage}
                    />
                </Box>
            </Fade>
        </Modal>
    );
};

export default IndividualSchedule;