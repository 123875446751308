import React from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Axios from 'axios';

/*
 * for each course, there is a vert icon 
 */
const DeletePotentialCourseDialog = ({
    setAnchorEl,
    potentialCourse,
    fetchPotentialCourses,
    setSuccessAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertOpen,
    setErrorAlertMessage,
}) => {

    // for dialog
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const handleClickDialogOpen = () => {
        setDialogOpen(true);
    };
    const handleDialogClose = () => {
        setDialogOpen(false);
        setAnchorEl(null);
    };

    const handleCourseDelete = async (e) => {
        e.preventDefault();

        await Axios.delete(`${process.env.REACT_APP_URL}/api/v1/potential-schedules/${potentialCourse.course_potential_schedule?.potential_schedule?.id}/potential-courses/${potentialCourse.uuid}`, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setSuccessAlertOpen(true);
            setSuccessAlertMessage(response.data.message || "Potential Course deleted successfully!");
            setTimeout(() => {
                window.location.reload();
            }, 500);
        }).catch((error) => {
            console.log(error);
            setErrorAlertMessage("Error occurred while deleting course!");
            setErrorAlertOpen(true);
        });
    };

    return (
        <>
            <Button
                variant="outlined"
                color="error"
                sx={{ 
                    width: "100%", 
                    border: "1px solid transparent",
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                }}
                onClick={() => handleClickDialogOpen()}
            >
                Delete
            </Button>
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure you want to delete this potential course?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={(e) => handleCourseDelete(e)} color="error" autoFocus>
                        Delete
                    </Button>
                    <Button onClick={handleDialogClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default DeletePotentialCourseDialog