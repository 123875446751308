import React from 'react';
import Loading from '../components/Loading';
import Header from '../components/Header';
import { Box, Typography, useTheme, Button, TextField } from '@mui/material';
import { tokens } from '../theme';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSignOut } from "react-auth-kit";
import IconButton from '@mui/material/IconButton';
import { FileUploadOutlined } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import Alert from '../components/alert/Alert';


function NewsLetters() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // for 401 error
    const signOut = useSignOut();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut();
        localStorage.removeItem("userName");
        localStorage.removeItem("who");
        navigate("/signin");
    }

    const [loading, setLoading] = React.useState(true);
    const [emails, setEmails] = React.useState(null);
    const [unsubEmails, setUnsubEmails] = React.useState(null);

    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = React.useState('');
    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = React.useState('');

    const [backdropOpen, setBackdropOpen] = React.useState(false);

    const [files, setFiles] = React.useState([]);

    const [from, setFrom] = React.useState(0);
    const [to, setTo] = React.useState(0);

    const handleUpload = (event) => {
        // only allow excel files and only one file
        if (event.target.files.length > 1) {
            setErrorAlertMessage("Only one file is allowed!");
            setErrorAlertOpen(true);
            return;
        }

        if (event.target.files[0].type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            setErrorAlertMessage("Only excel files are allowed!");
            setErrorAlertOpen(true);
            return;
        }

        setFiles([...files, ...Array.from(event.target.files)]);
    };

    const handleFileRemove = (event, index) => {
        event.preventDefault();
        const newFiles = [...files];
        newFiles.splice(index, 1);
        setFiles(newFiles);
    };

    React.useEffect(() => {
        localStorage.setItem('selectedMenu', 'news-letter-emails');

        async function fetchEmails () {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/news-letter-emails`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then(async (response) => {
                setEmails(response.data.emails);
                setUnsubEmails(response.data.unsubEmails);
                setLoading(false);
            }).catch((error) => {
                if (error.response.status === 404) {
                    setEmails([]);
                    setUnsubEmails([]);
                    setLoading(false);
                    return;
                } else if (error.response.status === 401) {
                    handleLogout();
                    return;
                }
                setErrorAlertMessage(error.response.data.message);
                setErrorAlertOpen(true);
                setLoading(false);
            });
        }

        fetchEmails();
    }, []);

    async function handleSubmit() {
        setBackdropOpen(true);

        if (files.length === 0) {
            setErrorAlertOpen(true);
            setErrorAlertMessage("Please upload a file!");
            setBackdropOpen(false);
            return
        }

        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            if (files[i].name !== undefined 
                && files[i].name !== null 
                && files[i].name !== ""
                && files[i].name.length > 150) {
                setErrorAlertOpen(true);
                setErrorAlertMessage("File name should be less than 150 characters");
                setBackdropOpen(false);
                return;
            }
            formData.append('file', files[i]);
        }

        await Axios.post(`${process.env.REACT_APP_URL}/api/v1/news-letter-emails/excel-email-list`,
            formData, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                setSuccessAlertOpen(true);
                setSuccessAlertMessage(response.data.message);
                // window.location.reload();
            }).catch((error) => {
                console.log(error);
                if (error.response.status === 401) {
                    handleLogout();
                    return;
                }

                setErrorAlertOpen(true);
                setErrorAlertMessage(error.response.data.error);
                setBackdropOpen(false);
            });
    }

    return (
        <Box m="20px" marginX={3} marginY={3} >
            <Header title="Email List for News Letters" subtitle="A ONE Institute" />
            { loading  || emails === null || unsubEmails === null ?
                <Loading />
            : <>
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={2}
                >
                    <a 
                        href={`/news-letters/unsubscription`}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                            fontSize: "1rem",
                            maxWidth: "150px",
                        }}
                    >
                        Unsubscription Link
                    </a>
                    {/* <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: "10px",
                            marginBottom: "10px",
                        }}
                    >
                        <Typography variant="h6" fontWeight="bold" color={colors.primary[450]}>
                            Upload excel file (excel file must have numbers, emails, and remarks in the first, second, and third columns respectively)
                        </Typography>
                        <IconButton component="label">
                            <FileUploadOutlined />
                            <input
                                multiple={true}
                                styles={{display:"none"}}
                                type="file"
                                hidden
                                onChange={handleUpload}
                                name="[licenseFile]"
                            />
                        </IconButton>
                        <Button
                            type="submit"
                            variant="contained"
                            style={{
                                backgroundColor: colors.primary[300],
                                color: "white",
                                marginLeft: "0.5rem",
                                width: "5rem",
                            }}
                            onClick={handleSubmit}
                        >
                            Upload
                        </Button>
                    </Box>
                    <Box
                        display="flex"
                        minWidth="100%"
                        flexDirection="column"
                    >
                        {files.length > 0 && files.map((file, index) => {
                            return (
                                <Box key={index} display="flex" alignItems="center">
                                    <Typography variant="body1" color={colors.primary[450]}>
                                        {file.name}
                                    </Typography>
                                    <IconButton onClick={(e) => handleFileRemove(e, index)}>
                                        <ClearIcon />
                                    </IconButton>
                                </Box>
                            )
                        })}
                    </Box> */}
                </Box>
                <Box display="flex" justifyContent="left" gap={2} p={0} minHeight={"74vh"} mb={2}>
                    <Box display="flex" flexDirection="column" justifyContent="left" alignItems="center" gap={2} p={0} sx={{ width: "50%" }}>
                        <Typography variant="h4" sx={{ color: colors.text, fontWeight: 600 }}>
                            Subscribed Emails {emails.length > 0 && `(${emails.length})`}
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: "10px",
                                width: "50%",
                                height: "100%",
                                gap: 1,
                            }}
                        >
                            <TextField
                                id="outlined-basic"
                                label="from"
                                type='number'
                                variant="outlined"
                                color="primary"
                                value={from}
                                onChange={(e) => setFrom(e.target.value)}
                                sx={{ 
                                    width: '100%', 
                                }}
                            />
                            <TextField
                                id="outlined-basic"
                                label="to"
                                type='number'
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                variant="outlined"
                                color="primary"
                                value={to}
                                onChange={(e) => setTo(e.target.value)}
                                sx={{ 
                                    width: '100%', 
                                }}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{ 
                                    width: '100%', 
                                    ml: 1,
                                }}
                                onClick={() => {
                                    let _from = null;
                                    let _to = null;

                                    try {
                                        _from = parseInt(from);
                                        _to = parseInt(to);
                                    } catch (error) {
                                        setErrorAlertMessage("from/to should be numbers");
                                        setErrorAlertOpen(true);
                                        return;
                                    }

                                    if (isNaN(_from) || isNaN(_to)) {
                                        setErrorAlertMessage("from/to should not be empty");
                                        setErrorAlertOpen(true);
                                        return;
                                    }

                                    if (_from > _to) {
                                        setErrorAlertMessage("from should be less than to");
                                        setErrorAlertOpen(true);
                                        return;
                                    }

                                    if (_from < 1 || _to < 1) {
                                        setErrorAlertMessage("from/to should be greater than 0");
                                        setErrorAlertOpen(true);
                                        return;
                                    }

                                    if (_from > emails.length || _to > emails.length) {
                                        setErrorAlertMessage("from/to should be less than the number of emails");
                                        setErrorAlertOpen(true);
                                        return;
                                    }

                                    navigator.clipboard.writeText(emails.slice(_from - 1, to).map(email => email.email).join(", "));
                                    setSuccessAlertMessage("Emails copied to clipboard");
                                    setSuccessAlertOpen(true);
                                }}
                            >
                                Copy
                            </Button>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                padding: "10px",
                                width: "50%",
                                height: "100%",
                                backgroundColor: colors.primary[600],
                                border: "3px solid #ccc",
                            }}
                        >
                            {emails.length === 0 ? (
                                <Typography variant="h6" sx={{ color: colors.text, fontWeight: 600 }}>
                                    No Emails Found!
                                </Typography>
                            ) : (
                                emails.map((email, index) => (
                                    <Box
                                        key={index}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            width: "100%",
                                            padding: "10px",
                                            margin: "5px",
                                            backgroundColor: colors.primary[750],
                                            border: "2px solid #ccc",
                                        }}
                                    >
                                        <Typography variant="body1" sx={{ color: colors.text, fontWeight: 600 }}>
                                            {index + 1}. {email.email} {email.isEnrolled && "(Enrolled)"}
                                        </Typography>
                                    </Box>
                                ))
                            )}
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="column" justifyContent="left" alignItems="center" gap={2} p={0} sx={{ width: "50%" }}>
                        <Typography variant="h4" sx={{ color: colors.text, fontWeight: 600 }}>
                            Unsubscribed Emails {unsubEmails.length > 0 && `(${unsubEmails.length})`}
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                padding: "10px",
                                width: "50%",
                                height: "100%",
                                backgroundColor: colors.primary[600],
                                border: "3px solid #ccc",
                            }}
                        >
                            {unsubEmails.length === 0 ? (
                                <Typography variant="h6" sx={{ color: colors.text, fontWeight: 600 }}>
                                    No Emails Found!
                                </Typography>
                            ) : (
                                unsubEmails.map((email, index) => (
                                    <Box
                                        key={index}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            width: "100%",
                                            padding: "10px",
                                            margin: "5px",
                                            backgroundColor: colors.primary[750],
                                            border: "2px solid #ccc",
                                        }}
                                    >
                                        <Typography variant="body1" sx={{ color: colors.text, fontWeight: 600 }}>
                                            {index + 1}. {email.email} {email.isEnrolled && "(Enrolled)"}
                                        </Typography>
                                    </Box>
                                ))
                            )}
                        </Box>
                    </Box>
                </Box>
            </>}
            <Alert
                successAlertOpen={successAlertOpen}
                setSuccessAlertOpen={setSuccessAlertOpen}
                errorAlertOpen={errorAlertOpen}
                setErrorAlertOpen={setErrorAlertOpen}
                successMsg={successAlertMessage}
                errorMsg={errorAlertMessage}
                vertical='bottom'
                horizontal='left'
            />
        </Box>
    )
}

export default NewsLetters