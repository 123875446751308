import React from 'react';
import { Box, Typography, Button, Modal, Backdrop, Fade } from '@mui/material';

function InstructionModal({
    colors,
    instructionModalOpen,
    setInstructionModalOpen,
}) {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        height: 500,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        bgcolor: colors.primary[600],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const [chosenLanguage, setChosenLanguage] = React.useState("eng");

    return (
        <Modal
            aria-labelledby="instruction-modal-title"
            aria-describedby="instruction-modal-description"
            open={instructionModalOpen}
            onClose={() => setInstructionModalOpen(false)}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={instructionModalOpen}>
                <Box sx={style}>
                    <Typography variant="h2" color={colors.primary[450]} fontWeight={"bold"} mx={"auto"}>
                        {chosenLanguage === "eng" ? "Instructions" : "안내사항"}
                    </Typography>
                    <Box 
                        sx={{ 
                            display: "flex", 
                            flexDirection: "column", 
                            gap: 1, 
                            mt: 2, 
                            pl: 2,
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                ml: "auto",
                                gap: 1,
                            }}
                        >
                            <Button
                                sx={{ 
                                    padding: 0,
                                    minWidth: "30px",
                                    backgroundColor: "transparent",
                                    color: chosenLanguage === "eng" ? "black" : colors.grey[700],
                                    textDecoration: chosenLanguage === "eng" ? "underline" : "none",
                                    cursor: "pointer",
                                    '&:hover': {
                                        color: "black",
                                        backgroundColor: "transparent",
                                        textDecoration: chosenLanguage === "eng" ? "underline" : "none",
                                    },
                                }}
                                onClick={() => setChosenLanguage("eng")}
                            >
                                Eng
                            </Button>
                            <Button
                                sx={{ 
                                    padding: 0,
                                    minWidth: "30px",
                                    backgroundColor: "transparent",
                                    color: chosenLanguage === "kor" ? "black" : colors.grey[700],
                                    textDecoration: chosenLanguage === "kor" ? "underline" : "none",
                                    cursor: "pointer",
                                    '&:hover': {
                                        color: "black",
                                        backgroundColor: "transparent",
                                        textDecoration: chosenLanguage === "kor" ? "underline" : "none",
                                    },
                                }}
                                onClick={() => setChosenLanguage("kor")}
                            >
                                한
                            </Button>
                        </Box>
                        {chosenLanguage === "eng" ? (
                            <>
                                <Typography variant="h5" color={colors.primary[450]}>
                                    1. Please fill out the form.
                                </Typography>
                                <Typography variant="h5" color={colors.primary[450]} ml={4}>
                                    1.1 Make sure to fill out all the <span style={{ color: 'red' }}>required</span> fields.
                                </Typography>
                                <Typography variant="h5" color={colors.primary[450]} ml={4}>
                                    1.2 If you don't fill out the <span style={{ color: 'red' }}>required</span> fields, you will not be able to submit the form.
                                </Typography>
                                <Typography variant="h5" color={colors.primary[450]}>
                                    2. Please double check all the information before submitting.
                                </Typography>
                                <Typography variant="h5" color={colors.primary[450]}>
                                    3. Click <span style={{ backgroundColor: colors.greenAccent[500], color: "black"}}>"Submit"</span> button at the bottom of the form.
                                </Typography>
                                <Typography variant="h5" color={colors.primary[450]}>
                                    4. If the form is submitted successfully, you will see a <span style={{color: colors.greenAccent[400]}}>success</span> message.
                                </Typography>
                                <br />
                                <Typography variant="h5" color={colors.primary[450]}>
                                    If you have any questions, please contact <span style={{color: colors.blueAccent[600]}}>A ONE</span> Institute.
                                </Typography>
                                <Typography variant="h5" color={colors.primary[450]}>
                                    Thank you for your cooperation.
                                </Typography>
                            </>
                        ) : (
                            <>
                                <Typography variant="h5" color={colors.primary[450]}>
                                    1. 학생과 학부모님 정보를 입력해주세요.
                                </Typography>
                                <Typography variant="h5" color={colors.primary[450]} ml={4}>
                                    1.1 <span style={{ color: 'red' }}>필수 "Required*"</span> 항목을 꼭 모두 입력해주셔야 합니다.
                                </Typography>
                                <Typography variant="h5" color={colors.primary[450]} ml={4}>
                                    1.2 만약 <span style={{ color: 'red' }}>필수 "Required*"</span> 항목을 입력하지 않으시면, 제출이 불가능합니다.
                                </Typography>
                                <Typography variant="h5" color={colors.primary[450]}>
                                    2. 제출 전에 모든 정보를 다시 한 번 확인해주세요.
                                </Typography>
                                <Typography variant="h5" color={colors.primary[450]}>
                                    3. 아래에 있는 <span style={{ backgroundColor: colors.greenAccent[500], color: "black"}}>"Submit"</span> 버튼을 클릭해주세요.
                                </Typography>
                                <Typography variant="h5" color={colors.primary[450]}>
                                    4. 제출이 성공적으로 되면, <span style={{color: colors.greenAccent[400]}}>성공</span> 메시지가 나타납니다.
                                </Typography>
                                <br />
                                <Typography variant="h5" color={colors.primary[450]}>
                                    궁금한 사항이 있으시면, <span style={{color: colors.blueAccent[600]}}>A ONE</span> Institute로 연락해주세요.
                                </Typography>
                                <Typography variant="h5" color={colors.primary[450]}>
                                    협조해주셔서 감사합니다.
                                </Typography>
                            </>
                        )}
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: 2,
                            mt: "auto",
                        }}
                    >
                        <Button
                            variant="contained"
                            color="secondary"
                            sx={{ width: 200 }}
                            type="button"
                            onClick={() => setInstructionModalOpen(false)}
                        >
                            {chosenLanguage === "eng" ? "Close" : "닫기"}
                        </Button>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    )
}

export default InstructionModal