import React from 'react';
import Button from '@mui/material/Button';
import { Box, IconButton, Select, TextField, Typography } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CloseIcon from '@mui/icons-material/Close';
import Axios from 'axios';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import AreYouSureModal from '../../../components/AreYouSureModal';
import SearchIcon from '@mui/icons-material/Search';
import MenuItem from '@mui/material/MenuItem';

const tableBorder = {
    border: '1px solid black',
    borderCollapse: 'collapse',
    padding: '8px',
};

const search_categories = [
    {label: "Job ID", value: "job_id"},
    {label: "Message", value: "message"},
    {label: "Phone Numbers", value: "phone_numbers"},
    {label: "Student Name", value: "student_name"},
    {label: "Teacher Name", value: "teacher_name"},
    {label: "Admin Name", value: "admin_name"},
    {label: "Memo", value: "memo"},
    {label: "Status", value: "status"},
];


function ScheduledMessagesModal({
    user_id,
    colors,
    open,
    handleClose,
    socket,
    socketConnected,
    enqueueSnackbar,
    setBackdropOpen,
    handleBackdropClose,
    fetchTriggerScheduledMessages,
    setFetchTriggerScheduledMessages,
}) {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        maxHeight: '96%',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2,
        bgcolor: colors.primary[600],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const [scheduledMessages, setScheduledMessages] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(30);
    const [totalPages, setTotalPages] = React.useState(1);

    const [query, setQuery] = React.useState("job_id");
    const [queryInput, setQueryInput] = React.useState("");
    
    const [areYouSureModalOpen, setAreYouSureModalOpen] = React.useState(false);
    const [selectedMessageForCancellation, setSelectedMessageForCancellation] = React.useState(null);

    React.useEffect(() => {
        if (!areYouSureModalOpen) {
            // when the areYouSureModal is closed, reset the selected message for cancellation
            setSelectedMessageForCancellation(null);
        }
    }, [areYouSureModalOpen]);

    React.useEffect(() => {
        window.addEventListener("keydown", (event) => {
            const search = document.getElementById("search");

            // if search input is focused and enter key is pressed
            if (event.key === "Enter" && search === document.activeElement) {
                setFetchTriggerScheduledMessages(fetchTriggerScheduledMessages + 1);
            }
        });

        return () => {
            window.removeEventListener("keydown", (event) => {
                const search = document.getElementById("search");

                // if search input is focused and enter key is pressed
                if (event.key === "Enter" && search === document.activeElement) {
                    setFetchTriggerScheduledMessages(fetchTriggerScheduledMessages + 1);
                }
            });
        }
    }, []);

    React.useEffect(() => {
        if (!open) return;

        setBackdropOpen(true);

        async function fetchScheduledMessages() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/scheduled-messages?page=${page}&limit=${limit}&${query}=${queryInput}`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                setScheduledMessages(response.data.scheduled_messages);
                setTotalPages(response.data.total_pages);
                handleBackdropClose();
            }).catch((error) => {
                if (error.response.status === 404) {
                    enqueueSnackbar("No scheduled messages found.", { variant: "info" });
                    setScheduledMessages([]);
                    handleBackdropClose();
                    return;
                }
                console.error(error);
                enqueueSnackbar("Error fetching scheduled messages.", { variant: "error" });
                handleBackdropClose();
            });
        }

        fetchScheduledMessages();
    }, [open, page, limit, fetchTriggerScheduledMessages]);

    const handleCancelScheduledMessage = (job_id) => {
        if (!socketConnected) {
            enqueueSnackbar("Error: Socket is not connected.", { variant: "error" });
            return;
        }

        socket.emit("cancel.ScheduledMessage", {
            job_id,
        });
    };
    
    return (
        <Modal
            aria-labelledby="scheduled-messages-modal"
            aria-describedby="scheduled-messages-modal-description"
            open={open}
            onClose={() => {
                setScheduledMessages([]);
                setPage(1);
                setLimit(30);
                setTotalPages(1);
                setQuery("job_id");
                setQueryInput("");
        
                handleClose();
            }}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <Typography variant="h4" color={colors.primary[450]} mb={2}>
                        Scheduled Messages (Total Pages: {totalPages})
                    </Typography>
                    <CloseIcon sx={{ position: "absolute", right: 10, top: 10, cursor: "pointer" }} onClick={handleClose} />
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: 2,
                            width: "100%",
                        }}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setFetchTriggerScheduledMessages(fetchTriggerScheduledMessages + 1);
                                setQuery("job_id");
                                setQueryInput("");
                            }}
                        >
                            Refresh
                        </Button>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "start",
                                gap: 1,
                            }}
                        >
                            <Select
                                value={query}
                                onChange={(event) => setQuery(event.target.value)}
                                variant="outlined"
                                size="small"
                                sx={{
                                    width: 150,
                                }}
                            >
                                {search_categories.map((category, index) => (
                                    <MenuItem key={index} value={category.value}>{category.label}</MenuItem>
                                ))}
                            </Select>
                            <div style={{ position: "relative", }}>
                                <TextField
                                    id='search'
                                    label="Search"
                                    variant="outlined"
                                    size="small"
                                    value={queryInput}
                                    onChange={(event) => setQueryInput(event.target.value)}
                                />
                                <IconButton
                                    onClick={() => {
                                        setFetchTriggerScheduledMessages(fetchTriggerScheduledMessages + 1);
                                    }}
                                    sx={{ 
                                        position: "absolute", 
                                        right: 0,
                                        top: "50%",
                                        transform: "translateY(-50%)",
                                    }}
                                >
                                    <SearchIcon />
                                </IconButton>
                            </div>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "end",
                                marginLeft: "auto",
                                gap: 1,
                            }}
                        >
                            <Typography variant="h5" color={colors.primary[450]}>
                                Limit:
                            </Typography>
                            <select
                                value={limit}
                                onChange={(event) => setLimit(event.target.value)}
                                style={{
                                    width: 50,
                                    padding: 5,
                                    borderRadius: 5,
                                    border: `1px solid ${colors.primary[450]}`,
                                }}
                            >
                                <option value={10}>30</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </select>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            position: "relative",
                            display: "inline-block",
                            width: "100%",
                            overflowX: "auto",
                        }}
                    >
                        <table style={{
                            border: '1px solid black',
                            borderCollapse: 'collapse',
                            padding: '8px',
                        }}>
                            <thead style={tableBorder}>
                                <tr>
                                    <th style={tableBorder}>Job ID</th>
                                    <th style={tableBorder}>Message</th>
                                    <th style={tableBorder}>Phone Numbers</th>
                                    <th style={tableBorder}>Student Name</th>
                                    <th style={tableBorder}>Teacher Name</th>
                                    <th style={tableBorder}>Admin Name</th>
                                    <th style={tableBorder}>Memo</th>
                                    <th style={tableBorder}>Scheduled Time</th>
                                    <th style={tableBorder}>Status</th>
                                    <th style={tableBorder}>Sent At</th>
                                    <th style={tableBorder}>Error</th>
                                    <th style={tableBorder}>Error At</th>
                                    <th style={tableBorder}>Actions</th>
                                </tr>
                            </thead>
                            <tbody style={tableBorder}>
                                {scheduledMessages.map((message, index) => (
                                    <tr key={index}>
                                        <td style={tableBorder}>{message.job_id ? message.job_id : "n/a"}</td>
                                        <td style={tableBorder}>
                                            {message.message && message.message.split("\n").map((line, index) => {
                                                return <div key={index}>{line}</div>
                                            })}
                                        </td>
                                        <td style={tableBorder}>{message.phone_numbers}</td>
                                        <td style={tableBorder}>{message.student_name}</td>
                                        <td style={tableBorder}>{message.teacher_name}</td>
                                        <td style={tableBorder}>{message.admin_name}</td>
                                        <td style={tableBorder}>
                                            {message.memo?.split("\n").map((line, index) => (
                                                <div key={index}>{line}</div>
                                            ))}
                                        </td>
                                        <td style={tableBorder}>{message.scheduled_at ? message.scheduled_at : "n/a"}</td>
                                        <td style={{
                                                ...tableBorder,
                                                backgroundColor: message.status === "pending" ? colors.blueAccent[900] : message.status === "sent" ? colors.greenAccent[500] : colors.redAccent[500],
                                            }}
                                        >
                                            {message.status}
                                        </td>
                                        <td style={tableBorder}>{message.sent_at}</td>
                                        <td style={tableBorder}>{message.error}</td>
                                        <td style={tableBorder}>{message.error_at}</td>
                                        <td style={tableBorder}>
                                            {message.scheduled_at !== null && new Date(message.scheduled_at) > new Date() && message.status === "pending" &&
                                                <>
                                                    <Button
                                                        variant="outlined"
                                                        color="error"
                                                        sx={{ width: 100, mt: 2 }}
                                                        onClick={() => {
                                                            setSelectedMessageForCancellation(message);
                                                            setAreYouSureModalOpen(true)}
                                                        }
                                                    >
                                                        Cancel
                                                    </Button>
                                                </>
                                            }
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: 2,
                        }}
                    >
                        <IconButton
                            disabled={page <= 1}
                            onClick={() => setPage(page - 1)}
                        >
                            <ArrowLeftIcon />
                        </IconButton>
                        <Typography variant="h6" color={colors.primary[450]}>
                            Page {page}
                        </Typography>
                        <IconButton
                            disabled={page >= totalPages}
                            onClick={() => setPage(page + 1)}
                        >
                            <ArrowRightIcon />
                        </IconButton>
                    </Box>

                    <AreYouSureModal
                        colors={colors}
                        open={areYouSureModalOpen}
                        setOpen={setAreYouSureModalOpen}
                        title="This action cannot be undone!"
                        message="Are you sure you want to cancel this scheduled message?"
                        onConfirm={() => {
                            handleCancelScheduledMessage(selectedMessageForCancellation.job_id);
                        }}
                    />
                </Box>
            </Fade>
        </Modal>
    )
}

export default ScheduledMessagesModal