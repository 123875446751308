import React from 'react'
import { tokens } from "../../theme";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import Loading from '../../components/Loading';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import BackdropComp from '../../components/Backdrop';
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import Axios from 'axios';
import dayjs from 'dayjs';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { SocketContext } from '../../socket';
import './Weekly.css';
import TimeBlock from './TimeBlock';
import WeeklySettingsModal from './WeeklySettingsModal';
import getCookies from '../../utils/getCookies';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useSnackbar } from 'notistack';
import MoreInfoModal from '../students/moreInfo/MoreInfoModal';
import TeacherMoreInfoModal from '../teachers/moreInfo/MoreInfoModal';
import MainMemoComp from './mainMemo/MainMemoComp';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import BulkAttendanceControl from './speedDial/BulkAttendanceControl';
import BulkSendMessageControl from './speedDial/BulkSendMessageControl';
import CircularProgress from '@mui/material/CircularProgress';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import ScheduledMessagesModal from './speedDial/ScheduledMessagesModal';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CopyPrevWeekZoomAssignModal from './speedDial/CopyPrevWeekZoomAssignModal';
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';
import MobileMessageTemplatesModal from './speedDial/MobileMessageTemplatesModal';
import DailyMemoComp from './dailyMemo/DailyMemoComp';
import HistoryIcon from '@mui/icons-material/History';
import PreviousMemosModal from './speedDial/PreviousMemosModal';
import RateReviewIcon from '@mui/icons-material/RateReview';
import SettingsIcon from '@mui/icons-material/Settings';
import TodayIcon from '@mui/icons-material/Today';
import Tooltip from '@mui/material/Tooltip';
import NotesModal from './NotesModal';
import CopyPrevWeekCellMemoModal from './speedDial/CopyPrevWeekMemoModal';


var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone');

dayjs.extend(utc)
dayjs.extend(timezone)

const semesterSeasons = ["n/a", "Spring", "Summer", "Fall", "Winter"];

const ROW_HEIGHT = 40;
const days = ["Mon", "", "Tue", "", "Wed", "", "Thu", "", "Fri", "", "Sat", "", "Sun", ""];
const times = [
    "00:00-00:30",
    "00:30-01:00",
    "01:00-01:30",
    "01:30-02:00",
    "02:00-02:30",
    "02:30-03:00",
    "03:00-03:30",
    "03:30-04:00",
    "04:00-04:30",
    "04:30-05:00",
    "05:00-05:30",
    "05:30-06:00",
    "06:00-06:30",
    "06:30-07:00",
    "07:00-07:30",
    "08:00-08:30",
    "09:00-09:30",
    "09:30-10:00",
    "10:00-10:30",
    "10:30-11:00",
    "11:00-11:30",
    "11:30-12:00",
    "12:00-12:30",
    "12:30-13:00",
    "13:00-13:30",
    "13:30-14:00",
    "14:00-14:30",
    "14:30-15:00",
    "15:00-15:30",
    "15:30-16:00",
    "16:00-16:30",
    "16:30-17:00",
    "17:00-17:30",
    "17:30-18:00",
    "18:00-18:30",
    "18:30-19:00",
    "19:00-19:30",
    "19:30-20:00",
    "20:00-20:30",
    "20:30-21:00",
    "21:00-21:30",
    "21:30-22:00",
    "22:00-22:30",
    "22:30-23:00",
    "23:00-23:30",
    "23:30-24:00",
];

const Weekly = () => {
    const socket = React.useContext(SocketContext);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { enqueueSnackbar } = useSnackbar();

    const currentUser = getCookies("_auth_state");
    // const who = currentUser ? currentUser.split(',')[2]?.split(':')[1]?.split('"')[1] : null;
    // const userRole = who;
    const userId = currentUser ? currentUser.split(',')[0]?.split(':')[1]?.split('"')[1] : null;
    const admin_name = localStorage.getItem("userName");

    // for 401 error
    const signOut = useSignOut();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut();
        localStorage.removeItem("userName");
        localStorage.removeItem("who");
        navigate("/signin");
    }

    const [refetchEventsByTime, setRefetchEventsByTime] = React.useState(false);

    // const [weeklyViewModeList, setWeeklyViewModeList] = React.useState([]);
    // const [isThisAdminInViewMode, setIsThisAdminInViewMode] = React.useState(false);
    const [adminWeeklySettings, setAdminWeeklySettings] = React.useState(null);
    const [weeklySettingsUpdated, setWeeklySettingsUpdated] = React.useState(true);

    const [tinyLoading, setTinyLoading] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [socketConnected, setSocketConnected] = React.useState(false);
    const [adminUuidList, setAdminUuidList] = React.useState([]);
    const [socketIoUsers, setSocketIoUsers] = React.useState({});
    const [globalMessages, setGlobalMessages] = React.useState([]);

    const [courseMap, setCourseMap] = React.useState({});
    const [attendanceMap, setAttendanceMap] = React.useState({});
    const [attendanceStudentIdMap, setAttendanceStudentIdMap] = React.useState({});
    const [teacherMap, setTeacherMap] = React.useState({});
    const [notesMap, setNotesMap] = React.useState({});

    // for mobile message templates
    const [mobileMessageTemplates, setMobileMessageTemplates] = React.useState([]);
    // for mobile message templates (speed dial)
    const [fetchTriggerMobileMessageTemplates, setFetchTriggerMobileMessageTemplates] = React.useState(0);
    const [mobileMessageTemplatesOpen, setMobileMessageTemplatesOpen] = React.useState(false);
    const handleMobileMessageTemplatesClose = () => { setMobileMessageTemplatesOpen(false); };
    const handleMobileMessageTemplatesOpen = () => { setMobileMessageTemplatesOpen(true); };

    // for scheduled messages (speed dial)
    const [fetchTriggerScheduledMessages, setFetchTriggerScheduledMessages] = React.useState(0);
    const [scheduledSendOpen, setScheduledSendOpen] = React.useState(false);
    const handleScheduledSendClose = () => { setScheduledSendOpen(false); };
    const handleScheduledSendOpen = () => { setScheduledSendOpen(true); };

    // for bulk attendance control (speed dial)
    const [showCheckBox, setShowCheckBox] = React.useState(false);
    const [checkedAttendanceList, setCheckedAttendanceList] = React.useState([]);
    const [checkedTeacherCourseList, setCheckedTeacherCourseList] = React.useState([]);

    // for copying prev week zoom assignments (speed dial)
    const [copyPrevWeekZoomAssignOpen, setCopyPrevWeekZoomAssignOpen] = React.useState(false);
    const handleCopyPrevWeekZoomAssignClose = () => { setCopyPrevWeekZoomAssignOpen(false); };
    const handleCopyPrevWeekZoomAssignOpen = () => { setCopyPrevWeekZoomAssignOpen(true); };

    // for copying prev week cell and daily memos (speed dial)
    const [copyPrevWeekMemoOpen, setCopyPrevWeekMemoOpen] = React.useState(false);
    const handleCopyPrevWeekMemoClose = () => { setCopyPrevWeekMemoOpen(false); };
    const handleCopyPrevWeekMemoOpen = () => { setCopyPrevWeekMemoOpen(true); };

    // for previous memos (speed dial)
    const [previousMemosOpen, setPreviousMemosOpen] = React.useState(false);
    const handlePreviousMemosClose = () => { setPreviousMemosOpen(false); };
    const handlePreviousMemosOpen = () => { setPreviousMemosOpen(true); };

    // fot weekly settings (speed dial)
    const [weeklySettingsOpen, setWeeklySettingsOpen] = React.useState(false);
    const handleWeeklySettingsClose = () => { setWeeklySettingsOpen(false); };
    const handleWeeklySettingsOpen = () => { setWeeklySettingsOpen(true); };

    const [mainMemo, setMainMemo] = React.useState([]);
    const [dailyMemos, setDailyMemos] = React.useState([]);
    const [cellMemoMap, setCellMemoMap] = React.useState({});
    // const [mainMemoUpdated, setMainMemoUpdated] = React.useState(false);

    const [date, setDate] = React.useState(dayjs().tz("America/New_York"));
    const [thisWeek, setThisWeek] = React.useState([]);
    const [semesterWeekNum, setSemesterWeekNum] = React.useState(0);
    const [semesterSeason, setSemesterSeason] = React.useState(null);
    const [eventsByTime, setEventsByTime] = React.useState({});

    // for backdrop
    const [backdropOpen, setBackdropOpen] = React.useState(false);
    const handleBackdropClose = () => {
        setBackdropOpen(false);
    };

    // zoom rooms
    const [zoomRooms, setZoomRooms] = React.useState([]);

    // for notes modal
    const [notesModalOpen, setNotesModalOpen] = React.useState(false);
    const [notesModalData, setNotesModalData] = React.useState(null);
    const [dataForRequest, setDataForRequest] = React.useState(null);

    // for student more info
    const [studentForMoreInfo, setStudentForMoreInfo] = React.useState(null);
    // for teacher more info
    const [teacherForMoreInfo, setTeacherForMoreInfo] = React.useState(null);

    // for when a teacher creates or updates class log
    const [classLogUpdatedEventId, setClassLogUpdatedEventId] = React.useState(null);

    const globalMessage = React.useCallback((data) => {
        setGlobalMessages((prev) => {
            const _prev = [...prev];
            _prev.push(data);
            return _prev;
        });
    }, []);

    const globalUsers = React.useCallback((data) => {
        const { id_list, io_users } = data;

        setAdminUuidList(id_list);
        setSocketIoUsers(io_users);
    }, []);

    const globalActiveUsers = React.useCallback((data) => {
        const { 
            admin_uuid, zoom_id, date, _time, event_idx, 
            is_cell_memo, is_daily_memo, is_main_memo,
            main_memo_id, daily_memo_id, active 
        } = data;
        
        if (active) {
            if (is_cell_memo) {
                // add this user to the list
                setTimeout(() => {
                    setCellMemoMap((prev) => {
                        const _prev = { ...prev };
                        if (!_prev[date])
                            _prev[date] = {};
                        if (!_prev[date][_time])
                            _prev[date][_time] = {};
                        if (!_prev[date][_time][zoom_id])
                            _prev[date][_time][zoom_id] = {};
                        if (_prev[date][_time][zoom_id].active_users_num === undefined)
                            _prev[date][_time][zoom_id].active_users_num = 1;
                        else
                            _prev[date][_time][zoom_id].active_users_num += 1;

                        return _prev;
                    });
                }, 200);
            } else if (is_daily_memo) {
                setDailyMemos((prev) => {
                    const _prev = [...prev];
                    const idx = _prev.findIndex((dm) => dm.id === daily_memo_id);
                    if (idx > -1) {
                        if (_prev[idx].active_users_num === undefined)
                            _prev[idx].active_users_num = 1;
                        else
                            _prev[idx].active_users_num += 1;
                    }
                    return _prev;
                });
            } else if (is_main_memo) {
                setMainMemo((prev) => {
                    const _prev = [...prev];
                    const idx = _prev.findIndex((mm) => mm.id === main_memo_id);
                    if (idx > -1) {
                        if (_prev[idx].active_users_num === undefined)
                            _prev[idx].active_users_num = 1;
                        else
                            _prev[idx].active_users_num += 1;
                    }

                    return _prev;
                });
            } else {
                // add this user to the list
                setTimeout(() => {
                    setEventsByTime((prev) => {
                        const _prev = { ...prev };
                        if (_prev[zoom_id] 
                            && _prev[zoom_id][date] 
                            && _prev[zoom_id][date][_time] 
                            && _prev[zoom_id][date][_time][event_idx]
                            && !_prev[zoom_id][date][_time][event_idx].active_users.includes(admin_uuid)
                        ) {
                            _prev[zoom_id][date][_time][event_idx].active_users?.push(admin_uuid);
                        }
                        return _prev;
                    });
                }, 200);
            }
        } else {
            if (is_cell_memo) {
                // remove this user from the list
                setTimeout(() => {
                    setCellMemoMap((prev) => {
                        const _prev = { ...prev };
                        if (_prev[date] && _prev[date][_time] && _prev[date][_time][zoom_id] && _prev[date][_time][zoom_id].active_users_num > 0) {
                            _prev[date][_time][zoom_id].active_users_num -= 1;
                        }
                        return _prev;
                    });
                }, 200);
            } else if (is_daily_memo) {
                setDailyMemos((prev) => {
                    const _prev = [...prev];
                    const idx = _prev.findIndex((dm) => dm.id === daily_memo_id);
                    if (idx > -1) {
                        if (_prev[idx].active_users_num > 0) {
                            _prev[idx].active_users_num -= 1;
                        }
                    }
                    return _prev;
                });
            } else if (is_main_memo) {
                setMainMemo((prev) => {
                    const _prev = [...prev];
                    const idx = _prev.findIndex((mm) => mm.id === main_memo_id);
                    if (idx > -1) {
                        if (_prev[idx].active_users_num > 0) {
                            _prev[idx].active_users_num -= 1;
                        }
                    }
                    return _prev;
                });
            } else {
                // remove this user from the list
                setTimeout(() => {
                    setEventsByTime((prev) => {
                        const _prev = { ...prev };
                        if (_prev[zoom_id] && _prev[zoom_id][date] && _prev[zoom_id][date][_time] && _prev[zoom_id][date][_time][event_idx]) {
                            const idx = _prev[zoom_id][date][_time][event_idx].active_users.indexOf(admin_uuid);
                            if (idx > -1) {
                                _prev[zoom_id][date][_time][event_idx].active_users.splice(idx, 1);
                            }
                        }
                        return _prev;
                    });
                }, 200);
            }
        }
    }, []);

    /**
     * Socket connection
     */
    React.useEffect(() => {
        function onConnect() {
            setSocketConnected(true);
            console.log("socket connected");
        }
      
        function onDisconnect() {
            setSocketConnected(false);
            console.log("socket disconnected");
        }
        
        setTimeout(() => {
            socket.connect();
        }, 100);
        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);
        socket.on('global.users', globalUsers);
        socket.on('global.activeUsers', globalActiveUsers);
        socket.on('global.message', globalMessage);
        
        return () => {
            socket.off('connect', onConnect);
            socket.off('disconnect', onDisconnect);
            socket.off('global.users', globalUsers);
            socket.off('global.activeUsers', globalActiveUsers);
            socket.off('global.message', globalMessage);
            socket.disconnect();
        }
    }, []);

    React.useEffect(() => {
        try {
            while (globalMessages.length > 0) {
                // const data = globalMessages.shift();
                const { 
                    message, 
                    need_update, 
                    is_error, 
                    zoom_id, 
                    new_zoom_id,
                    date, 
                    _time, 
                    event_idx, 
                    event,
                    event_id,
                    is_event_fetch, // this is for the updated event after axios call (not in this weekly page)
                    is_event_update,
                    is_event_delete,
                    is_event_zoom_assign,
                    is_event_schedule_update,
                    is_event_copy_prev_week_zoom_assign,
                    updated_week, // for is_event_copy_prev_week_zoom_assign
                    is_class_log_updated,
                    is_new_course_added,
                    events,
                    is_course_update,
                    course,
                    is_course_deleted,
                    is_teacher_card_update,
                    is_teacher_deleted,
                    is_teacher_removed,
                    is_teacher_assigned,
                    is_teacher_course_update,
                    teacher_course,
                    teacher_course_idx,
                    teacher,
                    _teacher, // for eventByTime when teacher card is updated
                    is_student_card_update,
                    student,
                    _student, // for eventByTime when student card is updated
                    is_attendances_update,
                    is_note_deleted,
                    attendances,
                    is_student_course_update,
                    student_course,
                    is_new_student_added,
                    is_student_removed,
                    is_student_deleted,
                    main_memo_post,
                    main_memo_update,
                    return_weekly_main_memo,
                    updated_main_memo,
                    main_memo_delete,
                    scheduled_messages_canceled,
                    is_mobile_message_template,
                    is_weekly_daily_memos,
                    updated_daily_memo,
                    updated_daily_memos,
                    is_weekly_cell_memos,
                    updated_cell_memo,
                    updated_cell_memos,
                    is_weekly_cell_daily_memos_copy_prev_week,
                    is_special_notes_added,
                    show_blue_x,
                    show_red_x,
                } = globalMessages.shift();
                
                if (is_error) {
                    enqueueSnackbar(message, { variant: 'error' });
                    return;
                }

                if (scheduled_messages_canceled) {
                    setFetchTriggerScheduledMessages(fetchTriggerScheduledMessages + 1);
                }

                if (is_mobile_message_template) {
                    setFetchTriggerMobileMessageTemplates(fetchTriggerMobileMessageTemplates + 1);
                }

                if (is_class_log_updated) {
                    setClassLogUpdatedEventId(event_id);
                }

                if (is_special_notes_added && event) {
                    const eventKeyObjs = courseMap[event.course_id];
                    
                    for (let eventKey of eventKeyObjs) {
                        const { zoom_id, date, _time, event_idx } = eventKey;
                        
                        if (eventsByTime[zoom_id][date][_time][event_idx].id === event.id) {
                            setEventsByTime((prev) => {
                                const _prev = { ...prev };
                                if (!_prev[zoom_id] || !_prev[zoom_id][date] || !_prev[zoom_id][date][_time]) {
                                    return _prev;
                                }
                                _prev[zoom_id][date][_time][event_idx].show_blue_x = show_blue_x;
                                _prev[zoom_id][date][_time][event_idx].show_red_x = show_red_x;
                                _prev[zoom_id][date][_time][event_idx].special_notes = event.special_notes;
                                return _prev;
                            });
                            //stop if you find the event
                            break;
                        }
                    }
                }
                if (is_course_deleted && course) {
                    const eventKeyObjs = courseMap[course.id];

                    if (eventKeyObjs && eventKeyObjs.length > 0) {
                        for (let eventKey of eventKeyObjs) {
                            // delete the event from eventsByTime
                            const { zoom_id, date, _time, event_idx } = eventKey;
                            setEventsByTime((prev) => {
                                const _prev = { ...prev };
                                if (_prev[zoom_id] && _prev[zoom_id][date] && _prev[zoom_id][date][_time]) {
                                    const prev_event = _prev[zoom_id][date][_time][event_idx];
                                    if (prev_event && event_idx === 0 && _prev[zoom_id][date][_time].length === 1) {
                                        _prev[zoom_id][date][_time] = [];
                                    } else if (prev_event) {
                                        _prev[zoom_id][date][_time].splice(event_idx, 1);
                                    }
                                }
                                return _prev;
                            });

                            // after splice the event, other events' event_idx should be updated in courseMap and attendanceMap
                            setTimeout(() => {
                                event_idx_update(zoom_id, date, _time, course.id);
                            }, 3000);
                        }

                        // also need to update the courseMap
                        setCourseMap((prev) => {
                            const _prev = { ...prev };
                            delete _prev[course.id];
                            return _prev;
                        });
                    }
                }

                if (is_new_course_added && events && events.length > 0) {
                    for (let event of events) {
                        const zoom_id = event.zoom_id;
                        const date = dayjs(event.start).tz("America/New_York").format("YYYY-MM-DD");

                        // if date is not in thisWeek, we don't need to update the eventsByTime
                        if (!thisWeek.includes(date)) {
                            continue;
                        }

                        if (event.title 
                            && event.title.includes("Class Module") 
                            && event.title.split(" ").length === 3 
                            && !isNaN(parseInt(event.title.split(" ")[2]))) {
                            event.curr_event_num = parseInt(event.title.split(" ")[2]);
                        } else {
                            event.curr_event_num = -1;
                        }

                        event.total_event_num = events.length;
                        
                        const start_time = dayjs(event.start).tz("America/New_York").format("HH:mm");
                        const end_time = dayjs(event.end).tz("America/New_York");
        
                        let _start_time = dayjs(event.start).tz("America/New_York");
                        let use_fake_start_time = false;
                        let fake_start_time = _start_time;
                        // check if the start_time is not 00 or 30 minutes
                        if (_start_time.minute() !== 0 && _start_time.minute() !== 30) {
                            // set fake_start_time to the nearest 00 or 30 minutes
                            fake_start_time = _start_time.minute() < 30 ? fake_start_time.minute(0) : fake_start_time.minute(30);
                            use_fake_start_time = true;
                        }

                        // _start_time - fake_start_time
                        let start_time_diff = _start_time.diff(fake_start_time, "minute");
                                        
                        // start_time - end_time
                        let start_end_diff = end_time.diff(_start_time, "minute");
                        start_end_diff = (start_end_diff / 30).toFixed(2);

                        event.start_time_diff = start_time_diff;
                        event.start_end_diff = start_end_diff;
                        event.active_users = [];
                        event.start_time = start_time;
                        event.end_time = end_time;
                        let event_idx = null;
                        if (use_fake_start_time) {
                            if (eventsByTime[zoom_id] && eventsByTime[zoom_id][date] && eventsByTime[zoom_id][date][fake_start_time.format("HH:mm")] && eventsByTime[zoom_id][date][fake_start_time.format("HH:mm")].length > 0) {
                                event_idx = eventsByTime[zoom_id][date][fake_start_time.format("HH:mm")].length;
                            } else {
                                event_idx = 0;
                            }
                        } else {
                            if (eventsByTime[zoom_id] && eventsByTime[zoom_id][date] && eventsByTime[zoom_id][date][start_time] && eventsByTime[zoom_id][date][start_time].length > 0) {
                                event_idx = eventsByTime[zoom_id][date][start_time].length;
                            } else {
                                event_idx = 0;
                            }
                        }

                        const _time = use_fake_start_time ? fake_start_time.format("HH:mm") : start_time;

                        setEventsByTime((prev) => {
                            const _prev = { ...prev };
                            if (!_prev[zoom_id]) {
                                _prev[zoom_id] = {};
                            }

                            if (!_prev[zoom_id][date]) {
                                _prev[zoom_id][date] = {};
                            }

                            if (!_prev[zoom_id][date][_time]) {
                                _prev[zoom_id][date][_time] = [];
                            }

                            _prev[zoom_id][date][_time].push(event);

                            return _prev;
                        });

                        // also need to update the courseMap
                        setCourseMap((prev) => {
                            const _prev = { ...prev };
                            if (!_prev[event.course_id]) {
                                _prev[event.course_id] = [];
                            }
                            _prev[event.course_id].push({
                                zoom_id,
                                date,
                                _time,
                                event_idx,
                                event_id: event.id,
                            });
                            return _prev;
                        });

                        // also need to update the attendanceMap
                        if (event.attendances && event.attendances.length > 0) {
                            setAttendanceMap((prev) => {
                                const _prev = { ...prev };
                                const attendances = event.attendances;
                                for (let i = 0; i < attendances.length; i++) {
                                    const attendance = attendances[i];
                                    if (!_prev[attendance.id]) {
                                        _prev[attendance.id] = [];
                                    }
                                    _prev[attendance.id].push({
                                        zoom_id,
                                        date,
                                        _time,
                                        event_idx,
                                        event_id: event.id,
                                        attendance_idx: i,
                                    });
                                }
                                return _prev;
                            });
                        }
                    }
                }

                if (is_course_update) {
                    // get event key list we need to update
                    const eventKeyObjs = courseMap[course.id];
                    for (let eventKey of eventKeyObjs) {
                        const { zoom_id, date, _time, event_idx } = eventKey;
                        setEventsByTime((prev) => {
                            const _prev = { ...prev };
                            if (!_prev[zoom_id] || !_prev[zoom_id][date] || !_prev[zoom_id][date][_time]) {
                                return _prev;
                            }

                            if (_prev[zoom_id][date][_time][event_idx]) {
                                _prev[zoom_id][date][_time][event_idx].course = course;
                            }
                            return _prev;
                        });
                    }
                }

                if (is_student_course_update) {
                    if (student_course) {
                        // need to update student_course in the eventsByTime
                        const eventKeyObjs = [];
                        Object.keys(attendanceStudentIdMap).forEach((attendance_id) => {
                            if (attendanceStudentIdMap[attendance_id] === student_course.student_id) {
                                const eventKeys = attendanceMap[attendance_id];
                                for (let eventKey of eventKeys) {
                                    eventKeyObjs.push(eventKey);
                                }
                            }
                        });

                        for (let eventKey of eventKeyObjs) {
                            const { zoom_id, date, _time, event_idx, attendance_idx, attendance_id } = eventKey;
                            setEventsByTime((prev) => {
                                const _prev = { ...prev };
                                if (_prev[zoom_id] && _prev[zoom_id][date] && _prev[zoom_id][date][_time] 
                                    && _prev[zoom_id][date][_time][event_idx] && _prev[zoom_id][date][_time][event_idx].attendances 
                                    && _prev[zoom_id][date][_time][event_idx].attendances[attendance_idx]
                                    && _prev[zoom_id][date][_time][event_idx].attendances[attendance_idx].student_course
                                    && _prev[zoom_id][date][_time][event_idx].attendances[attendance_idx].student_course.id === student_course.id) {

                                    _prev[zoom_id][date][_time][event_idx].attendances[attendance_idx].student_course = student_course;
                                }
                                return _prev;
                            });
                        }
                    }
                }
        
                if (is_student_card_update) {
                    if (studentForMoreInfo && student && studentForMoreInfo.uuid === student.uuid) {
                        setStudentForMoreInfo(student);
                    }

                    // need to update student in the eventsByTime
                    const eventKeyObjs = [];
                    Object.keys(attendanceStudentIdMap).forEach((attendance_id) => {
                        if (attendanceStudentIdMap[attendance_id] === student.id) {
                            const eventKeys = attendanceMap[attendance_id];
                            for (let eventKey of eventKeys) {
                                eventKeyObjs.push(eventKey);
                            }
                        }
                    });

                    for (let eventKey of eventKeyObjs) {
                        const { zoom_id, date, _time, event_idx, attendance_idx, attendance_id } = eventKey;
                        setEventsByTime((prev) => {
                            const _prev = { ...prev };
                            if (_prev[zoom_id] && _prev[zoom_id][date] && _prev[zoom_id][date][_time] 
                                && _prev[zoom_id][date][_time][event_idx] && _prev[zoom_id][date][_time][event_idx].attendances 
                                && _prev[zoom_id][date][_time][event_idx].attendances[attendance_idx]
                                && _prev[zoom_id][date][_time][event_idx].attendances[attendance_idx].student_id === student.id) {

                                _prev[zoom_id][date][_time][event_idx].attendances[attendance_idx].student = _student;
                            }
                            return _prev;
                        });
                    }
                }

                if (is_teacher_card_update) {
                    if (teacherForMoreInfo && teacher && teacherForMoreInfo.uuid === teacher.uuid) {
                        setTeacherForMoreInfo(teacher);
                    }
                        
                    // need to update teacher in the eventsByTime
                    if (_teacher) {
                        const eventKeyObjs = teacherMap[_teacher.id] ?? [];
                        for (let eventKey of eventKeyObjs) {
                            const { zoom_id, date, _time, event_idx } = eventKey;
                            setEventsByTime((prev) => {
                                const _prev = { ...prev };
                                if (_prev[zoom_id] && _prev[zoom_id][date] && _prev[zoom_id][date][_time] && _prev[zoom_id][date][_time][event_idx]) {
                                    const idx = _prev[zoom_id][date][_time][event_idx].course.teacher_courses.findIndex((tc) => tc.teacher_id === _teacher.id);
                                    if (idx !== -1) {
                                        _prev[zoom_id][date][_time][event_idx].course.teacher_courses[idx].teacher = _teacher;
                                    }
                                }
                                return _prev;
                            });
                        }
                    }
                }

                if ((is_teacher_removed || is_teacher_deleted) && teacher) {
                    const teacher_id = teacher.id;
                    const eventKeyObjs = teacherMap[teacher_id] ?? [];
                    for (let eventKey of eventKeyObjs) {
                        const { zoom_id, date, _time, event_idx } = eventKey;
                        setEventsByTime((prev) => {
                            const _prev = { ...prev };
                            if (_prev[zoom_id] && _prev[zoom_id][date] && _prev[zoom_id][date][_time] && _prev[zoom_id][date][_time][event_idx]) {
                                const idx = _prev[zoom_id][date][_time][event_idx].course.teacher_courses.findIndex((tc) => tc.teacher_id === teacher_id);
                                if (idx === 0 && _prev[zoom_id][date][_time][event_idx].course.teacher_courses.length === 1) {
                                    _prev[zoom_id][date][_time][event_idx].course.teacher_courses = [];
                                } else if (idx > 0) {
                                    _prev[zoom_id][date][_time][event_idx].course.teacher_courses.splice(idx, 1);
                                }
                            }
                            return _prev;
                        });
                    }
                }

                if (is_teacher_assigned && teacher_course) {
                    const course_id = teacher_course.course_id;
                    const eventKeyObjs = courseMap[course_id] ?? [];
                    for (let eventKey of eventKeyObjs) {
                        const { zoom_id, date, _time, event_idx } = eventKey;
                        setEventsByTime((prev) => {
                            const _prev = { ...prev };
                            if (_prev[zoom_id] && _prev[zoom_id][date] && _prev[zoom_id][date][_time] && _prev[zoom_id][date][_time][event_idx]) {
                                if (!_prev[zoom_id][date][_time][event_idx].course.teacher_courses) {
                                    _prev[zoom_id][date][_time][event_idx].course.teacher_courses = [];
                                }
                                _prev[zoom_id][date][_time][event_idx].course.teacher_courses.push(teacher_course);
                            }
                            return _prev;
                        });

                        // need to update the teacherMap
                        setTeacherMap((prev) => {
                            const _prev = { ...prev };
                            if (!_prev[teacher_course.teacher_id]) {
                                _prev[teacher_course.teacher_id] = [];
                            }
                            _prev[teacher_course.teacher_id].push({
                                zoom_id,
                                date,
                                _time,
                                event_idx,
                            });
                            return _prev;
                        });
                    }
                }

                if (is_teacher_course_update && teacher_course) {
                    const course_id = teacher_course.course_id;
                    const eventKeyObjs = courseMap[course_id] ?? [];
                    for (let eventKey of eventKeyObjs) {
                        const { zoom_id, date, _time, event_idx } = eventKey;
                        setEventsByTime((prev) => {
                            const _prev = { ...prev };
                            if (_prev[zoom_id] && _prev[zoom_id][date] && _prev[zoom_id][date][_time] && _prev[zoom_id][date][_time][event_idx]) {
                                const idx = _prev[zoom_id][date][_time][event_idx].course.teacher_courses.findIndex((tc) => tc.id === teacher_course.id);
                                if (idx !== -1) {
                                    _prev[zoom_id][date][_time][event_idx].course.teacher_courses[idx] = teacher_course;
                                }
                            }
                            return _prev;
                        });
                    }
                }

                if (is_event_copy_prev_week_zoom_assign) {
                    // if updated_week is not equal to thisWeek, we don't need to update the eventsByTime
                    let isThisWeek = true;
                    for (let i = 0; i < updated_week.length; i++) {
                        if (thisWeek[i] !== updated_week[i]) {
                            isThisWeek = false;
                            break;
                        }
                    }

                    // simply refetch the eventsByTime
                    if (isThisWeek) {
                        setRefetchEventsByTime(true);
                    }
                }

                if (is_event_delete) {
                    setEventsByTime((prev) => {
                        const _prev = { ...prev };
                        if (_prev[zoom_id] && _prev[zoom_id][date] && _prev[zoom_id][date][_time]) {
                            const prev_event = _prev[zoom_id][date][_time].find((e) => e.id === event.id);
                            if (prev_event) {
                                const idx = _prev[zoom_id][date][_time].indexOf(prev_event);
                                _prev[zoom_id][date][_time].splice(idx, 1);
                            }
                        }
                        return _prev;
                    });

                    // after splice the event, other events' event_idx should be updated in courseMap and attendanceMap
                    setTimeout(() => {
                        event_idx_update(zoom_id, date, _time, event.course_id);
                    }, 3000);
                }

                if (is_event_fetch && event) {
                    // need to find prev event key object
                    const prevEventKeyObj = courseMap[event.course_id].find((eventKey) => {
                        return eventKey.event_id === event.id;
                    });

                    if (!prevEventKeyObj) {
                        console.log("prevEventKeyObj is not found (new module added).");

                        let event_idx = null;
                        if (!eventsByTime[event.zoom_id] || !eventsByTime[event.zoom_id][date] || !eventsByTime[event.zoom_id][date][_time]) {
                            event_idx = 0;
                        } else {
                            event_idx = eventsByTime[event.zoom_id][date][_time].length; // this is the last index
                        }

                        // probably new module is added
                        setEventsByTime((prev) => {
                            const _prev = { ...prev };
                            if (!_prev[event.zoom_id]) {
                                _prev[event.zoom_id] = {};
                            }

                            if (!_prev[event.zoom_id][date]) {
                                _prev[event.zoom_id][date] = {};
                            }

                            if (!_prev[event.zoom_id][date][_time]) {
                                _prev[event.zoom_id][date][_time] = [];
                            }

                            _prev[event.zoom_id][date][_time].push(event);
                            return _prev;
                        });

                        // also need to update the courseMap
                        setCourseMap((prev) => {
                            const _prev = { ...prev };
                            if (!_prev[event.course_id]) {
                                _prev[event.course_id] = [];
                            }
                            _prev[event.course_id].push({
                                zoom_id: event.zoom_id,
                                date,
                                _time: _time,
                                event_idx: event_idx,
                            });
                            return _prev;
                        });

                        // also need to update the attendanceMap
                        setAttendanceMap((prev) => {
                            const _prev = { ...prev };
                            const attendances = event.attendances;
                            for (const attendance of attendances) {
                                if (!_prev[attendance.id]) {
                                    _prev[attendance.id] = [];
                                }
                                _prev[attendance.id].push({
                                    zoom_id: event.zoom_id,
                                    date,
                                    _time,
                                    event_idx,
                                    event_id: event.id,
                                    attendance_idx: _prev[attendance.id].length,
                                });
                            }
                            return _prev;
                        });

                        // setTimeout(() => {
                        //     setRefetchEventsByTime(true);
                        // }, 5000);
                        return;
                    }

                    const prev_zoom_id = prevEventKeyObj.zoom_id;
                    const prev_date = prevEventKeyObj.date;
                    const prev_time = prevEventKeyObj._time;
                    const prev_event_idx = prevEventKeyObj.event_idx;

                    let event_idx = null;
                    if (prev_zoom_id === zoom_id && prev_date === date && prev_time === _time) {
                        event_idx = prev_event_idx;
                    } else {
                        if (!eventsByTime[zoom_id] || !eventsByTime[zoom_id][date] || !eventsByTime[zoom_id][date][_time]) {
                            event_idx = 0;
                        } else {
                            event_idx = eventsByTime[zoom_id][date][_time].length; // this is the last index
                        }
                    }
                    
                    // delete the prev event and add the new event
                    setEventsByTime((prev) => {
                        const _prev = { ...prev };
                        if (_prev[prev_zoom_id] || _prev[prev_zoom_id][prev_date] || _prev[prev_zoom_id][prev_date][prev_time]) {
                            if (_prev[prev_zoom_id][prev_date][prev_time].length === 1) {
                                _prev[prev_zoom_id][prev_date][prev_time] = [];
                            } else {
                                _prev[prev_zoom_id][prev_date][prev_time].splice(prev_event_idx, 1);
                            }
                        }

                        // now add the new event
                        if (!_prev[zoom_id]) {
                            _prev[zoom_id] = {};
                        }

                        if (!_prev[zoom_id][date]) {
                            _prev[zoom_id][date] = {};
                        }

                        if (!_prev[zoom_id][date][_time]) {
                            _prev[zoom_id][date][_time] = [];
                        }

                        _prev[zoom_id][date][_time].push(event);

                        return _prev;
                    });

                    // also need to update the courseMap
                    setCourseMap((prev) => {
                        const _prev = { ...prev };
                        const eventKeyObjs = _prev[event.course_id];
                        for (let eventKey of eventKeyObjs) {
                            if (eventKey.zoom_id === prev_zoom_id && eventKey.date === prev_date && eventKey._time === prev_time && eventKey.event_idx === prev_event_idx) {
                                eventKey.zoom_id = zoom_id;
                                eventKey.date = date;
                                eventKey._time = _time;
                                eventKey.event_idx = event_idx;
                            }
                        }
                        return _prev;
                    });

                    // also need to update the attendanceMap
                    setAttendanceMap((prev) => {
                        const _prev = { ...prev };
                        const attendances = event.attendances;
                        for (let i = 0; i < attendances.length; i++) {
                            const attendance = attendances[i];
                            if (_prev[attendance.id]) {
                                const eventKeyObjs = _prev[attendance.id];
                                for (let eventKey of eventKeyObjs) {
                                    if (eventKey.zoom_id === prev_zoom_id && eventKey.date === prev_date && eventKey._time === prev_time && eventKey.event_idx === prev_event_idx) {
                                        eventKey.zoom_id = zoom_id;
                                        eventKey.date = date;
                                        eventKey._time = _time;
                                        eventKey.event_idx = event_idx;
                                        eventKey.event_id = event.id;
                                        eventKey.attendance_idx = i;
                                    }
                                }
                            }
                        }
                        return _prev;
                    });
                }

                if (is_event_zoom_assign && event) {
                    /**
                     * delete the prev event in zoom_id key
                     * and add the new event in new_zoom_id key
                     */
                    setEventsByTime((prev) => {
                        const _prev = { ...prev };
                        if (!_prev[zoom_id] || !_prev[zoom_id][date] || !_prev[zoom_id][date][_time]) {
                            return _prev;
                        }
                        const event_list = _prev[zoom_id][date][_time];
                        event_list.splice(event_idx, 1);
                        _prev[zoom_id][date][_time] = event_list;
                        
                        if (!_prev[new_zoom_id]) {
                            _prev[new_zoom_id] = {};
                        }

                        if (!_prev[new_zoom_id][date]) {
                            _prev[new_zoom_id][date] = {};
                        }

                        if (!_prev[new_zoom_id][date][_time]) {
                            _prev[new_zoom_id][date][_time] = [];
                        }

                        _prev[new_zoom_id][date][_time].push(event);
                        return _prev;
                    });

                    // also need to update the courseMap
                    setCourseMap((prev) => {
                        const _prev = { ...prev };
                        if (_prev[event.course_id]) {
                            const eventKeyObjs = _prev[event.course_id];
                            for (let eventKey of eventKeyObjs) {
                                if (eventKey.zoom_id === zoom_id && eventKey.date === date && eventKey._time === _time && eventKey.event_idx === event_idx) {
                                    eventKey.zoom_id = new_zoom_id;
                                }
                            }
                        }
                        return _prev;
                    });

                    // also need to update the attendanceMap
                    setAttendanceMap((prev) => {
                        const _prev = { ...prev };
                        const attendances = event.attendances;
                        for (const attendance of attendances) {
                            if (_prev[attendance.id]) {
                                const eventKeyObjs = _prev[attendance.id];
                                for (let eventKey of eventKeyObjs) {
                                    if (eventKey.zoom_id === zoom_id && eventKey.date === date && eventKey._time === _time && eventKey.event_idx === event_idx) {
                                        eventKey.zoom_id = new_zoom_id;
                                    }
                                }
                            }
                        }
                        return _prev;
                    });
                }

                if (is_event_schedule_update && event) {
                    /**
                     * for schedule update, we need to remove the prev event from eventsByTime.
                     * and then add the updated event to eventsByTime using new start time.
                     */
                    const new_time = dayjs(event.start).tz("America/New_York").format("HH:mm");

                    setEventsByTime((prev) => {
                        const _prev = { ...prev };
                        if (!_prev[zoom_id] || !_prev[zoom_id][date] || !_prev[zoom_id][date][_time]) {
                            return _prev;
                        }
                        
                        // splice the prev event
                        const event_list = _prev[zoom_id][date][_time];
                        event_list.splice(event_idx, 1);
                        _prev[zoom_id][date][_time] = event_list;
                        // add the updated event
                        if (!_prev[zoom_id][date][new_time]) {
                            _prev[zoom_id][date][new_time] = [];
                        }

                        _prev[zoom_id][date][new_time].push(event);
                        return _prev;
                    });

                    // also need to update the courseMap
                    setCourseMap((prev) => {
                        const _prev = { ...prev };
                        if (_prev[event.course_id]) {
                            const eventKeyObjs = _prev[event.course_id];
                            for (let eventKey of eventKeyObjs) {
                                if (eventKey.zoom_id === zoom_id && eventKey.date === date && eventKey._time === _time && eventKey.event_idx === event_idx) {
                                    eventKey._time = new_time;
                                }
                            }
                        }
                        return _prev;
                    });

                    // also need to update the attendanceMap
                    setAttendanceMap((prev) => {
                        const _prev = { ...prev };
                        const attendances = event.attendances;
                        for (const attendance of attendances) {
                            if (_prev[attendance.id]) {
                                const eventKeyObjs = _prev[attendance.id];
                                for (let eventKey of eventKeyObjs) {
                                    if (eventKey.zoom_id === zoom_id && eventKey.date === date && eventKey._time === _time && eventKey.event_idx === event_idx) {
                                        eventKey._time = new_time;
                                    }
                                }
                            }
                        }
                        return _prev;
                    });
                }

                if (is_event_update && event) {
                    // when zoom_id, date, and time are not changed, simply update the event
                    setEventsByTime((prev) => {
                        const _prev = { ...prev };
                        if (!_prev[zoom_id] || !_prev[zoom_id][date] || !_prev[zoom_id][date][_time]) {
                            return _prev;
                        }
                        _prev[zoom_id][date][_time][event_idx] = event;
                        return _prev;
                    });
                }

                if (is_attendances_update && attendances) {
                    for (let attendance of attendances) {
                        if (attendanceMap[attendance.id]) {

                            let course_id = null;

                            for (let eventKey of attendanceMap[attendance.id]) {
                                const { zoom_id, date, _time, event_idx, attendance_idx } = eventKey;
                                setEventsByTime((prev) => {
                                    const _prev = { ...prev };
                                    if (!_prev[zoom_id] || !_prev[zoom_id][date] || !_prev[zoom_id][date][_time]) {
                                        return _prev;
                                    }
                                    _prev[zoom_id][date][_time][event_idx].attendances[attendance_idx] = attendance;
                                    return _prev;
                                });

                                course_id = eventsByTime[zoom_id][date][_time][event_idx].course_id;
                            }

                            const note = notesMap[attendance.student_id][course_id][attendance.id];
                            if (note) {
                                if (is_note_deleted) {
                                    setNotesMap((prev) => {
                                        const _prev = { ...prev };
                                        _prev[attendance.student_id][course_id][attendance.id] = null;

                                        return _prev;
                                    });
                                } else {
                                    setNotesMap((prev) => {
                                        const _prev = { ...prev };

                                        if (attendance.note && attendance.note !== "") {
                                            const note = notesMap[attendance.student_id][course_id][attendance.id];
                                            if (note) {
                                                note.note = attendance.note;
                                                note.status = attendance.status;
                                                _prev[attendance.student_id][course_id][attendance.id] = note;
                                            } else {
                                                _prev[attendance.student_id][course_id][attendance.id] = {
                                                    attendance_id: attendance.id,
                                                    note: attendance.note,
                                                    status: attendance.status,
                                                    date: date,
                                                };
                                            }
                                        } else {
                                            const note = notesMap[attendance.student_id][course_id][attendance.id];
                                            if (note) {
                                                _prev[attendance.student_id][course_id][attendance.id] = null;
                                            }
                                        }

                                        return _prev;
                                    });
                                }
                            }
                        } else {
                            // probably new attendance is added
                            // so we need to update maps using refetchEventsByTime
                            setRefetchEventsByTime(true);
                            break;
                        }
                    }
                }

                if (is_new_student_added && attendances && course) {
                    const _attendanceMap = {};

                    // need to find the event key objects
                    const eventKeyObjs = courseMap[course.id];
                    for (let eventKey of eventKeyObjs) {
                        const { zoom_id, date, _time, event_idx, event_id } = eventKey;

                        const attendance = attendances.find((a) => a.event_id === event_id);
                        const attendance_idx = eventsByTime[zoom_id][date][_time][event_idx].attendances.length;

                        if (!attendance) {
                            continue;
                        }

                        setEventsByTime((prev) => {
                            const _prev = { ...prev };
                            if (!_prev[zoom_id] || !_prev[zoom_id][date] || !_prev[zoom_id][date][_time]) {
                                return _prev;
                            }
                            _prev[zoom_id][date][_time][event_idx].attendances.push(attendance);
                            return _prev;
                        });

                        // also need to update the attendanceMap
                        _attendanceMap[attendance.id] = {
                            zoom_id,
                            date,
                            _time,
                            event_idx,
                            attendance_idx,
                        };
                    }

                    setAttendanceMap((prev) => {
                        const _prev = { ...prev };
                        for (let key in _attendanceMap) {
                            if (!_prev[key]) {
                                _prev[key] = [];
                            }
                            _prev[key].push(_attendanceMap[key]);
                        }
                        return _prev;
                    });
                }

                if ((is_student_removed && attendances) || (is_student_deleted && student)) {
                    const eventKeyList = [];
                    const attendance_ids = [];

                    if (is_student_removed && attendances) {
                        attendance_ids.push(...attendances.map((a) => a.id));
                    } else if (is_student_deleted && student) {
                        attendance_ids.push(...Object.keys(attendanceStudentIdMap).filter((attendance_id) => {
                            return attendanceStudentIdMap[attendance_id] === student.id;
                        }));
                    }

                    for (let attendance_id of attendance_ids) {
                        if (attendanceMap[attendance_id]) {
                            for (let eventKey of attendanceMap[attendance_id]) {
                                const { zoom_id, date, _time, event_idx, attendance_idx } = eventKey;
                                setEventsByTime((prev) => {
                                    const _prev = { ...prev };
                                    if (!_prev[zoom_id] || !_prev[zoom_id][date] || !_prev[zoom_id][date][_time]) {
                                        return _prev;
                                    }

                                    if (_prev[zoom_id][date][_time][event_idx].attendances.length === 1) {
                                        _prev[zoom_id][date][_time][event_idx].attendances = [];
                                    } else {
                                        _prev[zoom_id][date][_time][event_idx].attendances.splice(attendance_idx, 1);
                                    }
                                    return _prev;
                                });

                                eventKeyList.push(eventKey);
                            }
                        }
                    }

                    // also need to update the attendance_idx for the rest of attendances
                    setTimeout(() => {
                        for (let eventKey of eventKeyList) {
                            const { zoom_id, date, _time, event_idx } = eventKey;
                            attendance_idx_update(zoom_id, date, _time, event_idx);
                        }
                    }, 5000); // wait for setEventsByTime to be updated
                }
        
                // Post a new main memo
                if (main_memo_post) {
                    setMainMemo((prev) => [...prev, return_weekly_main_memo]);
                }

                // Update an existing main memo
                if (main_memo_update) {
                    setMainMemo((prev) => {
                        const _prev = [...prev];
                        const idx = _prev.findIndex((memo) => memo.id === updated_main_memo.id);
                        if (idx > -1) {
                            const active_users_num = _prev[idx].active_users_num ? _prev[idx].active_users_num : 0;
                            updated_main_memo.active_users_num = active_users_num;

                            _prev[idx] = updated_main_memo;
                        }
                        return _prev;
                    });
                }

                // Delete a main memo and rearrange indexes
                if (main_memo_delete) {
                    setMainMemo(return_weekly_main_memo);
                }

                // update daily memos
                if (is_weekly_daily_memos) {
                    setDailyMemos(
                        (prev) => {
                            const new_memos = [];
                            let active_users_num = 0;
                            for (let memo of prev) {
                                if (memo.id !== updated_daily_memo.id) {
                                    new_memos.push(memo);
                                } else {
                                    active_users_num = memo.active_users_num ? memo.active_users_num : 0;
                                }
                            }

                            updated_daily_memo.active_users_num = active_users_num;
                            new_memos.push(updated_daily_memo);
                            return new_memos;
                        }
                    )
                }

                // update cell memos
                if (is_weekly_cell_memos) {
                    setCellMemoMap((prev) => {
                        const _prev = { ...prev };
                        let active_users_num = 0;
                        if (!_prev[updated_cell_memo.date]) {
                            _prev[updated_cell_memo.date] = {};
                        }
                        if (!_prev[updated_cell_memo.date][updated_cell_memo.time]) {
                            _prev[updated_cell_memo.date][updated_cell_memo.time] = {};
                        }
                        if (_prev[updated_cell_memo.date][updated_cell_memo.time][updated_cell_memo.zoom_id]) {
                            active_users_num = _prev[updated_cell_memo.date][updated_cell_memo.time][updated_cell_memo.zoom_id].active_users_num ? _prev[updated_cell_memo.date][updated_cell_memo.time][updated_cell_memo.zoom_id].active_users_num : 0;
                        }
                        
                        updated_cell_memo.active_users_num = active_users_num;
                        _prev[updated_cell_memo.date][updated_cell_memo.time][updated_cell_memo.zoom_id] = updated_cell_memo;
                        return _prev;
                    })
                }

                // copy cell memos from previous week
                if (is_weekly_cell_daily_memos_copy_prev_week) {
                    // if updated_week is not equal to thisWeek, we don't need to update the eventsByTime
                    let isThisWeek = true;
                    for (let i = 0; i < updated_week.length; i++) {
                        if (thisWeek[i] !== updated_week[i]) {
                            isThisWeek = false;
                            break;
                        }
                    }

                    // simply refetch the eventsByTime
                    if (isThisWeek) {
                        updated_cell_memos.forEach((memo) => {
                            setCellMemoMap((prev) => {
                                const _prev = { ...prev };
                                let active_users_num = 0;
                                if (!_prev[memo.date]) {
                                    _prev[memo.date] = {};
                                }
                                if (!_prev[memo.date][memo.time]) {
                                    _prev[memo.date][memo.time] = {};
                                }
                                if (_prev[memo.date][memo.time][memo.zoom_id]) {
                                    active_users_num = _prev[memo.date][memo.time][memo.zoom_id].active_users_num ? _prev[memo.date][memo.time][memo.zoom_id].active_users_num : 0;
                                }
                                
                                memo.active_users_num = active_users_num;
                                _prev[memo.date][memo.time][memo.zoom_id] = memo;
                                return _prev;
                            });
                        });

                        setDailyMemos(updated_daily_memos);
                    }
                }

                if (message){
                    enqueueSnackbar(message, { variant: 'success' });
                }
            }
        } catch (error) {
            console.log("Error: ", error);
            // need to refetch the eventsByTime so that the table will be updated
            setRefetchEventsByTime(true);
        }
    }, [globalMessages]);

    React.useEffect(() => {
        let ignore = false;

        // rerender the table
        async function getAdminWeeklySettings() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/admins/${userId}/weekly-settings`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                if (ignore) return;
                // setAdminWeeklySettings(response.data);
                setAdminWeeklySettings(response.data);
                setWeeklySettingsUpdated(false);
            }).catch((error) => {
                console.log(error.response.data.message);
                enqueueSnackbar(error.response.data.message || error.response.data.error || "Cannot fetch Weekly Settings at the moment!", { variant: 'error' });
                setWeeklySettingsUpdated(false);                
            });
        }
        
        if (weeklySettingsUpdated) {
            getAdminWeeklySettings();
        }

        return () => {
            ignore = true;
        };
    }, [weeklySettingsUpdated]);

    React.useEffect(() => {
        localStorage.setItem('selectedMenu', 'Weekly');

        async function fetchZoomRooms() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/zooms`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                }
            }).then((res) => {
                setZoomRooms(res.data);
                setLoading(false);
            }).catch((err) => {
                if (err.response.status === 401) {
                    handleLogout();
                } else {
                    enqueueSnackbar(err.response.data.message || err.response.data.error || "Cannot fetch Zoom at the moment!", { variant: 'error' });
                }

                setLoading(false);
            });
        }

        setLoading(true);
        fetchZoomRooms();
    }, []);

    React.useEffect(() => {
        let ignore = false;
        function findThisWeekWithDate(date) {
            const thisWeek = [];
            for (let i = 1; i < 8; i++) {
                // from Monday to Sunday
                thisWeek.push(date.startOf('week').add(i, 'day').format('YYYY-MM-DD'));
            }
            
            setThisWeek(thisWeek);
            setCheckedAttendanceList([]);
            setCheckedTeacherCourseList([]);
            return;
        }

        async function findSemesterAndSemesterWeekNum(date) {
            let _date = date.startOf('week').add(3, 'day');

            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/weekly/semester-weeks?date=${_date.format("YYYY-MM-DD HH:mm:ss")}`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((res) => {
                setSemesterSeason(res.data.semester_season);
                setSemesterWeekNum(res.data.semester_week_num);
            }).catch((err) => {
                console.log(err);
            });
        }
    
        if (!ignore) {
            findThisWeekWithDate(date);
            findSemesterAndSemesterWeekNum(date);
        }

        return () => {
            ignore = true;
        };
    }, [date]);

    React.useEffect(() => {
        async function fetchMobileMessageTemplates() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/mobile-message-templates`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                setMobileMessageTemplates(response.data);
            }).catch((error) => {
                if (error.response.status === 404) {
                    enqueueSnackbar("No mobile message templates found.", { variant: "info" });
                    setMobileMessageTemplates([]);

                    return;
                }
                console.error(error);
                enqueueSnackbar("Error fetching mobile message templates.", { variant: "error" });
            });
        }

        fetchMobileMessageTemplates();
    }, [fetchTriggerMobileMessageTemplates]);

    async function getEventsByZoomAndDate(ignore = false) {
        const start_of_week = dayjs(thisWeek[0]).tz("America/New_York").startOf("day").format();
        const end_of_week = dayjs(thisWeek[thisWeek.length - 1]).tz("America/New_York").endOf("day").format();

        await Axios.post(`${process.env.REACT_APP_URL}/api/v1/weekly/zooms/events`, {
            start_of_week,
            end_of_week,
        }, {
            headers: {
                Authorization: `Bearer ${document?.cookie?.split("=")[1]?.split(";")[0]}`,
            },
        }).then((res) => {
            if (ignore) return
            setEventsByTime(res.data.events_dict);
            setCourseMap(res.data.courseMap);
            setAttendanceMap(res.data.attendanceMap);
            setAttendanceStudentIdMap(res.data.attendanceStudentIdMap);
            setTeacherMap(res.data.teacherMap);
            setRefetchEventsByTime(false);
            setNotesMap(res.data.notesMap);
            setTinyLoading(false);
        }).catch((err) => {
            console.log("Error: ", err);
            setRefetchEventsByTime(false);
            setTinyLoading(false);
        });
    }

    async function getCellMemos(ignore = false) {
        const start_of_week = dayjs(thisWeek[0]).tz("America/New_York").startOf("day").format();

        await Axios.post(`${process.env.REACT_APP_URL}/api/v1/weekly/cell-memos`, {
            start_of_week,
        }, {
            headers: {
                Authorization: `Bearer ${document?.cookie?.split("=")[1]?.split(";")[0]}`,
            },
        }).then((res) => {
            if (ignore) return
            setCellMemoMap(res.data);
            setTinyLoading(false);
        }).catch((err) => {
            console.log("Error: ", err);
            setTinyLoading(false);
        });
    }

    React.useEffect(() => {
        setTinyLoading(true);
        let ignore = false;

        if (thisWeek.length > 0 && zoomRooms.length > 0 && socketConnected) {
            getEventsByZoomAndDate(ignore);
            getCellMemos(ignore);
        } else {
            setTinyLoading(false);
        }

        return () => {
            ignore = true;
        };
    }, [thisWeek, zoomRooms, socketConnected]);

    React.useEffect(() => {
        let ignore = false;

        async function getWeeklyDailyMemos(ignore = false) {
            const start_of_week = dayjs(thisWeek[0]).tz("America/New_York").startOf("day").format();
    
            await Axios.post(`${process.env.REACT_APP_URL}/api/v1/weekly/daily-memos`, {
                start_of_week,
            }, {
                headers: {
                    Authorization: `Bearer ${document?.cookie?.split("=")[1]?.split(";")[0]}`,
                },
            }).then((res) => {
                if (ignore) return;
                setDailyMemos(res.data);
            }).catch((err) => {
                console.log("Error: ", err);
            });
        }

        if (thisWeek.length > 0 && socketConnected) {
            getWeeklyDailyMemos(ignore);
        }

        return () => {
            ignore = true;
        }
    }, [thisWeek, socketConnected]);

    React.useEffect(() => {
        let ignore = false;

        if (refetchEventsByTime) {
            setTinyLoading(true);
            getEventsByZoomAndDate(ignore);
        }

        return () => {
            ignore = true;
        }
    }, [refetchEventsByTime]);

    function getDate(day_index, mm_dd = true) {
        return mm_dd ? thisWeek[day_index / 2]?.split("-")?.splice(1)?.join("-") : thisWeek[day_index / 2];
    }

    React.useEffect(() => {
        if (loading || !socketConnected || !adminWeeklySettings) {
            return;
        }

        // Query the table
        const table = document.getElementById('resizableTable');

        if (!table) {
            return;
        }

        // Query all headers
        const cols = table.querySelectorAll('.table-header');

        if (!cols) {
            return;
        }

        // Loop over them
        [].forEach.call(cols, function (col) {
            // Create a resizer element
            const resizer = document.createElement('div');
            resizer.classList.add('resizer');

            // Set the height
            resizer.style.height = `${table.offsetHeight}px`;

            // Add a resizer element to the column
            col.appendChild(resizer);

            // Will be implemented in the next section
            createResizableColumn(col, resizer);
        });

    }, [loading, socketConnected, adminWeeklySettings]);

    const createResizableColumn = function (col, resizer) {
        // Track the current position of mouse
        let x = 0;
        let w = 0;
    
        const mouseDownHandler = function (e) {
            // Get the current mouse position
            x = e.clientX;
    
            // Calculate the current width of column
            const styles = window.getComputedStyle(col);
            w = parseInt(styles.width, 10);
    
            // Attach listeners for document's events
            document.addEventListener('mousemove', mouseMoveHandler);
            document.addEventListener('mouseup', mouseUpHandler);

            resizer.classList.add('resizing');
        };
    
        const mouseMoveHandler = function (e) {
            // Determine how far the mouse has been moved
            const dx = e.clientX - x;
    
            // Update the width of column
            col.style.width = `${w + dx}px`;
        };
    
        // When user releases the mouse, remove the existing event listeners
        const mouseUpHandler = function () {
            document.removeEventListener('mousemove', mouseMoveHandler);
            document.removeEventListener('mouseup', mouseUpHandler);

            resizer.classList.remove('resizing');
        };
    
        resizer.addEventListener('mousedown', mouseDownHandler);
    };

    function event_idx_update(zoom_id, date, _time, course_id) {
        const events = eventsByTime[zoom_id][date][_time];
        for (let i = 0; i < events.length; i++) {
            const new_event_idx = i;
            const event = events[i];


            const eventKeyObjs = courseMap[course_id];
            if (eventKeyObjs && eventKeyObjs.length > 0) {
                setCourseMap((prev) => {
                    const _prev = { ...prev };
                    const eventKeyObjs = _prev[course_id];
                    for (let eventKey of eventKeyObjs) {
                        if (eventKey.zoom_id === zoom_id && eventKey.date === date && eventKey._time === _time && eventKey.event_id === event.id) {
                            eventKey.event_idx = new_event_idx;
                        }
                    }
                    return _prev;
                });
            }

            setAttendanceMap((prev) => {
                const _prev = { ...prev };
                const attendances = event.attendances ?? [];
                for (const attendance of attendances) {
                    if (_prev[attendance.id]) {
                        const eventKeyObjs = _prev[attendance.id];
                        for (let eventKey of eventKeyObjs) {
                            if (eventKey.zoom_id === zoom_id && eventKey.date === date && eventKey._time === _time && eventKey.event_id === event.id) {
                                eventKey.event_idx = new_event_idx;
                            }
                        }
                    }
                }
                return _prev;
            });
        }
    }

    function attendance_idx_update(zoom_id, date, _time, event_idx) {
        const event = eventsByTime[zoom_id][date][_time][event_idx];
        const attendances = event.attendances;
        for (let i = 0; i < attendances.length; i++) {
            const new_attendance_idx = i;
            const attendance = attendances[i];

            setAttendanceMap((prev) => {
                const _prev = { ...prev };
                if (_prev[attendance.id]) {
                    const eventKeyObjs = _prev[attendance.id];
                    for (let eventKey of eventKeyObjs) {
                        if (eventKey.zoom_id === zoom_id && eventKey.date === date && eventKey._time === _time && eventKey.event_idx === event_idx) {
                            eventKey.attendance_idx = new_attendance_idx;
                        }
                    }
                }
                return _prev;
            });
        }
    }

    function getSemesterSeason(season, week) {
        if (season === null || week === null) {
            return "n/a";
        }

        return semesterSeasons[season] + " Week " + week;
    }

    function getDailyMemoByDate(date) {
        return dailyMemos.find((memo) => {
            const mm_dd = memo.date.split("-").splice(1).join("-");
            return mm_dd === date;
        });
    }

    function getEachZoomColumnWidth() {
        if (adminWeeklySettings && adminWeeklySettings.each_zoom_column_width) {
            return adminWeeklySettings.each_zoom_column_width;
        }

        return 600; // default width
    }

    function getDayByLanguageForDay(day) {
        if (!adminWeeklySettings) {
            return day;
        }

        if (adminWeeklySettings.language_for_day === "ko") {
            if (day === "Mon")
                return "월";
            if (day === "Tue")
                return "화";
            if (day === "Wed")
                return "수";
            if (day === "Thu")
                return "목";
            if (day === "Fri")
                return "금";
            if (day === "Sat")
                return "토";
            if (day === "Sun")
                return "일";
        }

        return day;
    }

    return (
        <Box 
            minHeight={`calc(100vh - 70px)`} 
            maxHeight={`calc(100vh - 70px)`}
            sx={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
                overflow: "auto",
            }}
        >
            { loading || !zoomRooms || !socketConnected || !eventsByTime || !adminWeeklySettings ?
                <Loading />
            : <>      
                {/* Online Users on this page */}
                <Box
                    sx={{
                        position: "sticky",
                        // top: 0,
                        left: 10,
                        display: "flex",
                        flexDirection: "column",
                        padding: 1,
                        marginLeft: 1,
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            textAlign: "center",
                            py: 1,
                            gap: 1,
                        }}
                    >
                        <FiberManualRecordIcon fontSize='small' color='success' />
                        <Typography variant="h5" sx={{ color: colors.primary[100] }}>
                            Online
                        </Typography>
                    </Box>
                    <Stack direction="row" spacing={1}>
                        {adminUuidList.length > 0 && adminUuidList.map((id, idx) => {
                            return (
                                <Chip 
                                    label={socketIoUsers[id].name} 
                                    key={idx} 
                                    sx={{ 
                                        bgcolor: socketIoUsers[id].color ? socketIoUsers[id].color : "primary",
                                        color: 'black',
                                        border: `1px solid ${colors.primary[100]}`,
                                    }} 
                                />
                            )
                        })}
                    </Stack>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        // overflowX: "auto",
                        minHeight: "500px"
                    }}
                >
                    <MainMemoComp
                        colors={colors}
                        userId={userId}
                        socket={socket}
                        mainMemo={mainMemo}
                        setMainMemo={setMainMemo}
                        handleLogout={handleLogout}
                        enqueueSnackbar={enqueueSnackbar}
                        // isThisAdminInViewMode={isThisAdminInViewMode}
                    />
                </Box>
                <table
                    id='resizableTable'
                    className='table'
                    style={{
                        minWidth: getEachZoomColumnWidth() * zoomRooms.length + 200 + (adminWeeklySettings.is_no_zoom_visible ? getEachZoomColumnWidth() : 0),
                        minHeight: 'calc(100vh - 70px)',
                        borderCollapse: 'collapse',
                    }}
                >
                    <thead
                        style={{
                            position: "sticky",
                            top: 0,
                            zIndex: 3,
                            backgroundColor: colors.primary[100],
                            color: colors.primary[600],
                            border: '1px solid white',
                            borderCollapse: 'collapse',
                            height: 40,
                        }}
                    >
                        <tr>
                            <th
                                className='table-header'
                                style={{
                                    border: '1px solid white',
                                    borderCollapse: 'collapse',
                                    textAlign: "center",
                                    height: "100%",
                                    width: 200,
                                    // maxWidth: 200,
                                    color: colors.primary[600],
                                    zIndex: 3,
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        px: "10px",
                                        py: 1,
                                        textAlign: "center",
                                        // width: "100%",
                                        backgroundColor: colors.primary[100],
                                        border: '1px solid white',
                                    }}
                                >                                    
                                    <IconButton 
                                        onClick={() => setDate(date.subtract(1, 'week'))}
                                        sx={{
                                            color: colors.primary[600],
                                            padding: 0,
                                            flex: 1,
                                        }}
                                    >
                                        <KeyboardArrowLeftIcon />
                                    </IconButton>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            width: 44,
                                            height: 22,
                                            flex: 2,
                                        }}
                                    >
                                        {tinyLoading ? (
                                            <CircularProgress 
                                                size={20} 
                                                sx={{
                                                    color: colors.primary[600],
                                                }}
                                            />
                                        ) : (
                                            <Typography variant="h7">
                                                {getSemesterSeason(semesterSeason, semesterWeekNum)}
                                                {/* {date.format('MM-DD')} */}
                                            </Typography>
                                        )}
                                    </Box>
                                    <IconButton 
                                        onClick={() => setDate(date.add(1, 'week'))}
                                        sx={{
                                            color: colors.primary[600],
                                            padding: 0,
                                            flex: 1,
                                        }}
                                    >
                                        <KeyboardArrowRightIcon />
                                    </IconButton>

                                    {/* Today Icon */}
                                    <Tooltip title="This Week" placement="top" arrow>
                                        <IconButton
                                            onClick={() => {
                                                if (dayjs().tz("America/New_York").isSame(date, 'week')) {
                                                    return;
                                                }

                                                setDate(dayjs().tz("America/New_York"))
                                            }}
                                            sx={{
                                                color: colors.primary[600],
                                                padding: 0,
                                            }}
                                        >
                                            <TodayIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </th>
                            {zoomRooms && zoomRooms.map((room, idx) => {
                                return (
                                    <th 
                                        key={idx}
                                        className='table-header'
                                        style={{
                                            border: '1px solid white',
                                            borderCollapse: 'collapse',
                                            alignItems: "center",
                                            fontSize: 16,
                                            width: getEachZoomColumnWidth(),
                                        }}
                                    >
                                        <Typography 
                                            variant="h6"
                                        >
                                            Zoom {room.room_number} {room.remark && room.remark !== "" && `(${room.remark})`}
                                        </Typography>
                                    </th>
                                )
                            })}
                            {adminWeeklySettings.is_no_zoom_visible && (
                                <th 
                                    className='table-header'
                                    style={{
                                        border: '1px solid white',
                                        borderCollapse: 'collapse',
                                        alignItems: "center",
                                        fontSize: 16,
                                        width: getEachZoomColumnWidth(),
                                        // minWidth: 600,
                                    }}
                                >
                                    <Typography 
                                        variant="h6"
                                    >
                                        No Zoom
                                    </Typography>
                                </th>
                            )}
                        </tr>
                    </thead>
                    
                    <tbody
                        style={{
                            borderCollapse: 'collapse',
                        }}
                    >
                        {days.map((day, index) => {
                            return (
                                day !== "" && adminWeeklySettings.day_visibility_arr[index / 2] === true ? (
                                    times.map((time, idx) => {
                                        let ret = 1;
                                        switch (day) {
                                            case "Mon":
                                                if (!adminWeeklySettings.mon_times || !adminWeeklySettings.mon_times.includes(time)) {
                                                    ret = 0;
                                                }
                                                break;
                                            case "Tue":
                                                if (!adminWeeklySettings.tue_times || !adminWeeklySettings.tue_times.includes(time)) {
                                                    ret = 0;
                                                }
                                                break;
                                            case "Wed":
                                                if (!adminWeeklySettings.wed_times || !adminWeeklySettings.wed_times.includes(time)) {
                                                    ret = 0;
                                                }
                                                break;
                                            case "Thu":
                                                if (!adminWeeklySettings.thu_times || !adminWeeklySettings.thu_times.includes(time)) {
                                                    ret = 0;
                                                }
                                                break;
                                            case "Fri":
                                                if (!adminWeeklySettings.fri_times || !adminWeeklySettings.fri_times.includes(time)) {
                                                    ret = 0;
                                                }
                                                break;
                                            case "Sat":
                                                if (!adminWeeklySettings.sat_times || !adminWeeklySettings.sat_times.includes(time)) {
                                                    ret = 0;
                                                }
                                                break;
                                            case "Sun":
                                                if (!adminWeeklySettings.sun_times || !adminWeeklySettings.sun_times.includes(time)) {
                                                    ret = 0;
                                                }
                                                break;
                                            default:
                                                break;
                                        }

                                        if (ret === 0) {
                                            return null;
                                        }

                                        const _time = time.split("-")[0];

                                        return (
                                            <tr
                                                key={day + "-" + index + "-" + time + "-"  + idx}
                                                style={{
                                                    height: adminWeeklySettings.table_row_height ? adminWeeklySettings.table_row_height : ROW_HEIGHT,
                                                    borderBottom: `1px solid ${colors.grey[600]}`,
                                                    borderCollapse: 'collapse',
                                                }}
                                            >
                                                <th
                                                    scope="row"
                                                    style={{
                                                        height: "inherit",
                                                        borderBottom: `1px solid ${colors.grey[600]}`,
                                                        borderCollapse: 'collapse',
                                                        zIndex: 2,
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            backgroundColor: colors.grey[900],
                                                            borderRight: `1px solid ${colors.primary[100]}`,
                                                            height: "inherit",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            zIndex: 2,
                                                        }}
                                                    >
                                                        {getDate(index)} {getDayByLanguageForDay(day)} {dayjs(_time, "HH:mm").format("hh:mm") + "-" + dayjs(_time, "HH:mm").add(30, 'minute').format("hh:mm a")} 
                                                    </Box>
                                                </th>
                                                {zoomRooms && zoomRooms.map((room, idxx) => {
                                                    if (eventsByTime[room.id] && eventsByTime[room.id][getDate(index, false)] && eventsByTime[room.id][getDate(index, false)][_time]) {
                                                        return (
                                                            <td
                                                                key={day + "-" + index + "-" + time + "-" + idx + "-" + room.id + "-" + idxx}
                                                                style={{
                                                                    backgroundColor: colors.primary[600],
                                                                    borderRight: `1px solid ${colors.grey[600]}`,
                                                                    borderCollapse: 'collapse',
                                                                    height: "inherit",
                                                                }}
                                                            >
                                                                <TimeBlock
                                                                    admin_name={admin_name}
                                                                    userId={userId}
                                                                    socketConnected={socketConnected}
                                                                    zoomRooms={zoomRooms}
                                                                    zoom={room}
                                                                    date={getDate(index, false)}
                                                                    day={day}
                                                                    time={time}
                                                                    _time={_time}
                                                                    idx={idx}
                                                                    times_len={times.length}
                                                                    events={eventsByTime[room.id][getDate(index, false)][_time]}
                                                                    socketIoUsers={socketIoUsers}
                                                                    ROW_HEIGHT={adminWeeklySettings.table_row_height}
                                                                    enqueueSnackbar={enqueueSnackbar}
                                                                    setStudentForMoreInfo={setStudentForMoreInfo}
                                                                    setTeacherForMoreInfo={setTeacherForMoreInfo}
                                                                    setBackdropOpen={setBackdropOpen}
                                                                    handleBackdropClose={handleBackdropClose}
                                                                    setCheckedAttendanceList={setCheckedAttendanceList}
                                                                    setCheckedTeacherCourseList={setCheckedTeacherCourseList}
                                                                    checkedAttendanceList={checkedAttendanceList}
                                                                    checkedTeacherCourseList={checkedTeacherCourseList}
                                                                    showCheckBox={showCheckBox}
                                                                    mobileMessageTemplates={mobileMessageTemplates}
                                                                    cellMemoMap={cellMemoMap}
                                                                    notesMap={notesMap}
                                                                    setNotesModalOpen={setNotesModalOpen}
                                                                    setNotesModalData={setNotesModalData}
                                                                    setDataForRequest={setDataForRequest}
                                                                    classLogUpdatedEventId={classLogUpdatedEventId}
                                                                    setClassLogUpdatedEventId={setClassLogUpdatedEventId}
                                                                    // isThisAdminInViewMode={isThisAdminInViewMode}
                                                                />
                                                            </td>
                                                        )
                                                    } else {
                                                        return (
                                                            <td
                                                                key={day + "-" + index + "-" + time + "-" + idx + "-" + room.id + "-" + idxx}
                                                                style={{
                                                                    backgroundColor: colors.primary[600],
                                                                    borderRight: `1px solid ${colors.grey[600]}`,
                                                                    borderCollapse: 'collapse',
                                                                    height: "inherit",
                                                                }}
                                                            >
                                                                <TimeBlock
                                                                    admin_name={admin_name}
                                                                    userId={userId}
                                                                    socketConnected={socketConnected}
                                                                    zoomRooms={zoomRooms}
                                                                    zoom={room}
                                                                    date={getDate(index, false)}
                                                                    day={day}
                                                                    time={time}
                                                                    _time={_time}
                                                                    idx={idx}
                                                                    times_len={times.length}
                                                                    events={[]}
                                                                    socketIoUsers={socketIoUsers}
                                                                    ROW_HEIGHT={adminWeeklySettings.table_row_height}
                                                                    enqueueSnackbar={enqueueSnackbar}
                                                                    setStudentForMoreInfo={setStudentForMoreInfo}
                                                                    setTeacherForMoreInfo={setTeacherForMoreInfo}
                                                                    setBackdropOpen={setBackdropOpen}
                                                                    handleBackdropClose={handleBackdropClose}
                                                                    setCheckedAttendanceList={setCheckedAttendanceList}
                                                                    setCheckedTeacherCourseList={setCheckedTeacherCourseList}
                                                                    checkedAttendanceList={null}
                                                                    checkedTeacherCourseList={null}
                                                                    showCheckBox={null}
                                                                    mobileMessageTemplates={mobileMessageTemplates}
                                                                    cellMemoMap={cellMemoMap}
                                                                    notesMap={notesMap}
                                                                    setNotesModalOpen={setNotesModalOpen}
                                                                    setNotesModalData={setNotesModalData}
                                                                    setDataForRequest={setDataForRequest}
                                                                    classLogUpdatedEventId={classLogUpdatedEventId}
                                                                    setClassLogUpdatedEventId={setClassLogUpdatedEventId}
                                                                    // isThisAdminInViewMode={isThisAdminInViewMode}
                                                                />
                                                            </td>
                                                        )
                                                    }
                                                })}

                                                {/* Modules that have not been assigned to zoom yet */}
                                                {adminWeeklySettings.is_no_zoom_visible && eventsByTime[null] && eventsByTime[null][getDate(index, false)] && eventsByTime[null][getDate(index, false)][_time] ? (
                                                    <td
                                                        style={{
                                                            backgroundColor: colors.primary[600],
                                                            borderRight: `1px solid ${colors.grey[600]}`,
                                                            borderCollapse: 'collapse',
                                                            height: "inherit",
                                                        }}
                                                    >
                                                        <TimeBlock
                                                            admin_name={admin_name}
                                                            userId={userId}
                                                            socketConnected={socketConnected}
                                                            zoomRooms={zoomRooms}
                                                            zoom={null}
                                                            date={getDate(index, false)}
                                                            day={day}
                                                            time={time}
                                                            _time={_time}
                                                            idx={idx}
                                                            times_len={times.length}
                                                            events={eventsByTime[null][getDate(index, false)][_time]}
                                                            socketIoUsers={socketIoUsers}
                                                            ROW_HEIGHT={adminWeeklySettings.table_row_height}
                                                            enqueueSnackbar={enqueueSnackbar}
                                                            setStudentForMoreInfo={setStudentForMoreInfo}
                                                            setTeacherForMoreInfo={setTeacherForMoreInfo}
                                                            setBackdropOpen={setBackdropOpen}
                                                            handleBackdropClose={handleBackdropClose}
                                                            setCheckedAttendanceList={setCheckedAttendanceList}
                                                            setCheckedTeacherCourseList={setCheckedTeacherCourseList}
                                                            checkedAttendanceList={checkedAttendanceList}
                                                            checkedTeacherCourseList={checkedTeacherCourseList}
                                                            showCheckBox={showCheckBox}
                                                            mobileMessageTemplates={mobileMessageTemplates}
                                                            cellMemoMap={cellMemoMap}
                                                            notesMap={notesMap}
                                                            setNotesModalOpen={setNotesModalOpen}
                                                            setNotesModalData={setNotesModalData}
                                                            setDataForRequest={setDataForRequest}
                                                            classLogUpdatedEventId={classLogUpdatedEventId}
                                                            setClassLogUpdatedEventId={setClassLogUpdatedEventId}
                                                            // isThisAdminInViewMode={isThisAdminInViewMode}
                                                        />
                                                    </td>
                                                ) : adminWeeklySettings.is_no_zoom_visible ? (
                                                    <td
                                                        style={{
                                                            backgroundColor: colors.primary[600],
                                                            borderRight: `1px solid ${colors.grey[600]}`,
                                                            borderCollapse: 'collapse',
                                                            height: "inherit",
                                                        }}
                                                    >
                                                        <TimeBlock
                                                            admin_name={admin_name}
                                                            userId={userId}
                                                            socketConnected={socketConnected}
                                                            zoomRooms={zoomRooms}
                                                            zoom={null}
                                                            date={getDate(index, false)}
                                                            day={day}
                                                            time={time}
                                                            _time={_time}
                                                            idx={idx}
                                                            times_len={times.length}
                                                            events={[]}
                                                            socketIoUsers={socketIoUsers}
                                                            ROW_HEIGHT={adminWeeklySettings.table_row_height}
                                                            enqueueSnackbar={enqueueSnackbar}
                                                            setStudentForMoreInfo={setStudentForMoreInfo}
                                                            setTeacherForMoreInfo={setTeacherForMoreInfo}
                                                            setBackdropOpen={setBackdropOpen}
                                                            handleBackdropClose={handleBackdropClose}
                                                            setCheckedAttendanceList={setCheckedAttendanceList}
                                                            setCheckedTeacherCourseList={setCheckedTeacherCourseList}
                                                            checkedAttendanceList={null}
                                                            checkedTeacherCourseList={null}
                                                            showCheckBox={null}
                                                            mobileMessageTemplates={mobileMessageTemplates}
                                                            cellMemoMap={cellMemoMap}
                                                            notesMap={notesMap}
                                                            setNotesModalOpen={setNotesModalOpen}
                                                            setNotesModalData={setNotesModalData}
                                                            setDataForRequest={setDataForRequest}
                                                            classLogUpdatedEventId={classLogUpdatedEventId}
                                                            setClassLogUpdatedEventId={setClassLogUpdatedEventId}
                                                            // isThisAdminInViewMode={isThisAdminInViewMode}
                                                        />
                                                    </td>
                                                ) : null}
                                            </tr>
                                        )
                                    })
                                ) : day === "" && adminWeeklySettings.day_visibility_arr[parseInt(index / 2)] === true ? (
                                    <tr
                                        key={day + "-" + index + "-0"}
                                        style={{
                                            height: 400,
                                        }}
                                    >
                                        <th 
                                            style={{
                                                height: "inherit",
                                                resize: "vertical",
                                                overflowY: "auto",
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    height: "inherit",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    py: 1,
                                                    gap: 1,
                                                }}
                                            >
                                                {dailyMemos.length > 0 && getDailyMemoByDate(getDate(index - 1)) && getDailyMemoByDate(getDate(index - 1)).active_users_num > 0 && (
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <RateReviewIcon fontSize='large' />
                                                    </Box>
                                                )}
                                            </Box>
                                        </th>
                                        {/* daily memo here */}
                                        <td
                                            style={{
                                                height: "inherit",
                                                position: "relative",
                                            }}
                                        >
                                            {getDailyMemoByDate(getDate(index - 1)) &&
                                                <DailyMemoComp
                                                    colors={colors}
                                                    socket={socket}
                                                    dailyMemo={getDailyMemoByDate(getDate(index - 1))}
                                                    userId={userId}
                                                    enqueueSnackbar={enqueueSnackbar}
                                                    // isThisAdminInViewMode={isThisAdminInViewMode}
                                                />
                                            }
                                        </td>
                                    </tr>
                                ) : null
                            )
                        })}
                    </tbody>
                </table>
                <Box
                    sx={{
                        position: "sticky",
                        bottom: 0,
                        display: "flex",
                    }}
                ></Box>
                {/* ATTENDANCE CONTROLLER */}
                {showCheckBox &&
                    <Box
                        sx={{
                            position: "fixed",
                            bottom: "54px",
                            right: "0px",
                            transform: "translateX(-50%)",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            zIndex: 9999,
                            backgroundColor: colors.primary[600],
                            border: `1px solid ${colors.primary[100]}`,
                            borderRadius: "50px",
                            width: "400px",
                            height: "50px",
                            overflow: "hidden",
                        }}
                    >
                        <BulkAttendanceControl
                            userId={userId}
                            attendances={checkedAttendanceList}
                            enqueueSnackbar={enqueueSnackbar}
                            colors={colors}
                            socketConnected={socketConnected}
                            socket={socket}
                            setBackdropOpen={setBackdropOpen}
                            handleBackdropClose={handleBackdropClose}
                            // isThisAdminInViewMode={isThisAdminInViewMode}
                        />
                        <BulkSendMessageControl
                            admin_name={admin_name}
                            userId={userId}
                            attendances={checkedAttendanceList}
                            teacher_courses={checkedTeacherCourseList}
                            enqueueSnackbar={enqueueSnackbar}
                            colors={colors}
                            socketConnected={socketConnected}
                            socket={socket}
                            setBackdropOpen={setBackdropOpen}
                            handleBackdropClose={handleBackdropClose}
                            mobileMessageTemplates={mobileMessageTemplates}
                            // isThisAdminInViewMode={isThisAdminInViewMode}
                        />
                    </Box>
                }
                {/* WEEKLY SETTINGS */}
                <WeeklySettingsModal 
                    adminWeeklySettings={adminWeeklySettings} 
                    times={times} 
                    admin_uuid={userId}
                    enqueueSnackbar={enqueueSnackbar}
                    setWeeklySettingsUpdated={setWeeklySettingsUpdated}
                    open={weeklySettingsOpen}
                    handleClose={handleWeeklySettingsClose}
                />
                {/* PREVIOUS MEMOS (SPEED DIAL) */}
                <PreviousMemosModal
                    userId={userId}
                    colors={colors}
                    open={previousMemosOpen}
                    handleClose={handlePreviousMemosClose}
                    socket={socket}
                    socketConnected={socketConnected}
                    enqueueSnackbar={enqueueSnackbar}
                    setBackdropOpen={setBackdropOpen}
                    handleBackdropClose={handleBackdropClose}
                    // isThisAdminInViewMode={isThisAdminInViewMode}
                />

                {/* SCHEDULED SEND (SPEED DIAL) */}
                <ScheduledMessagesModal
                    userId={userId}
                    colors={colors}
                    open={scheduledSendOpen}
                    handleClose={handleScheduledSendClose}
                    socket={socket}
                    socketConnected={socketConnected}
                    enqueueSnackbar={enqueueSnackbar}
                    setBackdropOpen={setBackdropOpen}
                    handleBackdropClose={handleBackdropClose}
                    fetchTriggerScheduledMessages={fetchTriggerScheduledMessages}
                    setFetchTriggerScheduledMessages={setFetchTriggerScheduledMessages}
                    // isThisAdminInViewMode={isThisAdminInViewMode}
                />
                {/* COPY PREVIOUS WEEK ZOOM ASSIGNMENTS (SPEED DIAL) */}
                <CopyPrevWeekZoomAssignModal
                    admin_name={admin_name}
                    colors={colors}
                    socket={socket}
                    socketConnected={socketConnected}
                    enqueueSnackbar={enqueueSnackbar}
                    copyPrevWeekZoomAssignOpen={copyPrevWeekZoomAssignOpen}
                    handleCopyPrevWeekZoomAssignClose={handleCopyPrevWeekZoomAssignClose}
                    thisWeek={thisWeek}
                    // isThisAdminInViewMode={isThisAdminInViewMode}
                />
                {/* COPY PREVIOUS WEEK CELL MEMOS (SPEED DIAL) */}
                <CopyPrevWeekCellMemoModal
                    colors={colors}
                    socket={socket}
                    socketConnected={socketConnected}
                    enqueueSnackbar={enqueueSnackbar}
                    copyPrevWeekMemoOpen={copyPrevWeekMemoOpen}
                    handleCopyPrevWeekMemoClose={handleCopyPrevWeekMemoClose}
                    thisWeek={thisWeek}
                    // isThisAdminInViewMode={isThisAdminInViewMode}
                />
                {/* MOBILE MESSAGE TEMPLATES (SPEED DIAL) */}
                <MobileMessageTemplatesModal
                    admin_name={admin_name}
                    colors={colors}
                    open={mobileMessageTemplatesOpen}
                    handleClose={handleMobileMessageTemplatesClose}
                    socket={socket}
                    socketConnected={socketConnected}
                    enqueueSnackbar={enqueueSnackbar}
                    mobileMessageTemplates={mobileMessageTemplates}
                    // isThisAdminInViewMode={isThisAdminInViewMode}
                />
                {/* SPEED DIAL (CHECKBOX ENALBE OR DISALBE, SCHEDULED MESSAGES) */}
                <Box
                    sx={{
                        position: "fixed",
                        bottom: "50px",
                        right: "50px",
                        transform: 'translateZ(0px)', 
                        flexGrow: 1,
                        zIndex: 9999,
                    }}
                >
                    <SpeedDial
                        ariaLabel="SpeedDial"
                        icon={<SpeedDialIcon />}
                    >
                        <SpeedDialAction
                            key={"checkbox"}
                            icon={showCheckBox ? <CheckBoxOutlineBlankIcon /> : <CheckBoxIcon />}
                            tooltipTitle={showCheckBox ? "Disable Checkbox" : "Enable Checkbox"}
                            onClick={() => {
                                if (showCheckBox) {
                                    setCheckedAttendanceList([]);
                                    setCheckedTeacherCourseList([]);
                                }
                                setShowCheckBox(!showCheckBox)}
                            }
                            sx={{
                                bgcolor: colors.blueAccent[800],
                            }}
                        />
                        <SpeedDialAction
                            key={"scheduleSend"}
                            icon={<ScheduleSendIcon />}
                            tooltipTitle={"Check scheduled messages"}
                            onClick={() => {
                                handleScheduledSendOpen();
                            }}
                            sx={{
                                bgcolor: colors.redAccent[800],
                            }}
                        />
                        <SpeedDialAction
                            key={"mobile-message-templates"}
                            icon={<AddCommentOutlinedIcon />}
                            tooltipTitle={"Mobile Message Templates"}
                            onClick={() => {handleMobileMessageTemplatesOpen()}}
                            sx={{
                                bgcolor: colors.yellowAccent[900],
                            }}
                        />
                        <SpeedDialAction
                            key={"copy-prev-week-zoom-assignments"}
                            icon={<CopyAllIcon />}
                            tooltipTitle={"Copy Zoom Assignments from Previous Week"}
                            onClick={() => {handleCopyPrevWeekZoomAssignOpen()}}
                            sx={{
                                bgcolor: colors.greenAccent[800],
                            }}
                        />
                        <SpeedDialAction
                            key={"copy-prev-week-memos"}
                            icon={<FileCopyIcon />}
                            tooltipTitle={"Copy Cell and Daily Memos from Previous Week"}
                            onClick={() => {handleCopyPrevWeekMemoOpen()}}
                            sx={{
                                bgcolor: colors.purpleAccent[800],
                            }}
                        />
                        <SpeedDialAction
                            key={"history-for-memos"}
                            icon={<HistoryIcon />}
                            tooltipTitle={"Previous Memos to Restore"}
                            onClick={() => {handlePreviousMemosOpen()}}
                            sx={{
                                bgcolor: colors.primary[800],
                            }}
                        />
                        <SpeedDialAction
                            key={"weekly-settings"}
                            icon={<SettingsIcon />}
                            tooltipTitle={"Weekly Settings"}
                            onClick={() => {handleWeeklySettingsOpen()}}
                            sx={{
                                bgcolor: colors.grey[800],
                            }}
                        />
                    </SpeedDial>
                </Box>
                {/* FOR NOTES MODAL */}
                {notesModalOpen && 
                    <NotesModal
                        socket={socket}
                        socketConnected={socketConnected}
                        notesModalOpen={notesModalOpen}
                        setNotesModalOpen={setNotesModalOpen}
                        notesModalData={notesModalData}
                        setNotesModalData={setNotesModalData}
                        dateForRequest={dataForRequest}
                        enqueueSnackbar={enqueueSnackbar}
                        // isThisAdminInViewMode={isThisAdminInViewMode}
                    />
                }

                {/* FOR STUDENT INFO CARD MODAL */}
                {studentForMoreInfo &&
                    <MoreInfoModal 
                        student={studentForMoreInfo} 
                        setStudentUpdated={null} 
                        socket={socket}
                        socketConnected={socketConnected}
                        from_weekly={true} 
                        setStudentForMoreInfo={setStudentForMoreInfo}
                    />
                }
                {/* FOR TEACHER INFO CARD MODAL */}
                {teacherForMoreInfo &&
                    <TeacherMoreInfoModal 
                        teacher={teacherForMoreInfo} 
                        setTeacherUpdated={null} 
                        socket={socket}
                        socketConnected={socketConnected}
                        from_weekly={true} 
                        setTeacherForMoreInfo={setTeacherForMoreInfo}
                    />
                }
                {/* BACKDROP */}
                <BackdropComp backdropOpen={backdropOpen} handleBackdropClose={handleBackdropClose} />
            </>}
        </Box>
    )
}

export default Weekly