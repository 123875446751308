import React from "react";
import { styled } from '@mui/material/styles';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import MemoVertIcon from './MemoVertIcon';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Axios from "axios";
import dayjs from 'dayjs';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const MemoTableBodyContent = React.forwardRef(({ 
    memo,
    student_uuid,
    setStudentUpdated,
    setSuccessAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertOpen,
    setErrorAlertMessage,
    socket,
    socketConnected,
    from_weekly,
    getStudentName,
    ...props
}, ref) => {
   

    const removeReminder = async (event, memo_id ) => {
        event.preventDefault();
    
        await Axios.put(`${process.env.REACT_APP_URL}/api/v1/memos/${memo_id}/reminder`, {
            reminder: null,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            if (from_weekly && socketConnected) {
                socket.emit("put.StudentUpdate", {
                    student_uuid: student_uuid,
                    message: " removed one of the memo reminders of " + getStudentName(),
                });
            } else {
                setStudentUpdated(true);
                setSuccessAlertOpen(true);
                setSuccessAlertMessage(response.data.message);
            }
        }).catch((error) => {
            setErrorAlertOpen(true);
            setErrorAlertMessage(error.response.data.message);
        });
    }

    return (
        <StyledTableRow ref={ref} {...props}>
            <StyledTableCell component="th" scope="row">
                {memo.createdAt.split('T')[0]}
            </StyledTableCell>
            <StyledTableCell>{memo.title}</StyledTableCell>
            <StyledTableCell>
                {memo.content && memo.content.split('\n').map((line, index) => {
                    return (
                        <span key={index}>
                            {line}
                            <br />
                        </span>
                    )
                })}
            </StyledTableCell>
            <StyledTableCell align='center'>
                {memo.reminder !== null && memo.reminder.split('T')[0] <= dayjs().format('YYYY-MM-DD') 
                    ? <NotificationImportantIcon 
                        color='warning' 
                        onClick={(e) => removeReminder(e, memo.id)} 
                        style={{ cursor: "pointer" }} 
                    /> 
                    : null
                }
            </StyledTableCell>
            <StyledTableCell>
                {memo.reminder !== null ? memo.reminder.split('T')[0] : null}
            </StyledTableCell>
            <StyledTableCell align='center'>
                <MemoVertIcon 
                    memo={memo}
                    student_uuid={student_uuid}
                    setStudentUpdated={setStudentUpdated}
                    setSuccessAlertOpen={setSuccessAlertOpen}
                    setSuccessAlertMessage={setSuccessAlertMessage}
                    setErrorAlertOpen={setErrorAlertOpen}
                    setErrorAlertMessage={setErrorAlertMessage}
                    socket={socket}
                    socketConnected={socketConnected}
                    from_weekly={from_weekly}
                    getStudentName={getStudentName}
                />
            </StyledTableCell>
        </StyledTableRow>
    );
});

export default MemoTableBodyContent;