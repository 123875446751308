import * as React from "react";
import { Box, Button, Divider, TextField, Typography, Autocomplete } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import Axios from 'axios';
import Alert from '../../components/alert/Alert';
// import Autocomplete from "./Autocomplete";
// import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AddMemoDatePicker from "../students/moreInfo/AddMemoDatePicker";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { countryPhoneCodes } from "../weekly/studentButtonMenu/countryPhoneCode";

const AddStudent = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // for 401 error
    const signOut = useSignOut();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut();
        localStorage.removeItem("userName");
        localStorage.removeItem("who");
        navigate("/signin");
    }

    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = React.useState("");
    const isNonMobile = useMediaQuery("(min-width:600px)");

    // Google Places API (Disabled)
    // for auto-filling address
    // const [address, setAddress] = React.useState(null);

    const [zip, setZip] = React.useState("");
    const [street, setStreet] = React.useState("");
    const [city, setCity] = React.useState("");
    const [state, setState] = React.useState("");
    const [country, setCountry] = React.useState("");

    // for student and parents country phone code
    const [studentCountryPhoneCode, setStudentCountryPhoneCode] = React.useState(countryPhoneCodes.find((option) => option.name === "United States"));
    const [parentCountryPhoneCode, setParentCountryPhoneCode] = React.useState(countryPhoneCodes.find((option) => option.name === "United States"));
    const [parent2CountryPhoneCode, setParent2CountryPhoneCode] = React.useState(countryPhoneCodes.find((option) => option.name === "United States"));

    // for parent gender
    const [gender, setGender] = React.useState('');
    const handleGenderChange = (event) => {
        setGender(event.target.value);
    };

    // for addMemo checkbox
    const [addMemo, setAddMemo] = React.useState(false);
    const handleAddMemoChange = (event) => {
        setAddMemo(event.target.checked);
    };

    // for student type
    const [isPotentialStudent, setIsPotentialStudent] = React.useState(false);
    const handleStudentTypeChange = (event) => {
        setIsPotentialStudent(event.target.value);
    };

    // when potential student is selected
    // for last consultation number + 1
    const [newConsultationNumber, setNewConsultationNumber] = React.useState(null);

    // for info mail
    const [checked, setChecked] = React.useState(false);
    const handleCheckBoxChange = (event) => {
        setChecked(event.target.checked);
    };
    const [infoMailAuthor, setInfoMailAuthor] = React.useState("");
    const [infoMailDate, SetInfoMailDate] = React.useState(null);
    const [reminderDate, setReminderDate] = React.useState(null);

    const [memoTitle, setMemoTitle] = React.useState("");
    const [memoContent, setMemoContent] = React.useState("");

    React.useEffect(() => {
        localStorage.setItem('selectedMenu', 'Add Student');

        async function fetchLastNumberOfConsNum() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/students/consultation-number/last-number`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                let lastNumber = response.data;
                if (lastNumber === null) {
                    lastNumber = 0;
                }
                setNewConsultationNumber(lastNumber);
            }).catch((error) => {
                if (error.response !== null && error.response.status === 404) {
                    setNewConsultationNumber(0);
                    return;
                }
                if (error.response !== null && error.response.status === 401) {
                    handleLogout();
                    return;
                }
                setErrorAlertMessage(error.response.data.message);
                setErrorAlertOpen(true);
            });
        }

        fetchLastNumberOfConsNum();
    }, []);
    
    const reinitialize = (values) => {
        values.first_name = "";
        values.last_name = "";
        values.phone = "";
        values.email = "";
        values.grade = "";
        values.school = "";
        values.remark = "";
        values.consultation_number = "";
        values.street = "";
        values.city = "";
        values.state = "";
        values.country = "";
        values.zip = "";
        values.parent_phone = "";
        values.parent_phone_2 = "";
        values.parent_email = "";
        values.parent_email_2 = "";
        // setAddress(null);
        setZip("");
        setStreet("");
        setCity("");
        setState("");
        setCountry("");
        setGender("");
        setIsPotentialStudent(false);
        setStudentCountryPhoneCode(countryPhoneCodes.find((option) => option.name === "United States"));
        setParentCountryPhoneCode(countryPhoneCodes.find((option) => option.name === "United States"));
        setParent2CountryPhoneCode(countryPhoneCodes.find((option) => option.name === "United States"));
    };

    function isNumeric(value) {
        return /^-?\d+$/.test(value);
    }

    const handleFormSubmit = async (values) => {
        // console.log(values);
        let email = values.email;
        let parent_email = values.parent_email;
        let parent_email_2 = values.parent_email_2;
        let consultation_number = values.consultation_number;

        let korean_name = values.korean_name;
        let first_name = values.first_name;
        let last_name = values.last_name;

        email = email.toLowerCase();
        parent_email = parent_email.toLowerCase();
        parent_email_2 = parent_email_2.toLowerCase();

        if (consultation_number === "") {
            consultation_number = null;
        }

        if (consultation_number && !isNumeric(consultation_number)) {
            setErrorAlertMessage("Consultation number must be a number!");
            setErrorAlertOpen(true);
            return;
        }

        if (isPotentialStudent && addMemo) {
            if (memoTitle === "" || memoContent === "") {
                setErrorAlertMessage("Please fill in the memo title and content!");
                setErrorAlertOpen(true);
                return;
            }
        }

        if (consultation_number !== null) {
            const res = await Axios.get(`${process.env.REACT_APP_URL}/api/v1/students/consultation-number/check/${consultation_number}`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).catch((error) => {
                if (error.response !== null && error.response.status === 401) {
                    handleLogout();
                    return;
                }
                setErrorAlertMessage(error.response.data.message || error.response.data.error || "An error occurred!");
                setErrorAlertOpen(true);
            });

            if (res.status !== 200) {
                return;
            }

            if (res.data.exists) {
                if (!window.confirm("This consultation number already exists. Do you want to continue?")) {
                    return;
                }
            }
        }

        // check if country phone codes are selected
        if (values.phone !== "" && values.phone !== null && studentCountryPhoneCode === null) {
            setErrorAlertMessage("Please select the country phone code for student's phone number!");
            setErrorAlertOpen(true);
            return;
        }

        if (values.parent_phone !== "" && values.parent_phone !== null && parentCountryPhoneCode === null) {
            setErrorAlertMessage("Please select the country phone code for parent's phone number!");
            setErrorAlertOpen(true);
            return;
        }

        if (values.parent_phone_2 !== "" && values.parent_phone_2 !== null && parent2CountryPhoneCode === null) {
            setErrorAlertMessage("Please select the country phone code for parent's phone number!");
            setErrorAlertOpen(true);
            return;
        }

        if ((korean_name && korean_name.length > 255) || (first_name && first_name.length > 255) || (last_name && last_name.length > 255)) {
            setErrorAlertMessage("Name fields can't be more than 255 characters!");
            setErrorAlertOpen(true);
            return;
        }

        await Axios.post(`${process.env.REACT_APP_URL}/api/v1/students`, {
            first_name: values.first_name,
            last_name: values.last_name,
            korean_name: values.korean_name,
            country_phone_code: studentCountryPhoneCode === null ? "+1 United States" : studentCountryPhoneCode.code + " " + studentCountryPhoneCode.name,
            phone: values.phone,
            email: email,
            grade: values.grade,
            school: values.school,
            remark: values.remark,
            consultation_number: consultation_number,
            is_potential_student: isPotentialStudent,
            street: street,
            city: city,
            state: state,
            zip: zip,
            country: country,
            parent_email: parent_email,
            parent_email_2: parent_email_2,
            parent_country_phone_code: parentCountryPhoneCode === null ? "+1 United States" : parentCountryPhoneCode.code + " " + parentCountryPhoneCode.name,
            parent_phone: values.parent_phone,
            parent_country_phone_code_2: parent2CountryPhoneCode === null ? "+1 United States" : parent2CountryPhoneCode.code + " " + parent2CountryPhoneCode.name,
            parent_phone_2: values.parent_phone_2,
            parent_gender: gender
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then(async (response) => {
            const student_uuid = response.data.student_uuid;
            if (!isPotentialStudent) {
                setSuccessAlertOpen(true);
                reinitialize(values);    
                return;
            } else {
                await Axios.post(`${process.env.REACT_APP_URL}/api/v1/students/${student_uuid}/info-mails`, {
                    date: checked ? dayjs(infoMailDate) : null,
                    is_sent: checked ? true : false,
                    author: checked ? infoMailAuthor : null,
                }, {
                    headers: {
                        Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                    },
                }).catch((error) => {
                    setErrorAlertMessage(error.response.data.message);
                    setErrorAlertOpen(true);
                });

                if (addMemo) {
                    await Axios.post(`${process.env.REACT_APP_URL}/api/v1/students/${student_uuid}/memos`, {
                        title: memoTitle,
                        content: memoContent,
                        reminder: reminderDate,
                    }, {
                        headers: {
                            Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                        },
                    }).catch((error) => {
                        setErrorAlertMessage(error.response.data.message);
                        setErrorAlertOpen(true);
                    });
                }

                setSuccessAlertOpen(true);
                reinitialize(values);
            }
        }).catch((error) => {
            setErrorAlertMessage(error.response.data.message);
            setErrorAlertOpen(true);
        });
    };

    return (
        <Box m="20px" marginX={3} marginY={3} maxWidth={"70rem"}>
            <Header title="Add Student" subtitle="A ONE Institute" />
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
                // innerRef={formRef}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                }) => (
                <form onSubmit={handleSubmit}>
                    <Box
                        padding={4}
                        boxShadow={3}
                        borderRadius={3}
                        m={1}
                        bgcolor={colors.primary[600]}
                    >
                        <Typography
                            variant="h4"
                            fontWeight="bold"
                            mb={3}
                        >
                            Student Information
                        </Typography>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                        >
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="First Name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.first_name}
                                name="first_name"
                                error={!!touched.first_name && !!errors.first_name}
                                helperText={touched.first_name && errors.first_name}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Last Name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.last_name}
                                name="last_name"
                                error={!!touched.last_name && !!errors.last_name}
                                helperText={touched.last_name && errors.last_name}
                                sx={{ gridColumn: "span 1" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Korean Name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.korean_name}
                                name="korean_name"
                                error={!!touched.korean_name && !!errors.korean_name}
                                helperText={touched.korean_name && errors.korean_name}
                                sx={{ gridColumn: "span 1" }}
                            />
                            <Box
                                sx={{ 
                                    gridColumn: "span 2",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "10px",
                                }}
                            >
                                <Autocomplete
                                    id="country-phone-code"
                                    value={studentCountryPhoneCode}
                                    options={countryPhoneCodes}
                                    getOptionLabel={(option) => option.code + " " + option.name}
                                    sx={{ width: "50%" }}
                                    renderInput={(params) => <TextField {...params} label="Country Phone Code" />}
                                    onChange={(e, value) => {
                                        setStudentCountryPhoneCode(value);
                                    }}
                                    isOptionEqualToValue={(option, value) => option.code === value.code}
                                />
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Phone"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.phone}
                                    name="phone"
                                    error={!!touched.phone && !!errors.phone}
                                    helperText={touched.phone && errors.phone}
                                    sx={{ width: "50%" }}
                                />
                            </Box>
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.email}
                                name="email"
                                error={!!touched.email && !!errors.email}
                                helperText={touched.email && errors.email}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Grade"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.grade}
                                name="grade"
                                error={!!touched.grade && !!errors.grade}
                                helperText={touched.grade && errors.grade}
                                sx={{ gridColumn: "span 1" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="School"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.school}
                                name="school"
                                error={!!touched.school && !!errors.school}
                                helperText={touched.school && errors.school}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Remark (특이사항)"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.remark}
                                name="remark"
                                error={!!touched.remark && !!errors.remark}
                                helperText={touched.remark && errors.remark}
                                sx={{ gridColumn: "span 1" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Consultation Number"
                                placeholder={"The last number of consultation number is " + newConsultationNumber}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.consultation_number}
                                name="consultation_number"
                                error={!!touched.consultation_number && !!errors.consultation_number}
                                helperText={touched.consultation_number && errors.consultation_number}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <FormControl variant="filled" sx={{ gridColumn: "span 1" }}>
                                <InputLabel id="demo-simple-select-filled-label">Student Type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-filled-label"
                                    id="demo-simple-select-filled"
                                    value={isPotentialStudent}
                                    onChange={handleStudentTypeChange}
                                >
                                    <MenuItem value={false}>Enrolled Student</MenuItem>
                                    <MenuItem value={true}>Potential Student</MenuItem>
                                </Select>
                            </FormControl>
                            {isPotentialStudent && ( <>
                                <FormControlLabel
                                    control={<Checkbox checked={addMemo} onChange={handleAddMemoChange} />}
                                    label="Want to add a memo?"
                                    sx={{ gridColumn: "span 1" }}
                                />
                                <Box  sx={{ gridColumn: "span 4" }}>
                                    <FormControlLabel
                                        control={<Checkbox checked={checked} onChange={handleCheckBoxChange} />}
                                        label="Did you already send the information email?"
                                        sx={{ gridColumn: "span 1" }}
                                    />
                                    {checked && (
                                        <Box>
                                            <TextField
                                                id="filled-basic"
                                                label="Info Email Author"
                                                variant="filled"
                                                name='email-author'
                                                value={infoMailAuthor}
                                                onChange={(e) => setInfoMailAuthor(e.target.value)}
                                                sx={{
                                                    gridColumn: "span 2",
                                                }}
                                            />
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    label="Date for the information mail to be sent"
                                                    value={infoMailDate}
                                                    onChange={(newValue) => SetInfoMailDate(newValue)}
                                                    sx={{
                                                        width: "20rem",
                                                        marginLeft: "1rem",
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        </Box>
                                    )}
                                </Box>
                            </>)}
                            {isPotentialStudent && addMemo && (<>
                                <Divider sx={{ gridColumn: "span 4" }} />
                                <Typography variant='h4' sx={{ gridColumn: "span 4", fontWeight: "bold" }}>Add Memo</Typography>
                                <TextField
                                    id="filled-basic"
                                    label="Title"
                                    variant="filled"
                                    name='title'
                                    value={memoTitle}
                                    onChange={(e) => setMemoTitle(e.target.value)}
                                    sx={{
                                        gridColumn: "span 2",
                                    }}
                                />
                                <AddMemoDatePicker 
                                    reminderDate={reminderDate} 
                                    setReminderDate={setReminderDate} 
                                    style={{ gridColumn: "span 1" }}
                                />
                                <TextField
                                    id="filled-basic"
                                    label="Content"
                                    variant="filled"
                                    name='content'
                                    value={memoContent}
                                    onChange={(e) => setMemoContent(e.target.value)}
                                    fullWidth={true}
                                    multiline={true}
                                    rows={5}
                                    sx={{
                                        gridColumn: "span 2",
                                        borderRadius: "5px",
                                    }}
                                />
                                <Divider sx={{ gridColumn: "span 4" }} />
                            </>)}
                            {/* <Box sx={{ gridColumn: "span 2", zIndex: "10" }}>
                                <Typography variant="h5" fontWeight="bold" mb={1}>Address Autocomplete</Typography>
                                <Autocomplete address={address} setAddress={setAddress} />
                            </Box> */}
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Street Address"
                                value={street}
                                onChange={(e) => setStreet(e.target.value)}
                                name="street"
                                sx={{ gridColumn: "span 4" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="City"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                name="city"
                                sx={{ gridColumn: "span 1" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="State"
                                value={state}
                                onChange={(e) => setState(e.target.value)}
                                name="state"
                                sx={{ gridColumn: "span 1" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Country"
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                                name="country"
                                sx={{ gridColumn: "span 1" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Zip Code"
                                value={zip}
                                onChange={(e) => setZip(e.target.value)}
                                name="zip"
                                sx={{ gridColumn: "span 1" }}
                            />
                        </Box>
                    </Box>
                    <Box
                        padding={4}
                        boxShadow={3}
                        borderRadius={3}
                        m={1}
                        bgcolor={colors.primary[600]}
                    >
                        <Typography
                            variant="h4"
                            fontWeight="bold"
                            mb={3}
                        >
                            Parents Information
                        </Typography>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                        >
                            <Autocomplete
                                id="country-phone-code"
                                value={parentCountryPhoneCode}
                                options={countryPhoneCodes}
                                getOptionLabel={(option) => option.code + " " + option.name}
                                sx={{ width: "100%" }}
                                renderInput={(params) => <TextField {...params} label="Country Phone Code" />}
                                onChange={(e, value) => {
                                    setParentCountryPhoneCode(value);
                                }}
                                isOptionEqualToValue={(option, value) => option.code === value.code}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Parent Phone 1"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.parent_phone}
                                name="parent_phone"
                                error={!!touched.parent_phone && !!errors.parent_phone}
                                helperText={touched.parent_phone && errors.parent_phone}
                                sx={{ gridColumn: "span 1" }}
                            />
                            <Autocomplete
                                id="country-phone-code"
                                value={parent2CountryPhoneCode}
                                options={countryPhoneCodes}
                                getOptionLabel={(option) => option.code + " " + option.name}
                                sx={{ width: "100%" }}
                                renderInput={(params) => <TextField {...params} label="Country Phone Code" />}
                                onChange={(e, value) => {
                                    setParent2CountryPhoneCode(value);
                                }}
                                isOptionEqualToValue={(option, value) => option.code === value.code}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Parent Phone 2"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.parent_phone_2}
                                name="parent_phone_2"
                                error={!!touched.parent_phone_2 && !!errors.parent_phone_2}
                                helperText={touched.parent_phone_2 && errors.parent_phone_2}
                                sx={{ gridColumn: "span 1" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Parent Email 1"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.parent_email}
                                name="parent_email"
                                error={!!touched.parent_email && !!errors.parent_email}
                                helperText={touched.parent_email && errors.parent_email}
                                sx={{ gridColumn: "span 1" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Parent Email 2"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.parent_email_2}
                                name="parent_email_2"
                                error={!!touched.parent_email_2 && !!errors.parent_email_2}
                                helperText={touched.parent_email_2 && errors.parent_email_2}
                                sx={{ gridColumn: "span 1" }}
                            />
                            <FormControl variant="filled" sx={{ gridColumn: "span 1" }}>
                                <InputLabel id="demo-simple-select-filled-label">Gender</InputLabel>
                                <Select
                                    labelId="demo-simple-select-filled-label"
                                    id="demo-simple-select-filled"
                                    value={gender}
                                    onChange={handleGenderChange}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={"Male"}>Male</MenuItem>
                                    <MenuItem value={"Female"}>Female</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="start" mt="20px">
                        <Button type="submit" color="secondary" variant="contained">
                            Create New Student
                        </Button>
                    </Box>
                </form>
                )}
            </Formik>
            <Alert
                successAlertOpen={successAlertOpen}
                setSuccessAlertOpen={setSuccessAlertOpen}
                errorAlertOpen={errorAlertOpen}
                setErrorAlertOpen={setErrorAlertOpen}
                successMsg="Student has been added successfully!"
                errorMsg={errorAlertMessage}
                vertical="bottom"
                horizontal="left"
            />
        </Box>
    );
};

const phoneRegExp =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
const gradeRegExp =
    /^([0-9]{1,2})$/;


const checkoutSchema = yup.object().shape({
    first_name: yup.string().optional(),
    last_name: yup.string().optional(),
    korean_name: yup.string().optional(),
    phone: yup
        .string()
        .matches(phoneRegExp, "Phone number is not valid")
        .optional(),
    email: yup.string().email("invalid email").optional(),
    grade: yup
        .string()
        .matches(gradeRegExp, "Grade is not valid")
        .optional(),
    school: yup.string().optional(),
    remark: yup.string().optional(),
    consultation_number: yup.string().optional(),
    parent_phone: yup
        .string()
        .matches(phoneRegExp, "Phone number is not valid")
        .optional(),
    parent_phone_2: yup
        .string()
        .matches(phoneRegExp, "Phone number is not valid")
        .optional(),
    parent_email: yup.string().email("invalid email").optional(),
    parent_email_2: yup.string().email("invalid email").optional(),
});

const initialValues = {
    first_name: "",
    last_name: "",
    korean_name: "",
    phone: "",
    email: "",
    grade: "",
    school: "",
    remark: "",
    consultation_number: "",
    parent_phone: "",
    parent_phone_2: "",
    parent_email: "",
    parent_email_2: "",
};

export default AddStudent;