import React from 'react';
import { tokens } from "../../../theme";
import { Box, Typography } from '@mui/material';
import { DateTime, Settings } from 'luxon';
import { generateWeekDatesForTeacherScheduler, generateWeekDates } from '../../../utils/weekDates';
import { useTheme } from "@mui/material";
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import Loading from '../../../components/Loading';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Axios from 'axios';
import Alert from '../../../components/alert/Alert';
import getCookies from '../../../utils/getCookies';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';

Settings.defaultZone = 'America/New_York';

const AvailabilityModal = ({ iconColor, button = true, userId }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isDarkMode = theme.palette.mode === 'dark';

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        // width: 1540,
        // height: 800,
        width: '90%',
        maxWidth: 1520,
        height: '80%',
        bgcolor: colors.primary[600],
        border: '2px solid grey',
        boxShadow: 24,
        p: 4,
        overflow: "auto",
        borderRadius: '8px'
    };

    const deadlineNotificationStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '30%',
        bgcolor: colors.primary[700],
        border: 'none',
        borderRadius: 3,
        boxShadow: 24,
        p: 4,
        textAlign: 'center',
    };

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    
    const [isDragging, setIsDragging] = React.useState(false);
    const [deadlineModalOpen, setDeadlineModalOpen] = React.useState(false);

    const handleDeadlineWarningOpen = () => setDeadlineModalOpen(true);
    const handleDeadlineWarningClose = () => setDeadlineModalOpen(false);

    const today = DateTime.now().setZone('America/New_York').set({ hour: 12, minute: 0, second: 0 });
    const two_weeks_later = today.plus({ weeks: 2 });

    const [date, setDate] = React.useState(two_weeks_later);
    const [deadlineDate, setDeadlineDate] = React.useState(today);
    
    const weekForDeadline = generateWeekDates(deadlineDate);
    const [weekDates, setWeekDates] = React.useState(generateWeekDatesForTeacherScheduler(date, 0.5));

    const [loading, setLoading] = React.useState(false);
    const [selectedSlots, setSelectedSlots] = React.useState([]);
    const [classSlots, setClassSlots] = React.useState([]);
    
    const [updated, setUpdated] = React.useState(false);

    // dummy dragging data
    const [draggingData, setDraggingData] = React.useState({});

    const currentUser = getCookies("_auth_state");
    const userRole = currentUser ? currentUser.split(',')[2]?.split(':')[1]?.split('"')[1] : null;    

    // for alert
    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = React.useState("");
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = React.useState("");    

    const ROWHEIGHT = 30;

    // Deadline date for the current week
    // 
    const deadline_date = weekForDeadline && weekForDeadline.length > 0 
        && weekForDeadline[weekForDeadline.length - 1].date.toJSDate() < new Date('2024-10-07T23:59:59.000Z') 
        && weekForDeadline[1].date.toJSDate() > new Date('2024-09-29T00:00:00.000Z') 
        ? weekForDeadline[5].date.set({ hour: 23, minute: 59, second: 59 }).toFormat('ccc, DD') 
        : weekForDeadline[3].date.set({ hour: 23, minute: 59, second: 59 }).toFormat('ccc, DD');

    // Fetch teacher's availability
    React.useEffect(() => {
        const fetchTeacherAvailability = async () => {
            setLoading(true);
            try {
                const response = await Axios.get(`${process.env.REACT_APP_URL}/api/v1/teachers/${userId}/weekly-schedule/availability`, {
                    params: {
                        week_start: weekDates[1].date.set({ hour: 0, minute: 0, second: 0 }).toISO(),
                        week_end: weekDates[7].date.set({ hour: 23, minute: 59, second: 59 }).toISO(),
                    },
                    headers: {
                        Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                    },
                });
                
                // Process availability data
                const weeklyAvailability = response.data.teacherAvailabilities || [];
    
                const availabilitySlots = weeklyAvailability.map(slot => {
                    // set timezone to New York
                    const date = DateTime.fromISO(slot.date).setZone('America/New_York');
                    const dateIndex = weekDates.findIndex(weekDate => weekDate.date?.toISODate() === date.toISODate());
                    const slotIndex = weekDates[dateIndex].hours.findIndex(hour => hour === date.toFormat('h:mm a'));
                    return [dateIndex, slotIndex, date, date.toFormat('h:mm a')];
                });
    
                setSelectedSlots(availabilitySlots);

                // Process class data separately
                const classSlots = response.data.modules.flatMap(slot => {
                    // set timezone to New York
                    const start = DateTime.fromISO(slot.start).setZone('America/New_York');
                    const end = DateTime.fromISO(slot.end).setZone('America/New_York');
                    const dateIndex = weekDates.findIndex(weekDate => weekDate.date?.toISODate() === start.toISODate());
                    
                    const startSlotIndex = weekDates[dateIndex].hours.findIndex(hour => hour === start.toFormat('h:mm a'));
                    const endSlotIndex = weekDates[dateIndex].hours.findIndex(hour => hour === end.toFormat('h:mm a'));

                    const title = slot.course.course_title;
                    const code = slot.course.code;
                    const is_canceled = slot.is_canceled;
    
                    // Create slots for the entire class duration
                    const slots = [];
                    for (let i = startSlotIndex; i < endSlotIndex; i++) {
                        slots.push([dateIndex, i, start, start.toFormat('h:mm a'), end, end.toFormat('h:mm a'), title, code, is_canceled]);
                    }
                    return slots;
                });
                setClassSlots(classSlots);
            } catch (error) {
                console.error(error);
                setErrorAlertMessage(error.response.data.message || 'Error loading data');
                setErrorAlertOpen(true);
            } finally {
                setLoading(false); // Ensure loading is false no matter what
            }
        };
    
        fetchTeacherAvailability();
    }, [weekDates, updated]);

    const handleSlotMouseDown = (dateIndex, slotIndex, date, time, e) => {
        e.preventDefault();
        e.stopPropagation(); // Prevents propagation of the event and avoids multiple quick updates
    
        if (userRole === 'teacher') {
            if (today > deadlineDate.set({ hour: 23, minute: 59, second: 59 })) {
                handleDeadlineWarningOpen();
            } else if (dateIndex > 0) {
                setTimeout(() => {
                    setIsDragging(true);  // Start dragging
                }, 10);
                toggleSlotSelection(dateIndex, slotIndex, date, time);
            }
        }
    };
    
    const handleSlotMouseOver = (dateIndex, slotIndex, date, time, e) => {
        e.preventDefault();
        e.stopPropagation();  // Stops propagation when dragging
    
        if (userRole === 'teacher' && isDragging && dateIndex > 0 && today < deadlineDate.set({ hour: 23, minute: 59, second: 59 })) {
            if (draggingData[dateIndex] && draggingData[dateIndex][slotIndex] === true) {
                return; // Prevents multiple updates when dragging over the same slot
            }

            setDraggingData((prev) => {
                if (prev[dateIndex] === undefined) {
                    prev[dateIndex] = {};
                }
                if (prev[dateIndex][slotIndex] === undefined) {
                    prev[dateIndex][slotIndex] = true;
                }

                return { ...prev };
            });
            toggleSlotSelection(dateIndex, slotIndex, date, time);
        }
    };

    const handleSlotMouseUp = () => {
        if (userRole === 'teacher' && today < deadlineDate.set({ hour: 23, minute: 59, second: 59 })) {
            setIsDragging(false);
        }
    };

    const toggleSlotSelection = (dateIndex, slotIndex, date, time) => {
        // Split time and add logic for AM/PM
        let [hour, minute] = time.split(':');
        if (time.includes('PM') && hour !== '12') {
            hour = parseInt(hour, 10) + 12;
        } else if (time.includes('AM') && hour === '12') {
            hour = '00';
        }
        minute = minute.replace(/\D/g, ''); // Remove AM/PM from minutes if present
    
        // Construct DateTime object with the correct hour and minute
        // set timezone to New York
        const slotDateTime = DateTime.fromISO(date.toISO()).setZone('America/New_York').set({ hour: parseInt(hour, 10), minute: parseInt(minute, 10) });
        
        const slot = [dateIndex, slotIndex, slotDateTime, time];
        const isSelected = selectedSlots.some(selectedSlot => selectedSlot[0] === dateIndex && selectedSlot[1] === slotIndex);
    
        if (isSelected) {
            setSelectedSlots(selectedSlots.filter(selectedSlot => !(selectedSlot[0] === dateIndex && selectedSlot[1] === slotIndex)));
        } else {
            setSelectedSlots([...selectedSlots, slot]);
        }
    };

    const isNoClassSlot = (dateObj, hour) => {
        if (!dateObj || !dateObj.date) return false; // Ensure dateObj and date exist
    
        const noClassStartTime = 8; // 8:00 AM
        const noClassEndTime = 15.5; // 3:30 PM (inclusive)
    
        const hourValue = DateTime.fromFormat(hour, 'h:mm a').hour + (DateTime.fromFormat(hour, 'h:mm a').minute === 30 ? 0.5 : 0);
    
        // Check if it's a Sunday or if the hour falls between the no-class range for Monday-Friday
        const weekday = dateObj.date.weekday; // Safely access weekday
        if (weekday === 7 || (weekday >= 1 && weekday <= 5 && hourValue >= noClassStartTime && hourValue <= noClassEndTime)) {
            return true;
        }
        return false;
    };
    

    function handleDateChange(date, lOrR) {
        if (lOrR === -1) {
            setDate(two_weeks_later);
            setDeadlineDate(today);
            setWeekDates(generateWeekDatesForTeacherScheduler(two_weeks_later, 0.5));
        }
        else if (lOrR === 0) {
            setDate(date.minus({ days: 7 }));
            setDeadlineDate(deadlineDate.minus({ days: 7 }));
            setWeekDates(generateWeekDatesForTeacherScheduler(date.minus({ days: 7 }), 0.5));
        }
        else if (lOrR === 1) {
            setDate(date.plus({ days: 7 }));
            setDeadlineDate(deadlineDate.plus({ days: 7 }));
            setWeekDates(generateWeekDatesForTeacherScheduler(date.plus({ days: 7 }), 0.5));
        }
    };

    const handleAvailability = async () => {
        // if (userRole !== 'admin') {
        if (userRole !== 'teacher' || today > DateTime.fromFormat(deadline_date, 'ccc, DD').set({ hour: 23, minute: 59, second: 59 }))
            return;

        // }
        await Axios.post(`${process.env.REACT_APP_URL}/api/v1/teachers/${userId}/availability`,
            {
                selectedSlots: selectedSlots.map(slot => slot[2].toISO()),
                week_start: weekDates[1].date.set({ hour: 0, minute: 0, second: 0 }).toISO(),
                week_end: weekDates[7].date.set({ hour: 23, minute: 59, second: 59 }).toISO(),
            },
        {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setSuccessAlertMessage(response.data.message);
            setSuccessAlertOpen(true);
            setUpdated(true);
        }).catch((error) => {
            setErrorAlertMessage(error.response.data.message || "An error occurred. Please try again.");
            setErrorAlertOpen(true);
            console.error(error);
        });
    };

    const handleCopyLastWeekAvailability = async () => {
        if (userRole !== 'teacher')
            return;
    
        if (today > weekForDeadline[3].date.set({ hour: 23, minute: 59, second: 59 })) {
            setErrorAlertMessage("Deadline for this week has passed. Please contact the admin to report availability for this week.");
            setErrorAlertOpen(true);
            return;
        }
    
        await Axios.get(`${process.env.REACT_APP_URL}/api/v1/teachers/${userId}/availability/copy-last-week`, {
            params: {
                week_start: weekDates[1].date.set({ hour: 0, minute: 0, second: 0 }).toISO(),
                week_end: weekDates[7].date.set({ hour: 23, minute: 59, second: 59 }).toISO(),
            },
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setSuccessAlertMessage("Last week's availability has been copied.");
            setSuccessAlertOpen(true);
            // Process last week's availability data and adjust to current week

            const weeklyAvailability = response.data || [];
            
            if (weeklyAvailability.length === 0) {
                setErrorAlertMessage("No availability data found for last week.");
                setErrorAlertOpen(true);
                return;
            };

            const availabilitySlots = weeklyAvailability.map(slot => {
                const lastWeekDate = DateTime.fromISO(slot.date);
                // Add 7 days to map it to the current week
                const currentWeekDate = lastWeekDate.plus({ days: 7 });
                const dateIndex = weekDates.findIndex(weekDate => weekDate.date?.toISODate() === currentWeekDate.toISODate());
                const slotIndex = weekDates[dateIndex].hours.findIndex(hour => hour === currentWeekDate.toFormat('h:mm a'));
    
                return [dateIndex, slotIndex, currentWeekDate, currentWeekDate.toFormat('h:mm a')];
            });

            setSelectedSlots(availabilitySlots);
        }).catch((error) => {
            setErrorAlertMessage(error.response.data.message || "No availability data found for last week.");
            setErrorAlertOpen(true);
            console.error(error);
        });
    };

    // Cancel > class > availability > no class
    const getSlotBackgroundColor = (isAvailability, isClass, classInfo, hourIndex) => {
        if (isAvailability) {
            return '#FFA500'; // orange
        }
        if (classInfo !== undefined && classInfo !== null && classInfo[8] === true) {
            return '#db4f4a'; // cancelled -> red
        }
        if (isClass) {
            return 'skyblue'; // class
        }
        if (hourIndex % 2 === 0) {
            return isDarkMode ? '#121212' : 'Azure';
        }
        return isDarkMode ? '#373737' : 'Beige';
    };

    // Cancel > overlappingClasses > class > availability > no class
    const getSlotInfo = (overlappingClasses, isClass, isAvailability, isNoClass, classInfo) => {
        if (isAvailability) {
            return 'Available';
        }
        // Class -> Cancel
        if (classInfo !== undefined && classInfo !== null && classInfo[8] === true) {
            return `${classInfo[7]}. ${classInfo[6]} → Canceled`;
        }
        // Available -> Assigned Class
        if (isAvailability && overlappingClasses.length > 0) {
            return `${classInfo[7]}. ${classInfo[6]}`;
        }
        if (isClass) {
            return `${classInfo[7]}. ${classInfo[6]}`;
        }
        if (isNoClass) {
            return 'No Class';
        }
    };

    return (
        <div>
            {button ? 
                <Button size="small" style={{"backgroundColor": colors.primary[800], "color": colors.primary[400]}} onClick={handleOpen}>Availability</Button>
                :
                <IconButton onClick={handleOpen}>
                    <WorkHistoryIcon color={iconColor} />
                </IconButton>
            }
      
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Box 
                            borderBottom={1} 
                            sx={{
                                position: "sticky",
                                left: 0,
                                minWidth: 700,
                                flexDirection: "row",
                                display: 'flex', 
                                justifyContent: 'space-between', 
                                mb: 2 
                            }}
                        >
                            <Typography 
                                variant="h2" 
                                color="primary"
                                sx={{
                                    '@media screen and (max-width: 1495px)': {
                                        fontSize: 20,
                                    },
                                }}
                            >
                                Your Weekly Schedule
                            </Typography>
                            <Box display="flex" alignItems="center" gap={1}>
                                <Typography 
                                    id="modal-modal-title" 
                                    variant="h5" 
                                    sx={{
                                        display: "flex", 
                                        alignItems: "center", 
                                        color: "red",
                                        '@media screen and (max-width: 1495px)': {
                                            fontSize: 12,
                                        },
                                    }}
                                >
                                    Deadline for this week: {deadline_date} (EDT)
                                </Typography>
                            </Box>
                            <Box display="flex" gap={2} sx={{mb: 1}}>
                                <Button 
                                    variant="contained" 
                                    color="primary" 
                                    onClick={() => handleDateChange(date, -1)} 
                                    sx={{  
                                        '@media screen and (max-width: 1495px)': {
                                            size: 'small',
                                            fontSize: 10,
                                        },
                                    }}
                                >
                                    First Page
                                </Button>
                                <Button 
                                    variant="contained" 
                                    color="primary" 
                                    onClick={() => handleDateChange(date, 0)} 
                                    sx={{  
                                        '@media screen and (max-width: 1495px)': {
                                            size: 'small',
                                        },
                                    }}
                                >
                                    <KeyboardArrowLeftIcon />
                                </Button>
                                <Button 
                                    variant="contained" 
                                    color="primary" 
                                    onClick={() => handleDateChange(date, 1)} 
                                    sx={{  
                                        '@media screen and (max-width: 1495px)': {
                                            size: 'small',
                                        },
                                    }}
                                >
                                    <KeyboardArrowRightIcon />
                                </Button>

                                <Tooltip title="Copy Last week availability" placement='top' >
                                    <Button 
                                        variant="contained" 
                                        color="primary"
                                        type='button'
                                        onClick={() => handleCopyLastWeekAvailability()}
                                        sx={{
                                            '&:hover': { backgroundColor: colors.blueAccent[600] },
                                            '@media screen and (max-width: 1495px)': {
                                                size: 'small',
                                                fontSize: 10,
                                            },
                                        }}
                                    >
                                        Copy
                                    </Button>
                                </Tooltip>
                                <Button 
                                    variant="contained" 
                                    color="warning"
                                    type='button'
                                    disabled={today > DateTime.fromFormat(deadline_date, 'ccc, DD').set({ hour: 23, minute: 59, second: 59 })}
                                    onClick={() => handleAvailability()}
                                    sx={{
                                        '&:hover': { backgroundColor: colors.blueAccent[600] },
                                        '@media screen and (max-width: 1495px)': {
                                            size: 'small',
                                            fontSize: 10,
                                        },
                                    }}
                                >
                                    Submit
                                </Button>
                                <Button 
                                    variant="contained" 
                                    color="secondary"
                                    onClick={handleClose}
                                    sx={{
                                        '&:hover': { backgroundColor: colors.blueAccent[600] },
                                        '@media screen and (max-width: 1495px)': {
                                            size: 'small',
                                            fontSize: 10,
                                        },
                                    }}
                                >
                                    Close
                                </Button>
                            </Box>
                        </Box>
                        {loading ? <Loading /> : <>
                            <Modal
                                aria-labelledby="transition-modal-title"
                                aria-describedby="transition-modal-description"
                                open={deadlineModalOpen}
                                onClose={handleDeadlineWarningClose}
                                closeAfterTransition
                                slots={{ backdrop: Backdrop }}
                                slotProps={{
                                    backdrop: {
                                        timeout: 500,
                                    },
                                }}
                            >
                                <Fade in={deadlineModalOpen}>
                                    <Box sx={deadlineNotificationStyle}>
                                        <Typography 
                                            id="transition-modal-title"
                                            variant="h5"
                                            sx={{ mt: 2, mb: 2, fontWeight: 'bold', textAlign: 'center', color: colors.grey[200] }}
                                        >
                                            <SentimentVeryDissatisfiedIcon/>
                                        </Typography>
                                        <Typography
                                            id="transition-modal-description"
                                            sx={{ 
                                                mt: 2, 
                                                mb: 2, 
                                                mx: 2, 
                                                lineHeight: 1.8, 
                                                textAlign: 'center', 
                                                color: colors.grey[200],
                                            }}
                                        >
                                            Deadline for this week has passed.
                                        </Typography>
                                        <Typography
                                            sx={{ mx: 2, lineHeight: 1.8, textAlign: 'center', color: colors.grey[200] }}
                                        >
                                            Please contact the admin to report availability for this week.
                                        </Typography>
                                        <Button 
                                            variant="contained" 
                                            color="secondary"
                                            sx={{ mt: 3, display: 'block', mx: 'auto', width: '50%', borderRadius: 2 }}
                                            onClick={handleDeadlineWarningClose}
                                        >
                                            Okay
                                        </Button>
                                    </Box>
                                </Fade>
                            </Modal>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    width: '100%',
                                    // height: '100%',
                                    minHeight: 660,
                                    // overflowY: "scroll",
                                }}
                                onMouseUp={handleSlotMouseUp}
                            >
                                {weekDates.map((dateObj, dateIndex) => (
                                    <Box
                                        key={dateIndex}
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            pb: 1,
                                            minWidth: dateIndex === 0 ? 100 : 190,
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                position: 'sticky',
                                                top: '-32px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                bgcolor: dateObj.date?.toISODate() === DateTime.now().toISODate() ? 'green' : 'lightgrey',
                                                color: isDarkMode ? 'white' : 'black',
                                                minHeight: ROWHEIGHT,
                                                border: '1px solid lightgrey',
                                                borderRadius: 1,
                                                zIndex: 999999,
                                            }}
                                        >
                                            {dateIndex === 0 ? "Time (EDT)" :
                                                <Typography variant="h6">
                                                    {dateObj.date.toLocaleString({ weekday: "short" })}, {dateObj.date.toLocaleString({ month: "short" })} {dateObj.date.day}, {dateObj.date.year}
                                                </Typography>
                                            }
                                        </Box>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                flex: 1,
                                                borderRight: "1px solid lightgrey",
                                            }}
                                        >
                                            {dateObj.hours.map((hour, hourIndex) => {
                                                const isAvailability = selectedSlots.some(slot => slot[0] === dateIndex && slot[1] === hourIndex);
                                                const isClass = classSlots.some(slot => slot[0] === dateIndex && slot[1] === hourIndex);
                                                const classInfo = classSlots.find(slot => slot[0] === dateIndex && slot[1] === hourIndex);
                                                const overlappingClasses = classSlots.filter(slot => slot[0] === dateIndex && slot[1] === hourIndex);
                                                const isNoClass = isNoClassSlot(dateObj, hour);

                                                return (
                                                    <Tooltip key={hourIndex} title={`${hour} ~ ${DateTime.fromFormat(hour, 'h:mm a').plus({ minutes: 30 }).toFormat('h:mm a')} (EDT)`} placement="right">
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                bgcolor: getSlotBackgroundColor(isAvailability, isClass, classInfo, hourIndex),
                                                                height: ROWHEIGHT,
                                                                border: '1px solid lightgrey',
                                                                borderRadius: 1,
                                                                userSelect: 'none',
                                                                ...(isNoClass && { cursor: 'not-allowed' }), // Disable interaction for No Class slots
                                                                ...(userRole === 'teacher' && dateIndex > 0 && !isNoClass && { '&:hover': { cursor: 'grab' } })
                                                            }}
                                                            onMouseDown={isNoClass ? null : (e) => {
                                                                if ((!isClass && !isNoClass) || (classInfo[8] === true)) {
                                                                    handleSlotMouseDown(dateIndex, hourIndex, dateObj.date, hour, e);
                                                                }
                                                            }}
                                                            onMouseUp={isNoClass ? null : (e) => {
                                                                if ((!isClass && !isNoClass) || (classInfo[8] === true)) {
                                                                    setTimeout(() => {
                                                                        setIsDragging(false);
                                                                    }, 20);
                                                                    setDraggingData({});
                                                                }
                                                            }}
                                                            onMouseOver={isNoClass ? null : (e) => {
                                                                if ((!isClass && !isNoClass) || (classInfo[8] === true)) {
                                                                    handleSlotMouseOver(dateIndex, hourIndex, dateObj.date, hour, e);
                                                                }
                                                            }}
                                                        >
                                                            {dateIndex === 0 ? (
                                                                <Typography variant="h6">
                                                                    {hour}
                                                                </Typography>
                                                            ) : (
                                                                <Typography variant="body2">
                                                                    {getSlotInfo(overlappingClasses, isClass, isAvailability, isNoClass, classInfo)}
                                                                </Typography>
                                                                )
                                                            }
                                                        </Box>
                                                    </Tooltip>
                                                );
                                            })}
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                        </>}
                        <Alert
                            successAlertOpen={successAlertOpen}
                            setSuccessAlertOpen={setSuccessAlertOpen}
                            errorAlertOpen={errorAlertOpen}
                            setErrorAlertOpen={setErrorAlertOpen}
                            successMsg={successAlertMessage}
                            errorMsg={errorAlertMessage}
                            vertical="bottom"
                            horizontal="left"
                        />
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
};

export default AvailabilityModal;