import React from 'react'
import Button from '@mui/material/Button';
import { Box, TextField, Typography } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CloseIcon from '@mui/icons-material/Close';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
// import AreYouSureModal from '../../../components/AreYouSureModal';

const SetMakeUpEventButton = ({ 
    userId,
    zoom_id,
    date,
    _time,
    event_idx,
    event,
    colors, 
    handlePopOverClose,
    enqueueSnackbar,
    socketConnected,
    socket,
}) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: colors.primary[600],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const [isMakeUpClass, setIsMakeUpClass] = React.useState(event.is_make_up_event || false);
    const [note, setNote] = React.useState("");

    // for modal
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => { setOpen(false); handlePopOverClose(); };

    // const [areYouSureModalOpen, setAreYouSureModalOpen] = React.useState(false);

    React.useEffect(() => {
        if (event) {
            setIsMakeUpClass(event.is_make_up_event || false);
        }
    }, [event]);

    const handleSave = async () => {
        if (!socketConnected) {
            enqueueSnackbar("Socket is not connected. Please refresh the page.", { variant: "error" });
            return;
        }

        try {
            setTimeout(() => {
                // emit to server
                socket.emit("put.EventMakeUp", {
                    zoom_id: zoom_id,
                    date: date,
                    _time: _time,
                    event_idx: event_idx,
                    event: event,
                    is_make_up_event: isMakeUpClass,
                    note: note,
                });
            }, 300);
        } catch (error) {
            console.error(error);
            enqueueSnackbar("Error: Cannot save the changes. Please try again.", { variant: "error" });
            return;
        }

        handleClose();
    };

    return (
        <>
            <Button 
                onClick={handleOpen} 
                sx={{ 
                    p: 1, 
                    width: "100%",
                    bgcolor: colors.greenAccent[700],
                    borderRadius: "0px",
                    '&:hover': {
                        bgcolor: colors.greenAccent[600],
                    },
                }}
            >
                Set Make-Up Module
            </Button>
            <Modal
                aria-labelledby="one-drive-setup-modal"
                aria-describedby="one-drive-setup-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        {event && event.is_canceled_int > 0 ? (
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: "5px",
                                    width: "100%",
                                    height: "100%",
                                }}
                            >
                                <Typography variant="h4" color={colors.primary[450]}>
                                    This class is canceled. You cannot set a make-up class for a canceled class.
                                </Typography>
                                <Typography variant="h4" color={colors.primary[450]}>
                                    If you want to set a make-up class, please first restore the class module.
                                </Typography>
                            </Box>
                        ) : (
                            <>
                                <Typography variant="h4" color={colors.primary[450]}>
                                    Set Make-Up Module
                                </Typography>
                                <CloseIcon sx={{ position: "absolute", right: 10, top: 10, cursor: "pointer" }} onClick={handleClose} />
                                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", mt: 2 }}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            gap: "5px",
                                            minWidth: "100%",
                                        }}
                                    >
                                        <FormGroup>
                                            <FormControlLabel 
                                                control={
                                                    <Checkbox
                                                        checked={isMakeUpClass}
                                                        onChange={(e) => setIsMakeUpClass(e.target.checked)}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                    />
                                                } 
                                                label="Is a Make-Up Class?"
                                            />
                                        </FormGroup>
                                        <TextField
                                            label="Note"
                                            placeholder='Enter a note'
                                            multiline
                                            rows={5}
                                            variant="outlined"
                                            value={note}
                                            onChange={(e) => setNote(e.target.value)}
                                            sx={{ width: 400, mt: 2 }}
                                        />
                                        <Typography variant="body1" color={colors.grey[400]} mt={1}>
                                                This note will be shown to the students attendances in the Attendance page.
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        gap: 2,
                                        mt: 2,
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        sx={{ width: 200, mt: 2 }}
                                        onClick={() => handleSave()}
                                    >
                                        Save
                                    </Button>
                                    {/* <AreYouSureModal
                                        colors={colors}
                                        open={areYouSureModalOpen}
                                        setOpen={setAreYouSureModalOpen}
                                        title="Are you sure?"
                                        message="Are you sure you want to save the changes?"
                                        onConfirm={handleSave}
                                    /> */}
                                    <Button
                                        variant="contained"
                                        color="error"
                                        sx={{ width: 200, mt: 2 }}
                                        onClick={() => handleClose()}
                                    >
                                        Close
                                    </Button>
                                </Box>
                            </>
                        )}
                    </Box>
                </Fade>
            </Modal>
        </>
    )
}

export default SetMakeUpEventButton