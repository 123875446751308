import React from 'react'
import { Box, Button, TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import Dropdown from "../../../components/dropdown/Dropdown";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckSharpIcon from '@mui/icons-material/CheckSharp';
import ClearSharpIcon from '@mui/icons-material/ClearSharp';
import Axios from 'axios';
import dayjs from 'dayjs';
import CloseIcon from '@mui/icons-material/Close';
import ClassLogsConfirmModal from './ClassLogsConfirmModal';
import getStudentName from '../../../utils/getStudentName';

var options = {
    year: "numeric",
    month: "short",
    day: "numeric"
};

const CreateLogsForm = ({ 
    eventModules, 
    courseStudents,
    setBackdropOpen,
    setErrorAlertOpen,
    setSuccessAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertMessage,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:600px)");

    // for event modules
    const [event, setEvent] = React.useState("");
    const eventModuleOption = eventModules.map((module, index) => { 
        return { 
            value: module.id, 
            label: module.title + " (" + new Date(module.start).toLocaleDateString("en", options) + ")"
        }
    });

    const [studentsBasedOnEvent, setStudentsBasedOnEvent] = React.useState([]);

    // requirment check for course students evaluation
    const [courseStudentsEvaluation, setCourseStudentsEvaluation] = React.useState([]);

    // for adding progress chapter
    const [isProgressAdded, setIsProgressAdded] = React.useState(1); // number of progress added

    // for modal
    const [modalOpen, setModalOpen] = React.useState(false);

    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };
 
    React.useEffect(() => {
        let start_date = null;
        for (let i = 0; i < eventModules.length; i++) {
            if (eventModules[i].id === event) {
                start_date = eventModules[i].start;
                break;
            }
        }

        if (start_date !== null) {
            let students = [];
            for (let i = 0; i < courseStudents.length; i++) {
                if (dayjs(courseStudents[i].end_date).add(1, 'day').isAfter(dayjs(start_date))) {
                    students.push(courseStudents[i]);
                }
            }
            setStudentsBasedOnEvent(students);
        }
    }, [event]);

    React.useEffect(() => {
        setCourseStudentsEvaluation(
            studentsBasedOnEvent.length > 0 ? studentsBasedOnEvent.map((element) => {
                return {
                    id: element.student_id,
                    first_name: element.student.first_name,
                    last_name: element.student.last_name,
                    korean_name: element.student.korean_name,
                    eval_1: false,
                    eval_1_data: "",
                    eval_2: false,
                    eval_2_data: "",
                }
            }) : []
        );
    }, [studentsBasedOnEvent]);

    // for evaluation check
    const handleEvaluationChange = (event) => {
        setCourseStudentsEvaluation(courseStudentsEvaluation.map((element) => {
            if (element.id === parseInt(event.target.name.split('-')[0])) {
                if (event.target.name.split('-')[1] === "eval_1")
                    return {
                        ...element,
                        eval_1: event.target.value !== "" ? true : false,
                        eval_1_data: event.target.value
                    }
                else if (event.target.name.split('-')[1] === "eval_2")
                    return {
                        ...element,
                        eval_2: event.target.value !== "" ? true : false,
                        eval_2_data: event.target.value
                    }
            }
            return element;
        }));
    };

    const progressErrorCheck = (values) => {
        if (values.chapter_name === "" || values.chapter_name === null) {
            setErrorAlertMessage("Progress cannot be empty.");
            setErrorAlertOpen(true);
            return false;
        }

        if (values.chapter_name.length > 250) {
            setErrorAlertMessage("Progress is too long.");
            setErrorAlertOpen(true);
            return false;
        }

        if (isNaN(values.from) || isNaN(values.to)) {
            setErrorAlertMessage("Progress 'From' and 'To' must be a number.");
            setErrorAlertOpen(true);
            return false;
        }

        if (parseInt(values.from) > parseInt(values.to)) {
            setErrorAlertMessage("Progress 'From' cannot be greater than progress 'To'.");
            setErrorAlertOpen(true);
            return false;
        }

        if (values.from < 0 || values.to < 0) {
            setErrorAlertMessage("Progress 'From' and 'To' cannot be negative.");
            setErrorAlertOpen(true);
            return false;
        }

        if (isProgressAdded <= 1)
            return true;

        if (values.chapter_name_2 === "" || values.chapter_name_2 === null) {
            setErrorAlertMessage("Progress cannot be empty.");
            setErrorAlertOpen(true);
            return false;
        }

        if (values.chapter_name_2.length > 250) {
            setErrorAlertMessage("Progress is too long.");
            setErrorAlertOpen(true);
            return false;
        }

        if (isNaN(values.from_2) || isNaN(values.to_2)) {
            setErrorAlertMessage("Progress 'From' and 'To' must be a number.");
            setErrorAlertOpen(true);
            return false;
        }

        if (parseInt(values.from_2) > parseInt(values.to_2)) {
            setErrorAlertMessage("Progress 'From' cannot be greater than progress 'To'.");
            setErrorAlertOpen(true);
            return false;
        }

        if (values.from_2 < 0 || values.to_2 < 0) {
            setErrorAlertMessage("Progress 'From' and 'To' cannot be negative.");
            setErrorAlertOpen(true);
            return false;
        }

        if (isProgressAdded <= 2)
            return true;

        if (values.chapter_name_3 === "" || values.chapter_name_3 === null) {
            setErrorAlertMessage("Progress cannot be empty.");
            setErrorAlertOpen(true);
            return false;
        }

        if (values.chapter_name_3.length > 250) {
            setErrorAlertMessage("Progress is too long.");
            setErrorAlertOpen(true);
            return false;
        }

        if (isNaN(values.from_3) || isNaN(values.to_3)) {
            setErrorAlertMessage("Progress 'From' and 'To' must be a number.");
            setErrorAlertOpen(true);
            return false;
        }

        if (parseInt(values.from_3) > parseInt(values.to_3)) {
            setErrorAlertMessage("Progress 'From' cannot be greater than progress 'To'.");
            setErrorAlertOpen(true);
            return false;
        }

        if (values.from_3 < 0 || values.to_3 < 0) {
            setErrorAlertMessage("Progress 'From' and 'To' cannot be negative.");
            setErrorAlertOpen(true);
            return false;
        }

        return true;
    };
    
    // send a request to create a class log
    const handleFormSubmit = async (values) => {
        if (courseStudents.length === 0) {
            setErrorAlertMessage("There are no students in this course.");
            setErrorAlertOpen(true);
            return;
        }
        
        setBackdropOpen(true);

        //check if event is selected
        if (event === null || event === undefined || event === "") {
            setErrorAlertMessage("Please select a module.");
            setErrorAlertOpen(true);
            setBackdropOpen(false);
            return;
        }

        // check if all students have been evaluated
        let allEvaluated = true;
        courseStudentsEvaluation.forEach((element) => {
            if (!element.eval_1 || !element.eval_2) {
                allEvaluated = false;
            }
        });

        if (!allEvaluated) {
            setErrorAlertMessage("Please evaluate all students before submitting.");
            setErrorAlertOpen(true);
            setBackdropOpen(false);
            return;
        }

        let doesRequestHaveError = false;
        let errorMsg = "";

        // const progress = values.chapter_name + " (pp." + values.from + " - " + "pp." + values.to + ")";
        const progress = values.chapter_name;
        const progress_from = values.from;
        const progress_to = values.to;
        const progress_2 = values.chapter_name_2 || null;
        const progress_from_2 = values.from_2 || null;
        const progress_to_2 = values.to_2 || null;
        const progress_3 = values.chapter_name_3 || null;
        const progress_from_3 = values.from_3 || null;
        const progress_to_3 = values.to_3 || null;

        if (!progressErrorCheck(values)) {
            setBackdropOpen(false);
            return;
        }

        // send request to create progress in a event module
        await Axios.put(`${process.env.REACT_APP_URL}/api/v1/events/${event}/progress`, {
            progress: progress,
            progress_from: progress_from,
            progress_to: progress_to,
            progress_2: progress_2,
            progress_from_2: progress_from_2,
            progress_to_2: progress_to_2,
            progress_3: progress_3,
            progress_from_3: progress_from_3,
            progress_to_3: progress_to_3,
            progress_count: isProgressAdded,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((res) => {
            // console.log(res);
        }).catch((err) => {
            console.log(err);
            errorMsg = err.response.data.message;
            doesRequestHaveError = true;
        });

        if (!doesRequestHaveError) {
            const requestBodyArray = [];
            for (let i = 0; i < courseStudentsEvaluation.length; i++) {
                requestBodyArray.push({
                    student_id: courseStudentsEvaluation[i].id,
                    eval_1: courseStudentsEvaluation[i].eval_1_data,
                    eval_2: courseStudentsEvaluation[i].eval_2_data,
                });
            }
    
            // send request to create class log
            await Axios.post(`${process.env.REACT_APP_URL}/api/v1/events/${event}/special-notes`, {
                all_special_notes: requestBodyArray,
            }, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((res) => {
                // console.log(res);
            }).catch((err) => {
                console.log(err);
                errorMsg = err.response.data.message;
                doesRequestHaveError = true;
            });
        }

        setBackdropOpen(false);
        if (!doesRequestHaveError) {
            setSuccessAlertMessage("Class log has been created.");
            setSuccessAlertOpen(true);
            window.location.reload();
        } else {
            setErrorAlertMessage(errorMsg);
            setErrorAlertOpen(true);
        }
    };

    return (
        <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={checkoutSchema}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
            }) => (
            <form onSubmit={handleSubmit}>
                <Box
                    padding={4}
                    boxShadow={3}
                    borderRadius={3}
                    m={1}
                    bgcolor={colors.primary[600]}
                >
                    <Typography
                        variant="h4"
                        fontWeight="bold"
                        mb={3}
                    >
                        Create a Class Log
                    </Typography>
                    <Box
                        minWidth="100%"
                        height="4rem"
                    >
                        {/* Module SELECT */}
                        <Dropdown 
                            maxWidth="100%" 
                            maxHeight={3} 
                            reactUseState={event} 
                            setReactUseState={setEvent}
                            inputLabel="Select Module"
                            data={eventModuleOption} 
                            style={{ marginBottom: "20px" }}
                        />
                    </Box>
                    <Typography
                        variant="h6"
                        fontWeight="bold"
                        mb={1}
                    >
                        Progress
                    </Typography>
                    <Box
                        display="grid"
                        gap="30px"
                        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                        mb={1}
                        sx={{
                            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                        }}
                    >
                        <TextField
                            fullWidth
                            variant="filled"
                            type="text"
                            label="Name of Chapter"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.chapter_name}
                            name="chapter_name"
                            error={!!touched.chapter_name && !!errors.chapter_name}
                            helperText={touched.chapter_name && errors.chapter_name}
                            sx={{ gridColumn: "span 2" }}
                        />
                        <TextField
                            fullWidth
                            variant="filled"
                            type="text"
                            label="From (Page)"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.from}
                            name="from"
                            error={!!touched.from && !!errors.from}
                            helperText={touched.from && errors.from}
                            sx={{ gridColumn: "span 1" }}
                        />
                        <TextField
                            fullWidth
                            variant="filled"
                            type="text"
                            label="To"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.to}
                            name="to"
                            error={!!touched.to && !!errors.to}
                            helperText={touched.to && errors.to}
                            sx={{ gridColumn: "span 1" }}
                        />
                    </Box>
                    {isProgressAdded >= 2 && (
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            mb={1}
                            position="relative"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                        >
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Name of Chapter"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.chapter_name_2}
                                name="chapter_name_2"
                                error={!!touched.chapter_name_2 && !!errors.chapter_name_2}
                                helperText={touched.chapter_name_2 && errors.chapter_name_2}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="From (Page)"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.from_2}
                                name="from_2"
                                error={!!touched.from_2 && !!errors.from_2}
                                helperText={touched.from_2 && errors.from_2}
                                sx={{ gridColumn: "span 1" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="To"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.to_2}
                                name="to_2"
                                error={!!touched.to_2 && !!errors.to_2}
                                helperText={touched.to_2 && errors.to_2}
                                sx={{ gridColumn: "span 1" }}
                            />
                            <CloseIcon 
                                color='error' 
                                onClick={() => setIsProgressAdded(isProgressAdded - 1)} 
                                sx={{ 
                                    cursor: "pointer",
                                    position: "absolute",
                                    top: "0",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    right: "-20px",
                                    zIndex: "100",
                                }} 
                            />
                        </Box>
                    )}
                    {isProgressAdded >= 3 && (
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            position="relative"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                        >
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Name of Chapter"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.chapter_name_3}
                                name="chapter_name_3"
                                error={!!touched.chapter_name_3 && !!errors.chapter_name_3}
                                helperText={touched.chapter_name_3 && errors.chapter_name_3}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="From (Page)"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.from_3}
                                name="from_3"
                                error={!!touched.from_3 && !!errors.from_3}
                                helperText={touched.from_3 && errors.from_3}
                                sx={{ gridColumn: "span 1" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="To"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.to_3}
                                name="to_3"
                                error={!!touched.to_3 && !!errors.to_3}
                                helperText={touched.to_3 && errors.to_3}
                                sx={{ gridColumn: "span 1" }}
                            />
                            <CloseIcon 
                                color='error' 
                                onClick={() => setIsProgressAdded(isProgressAdded - 1)} 
                                sx={{ 
                                    cursor: "pointer",
                                    position: "absolute",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    right: "-20px",
                                    zIndex: "100",
                                }} 
                            />
                        </Box>
                    )}
                    {isProgressAdded < 3 && (
                        <Button
                            variant="contained"
                            style={{
                                backgroundColor: colors.blueAccent[600],
                                color: "white",
                                width: "12rem",
                                marginTop: "20px",
                            }}
                            onClick={() => {
                                setIsProgressAdded(isProgressAdded + 1);
                            }}
                        >
                            Add Chapter
                        </Button>
                    )}

                    <Typography
                        variant="h6"
                        fontWeight="bold"
                        mb={1}
                        mt={2}
                    >
                        Student Evaluations
                    </Typography>
                    {courseStudentsEvaluation && courseStudentsEvaluation.map((student, index) => (
                        <div 
                            key={index}
                            style={{
                                width: "100%",
                                marginBottom: "1rem",
                            }}
                        >
                            <Accordion
                                key={index}
                                style={{
                                    backgroundColor: colors.primary[750],
                                }}
                                component="div"
                                defaultExpanded={index === 0 ? true : false}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography variant='h6' mr={1}>
                                        {getStudentName(student)}
                                    </Typography>
                                    {student.eval_1 && student.eval_2 ? 
                                        <CheckSharpIcon color='success' /> : <ClearSharpIcon color='error' />
                                    }
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box 
                                        display="flex"
                                        gap={1}
                                    >
                                        <TextField
                                            id="filled-basic"
                                            label="Evaluation: Student's good at this..."
                                            variant="filled"
                                            name={student.id + "-eval_1"}
                                            fullWidth={true}
                                            multiline={true}
                                            rows={5}
                                            sx={{
                                                minWidth: "40%",
                                                borderRadius: "5px",
                                                '& .MuiFilledInput-root': {
                                                    ":focus": {
                                                        backgroundColor: 'white',
                                                    },
                                                },
                                            }}
                                            onChange={handleEvaluationChange}
                                        />
                                        <TextField
                                            id="filled-basic"
                                            label="Evaluation: Student needs to improve on this..."
                                            variant="filled"
                                            name={student.id + "-eval_2"}
                                            fullWidth={true}
                                            multiline={true}
                                            rows={5}
                                            sx={{
                                                minWidth: "40%",
                                                borderRadius: "5px",
                                                '& .MuiFilledInput-root': {
                                                    ":focus": {
                                                        backgroundColor: 'white',
                                                    },
                                                },
                                            }}
                                            onChange={handleEvaluationChange}
                                        />
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    ))}
                    <Box display="flex" justifyContent="center" mt="20px">
                        <Button
                            onClick={handleModalOpen}
                            // onClick={handleSubmit}
                            variant="contained"
                            style={{
                                backgroundColor: colors.primary[300],
                                color: "white",
                                width: "10rem",
                            }}
                        >
                            Create
                        </Button>
                        <ClassLogsConfirmModal 
                            open={modalOpen}
                            onClose={handleModalClose}
                            onSubmit={handleSubmit}                            
                        />
                    </Box>
                </Box>
            </form>
            )}
        </Formik>
    )
}

const checkoutSchema = yup.object().shape({
    chapter_name: yup.string().required("required"),
    from: yup.number().required("required"),
    to: yup.number().required("required"),
    chapter_name_2: yup.string().optional(),
    from_2: yup.number().optional(),
    to_2: yup.number().optional(),
    chapter_name_3: yup.string().optional(),
    from_3: yup.number().optional(),
    to_3: yup.number().optional(),
});

const initialValues = {
    chapter_name: "",
    from: "",
    to: "",
    chapter_name_2: "",
    from_2: "",
    to_2: "",
    chapter_name_3: "",
    from_3: "",
    to_3: "",
};


export default CreateLogsForm