import React from 'react'
import Button from '@mui/material/Button';

const GoToClassRoomButton = ({ course }) => {

    return (
        <Button 
            component='a'
            href={"/classrooms/" + course.uuid + "/home"}
            target='_blank'
            rel='noopener noreferrer'
            sx={{ p: 1, width: "100%" }}
        >
            Go to Classroom
        </Button>
    )
}

export default GoToClassRoomButton