import React from "react";
import { Box, IconButton, Button, Typography, useTheme } from "@mui/material";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import AccountUpdate from "./topBarComp/AccountUpdate";
import ScheduleModal from "../adminDashboard/teachers/moreInfo/ScheduleModal";
import AvailabilityModal from "../adminDashboard/teachers/moreInfo/AvailabilityModal";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import GuidelineModal from './guideline/GuidelineModal';

const ClassesTopbar = ({ userRole, userId }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);

    // auth
    const signOut = useSignOut();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut();
        localStorage.removeItem("userName");
        localStorage.removeItem("who");
        navigate("/signin");
    }

    // for guideline
    const [openGuideline, setOpenGuideline] = React.useState(false);
    const handleOpenGuideline = () => setOpenGuideline(true);
    const handleCloseGuideline = () => setOpenGuideline(false);


    return (
        <Box 
            display="flex" 
            justifyContent="space-between" 
            p={2} 
            backgroundColor={colors.primary[500]} 
            position="fixed" 
            top={0} 
            width="100%"
            boxShadow={10}
            zIndex={1000}
        >
            <Box display="flex">
                <img src="/imgs/a-one-logo-white.png" alt="logo" style={{height: "30px"}} />
            </Box>

            {/* ICONS */}
            <Box display="flex" gap={1}>
                {/* Teacher Weekly Schedule */}
                {userRole === "teacher" ? (
                    <>
                        <IconButton onClick={handleOpenGuideline}>
                            <HelpOutlineIcon color="secondary"/>
                        </IconButton>
                        <AvailabilityModal iconColor={"secondary"} button={false} userId={userId}/>                
                        <ScheduleModal iconColor={"secondary"} teacher={null} button={false} userId={userId} />
                    </>
                ) : null}
                
                {/* Password update icon */}
                <AccountUpdate color="secondary" />

                {/* color mode change */}
                <IconButton onClick={colorMode.toggleColorMode} style={{marginRight: "10px"}}>
                    {theme.palette.mode === "dark" ? (
                        <DarkModeOutlinedIcon color="secondary" />
                    ) : (
                        <LightModeOutlinedIcon color="secondary" />
                    )}
                </IconButton>

                {/* Sign out button */}
                <Button
                    fullWidth
                    variant="contained"
                    color='secondary'
                    onClick={handleLogout}
                >
                    <Typography color={colors.primary[400]}>Sign Out</Typography>
                </Button>
            </Box>
            <GuidelineModal userRole={userRole} userId={userId} open={openGuideline} handleClose={handleCloseGuideline} />
        </Box>
    );
};

export default ClassesTopbar;