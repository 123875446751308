import * as React from 'react';
import { tokens } from "../../theme";
import { Button, useTheme } from "@mui/material";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import RestoreIcon from '@mui/icons-material/Restore';

function PrevEmailContentModal({
    modalOpen, 
    setModalOpen,
    pToDiv,
    endOfEmail,
    setEmailContent,
}) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1000,
        height: 800,
        bgcolor: colors.primary[600],
        border: '2px solid grey',
        boxShadow: 24,
        p: 4,
        overflowY: "auto",
    };
    
    const handleModalClose = () => setModalOpen(false);
    const [page, setPage] = React.useState(1);
    const [prevEmailContents, setPrevEmailContents] = React.useState(
        localStorage.getItem('prevEmailContents') ? JSON.parse(localStorage.getItem('prevEmailContents')).reverse() : []
    );
    React.useEffect(() => {
        setPrevEmailContents(localStorage.getItem('prevEmailContents') ? JSON.parse(localStorage.getItem('prevEmailContents')).reverse() : []);
    }, [page,modalOpen]);
    function restoreData() {
        setEmailContent(prevEmailContents[page - 1]);
        handleModalClose();
    }
    return (
        <Modal
            open={modalOpen}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={modalOpen}>
                <Box sx={style}>
                    <Typography variant="h3" ml={1} borderBottom={1}>
                        Restore Previous Email Content
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: 2,
                            padding: "10px",
                            marginBottom: "20px",
                        }}
                    >
                        <Button
                            variant="text"
                            color="primary"
                            disabled={page === 1}
                            onClick={() => setPage(page - 1)}
                            sx={{
                                backgroundColor: colors.greenAccent[500],
                            }}
                        >
                            Previous
                        </Button>
                        <Button
                            variant="text"
                            color="primary"
                            disabled={page === prevEmailContents.length || prevEmailContents.length === 0}
                            onClick={() => setPage(page + 1)}
                            sx={{
                                backgroundColor: colors.greenAccent[500],
                            }}
                        >
                            Next
                        </Button>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginBottom: "20px",
                        }}
                    >
                        <Box 
                            sx={{
                                display: 'flex', 
                                flexDirection: 'column',
                                alignItems: 'center', 
                                height: "100%",
                                width: "100px",
                            }}
                        >
                            {prevEmailContents.length === 0 && (
                                <Box 
                                    sx={{
                                        display: 'flex', 
                                        alignItems: 'center', 
                                        justifyContent: 'center',
                                        padding: "50px 10px",
                                        width: "800px",
                                        border: 1,
                                    }}
                                >
                                    <Typography variant="h6">
                                        No Previous Email Contents
                                    </Typography>
                                </Box>
                            
                            )}
                            {prevEmailContents.map((emailContent, index) => {
                                if (index === page - 1) {
                                    return (
                                        <Box 
                                            key={index}
                                            sx={{
                                                display: 'flex', 
                                                flexDirection: 'column',
                                                alignItems: 'center', 
                                                width: "800px",
                                                border: 1,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: 2,
                                                    padding: 2,
                                                    borderRadius: 1,
                                                }}
                                            >
                                                <div 
                                                    dangerouslySetInnerHTML={{ __html: pToDiv(emailContent) + endOfEmail }} 
                                                    style={{
                                                        minHeight: 800,
                                                        padding: "10px 20px",
                                                        backgroundColor: colors.primary[600],
                                                    }}
                                                />
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    width: "100%",
                                                    height: "50px",
                                                    padding: "0 10px",
                                                }}
                                            >
                                                <Button
                                                    variant="contained"
                                                    onClick={restoreData}
                                                    color='secondary'
                                                    sx={{
                                                        display: 'flex',
                                                        gap: 1,
                                                        mb: 2,
                                                    }}
                                                >
                                                    <RestoreIcon color='primary' />
                                                    Restore
                                                </Button>                                                        
                                            </Box>
                                        </Box>
                                    )
                                }
                                return null;
                            })}
                        </Box>
                    </Box>
                    <Box sx={{ pb: 2 }}>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
}

export default PrevEmailContentModal;