import React from 'react'
import { tokens } from "../../theme";
import { Box, Button, IconButton, useTheme, Modal, Fade, TextField, MenuItem, ListItemText } from "@mui/material";
import CompetitionCard from "./CompetitionCard";
import Header from "../../components/Header";
import Loading from '../../components/Loading';
import Axios from 'axios';
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { motion, AnimatePresence } from "framer-motion";
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import Alert from '../../components/alert/Alert';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';

const Competitions = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // for subject categories
    const subjectCategories = [
        "Biology",
        "Chemistry",
        "Physics",
        "Computer Science",
        "Economics",
        "English",
        "History",
        "Math",
        "Research",
        "Writing Competition",
    ];

    const [selectedSubject, setSelectedSubject] = React.useState([]);
    const handleSubjectChange = (event) => {
        setSelectedSubject(event.target.value);
        setOpenSubjectSelect(false);
    };

    const [openSubjectSelect, setOpenSubjectSelect] = React.useState(false);
    const [targetSubjects, setTargetSubjects] = React.useState([]);

    const handleSelectSubjects = (event) => {
        const { target: { value } } = event;
        setTargetSubjects(typeof value === 'string' ? value.split(',') : value);
    };

    React.useEffect(() => {
        fetchCompetitions();
    }, [targetSubjects]);

    const style = {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "20%",
        height: "35%",
        bgcolor: colors.primary[600],
        border: '2px solid #000',
        p: 4,
    };

    // for 401 error
    const signOut = useSignOut();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut();
        localStorage.removeItem("userName");
        localStorage.removeItem("who");
        navigate("/signin");
    }

    // for alert
    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = React.useState("");
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = React.useState("");

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);    
    
    const [loading, setLoading] = React.useState(true);
    const [competitions, setCompetitions] = React.useState([]);
    const [search, setSearch] = React.useState('');
    const [competitionUpdated, setCompetitionUpdated] = React.useState(false);
    const [competitionName, setCompetitionName] = React.useState("");
    const [competitionUrl, setCompetitionUrl] = React.useState("");

    async function fetchCompetitions() {
        await Axios.get(`${process.env.REACT_APP_URL}/api/v1/competitions`, {
            params: {
                subjects: targetSubjects,
            },
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then(async (response) => {
                setCompetitions(response.data);
                setLoading(false);
            }).catch((error) => {
                if (error.response !== null && error.response.status !== 404)
                    console.log(error.response.data.message);
                else if (error.response !== null && error.response.status === 401)
                    handleLogout();
                setLoading(false);
            });
        setCompetitionUpdated(false);
    };

    async function saveCompetitionInfo() {
        if (!competitionName) {
            setErrorAlertOpen(true);
            setErrorAlertMessage("Title is not provided!");
            return;
        }

        handleClose();

        await Axios.post(`${process.env.REACT_APP_URL}/api/v1/competitions`, {
            name: competitionName,
            url: competitionUrl,
            subject: selectedSubject,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setSuccessAlertOpen(true);
            setSuccessAlertMessage(response.data.message);
            setCompetitionUpdated(true);
        }).catch((error) => {
            setErrorAlertOpen(true);
            setErrorAlertMessage(error.response.data.error);
        });
    };

    React.useEffect(() => {
        localStorage.setItem('selectedMenu', 'Competitions');
        fetchCompetitions();

    }, [competitionUpdated]);

    const handleCompetitionNameChange = (e) => {
        setCompetitionName(e.target.value);
    };

    const handleUrlChange = (e) => {
        setCompetitionUrl(e.target.value);
    };

    return (
        <Box m="20px" marginX={3} marginY={3} >
            <Header title="Competitions" subtitle="Competitions for calendar" />
            { loading ? 
                <Loading />
            : <>
                <Box display="flex" justifyContent="left" gap={2} p={0} minHeight={70} mb={2}>
                    {/* SEARCH BAR */}
                    <Box
                        display="flex"
                        backgroundColor={colors.primary[600]}
                        borderRadius="3px"
                        boxShadow={10}
                        mb={2}
                        maxWidth={300}
                        minWidth={220}
                    >
                        <InputBase sx={{ ml: 2, flex: 1 }} onChange={(e) => setSearch(e.target.value)} placeholder="Search by competition title" />
                        <IconButton type="button" sx={{ p: 1 }}>
                            <SearchIcon />
                        </IconButton>
                    </Box>
                
                    {/* Subject */}
                    <FormControl sx={{minWidth: '100px'}}>
                    <InputLabel id="subject-availability-label">Subjects</InputLabel>
                        <Select
                            labelId="subject-availability-label"
                            id="subject-availability"
                            multiple
                            value={targetSubjects}
                            onChange={handleSelectSubjects}
                            renderValue={(selected) => selected.join(', ')}
                        >
                            {subjectCategories.map((subject, index) => (
                                <MenuItem key={index} value={subject}>
                                    <Checkbox checked={targetSubjects.indexOf(subject) > -1} />
                                    <ListItemText primary={subject} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <Box
                        display="flex"
                        backgroundColor={colors.primary[600]}
                        borderRadius="3px"
                        boxShadow={10}
                        mb={2}
                        maxWidth={300}
                        minWidth={220} 
                    >
                        <>
                        <Button
                            sx={{flex: 1 }}
                            variant="contained"
                            color="primary"
                            onClick={handleOpen}
                        >
                            Add Competition
                        </Button>
                        </>
                    </Box>
                </Box>

                {/* Competition CARDS */}
                <motion.div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
                        rowGap: "1rem",
                        columnGap: "1rem",
                    }}
                    layout
                >
                    <AnimatePresence>
                        {competitions
                            .filter((competition) => {
                                return search === ''
                                    ? competition
                                    : competition.name.toLowerCase().includes(search.toLowerCase())
                                        || competition.name.toLowerCase().includes(search.toLowerCase());
                            })
                        .map((competition) => {
                            return (
                                <CompetitionCard
                                    key={competition.id}
                                    competition={competition}
                                    competitionUpdated={competitionUpdated}
                                    setCompetitionUpdated={setCompetitionUpdated}
                                    setSuccessAlertOpen={setSuccessAlertOpen}
                                    setSuccessAlertMessage={setSuccessAlertMessage}
                                    setErrorAlertOpen={setErrorAlertOpen}
                                    setErrorAlertMessage={setErrorAlertMessage}                
                                />
                            );
                        })}
                    </AnimatePresence>
                </motion.div>
            </>}
            <Alert
                successAlertOpen={successAlertOpen}
                setSuccessAlertOpen={setSuccessAlertOpen}
                errorAlertOpen={errorAlertOpen}
                setErrorAlertOpen={setErrorAlertOpen}
                successMsg={successAlertMessage}
                errorMsg={errorAlertMessage}
                vertical="bottom"
                horizontal="left"
            />
            <Modal
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                open={open}
                onClose={handleClose}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Box 
                            sx={{ 
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: 4,
                                width: "100%",
                            }}
                        >
                            {/* Name */}
                            <TextField
                                id="input-with-icon-textfield"
                                label="Title of the competition?"
                                variant="standard"
                                onChange={handleCompetitionNameChange}
                                fullWidth
                            />
                            {/* URL */}
                            <TextField                        
                                id="input-with-icon-textfield"
                                label="URL of the competition homepage?"
                                variant="standard"
                                onChange={handleUrlChange}
                                fullWidth
                            />
                            {/* Subject */}
                            <FormControl variant="filled" sx={{ width: '100%' }}>
                                <InputLabel id="subject">Subject</InputLabel>
                                    <Select
                                        labelId="subject"
                                        id="subject"
                                        value={selectedSubject}
                                        onChange={handleSubjectChange}
                                        onOpen={() => setOpenSubjectSelect(true)}
                                        onClose={() => setOpenSubjectSelect(false)}
                                        open={openSubjectSelect}
                                        renderValue={(value) => value || 'Select a subject'}
                                    >
                                        {subjectCategories.map((subject, index) => (
                                            <MenuItem key={index} value={subject}>
                                                <ListItemText primary={subject} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            <Button
                                variant='contained'
                                onClick={saveCompetitionInfo}
                            >
                                Create
                            </Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </Box>
    );
};


export default Competitions;