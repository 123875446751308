import React from 'react'
import { Box, useTheme, Typography } from "@mui/material";
import { tokens } from "../../../theme";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import MoreVertIcon from '../../../components/MoreVertIcon';
import Popover from '@mui/material/Popover';
import UpdateModal from './UpdateModal';
import Axios from 'axios';
import DeleteDialog from '../../../components/DeleteDialog';
import dayjs from 'dayjs';
import AssignZoomModal from './AssignZoomModal';

const ModuleBox = ({ 
    courseId, 
    courseData,
    userRole, 
    eventModule, 
    eventModules_len, 
    zooms, 
    index, 
    setBackdropOpen,
    setSuccessAlertOpen,
    setErrorAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertMessage,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // for module visibility
    const eventDate = dayjs(eventModule.end);
    const today = dayjs().hour(eventDate.hour()).minute(eventDate.minute()).second(0).millisecond(0);
    const visibilityForStudentsAndTeachers = eventDate.isBefore(today.add(7, 'day'));

    // for moreVertIcon popover
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleMoreVertIconClick = (event) => {
        if (userRole === "student")
            return;
        setAnchorEl(event.currentTarget);
    };
    const handlePopOverClose = () => {
        setAnchorEl(null);
    };
    const popoverOpen = Boolean(anchorEl);
    const popoverId = popoverOpen ? 'popover' : undefined;

    // for homework chip click
    const handleClick = (event, homework_id) => {
        event.preventDefault();
        localStorage.setItem('selectedMenu', 'Homework');
        window.location.href = `/classrooms/${courseId}/homework/${homework_id}`;
    };

    // for delete event
    const handleDeleteEventModule = async () => {
        setAnchorEl(null);
        setBackdropOpen(true);
        await Axios.delete(`${process.env.REACT_APP_URL}/api/v1/events/${eventModule.id}`, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((res) => {
            setBackdropOpen(false);
            setSuccessAlertMessage("Module deleted successfully");
            setSuccessAlertOpen(true);
            window.location.reload();
        }).catch((err) => {
            setBackdropOpen(false);
            setErrorAlertMessage(err.response.data.message || "Failed to delete the module");
            setErrorAlertOpen(true);
            console.log(err);
        });
    };

    return (
        <Box sx={{ mb: "1rem" }}>
            {visibilityForStudentsAndTeachers === false && userRole === "student" ? null : (
                <Accordion 
                    key={eventModule.id}
                    sx={{ 
                        backgroundColor: colors.primary[750],
                        boxShadow: "0px 0px 1px 2px rgba(0, 0, 0, 0.30)",
                    }}
                    defaultExpanded={index === 0 ? true : false}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        {/* <Typography>{eventModule.title}</Typography> */}
                        <Typography
                            color={eventModule.is_canceled ? "error" : "primary"}
                        >
                            Class Module {eventModules_len - index}{eventModule.is_make_up_event && ' (Make-up)'}{userRole === "admin" && eventModule.zoom !== null && ` (Zoom ${eventModule.zoom.room_number})`}{eventModule.is_canceled && ' (Canceled)'}
                        </Typography>
                        <Typography sx={{ ml: "auto", mr: "1rem" }}>
                            {dayjs(eventModule.start).format('MMM D, YYYY h:mm A')} - {dayjs(eventModule.end).format('h:mm A')}
                        </Typography>
                    </AccordionSummary>
                    <Divider />
                    <AccordionDetails sx={{ backgroundColor: colors.primary[600] }} >
                        {(userRole === "admin" || userRole === "teacher") &&
                            <><Box sx={{ display: "flex", justifyContent: "space-between", py: "1rem" }}>
                                <FormControlLabel 
                                    control={
                                        <Switch 
                                            checked={visibilityForStudentsAndTeachers}
                                            sx={{
                                                "& .MuiSwitch-switchBase.Mui-checked": {
                                                    color: colors.greenAccent[500],
                                                },
                                                "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                                                    backgroundColor: colors.primary[800],
                                                },
                                                "& .MuiSwitch-switchBase + .MuiSwitch-track": {
                                                    backgroundColor: colors.primary[800],
                                                },
                                                "& .MuiSwitch-switchBase.Mui-disabled": {
                                                    color: colors.greenAccent[500],
                                                },
                                            }}
                                        />
                                    }
                                    label={visibilityForStudentsAndTeachers ? "Visible" : "Hidden"}
                                />
                                <MoreVertIcon handleClick={handleMoreVertIconClick} />
                                <Popover
                                    id={popoverId}
                                    open={popoverOpen}
                                    anchorEl={anchorEl}
                                    onClose={handlePopOverClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                >
                                    {userRole === "admin" && 
                                        <AssignZoomModal 
                                            moduleNumber={eventModules_len - index}
                                            zooms={zooms}
                                            lectureModule={eventModule}
                                            setBackdropOpen={setBackdropOpen}
                                            setSuccessAlertOpen={setSuccessAlertOpen}
                                            setErrorAlertOpen={setErrorAlertOpen}
                                            setSuccessAlertMessage={setSuccessAlertMessage}
                                            setErrorAlertMessage={setErrorAlertMessage}
                                            setAnchorEl={setAnchorEl}
                                        />
                                    }
                                    <UpdateModal 
                                        courseId={courseId} 
                                        courseData={courseData}
                                        lectureModule={eventModule}
                                        userRole={userRole}
                                        setBackdropOpen={setBackdropOpen}
                                        setSuccessAlertOpen={setSuccessAlertOpen}
                                        setErrorAlertOpen={setErrorAlertOpen}
                                        setSuccessAlertMessage={setSuccessAlertMessage}
                                        setErrorAlertMessage={setErrorAlertMessage}
                                        setAnchorEl={setAnchorEl}
                                    />
                                    {userRole === "admin" &&
                                    <DeleteDialog 
                                        handleDelete={handleDeleteEventModule}
                                        setAnchorEl={setAnchorEl}
                                        dialogTitle="Are you sure you want to delete this lecture module?"
                                    />
                                    }
                                </Popover>
                            </Box>
                            <Divider /></>
                        }
                        <Box sx={{ py: "1rem", display: "flex" }}>
                            <Typography>Class note: </Typography>
                            {eventModule.class_notes !== null && eventModule.class_notes.length > 0 &&
                                eventModule.class_notes.map((class_note) => {
                                    return (
                                        <Typography 
                                            key={class_note.id}
                                            component="a" 
                                            href={class_note.file_url}
                                            target="_blank"
                                            rel="noreferrer"
                                            sx={{
                                                ml: "0.5rem",
                                                textDecoration: "underline",
                                                "&:hover": {
                                                    color: colors.primary[500],
                                                }
                                            }}
                                        >
                                            {class_note.name}
                                        </Typography>
                                    )
                                })
                            }
                        </Box>
                        <Divider />
                        <Box display="flex" py="1rem">
                            <Typography mt="5px" mr="1rem">Homework: </Typography>
                            <Stack direction="row" gap={1} flexWrap="wrap" alignItems="center">
                                {eventModule.homeworks !== null &&
                                    eventModule.homeworks.map((homework) => {
                                        return (
                                            <Box 
                                                key={homework.id} 
                                                component="div" 
                                                onClick={(e) => handleClick(e, homework.id)}
                                            >
                                                <Chip 
                                                    key={homework.id} 
                                                    label={homework.title} 
                                                    size="medium"
                                                    sx={{
                                                        cursor: "pointer",
                                                        "&:active" : {
                                                            backgroundColor: colors.primary[450],
                                                            color: colors.primary[50],
                                                        }
                                                    }}
                                                />
                                            </Box>
                                        )
                                    })
                                }
                            </Stack>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            )}
        </Box>
    )
}

export default ModuleBox