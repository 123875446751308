import * as React from "react";
import { Box, Button, TextField } from "@mui/material";
import Axios from 'axios';
import Alert from '../../components/alert/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTime, Settings } from 'luxon';
import Typography from '@mui/material/Typography';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import * as yup from 'yup';

Settings.defaultZone = "system";

const ModifyTab = ({ courseData, setCourseUpdated }) => {

    const gridItemStyle = {
        '& > :not(style)': { m: 1, width: '25rem' }
    };

    const [isPrivateCourse, setIsPrivateCourse] = React.useState(courseData.is_private_course);

    // for course "from date" and "to date" (required)
    const [startDate, setStartDate] = React.useState(courseData !== null && courseData.start_date !== null ? DateTime.fromISO(courseData.start_date) : null);
    const [endDate, setEndDate] = React.useState(courseData !== null && courseData.end_date !== null ? DateTime.fromISO(courseData.end_date) : null);
    
    // for dialog
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const handleClickDialogOpen = () => {
        setDialogOpen(true);
    };
    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    // for alert
    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = React.useState("");
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = React.useState("");
    const [priceError, setPriceError] = React.useState('');

    // for semester autocomplete
    const seasons = ['Spring', 'Summer', 'Fall', 'Winter'];
    const [semesters, setSemesters] = React.useState(null);
    const [semesterForCourse, setSemesterForCourse] = React.useState(courseData.course_semesters.map((course_semester) => {
        return course_semester.semester;
    }));

    // for days of week for couese schedule
    const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    // const daysValue = {'Monday' : 1, 'Tuesday' : 2, 'Wednesday' : 3, 'Thursday' : 4, 'Friday' : 5, 'Saturday' : 6}
    const [days, setDays] = React.useState(null);

    // for time schedule in each day of class session
    const [fromValue, setFromValue] = React.useState(DateTime.now());
    const [toValue, setToValue] = React.useState(DateTime.now());


    const priceSchema = yup.number()
    .optional()
    .nullable()
    .transform((value, originalValue) => {
        if (originalValue === '' || originalValue === null) return null;
        return value;
    })
    .typeError('Price must be a number')
    .positive('Price must be greater than 0')
    .integer('Price must be an integer');

    const handlePriceChange = (event) => {
        const value = event.target.value;
        try {
            if (value === '') {
                setPriceError('');
                return;
            }
            priceSchema.validateSync(value);
            setPriceError('');
        } catch (err) {
            setPriceError(err.message);
        }
    };

    const handleCourseDelete = async () => {
        handleDialogClose();
        
        await Axios.delete(`${process.env.REACT_APP_URL}/api/v1/courses/${courseData.uuid}`, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
                setSuccessAlertOpen(true);
                setSuccessAlertMessage(response.data.message);
                window.location.href = "/admin/courses";
            }).catch((error) => {
                console.log(error.response.data.message);
                setErrorAlertOpen(true);
                setErrorAlertMessage(error.response.data.message);
            });
    };
    
    const handleFormSubmit = async (event) => {
        event.preventDefault();

        if (isPrivateCourse === null) {
            setErrorAlertMessage("Course type is required.");
            setErrorAlertOpen(true);
            return;
        }

        if (event.target.course_title.value === null || event.target.course_title.value.trim() === "") {
            setErrorAlertMessage("Course title cannot be empty.");
            setErrorAlertOpen(true);
            return;
        }

        if (event.target.subject.value === null || event.target.subject.value.trim() === "") {
            setErrorAlertMessage("Subject cannot be empty.");
            setErrorAlertOpen(true);
            return;
        }

        if (startDate === null || endDate === null) {
            setErrorAlertMessage("Start date and end date are required.");
            setErrorAlertOpen(true);
            return;
        }
        
        if (startDate > endDate) {
            setErrorAlertMessage("Start date cannot be greater than end date!");
            setErrorAlertOpen(true);
            return;
        }

        if (priceError) {
            setErrorAlertMessage("Please enter a valid integer for price!");
            setErrorAlertOpen(true);
            return;
        }

        const utcStartDate = startDate.toUTC();
        const utcEndDate = endDate.toUTC();

        const old_semesters_set = new Set(courseData.course_semesters.map((course_semester) => {
            return course_semester.semester.id;
        }));
        const new_semesters_list = [];
        const semesters_list_to_delete = [];
        for (let j = 0; j < semesterForCourse.length; j++) {
            if (!old_semesters_set.has(semesterForCourse[j].id)) {
                new_semesters_list.push(semesterForCourse[j].id);
            }
        }

        for (let j = 0; j < courseData.course_semesters.length; j++) {
            if (!semesterForCourse.some((semester) => semester.id === courseData.course_semesters[j].semester.id)) {
                semesters_list_to_delete.push(courseData.course_semesters[j].semester.id);
            }
        }

        let moduleStartTime = null;
        let moduleEndTime = null;

        if (days !== null && days.length > 0) {
            if (fromValue === null || toValue === null) {
                setErrorAlertMessage("From time and to time are required.");
                setErrorAlertOpen(true);
                return;
            }

            if (fromValue > toValue) {
                setErrorAlertMessage("From time cannot be greater than to time!");
                setErrorAlertOpen(true);
                return;
            }

            if (fromValue === toValue) {
                setErrorAlertMessage("From time cannot be equal to to time!");
                setErrorAlertOpen(true);
                return;
            }

            // only need hour and minute
            moduleStartTime = fromValue.toISO().split("T")[1].split("-")[0];
            moduleEndTime = toValue.toISO().split("T")[1].split("-")[0];
        }

        await Axios.put(`${process.env.REACT_APP_URL}/api/v1/courses/${courseData.uuid}`, {
            code: event.target.code.value ? event.target.code.value.trim() : courseData.code,
            course_title: event.target.course_title.value.trim(),
            subject: event.target.subject.value.trim(),
            textbook: event.target.textbook.value ? event.target.textbook.value.trim() : null,
            textbook_total_page: event.target.textbook_total_page.value ? event.target.textbook_total_page.value : 0,
            price: event.target.price.value ? event.target.price.value : null,
            new_semesters_list: new_semesters_list,
            semesters_list_to_delete: semesters_list_to_delete,
            start_date: utcStartDate,
            end_date: utcEndDate,
            days: days,
            start_time: moduleStartTime,
            end_time: moduleEndTime,
            is_private_course: isPrivateCourse,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setSuccessAlertOpen(true);
            setSuccessAlertMessage(response.data.message);
            setCourseUpdated(true);
        }).catch((error) => {
            console.log(error.response.data.message);
            setErrorAlertOpen(true);
            setErrorAlertMessage(error.response.data.message);
        });
    };

    React.useEffect(() => {
        async function fetchSemesters() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/semesters`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                    setSemesters(response.data);
                }).catch((error) => {
                    console.log(error.response.data.message);
                    setErrorAlertOpen(true);
                    setErrorAlertMessage(error.response.data.message);
                });
        };

        fetchSemesters();
    }, []);

    return (
        <>
            <Box component="form" onSubmit={handleFormSubmit} noValidate maxWidth="60rem">
                <Typography variant="h6" fontWeight='bold' sx={{ py: 1 }}>
                    Course Infomation
                </Typography>
                <Grid container direction="row" alignItems="center">
                    <Grid item sx={gridItemStyle}>
                        <TextField
                            id="code"
                            name="code"
                            label="Course Code"
                            variant="filled"
                            defaultValue={courseData.code}
                        />
                    </Grid>
                    <Grid item sx={gridItemStyle}>
                        <FormControl>
                            <InputLabel id="demo-simple-select-label">
                                Course Type
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={isPrivateCourse}
                                label="Course Type"
                                onChange={(event) => setIsPrivateCourse(event.target.value)}
                            >
                                <MenuItem value={null}>None</MenuItem>
                                <MenuItem value={true}>Private</MenuItem>
                                <MenuItem value={false}>Group</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container direction="row" alignItems="center">
                    <Grid item sx={gridItemStyle}>
                        <TextField
                            id="course_title"
                            name="course_title"
                            label="Course Title"
                            variant="filled"
                            defaultValue={courseData.course_title}
                        />
                    </Grid>
                    <Grid item sx={gridItemStyle}>
                        <TextField
                            id="subject"
                            name="subject"
                            label="Subject"
                            variant="filled"
                            defaultValue={courseData.subject}
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" alignItems="center">
                    <Grid item sx={gridItemStyle}>
                        <TextField
                            id="textbook"
                            name="textbook"
                            label="Textbook"
                            variant="filled"
                            defaultValue={courseData.textbook}
                        />
                    </Grid>
                    <Grid item sx={gridItemStyle}>
                        <TextField
                            id="textbook_total_page"
                            type="number"
                            name="textbook_total_page"
                            label="Total Page of Textbook"
                            variant="filled"
                            defaultValue={courseData.textbook_total_page}
                        />
                    </Grid>
                    <Grid item sx={gridItemStyle}>
                        <TextField
                            id="price"
                            name="price"
                            label="Price"
                            variant="filled"
                            defaultValue={courseData.price}
                            onChange={(event) => handlePriceChange(event)}
                            error={Boolean(priceError)}
                            helperText={priceError}
                        />
                    </Grid>
                </Grid>
                <Typography variant="h6" fontWeight='bold' sx={{ py: 1 }}>
                    Course Schedule
                </Typography>
                <Grid container direction="row" alignItems="center">
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <Grid item sx={gridItemStyle}>
                            <DatePicker
                                label="From (required)"
                                value={startDate}
                                onChange={(newValue) => {
                                    const startOfDate = newValue.startOf('day');
                                    setStartDate(startOfDate);
                                }}
                                sx={{ width: "100%" }}
                            />
                        </Grid>
                        <Grid item sx={gridItemStyle}>
                            <DatePicker
                                label="To (required)"
                                value={endDate}
                                onChange={(newValue) => {
                                    const endOfDate = newValue.endOf('day');
                                    setEndDate(endOfDate);
                                }}
                                sx={{ width: "100%" }}
                            />
                        </Grid>
                    </LocalizationProvider>
                </Grid>
                <Grid container direction="row" alignItems="center">
                    <Grid item sx={gridItemStyle}>
                        <Autocomplete
                            multiple
                            id="tags-outlined-1"
                            options={semesters !== null ? semesters : []}
                            getOptionLabel={(semester) => semester.year + " " + seasons[semester.season - 1]}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Semester"
                                    placeholder="Select Semester"
                                />
                            )}
                            onChange={(event, value) => setSemesterForCourse(value)}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            defaultValue={semesterForCourse}
                        />
                    </Grid>
                </Grid>
                {(DateTime.fromISO(courseData.start_date).toISO() !== startDate?.toISO() || DateTime.fromISO(courseData.end_date).toISO() !== endDate?.toISO()) && (
                    <>
                        <Typography variant="h6" fontWeight='bold' sx={{ py: 1 }}>
                            Class Schedule for Each Session (it will be applied to new generated modules only)
                        </Typography>
                        <Grid container direction="row" alignItems="center">
                            <Grid item sx={gridItemStyle}>
                                <Autocomplete
                                    multiple
                                    id="tags-outlined-1"
                                    options={daysOfWeek}
                                    getOptionLabel={(day) => day}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Days (required)"
                                            placeholder="Select days"
                                        />
                                    )}
                                    sx={{ gridColumn: "span 3" }}
                                    onChange={(event, value) => setDays(value)}
                                />
                            </Grid>
                            {days !== null && days.length > 0 && (
                                <Grid item sx={gridItemStyle}>
                                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                                        <TimePicker
                                            label="From (class time)"
                                            value={fromValue}
                                            onChange={(newValue) => setFromValue(newValue)}
                                            format="HH:mm"
                                            sx={{ gridColumn: "span 2" }}
                                        />
                                        <TimePicker
                                            label="To"
                                            value={toValue}
                                            onChange={(newValue) => setToValue(newValue)}
                                            format="HH:mm"
                                            sx={{ gridColumn: "span 2" }}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            )}
                        </Grid>
                    </>
                )}
                <Box display="flex" justifyContent="start" mt="1rem" gap="1rem">
                    <Button type="submit" color="secondary" variant="contained" sx={{width: "10rem"}}>
                        Update
                    </Button>
                    <Button type="button" color="error" variant="contained" sx={{width: "10rem"}} onClick={handleClickDialogOpen}>
                        Delete
                    </Button>
                    <Dialog
                        open={dialogOpen}
                        onClose={handleDialogClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                        {"Are you sure you want to delete this course?"}
                        </DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            This action cannot be undone.
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={handleDialogClose}>Cancel</Button>
                        <Button onClick={handleCourseDelete} color="error" autoFocus>
                            Delete
                        </Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            </Box>
            <Alert
                successAlertOpen={successAlertOpen}
                setSuccessAlertOpen={setSuccessAlertOpen}
                errorAlertOpen={errorAlertOpen}
                setErrorAlertOpen={setErrorAlertOpen}
                successMsg={successAlertMessage}
                errorMsg={errorAlertMessage}
                vertical="bottom"
                horizontal="left"
            />
        </>
    );
};

export default ModifyTab