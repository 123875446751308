import React from 'react'
import { tokens } from "../../theme";
import { Box, useTheme, Typography } from "@mui/material";
import StudentChip from './StudentChip';
import TeacherChip from './TeacherChip';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import { generateDate } from '../../utils/Dates';
import dayjs from 'dayjs';
import EachCourseVertIcon from './vertIcon/EachCourseVertIcon';
import TopBarCoursesVertIcon from './vertIcon/TopBarCoursesVertIcon';
import AttendanceCheck from './AttendanceCheck';
import CircularProgress from '@mui/material/CircularProgress';
// import DashboardLoading from '../../DashboardLoading';
// import { Dashboard } from '@mui/icons-material';

const AllInfoBox = ({ 
    year, 
    semester, 
    courses, 
    attendance_map,
    attendance_count_map,
    note_count_map,
    event_modules_hash_map,
    setInfoUpdated, 
    setBackdropOpen,
    setSuccessAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertOpen,
    setErrorAlertMessage,
    semestersInDB,
    semestersForCreateCourseModal,
    eventCountByStudent,
    courseLoading,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    /*
     * generate date array
     */
    let start = null;
    let end = null;
    if (semester && semester !== "") {
        for (let i = 0; i < semestersInDB[year].length; i++) {
            if (semestersInDB[year][i].value === semester) {
                start = semestersInDB[year][i].start;
                end = semestersInDB[year][i].end;
                break;
            }
        }
    }
    const dateArray = generateDate({
        year: year,
        start: start,
        end: end,
    });

    const dateColor = (date) => {
        if (date === "Mon")
            return "#FE999A";
        else if (date === "Tue")
            return "#FFDA64";
        else if (date === "Wed")
            return "#F4B084";
        else if (date === "Thu")
            return "#A9D08E";
        else if (date === "Fri")
            return "#8FA9DB";
        else if (date === "Sat")
            return "#CC6EF0";
        else if (date === "Sun")
            return "#DADCDB";
    }

    // check if there is an event on the date
    const eventModuleBoolean = (course, date) => {
        if (course.events === undefined || course.events === null || course.events.length === 0)
            return false;

        const events = event_modules_hash_map[course.id];
        const targetDate = dayjs(date).format('YYYY-MM-DD');
        if (events[targetDate])
            return true;
        return false;
    }

    // check if there is more than one event on the same date
    const checkEventDuplicate = (course, date) => {
        if (course.events === undefined || course.events === null || course.events.length === 0)
            return false;

        const events = event_modules_hash_map[course.id];
        const targetDate = dayjs(date).format('YYYY-MM-DD');

        if (!events[targetDate])
            return false;
        
        return events[targetDate].num_of_modules > 0;
    }

    // find event id by course id and date
    const findEventId = (course, date) => {
        if (course.events === undefined || course.events === null || course.events.length === 0)
            return null;
        
        const events = event_modules_hash_map[course.id];
        const targetDate = dayjs(date).format('YYYY-MM-DD');
        
        if (events[targetDate])
            return events[targetDate].id;

        return null;
    }

    // find attendance by event id
    const findAttendanceByEventId = (course_id, event_id, student_id) => {
        if ((attendance_map[student_id] === undefined || attendance_map[student_id] === null)
        || (attendance_map[student_id][course_id] === undefined || attendance_map[student_id][course_id] === null)
        || (attendance_map[student_id][course_id][event_id] === undefined || attendance_map[student_id][course_id][event_id] === null))
            return null;
        
        return attendance_map[student_id][course_id][event_id].status;
    }

    const weekCalculation = (index) => {
        if (dateArray.length - index < 7)
            return `${(dateArray.length - index) * 3}rem`;
        return '21rem';
    }

    // // attendance count for each student
    // const attendanceCount = (course, student_id) => {
    //     if (attendance_count_map[student_id] === undefined || attendance_count_map[student_id] === null || attendance_count_map[student_id][course.id] === undefined || attendance_count_map[student_id][course.id] === null)
    //         return 0;

    //     return attendance_count_map[student_id][course.id];
    // }

    // note count for each student
    const noteCount = (course, student_id) => {
        if (note_count_map[student_id] === undefined || note_count_map[student_id] === null)
            return 0;

        return note_count_map[student_id][course.id];
    }

    /*
     * for styling
     */
    const leftTopBarBorderLeftStyling = (day, date) => {
        if (date.format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD'))
            return `5px solid ${colors.redAccent[600]}`
        if (day === "Mon")
            return '1px solid';
        return `1px solid ${colors.primary[800]}`;
    }

    const rightTopBarBorderLeftStyling = (day, date) => {
        if (date.format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD'))
            return `5px solid ${colors.redAccent[600]}`
        if (day === "Sun")
            return '1px solid';
        return `1px solid ${colors.primary[800]}`;
    }

    const contentMinHeight = (course) => {
        if (course.student_courses.length < 3)
            return '10rem';
        return course.student_courses.length * 4 + 'rem'
    };

    const containerBoxStyle = {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: colors.primary[750],
        maxHeight: '78vh',
        width: '100%',
        overflowY: 'scroll',
        overflowX: 'hidden',
        scrollBehavior: 'smooth',
        overscrollBehavior: 'contain',
        '&::-webkit-scrollbar': {
            width: '0.4rem'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: colors.grey[600],
        }
    }

    const stickyLeftTopBarStyle = {
        position: 'sticky',
        display: 'absolute',
        backgroundColor: colors.primary[400],
        width: '100%',
        height: '4.4rem',
        alignItems: 'center',
        top: 0,
        zIndex: 2,
        overflowX: 'hidden',
    }

    const stickyRightTopBarStyle = {
        position: 'sticky',
        display: 'absolute',
        backgroundColor: colors.primary[400],
        width: '100%',
        height: '100%',
        alignItems: 'center',
        top: 0,
        zIndex: 2,
        overflowX: 'scroll',
        overflowY: 'hidden',
        scrollBehavior: 'smooth',
        '&::-webkit-scrollbar': {
            height: '0.7rem'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: colors.grey[600],
        }
    }

    const leftTopBarTypoStyle = {
        color: colors.greenAccent[500], 
        fontWeight: 'bold', 
        m: 2.5,
    }

    const leftTopBarBoxStyle = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%', 
        height: '100%',
        borderRight: `1px solid ${colors.primary[800]}`,
    }

    const contentBoxStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    }

    return (
        <Box sx={containerBoxStyle}>
            {/* LEFT */}
            <Box flex={3} sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: colors.primary[750],
                width: '40%',
                height: 'fit-content',
            }}>
                {/* STICKY TOPBAR */}
                <Box sx={stickyLeftTopBarStyle}>
                    <Box 
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        <Box sx={leftTopBarBoxStyle} flex={3}>
                            <Typography variant="h5" sx={leftTopBarTypoStyle}>Courses</Typography>
                            <TopBarCoursesVertIcon 
                                semestersForCreateCourseModal={semestersForCreateCourseModal}
                                setInfoUpdated={setInfoUpdated}
                                setBackdropOpen={setBackdropOpen}
                                setSuccessAlertOpen={setSuccessAlertOpen}
                                setSuccessAlertMessage={setSuccessAlertMessage}
                                setErrorAlertOpen={setErrorAlertOpen}
                                setErrorAlertMessage={setErrorAlertMessage}
                            />
                        </Box>
                        <Box sx={leftTopBarBoxStyle} flex={2}>
                            <Typography variant="h5" sx={leftTopBarTypoStyle}>Teachers</Typography>
                        </Box>
                        <Box sx={leftTopBarBoxStyle} flex={4}>
                            <Typography variant="h5" sx={leftTopBarTypoStyle}>Students</Typography>
                        </Box>
                    </Box>
                </Box>
                {/* CONTENT */}
                <Box sx={contentBoxStyle}>
                    {courseLoading ? (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '100%',
                                height: '100%',
                                minHeight: '5rem',
                                // position: 'fixed',
                                // top: '50%',
                                // left: '50%',
                                // transform: 'translate(-50%, -50%)',                             
                            }}
                        >
                            <CircularProgress color="success" />
                            {/* <DashboardLoading/>
                            <Typography variant="h5">
                                Loading...
                            </Typography> */}
                        </Box>
                    ) : courses !== null && courses
                        // .filter(selectedCourse === "none" ? <></>: "all" ? (course) => course : (course) => course.id === selectedCourse)
                        .map((course, index) => {
                        // 1. start date is after the end of date array
                        // 2. end date is before the start of date array

                        if ( dateArray.length === 0
                        || dayjs(course.start_date).format('YYYY-MM-DD') > dateArray[dateArray.length - 1].format('YYYY-MM-DD')
                        || dayjs(course.end_date).format('YYYY-MM-DD') < dateArray[0].format('YYYY-MM-DD'))
                            return null;

                        return (
                            <Box
                                key={index}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                    height: contentMinHeight(course),
                                }}
                            >
                                <Box 
                                    sx={{
                                        flex: 3,
                                        width: '100%',
                                        height: '100%',
                                        border: `1px solid`,
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Box 
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            borderBottom: `1px solid`,
                                            mb: 1,
                                            minHeight: '3rem',
                                        }}
                                    >
                                        <Box 
                                            sx={{ 
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                minWidth: "2.5rem", 
                                                height: "100%", 
                                                borderRight: 1 
                                            }}
                                        >
                                            <Typography variant="h5">
                                                {course.code}
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                width: "100%",
                                                height: "100%",
                                            }}
                                        >
                                            <Typography variant="h5" sx={{ ml: 1 }}>
                                                {course.course_title.split('(')[0]}
                                                <br />
                                                {course.course_title.split('(')[1] && `(${course.course_title.split('(')[1]}`}
                                            </Typography>
                                            <EachCourseVertIcon 
                                                semestersForCreateCourseModal={semestersForCreateCourseModal}
                                                course={course} 
                                                setInfoUpdated={setInfoUpdated}
                                                setBackdropOpen={setBackdropOpen}
                                                setSuccessAlertOpen={setSuccessAlertOpen}
                                                setSuccessAlertMessage={setSuccessAlertMessage}
                                                setErrorAlertOpen={setErrorAlertOpen}
                                                setErrorAlertMessage={setErrorAlertMessage}
                                            />
                                        </Box>
                                    </Box>
                                    <Typography variant="h7" sx={{ mx: 1 }}>
                                        Textbook: {course.textbook}
                                    </Typography>
                                    <Typography variant="h7" sx={{ mx: 1 }}>
                                        {dayjs(course.start_date).format('MMM DD, YYYY')} - {dayjs(course.end_date).format('MMM DD, YYYY')}
                                    </Typography>
                                    <Typography variant="h7" sx={{ mx: 1 }}>
                                        {course.course_memo && course.course_memo.split('\n').map((line, index) => {
                                            return (
                                                <span key={index}>
                                                    {line}
                                                    <br />
                                                </span>
                                            )
                                        })}
                                    </Typography>
                                </Box>
                                <Box flex={2} width='100%' borderBottom={1} borderTop={1} borderRight={1}>
                                    {course.teacher_courses && course.teacher_courses.length > 0 &&
                                        course.teacher_courses.map((teacher_course, index) => {
                                            return (
                                                <TeacherChip key={index} teacher={teacher_course.teacher} setInfoUpdated={setInfoUpdated} />
                                            )
                                        })
                                    }
                                </Box>
                                <Box flex={4} width='100%' borderTop={1} borderRight={1}>
                                    {course.student_courses && course.student_courses.length > 0 ? (
                                        course.student_courses.map((student_course, index) => {
                                            return (
                                                <Box 
                                                    key={index}
                                                    width="inherit" 
                                                    height={`calc(100% / ${course.student_courses.length})`} 
                                                    borderBottom={1}
                                                    display='flex'
                                                    alignItems='center'
                                                    justifyContent='space-between'
                                                >
                                                    <StudentChip 
                                                        course={course}
                                                        student_course={student_course} 
                                                        noteCount={noteCount}
                                                        attendance_map={attendance_map}
                                                        setInfoUpdated={setInfoUpdated}
                                                        setSuccessAlertOpen={setSuccessAlertOpen}
                                                        setSuccessAlertMessage={setSuccessAlertMessage}
                                                        setErrorAlertOpen={setErrorAlertOpen}
                                                        setErrorAlertMessage={setErrorAlertMessage}
                                                    />
                                                    <Box mr={1}>
                                                        <Typography variant="h7" sx={{ mx: 1 }}>
                                                            {student_course.attendance_count !== null ? student_course.attendance_count : -1} / {student_course.total_session_num <= 0 ? course.events.length : student_course.total_session_num}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            )
                                        })
                                        ) : (
                                            // if there is no student in the course, display empty box
                                            <Box 
                                                key={index}
                                                width='inherit' 
                                                height='100%' 
                                                borderBottom={1}
                                                display='flex'
                                                alignItems='center'
                                                justifyContent='space-between'
                                            ></Box>
                                        )
                                    }
                                </Box>
                            </Box>
                        )
                    })}
                </Box>
            </Box>

            {/* RIGHT */}
            <ScrollSync>
                <Box flex={6} sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: colors.primary[750],
                    width: '60%',
                    height: 'fit-content',
                }}>
                    <ScrollSyncPane>
                        {/* STICKY TOPBAR */}
                        <Box sx={stickyRightTopBarStyle}>
                            <Box 
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: colors.blueAccent[800],
                                }}
                            >
                                {dateArray.map((date, index) => {
                                    return (
                                        <Box
                                            key={index}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                maxWidth: '3rem',
                                            }}
                                        >
                                            {date.format('ddd') === "Mon" ?
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        minWidth: weekCalculation(index),
                                                        height: '1.2rem',
                                                        borderLeft: '1px solid',
                                                        borderRight: '1px solid',
                                                        backgroundColor: colors.blueAccent[800],
                                                    }}
                                                >
                                                    <Typography variant="h7">
                                                        {semester !== "all" && semester} Week {((index / 7) + 1) | 0}
                                                    </Typography>
                                                </Box>
                                                :
                                                <Box sx={{height: '1.2rem'}}></Box>
                                            }
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    minWidth: '3rem',
                                                    height: '2.5rem',
                                                    borderLeft: leftTopBarBorderLeftStyling(date.format('ddd'), date),
                                                    borderRight: rightTopBarBorderLeftStyling(date.format('ddd'), date),
                                                    backgroundColor: dateColor(date.format('ddd')),
                                                }}
                                            >
                                                <Typography variant="h6">
                                                    {<span>{date.format('M')}/</span>}{date.format('D')}
                                                </Typography>
                                                <Typography variant="h6">
                                                    {date.format('ddd')}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    )
                                })}
                            </Box>
                        </Box>
                    </ScrollSyncPane>
                    <ScrollSyncPane>
                        {/* CONTENT */}
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '100%',
                                overflow: 'hidden',
                                // scrollBehavior: 'smooth',
                            }}
                        >
                            {courses !== null && courses
                                .map((course, index) => {

                                if ( dateArray.length === 0
                                || dayjs(course.start_date).format('YYYY-MM-DD') > dateArray[dateArray.length - 1].format('YYYY-MM-DD')
                                || dayjs(course.end_date).format('YYYY-MM-DD') < dateArray[0].format('YYYY-MM-DD'))
                                    return null;
                                    

                                return (
                                    <Box
                                        key={index}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            width: '100%',
                                            height: contentMinHeight(course),
                                        }}
                                    >
                                        {/* calendar */}
                                        {dateArray.map((date, index) => {

                                            return (
                                                <Box
                                                    key={index}
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        minWidth: '3rem',
                                                        height: '100%',
                                                        borderLeft: leftTopBarBorderLeftStyling(date.format('ddd'), date),
                                                        borderRight: rightTopBarBorderLeftStyling(date.format('ddd'), date),
                                                        borderBottom: `1px solid ${colors.primary[100]}`,
                                                        borderTop: `1px solid ${colors.primary[100]}`,
                                                        backgroundColor: eventModuleBoolean(course, date) && checkEventDuplicate(course, date) ? "purple" : eventModuleBoolean(course, date) ? colors.grey[950] : null,
                                                    }}
                                                >
                                                    {course.student_courses && course.student_courses.length > 0 &&
                                                        course.student_courses.map((student_course, index) => {

                                                        // const event_id = findEventId(course, date);
                                                        // let count = null;
                                                        
                                                        // if (eventCountByStudent[student_course.student_id] !== undefined && eventCountByStudent[student_course.student_id][course.id] !== undefined && event_id !== null) {
                                                        //     count = eventCountByStudent[student_course.student_id][course.id][event_id];
                                                        // } else {
                                                        //     count = 0;
                                                        // }
                                                        
                                                        return (
                                                            <Box 
                                                                key={index}
                                                                width='100%'
                                                                height={`calc(100% / ${course.student_courses.length})`} 
                                                                borderBottom={`1px solid ${colors.primary[800]}`}
                                                                display='flex'
                                                                justifyContent='center'
                                                                alignItems='center'
                                                            >
                                                            {eventModuleBoolean(course, date) &&
                                                                <AttendanceCheck
                                                                    course={course}
                                                                    student_course={student_course}
                                                                    date={date}
                                                                    attendance_map={attendance_map}
                                                                    setInfoUpdated={setInfoUpdated}
                                                                    findEventId={findEventId}
                                                                    findAttendanceByEventId={findAttendanceByEventId}
                                                                    note_count_map={note_count_map}
                                                                />
                                                            }
                                                            </Box>
                                                        )
                                                    })}
                                                </Box>
                                            )
                                        })}
                                    </Box>
                                )
                            })}
                        </Box>
                    </ScrollSyncPane>
                </Box>
            </ScrollSync>
        </Box>
    )
}

export default AllInfoBox