import React from 'react'
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Box, useTheme, Typography } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import { tokens } from "../../../theme";
import Axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';

const RemoveStudentModal = ({ 
    setAnchorEl, 
    course, 
    setInfoUpdated,
    setBackdropOpen,
    setSuccessAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertOpen,
    setErrorAlertMessage
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // for modal
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => { setOpen(false); setAnchorEl(null); };

    const [removeStudent, setRemoveStudent] = React.useState(null);

    const handleRemoveStudent = async () => {
        if (removeStudent === null) {
            setErrorAlertMessage("Please select a student to remove.");
            setErrorAlertOpen(true);
            return;
        }

        setBackdropOpen(true);
        Axios.delete(`${process.env.REACT_APP_URL}/api/v1/courses/${course.uuid}/students/${removeStudent.uuid}`, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setSuccessAlertMessage(response.data.message);
            setSuccessAlertOpen(true);
            setBackdropOpen(false);
            setInfoUpdated(true);
            handleClose();
        }).catch((error) => {
            setErrorAlertMessage(error.response.data.message);
            setErrorAlertOpen(true);
            setBackdropOpen(false);
        });
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: colors.primary[600],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <div>
            <Button onClick={handleOpen} sx={{ p: 1, width: "100%" }}>Remove Student</Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography variant="h4" color={colors.primary[450]}>
                            Remove a student from {course.course_title}
                        </Typography>
                        <CloseIcon sx={{ position: "absolute", right: 10, top: 10, cursor: "pointer" }} onClick={handleClose} />
                        <Box display="flex" flexDirection="column" gap={3} width="100%" pt={2}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo-1"
                                    getOptionLabel={(option) => option.consultation_number ?
                                            `${option.name} (Consult #: ${option.consultation_number})`:`${option.name}`}
                                    renderOption={(props, option) => (
                                        <li {...props}>
                                            {option.name}
                                            {option.consultation_number && (
                                                <>
                                                    &nbsp;(<span style={{ color: "green" }}> Consult #: {option.consultation_number}</span>)
                                                </>
                                            )}
                                        </li>
                                    )}
                                    options={course.student_courses !== null ? course.student_courses.map(
                                        (student_course) => {
                                            return {
                                                id: student_course.student.id,
                                                uuid: student_course.student.uuid,
                                                name: student_course.student.korean_name !== null ? student_course.student.first_name + " " + student_course.student.korean_name + " " + student_course.student.last_name : student_course.student.first_name + " " + student_course.student.last_name,
                                                consultation_number: student_course.student.consultation_number,
                                            }
                                        }
                                    ) : []}
                                    renderInput={(params) => <TextField {...params} label="Remove Student" />}
                                    onChange={(event, value) => setRemoveStudent(value)} 
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    sx={{ width: "100%", pr: "5rem" }}
                                />
                                <Button
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    sx={{ mt: 2, width: "7rem" }}
                                    onClick={handleRemoveStudent}
                                >
                                    Remove
                                </Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </div>
    )
}

export default RemoveStudentModal