import React from 'react'
import { Box, Button, Modal, Typography, Backdrop, Fade } from '@mui/material'

function AreYouSureModal({
    colors,
    title,
    message,
    onConfirm,
    setOpen,
    open,
}) {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        gap: 2,
        bgcolor: colors.primary[600],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const handleYes = () => {
        onConfirm();
        setOpen(false);
    };

    const handleNo = () => {
        setOpen(false);
    };

    return (
        <Modal
            aria-labelledby="are-you-sure-modal-title"
            aria-describedby="are-you-sure-modal-description"
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <Typography variant="h4" color={colors.primary[450]}>
                        {title}
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", mt: 2 }}>
                        <Typography variant="body1" color={colors.primary[450]}>
                            {message}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            ml: "auto",
                            gap: 2,
                            mt: 2,
                        }}
                    >
                        <Button
                            variant="contained"
                            color="secondary"
                            sx={{ width: 200, mt: 2 }}
                            onClick={() => handleYes()}
                        >
                            Yes
                        </Button>
                        <Button
                            variant="contained"
                            color="error"
                            sx={{ width: 200, mt: 2 }}
                            onClick={() => handleNo()}
                        >
                            No
                        </Button>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    )
}

export default AreYouSureModal