import React from 'react';
import { tokens } from "../../theme";
import { Button, useTheme } from "@mui/material";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import Axios from 'axios';

const seasons = [
    "None",
    "Spring",
    "Summer",
    "Fall",
    "Winter",
];

function StudentInfoCardModal({
    student_uuid,
    setSuccessAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertOpen,
    setErrorAlertMessage,
}) {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        height: 540,
        bgcolor: colors.primary[600],
        border: '2px solid grey',
        boxShadow: 24,
        p: 4,
        overflowY: "auto",
    };

    const [modalOpen, setModalOpen] = React.useState(false);
    const handleModalClose = () => {
        setCurrentYear(new Date().getFullYear());
        setCurrentYearSeason("None");
        setNextYear(new Date().getFullYear());
        setNextYearSeason("None");

        setModalOpen(false);
    };

    const [loading, setLoading] = React.useState(false);

    const [currentYear, setCurrentYear] = React.useState(new Date().getFullYear());
    const [currentYearSeason, setCurrentYearSeason] = React.useState("None");
    const [nextYear, setNextYear] = React.useState(new Date().getFullYear());
    const [nextYearSeason, setNextYearSeason] = React.useState("None");
    const [koreanNameIncluded, setKoreanNameIncluded] = React.useState(true);
    const [isComplete, setIsComplete] = React.useState(false);
    const [link, setLink] = React.useState(null);
    
    const [studentInfoLink, setStudentInfoLink] = React.useState(null);

    React.useEffect(() => {
        async function getStudentInfoLink() {
            const uuid = student_uuid ? student_uuid.trim() : "";

            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/student_info_links/students/${uuid}`).then((response) => {
                console.log(response.data);
                setStudentInfoLink(response.data);
                setLink(window.location.origin + "/students/" + uuid + "/info_card");
                setLoading(false);
            }).catch((error) => {
                console.log(error);

                // 404
                if (error.response.status === 404) {
                    // create new student info link
                    setStudentInfoLink(null);
                    setLoading(false);
                    return;
                }

                setLoading(false);
            });
        }

        if (modalOpen) {
            setLoading(true);
            getStudentInfoLink();
        }
    }, [modalOpen]);

    React.useEffect(() => {
        if (studentInfoLink) {
            setCurrentYear(studentInfoLink.current_year);
            setCurrentYearSeason(studentInfoLink.current_year_season);
            setNextYear(studentInfoLink.next_year);
            setNextYearSeason(studentInfoLink.next_year_season);
            setIsComplete(studentInfoLink.is_complete);
        }
    }, [studentInfoLink]);

    const generateLink = async () => {

        if (!currentYear || !currentYearSeason || !nextYear || !nextYearSeason || currentYearSeason === "None" || nextYearSeason === "None") {
            setErrorAlertOpen(true);
            setErrorAlertMessage("Please fill out all fields.");
            return;
        }

        if (studentInfoLink) {
            // update
            await Axios.put(`${process.env.REACT_APP_URL}/api/v1/student_info_links/${studentInfoLink.id}`, {
                current_year: currentYear,
                current_year_season: currentYearSeason,
                next_year: nextYear,
                next_year_season: nextYearSeason,
                is_complete: isComplete,
                korean_name_included: koreanNameIncluded,
            }, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then(async (response) => {
                const link = window.location.origin + "/students/" + student_uuid + "/info_card";
                if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
                    await navigator.clipboard.writeText(link);
                    window.alert("Student Info Card Link Copied to Clipboard: " + link);

                    setLink(link);
                    setSuccessAlertOpen(true);
                    setSuccessAlertMessage("Student Info Card Link Generated!");
                } else {
                    setLink(link);
                    setSuccessAlertOpen(true);
                    setSuccessAlertMessage("Student Info Card Link Generated!");
                }
            }).catch((error) => {
                console.log(error);
                setErrorAlertOpen(true);
                setErrorAlertMessage("Error: Cannot update Student Info Card Link at the moment.");
            });
        } else {
            // create new one
            await Axios.post(`${process.env.REACT_APP_URL}/api/v1/student_info_links`, {
                student_uuid,
                current_year: currentYear,
                current_year_season: currentYearSeason,
                next_year: nextYear,
                next_year_season: nextYearSeason,
                korean_name_included: koreanNameIncluded,
            }, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then(async (response) => {
                const link = window.location.origin + "/students/" + student_uuid + "/info_card";

                if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
                    await navigator.clipboard.writeText(link);
                    window.alert("Student Info Card Link Copied to Clipboard: " + link);

                    setLink(link);
                    setSuccessAlertOpen(true);
                    setSuccessAlertMessage("Student Info Card Link Generated!");
                } else {
                    setLink(link);
                    setSuccessAlertOpen(true);
                    setSuccessAlertMessage("Student Info Card Link Generated!");
                }
            }).catch((error) => {
                console.log(error);
                // if 400, link already exists
                if (error && error.response && error.response.status === 400) {
                    setErrorAlertOpen(true);
                    setErrorAlertMessage("Student Info Card Link already exists.");
                    return;
                }

                setErrorAlertOpen(true);
                setErrorAlertMessage("Error: Cannot generate Student Info Card Link at the moment.");
            });
        }

        return;
    };

    return ( 
        <>
            <Button 
                variant="outlined" 
                color="primary"
                onClick={() => setModalOpen(true)}
                sx={{
                    maxWidth: 300,
                }}
            >
                Generate Student Info Card Link
            </Button>
            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={modalOpen}>
                    <Box sx={style}>
                        {loading ? (
                            <Typography variant="h3" ml={1}>
                                Loading...
                            </Typography>
                        ) : (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '100%',
                                    gap: 2,
                                }}
                            >
                                <Typography variant="h3" ml={1} borderBottom={1}>
                                    {studentInfoLink ? "Update" : "Generate"} Student Info Card Link {studentInfoLink ? <><br /><span style={{ fontSize: "14px" }}>(Student Info Card Link for this student already exists)</span></> : ""}
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        mt: 2,
                                    }}
                                >
                                    <Typography variant="h4" ml={1} mb={2}>
                                        For School Breaks in Card:
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            mx: "auto",
                                            mb: 2,
                                            gap: 1,
                                        }}
                                    >
                                        <Typography variant="h5" ml={5}>
                                            First Break:
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                gap: 1,
                                            }}
                                        >
                                            <input
                                                type="number"
                                                value={currentYear}
                                                onChange={(e) => setCurrentYear(e.target.value)}
                                            />
                                            <select
                                                value={currentYearSeason}
                                                onChange={(e) => setCurrentYearSeason(e.target.value)}
                                            >
                                                {seasons.map((season) => (
                                                    <option value={season}>{season}</option>
                                                ))}
                                            </select>
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            mx: "auto",
                                            mb: 2,
                                            gap: 1,
                                        }}
                                    >
                                        <Typography variant="h5" ml={5}>
                                            Second Break:
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                gap: 1,
                                            }}
                                        >
                                            <input
                                                type="number"
                                                value={nextYear}
                                                onChange={(e) => setNextYear(e.target.value)}
                                            />
                                            <select
                                                value={nextYearSeason}
                                                onChange={(e) => setNextYearSeason(e.target.value)}
                                            >
                                                {seasons.map((season) => (
                                                    <option value={season}>{season}</option>
                                                ))}
                                            </select>
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            mb: 2,
                                            gap: 1,
                                        }}
                                    >
                                        <Typography variant="h4" ml={1}>
                                            Include Korean Name in Card:
                                        </Typography>
                                        <input
                                            type="checkbox"
                                            checked={koreanNameIncluded}
                                            onChange={(e) => setKoreanNameIncluded(!koreanNameIncluded)}
                                        />
                                    </Box>
                                    {studentInfoLink && studentInfoLink.is_complete && (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                border: '1px solid grey',
                                                p: 2,
                                            }}
                                        >
                                            <Typography variant="h6" ml={1}>
                                                This link is complete. If you would like to allow student or their parents to update the student card, please check the box below.
                                            </Typography>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <input
                                                    type="checkbox"
                                                    checked={!isComplete}
                                                    onChange={(e) => setIsComplete(!isComplete)}
                                                />
                                            </Box>
                                        </Box>
                                    )}
                                    {link && (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: 1,
                                                mt: 2,
                                            }}
                                        >
                                            <Typography variant="h4" ml={1}>
                                                Generated Link:
                                            </Typography>
                                            <Typography variant="h6" ml={5}>
                                                {link}
                                            </Typography>
                                        </Box>
                                    )}
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: 2,
                                        mt: 'auto',
                                    }}
                                >
                                    <Button variant="contained" color="secondary" onClick={generateLink}>
                                        {studentInfoLink ? "Update" : "Generate"} Link
                                    </Button>
                                    <Button variant="contained" color="error" onClick={handleModalClose}>
                                        Cancel
                                    </Button>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Fade>
            </Modal>
        </>
    );
}

export default StudentInfoCardModal