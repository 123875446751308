import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import AddMemoDatePicker from './AddMemoDatePicker';
import Axios from 'axios';


export default function AddMemoAccordion({ 
    student_uuid, 
    setStudentUpdated,
    setSuccessAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertOpen,
    setErrorAlertMessage, 
    socket = null,
    socketConnected = false,
    from_weekly = false,
    getStudentName,
}) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [reminderDate, setReminderDate] = React.useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        if (data.get('content') === "") {
            return;
        }

        await Axios.post(`${process.env.REACT_APP_URL}/api/v1/students/${student_uuid}/memos`, {
            title: data.get('title'),
            content: data.get('content'),
            reminder: reminderDate ? reminderDate.toDate() : null,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            if (!from_weekly) {
                setStudentUpdated(true);
                setSuccessAlertOpen(true);
                setSuccessAlertMessage(response.data.message);
            } else if (from_weekly && socketConnected) {
                // from weekly, so we need to emit to server (using socketio)
                socket.emit("put.StudentUpdate", {
                    student_uuid: student_uuid,
                    message: " created " + getStudentName() + "'s memo",
                });
            }
        }).catch((error) => {
            setErrorAlertOpen(true);
            setErrorAlertMessage(error.response.data.message);
        });
    };

    return (
        <div style={{
            width: "100%",
            marginBottom: "1rem",
        }}>
            <Accordion
                style={{
                    backgroundColor: colors.primary[750],
                }}
                component="div"
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant='h6'>Add Memo</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '25ch' },
                            display: "flex",
                            flexDirection: "column",
                        }}
                        noValidate
                        autoComplete="off"
                        onSubmit={handleSubmit}
                    >
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "1rem",
                            minWidth: "52rem",
                        }}>
                            <TextField
                                id="filled-basic"
                                label="Title"
                                variant="filled"
                                name='title'
                                sx={{
                                    flex: "1",
                                    maxWidth: "20rem",
                                    '& .MuiFilledInput-root': {
                                        backgroundColor: colors.primary[600],
                                        ":focus": {
                                            backgroundColor: 'white',
                                        },
                                    },
                                }}
                            />
                            <AddMemoDatePicker reminderDate={reminderDate} setReminderDate={setReminderDate} />
                        </Box>
                        <TextField
                            id="filled-basic"
                            label="Content"
                            variant="filled"
                            name='content'
                            fullWidth={true}
                            multiline={true}
                            rows={5}
                            sx={{
                                minWidth: "52rem",
                                borderRadius: "5px",
                                '& .MuiFilledInput-root': {
                                    backgroundColor: colors.primary[600],
                                    ":focus": {
                                        backgroundColor: 'white',
                                    },
                                },
                            }}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            style={{
                                backgroundColor: colors.primary[300],
                                color: "white",
                                float: "right",
                                marginTop: "0.5rem",
                                marginBottom: "1rem",
                                width: "10rem",
                            }}
                        >
                            Add Memo
                        </Button>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}