import React from 'react'
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Box, useTheme, Typography } from "@mui/material";
import { tokens } from "../../../theme";
import Autocomplete from '@mui/material/Autocomplete';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTime, Settings } from 'luxon';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


Settings.defaultZone = "system";

const UpdateCourseModal = ({
    semestersForCreateCourseModal,
    course,
    setAnchorEl, 
    setInfoUpdated,
    setBackdropOpen,
    setSuccessAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertOpen,
    setErrorAlertMessage
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    // const isNonMobile = useMediaQuery("(min-width:600px)");

    // for modal
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => { setOpen(false); setAnchorEl(null); };

    // for days of week for couese schedule
    // const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    // const daysValue = {'Moday' : 1, 'Tuesday' : 2, 'Wednesday' : 3, 'Thursday' : 4, 'Friday' : 5, 'Saturday' : 6}
    // const [days, setDays] = React.useState(null);

    // for course info
    const [isPrivateCourse, setIsPrivateCourse] = React.useState(course !== null ? course.is_private_course : null);
    const [code, setCode] = React.useState(course !== null ? course.code : null);
    const [title, setTitle] = React.useState(course !== null ? course.course_title : null);
    const [subject, setSubject] = React.useState(course !== null ? course.subject : null);
    const [textbook, setTextbook] = React.useState(course !== null ? course.textbook : null);
    const [textbookTotalPage, setTextbookTotalPage] = React.useState(course !== null ? course.textbook_total_page : 0);
    const [price, setPrice] = React.useState(course !== null ? course.price : null);
    const [courseMemo, setCourseMemo] = React.useState(course !== null ? course.course_memo : null);
    const handleCodeChange = (event) => setCode(event.target.value);
    const handleTitleChange = (event) => setTitle(event.target.value);
    const handleSubjectChange = (event) => setSubject(event.target.value);
    const handleTextbookChange = (event) => setTextbook(event.target.value);
    const handleTextbookTotalPageChange = (event) => setTextbookTotalPage(event.target.value);
    const handlePriceChange = (event) => setPrice(event.target.value);
    const handleCourseMemoChange = (event) => setCourseMemo(event.target.value);

    // for course "from date" and "to date" (required)
    const [startDate, setStartDate] = React.useState(course !== null && course.start_date !== null ? DateTime.fromISO(course.start_date) : null);
    const [endDate, setEndDate] = React.useState(course !== null && course.end_date !== null ? DateTime.fromISO(course.end_date) : null);
    
    // for course's semesters
    const seasons = ['Spring', 'Summer', 'Fall', 'Winter'];
    const [semesterForCourse, setSemesterForCourse] = React.useState(
        course !== null ? course.course_semesters?.map((course_semester) => {
            return course_semester.semester;
        }) : []
    );

    // for days of week for couese schedule
    const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    // const daysValue = {'Monday' : 1, 'Tuesday' : 2, 'Wednesday' : 3, 'Thursday' : 4, 'Friday' : 5, 'Saturday' : 6}
    // const [days, setDays] = React.useState(null);
    
    // for default days
    // const event_start_date = new Date(course.start_date);
    // const targetDate = new Date(event_start_date.setDate(event_start_date.getDate() + 7));

    const defaultDays = [];

    for (let i = 0; i < course.events?.length; i++) {
        let day = new Date(course.events[i].start);

        if (i > 0) {
            const index = daysOfWeek.indexOf(defaultDays[defaultDays.length - 1]);
            if (index >= day.getDay() - 1) {
                break;
            }
        }

        defaultDays.push(daysOfWeek[day.getDay() - 1]);
    }

    if (defaultDays.length === 0) {
        defaultDays.push(daysOfWeek[0]); // default is Monday
    }

    const [days, setDays] = React.useState(defaultDays);

    // for default time
    const event_start = course.events !== null && course.events.length > 0 ? DateTime.fromISO(course.events[0].start) : DateTime.local();
    const event_end = course.events !== null && course.events.length > 0 ? DateTime.fromISO(course.events[0].end) : DateTime.local();

    const [fromValue, setFromValue] = React.useState(event_start);
    const [toValue, setToValue] = React.useState(event_end);

       
    const handleUpdateCourse = async (e) => {
        e.preventDefault();
        setBackdropOpen(true);

        if (isPrivateCourse === null) {
            setErrorAlertMessage("Course type is required.");
            setErrorAlertOpen(true);
            return;
        }

        if (title === null || title.length === 0) {
            setErrorAlertMessage("Title is required!");
            setErrorAlertOpen(true);
            setBackdropOpen(false);
            return;
        }

        if (subject === null || subject.length === 0) {
            setErrorAlertMessage("Subject is required!");
            setErrorAlertOpen(true);
            setBackdropOpen(false);
            return;
        }

        if (price === "" || price === undefined || isNaN(price) || price < 0) {
            setPrice(null);
        }

        if (startDate === null || endDate === null) {
            setErrorAlertMessage("Start date and end date are required.");
            setErrorAlertOpen(true);
            return;
        }
        
        if (startDate > endDate) {
            setErrorAlertMessage("Start date cannot be greater than end date!");
            setErrorAlertOpen(true);
            return;
        }
        
        const utcStartDate = startDate.toUTC();
        const utcEndDate = DateTime.fromISO(endDate.toISO()).plus({ days: 1 }).minus({ seconds: 1 }).toUTC();

        if (semesterForCourse === null || semesterForCourse.length === 0) {
            setErrorAlertMessage("Semester is required!");
            setErrorAlertOpen(true);
            setBackdropOpen(false);
            return;
        }

        for (let i = 0; i < course.course_semesters?.length; i++) {
            let found = false;
            for (let j = 0; j < semesterForCourse.length; j++) {
                if (course.course_semesters[i].semester.id === semesterForCourse[j].id) {
                    found = true;
                    break;
                }
            }

            if (!found) {
                setErrorAlertMessage("Semester cannot be removed!");
                setErrorAlertOpen(true);
                setBackdropOpen(false);
                return;
            }
        }

        const semesters_list_to_delete = [];
        const new_semesters_list = [];
        const IsSemesterAdded = course.course_semesters?.length === semesterForCourse.length ? false : true;
        if (IsSemesterAdded) {
            for (let i = 0; i < semesterForCourse.length; i++) {
                let found = false;
                for (let j = 0; j < course.course_semesters?.length; j++) {
                    if (semesterForCourse[i].id === course.course_semesters[j].semester.id) {
                        found = true;
                        break;
                    }
                }

                if (!found)
                    new_semesters_list.push(semesterForCourse[i].id);
            }

        }

        let moduleStartTime = null;
        let moduleEndTime = null;

        if (days !== null && days.length > 0) {
            if (fromValue === null || toValue === null) {
                setErrorAlertMessage("From time and to time are required.");
                setErrorAlertOpen(true);
                return;
            }

            if (fromValue > toValue) {
                setErrorAlertMessage("From time cannot be greater than to time!");
                setErrorAlertOpen(true);
                return;
            }

            if (fromValue === toValue) {
                setErrorAlertMessage("From time cannot be equal to to time!");
                setErrorAlertOpen(true);
                return;
            }

            // only need hour and minute
            moduleStartTime = fromValue.toISO().split("T")[1].split("-")[0];
            moduleEndTime = toValue.toISO().split("T")[1].split("-")[0];
        }

        await Axios.put(`${process.env.REACT_APP_URL}/api/v1/courses/${course.uuid}`, {
            code: code,
            course_title: title,
            subject: subject,
            textbook: textbook,
            textbook_total_page: textbookTotalPage,
            price: price,
            new_semesters_list: new_semesters_list,
            semesters_list_to_delete: semesters_list_to_delete,
            start_date: utcStartDate,
            end_date: utcEndDate,
            course_memo: courseMemo ? courseMemo : null,
            days: days,
            start_time: moduleStartTime,
            end_time: moduleEndTime,
            is_private_course: isPrivateCourse,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setSuccessAlertMessage(response.data.message);
            setSuccessAlertOpen(true);
            setBackdropOpen(false);
            setInfoUpdated(true);
            handleClose();
        }).catch((error) => {
            console.log(error.response.data.message);
            setErrorAlertMessage(error.response.data.message);
            setErrorAlertOpen(true);
            setBackdropOpen(false);
            return;
        });
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 640,
        minHeight: 400,
        bgcolor: colors.primary[600],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <div>
            <Button onClick={handleOpen} sx={{ p: 1, width: "100%" }}>Update Course</Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Box display="flex" flexDirection="column" gap={3} width="100%" pt={2}>
                            <CloseIcon sx={{ position: "absolute", right: 10, top: 10, cursor: "pointer" }} onClick={handleClose} />
                            <Typography variant="h4" fontWeight='bold'>Update Course</Typography>
                            <Box display="flex" flexDirection="column" gap={2}>
                                <Box display="flex" flexDirection="row" gap={2}>
                                    <TextField
                                        variant="filled"
                                        type="text"
                                        label="Course Code"
                                        onChange={handleCodeChange}
                                        value={code}
                                        name="code"
                                    />
                                    <FormControl>
                                        <InputLabel id="demo-simple-select-label">
                                            Course Type
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={isPrivateCourse}
                                            label="Course Type"
                                            onChange={(event) => setIsPrivateCourse(event.target.value)}
                                        >
                                            <MenuItem value={null}>None</MenuItem>
                                            <MenuItem value={true}>Private</MenuItem>
                                            <MenuItem value={false}>Group</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box display="flex" flexDirection="row" gap={2}>
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Course Title"
                                        onChange={handleTitleChange}
                                        value={title}
                                        name="course_title"
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Subject"
                                        onChange={handleSubjectChange}
                                        value={subject}
                                        name="subject"
                                    />
                                </Box>
                                <Box display="flex" flexDirection="row" gap={2}>
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Textbook"
                                        onChange={handleTextbookChange}
                                        value={textbook}
                                        name="textbook"
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label="Total Page of Textbook"
                                        onChange={handleTextbookTotalPageChange}
                                        value={textbookTotalPage}
                                        name="textbook_total_page"
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Price"
                                        onChange={handlePriceChange}
                                        value={price}
                                        name="price"
                                    />
                                </Box>
                                <Box display="flex" flexDirection="row" gap={2}>
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        multiline={true}
                                        rows={3}            
                                        label="Course Memo"
                                        onChange={handleCourseMemoChange}
                                        value={courseMemo}
                                        name="course_memo"
                                    />
                                </Box>
                                <Typography variant="h6" fontWeight='bold' color={colors.primary[450]} sx={{ gridColumn: "span 4" }}>
                                    Course Schedule
                                </Typography>
                                <Box display="flex" flexDirection="row" gap={2}>
                                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                                        <DatePicker
                                            label="From (optional)"
                                            value={startDate}
                                            onChange={(newValue) => setStartDate(newValue)}
                                            sx={{ width: "100%" }}
                                        />
                                        <DatePicker
                                            label="To (optional)"
                                            value={endDate}
                                            onChange={(newValue) => setEndDate(newValue)}
                                            sx={{ width: "100%" }}
                                        />
                                    </LocalizationProvider>
                                </Box>
                                <Box display="flex" flexDirection="row" gap={2}>
                                    <Autocomplete
                                        fullWidth
                                        multiple
                                        options={semestersForCreateCourseModal !== null ? semestersForCreateCourseModal : []}
                                        getOptionLabel={(semester) => semester.year + " " + seasons[semester.season - 1]}
                                        filterSelectedOptions
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Semester"
                                                placeholder="Select semesters"
                                            />
                                        )}
                                        value={semesterForCourse !== null ? semesterForCourse : []}
                                        onChange={(event, value) => setSemesterForCourse(value)}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                    />
                                </Box>
                                {(DateTime.fromISO(course.start_date).toISO() !== startDate.toISO() || DateTime.fromISO(course.end_date).toISO() !== endDate.toISO()) && (
                                    <>
                                        <Typography variant="h6" fontWeight='bold' sx={{ py: 1 }}>
                                            Class Schedule for Each Session (it will be applied to new generated modules only)
                                        </Typography>
                                        <Autocomplete
                                            multiple
                                            id="tags-outlined-1"
                                            options={daysOfWeek}
                                            getOptionLabel={(day) => day}
                                            filterSelectedOptions
                                            defaultValue={defaultDays}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Days (required)"
                                                    placeholder="Select days"
                                                />
                                            )}
                                            sx={{ gridColumn: "span 3" }}
                                            onChange={(event, value) => setDays(value)}
                                        />
                                        {/* {days !== null && days.length > 0 && ( */}
                                        {defaultDays !== null && defaultDays.length > 0 && (
                                            <LocalizationProvider dateAdapter={AdapterLuxon}>
                                                <TimePicker
                                                    label="From (class time)"
                                                    value={fromValue}
                                                    onChange={(newValue) => setFromValue(newValue)}
                                                    format="HH:mm"
                                                    sx={{ gridColumn: "span 2" }}
                                                />
                                                <TimePicker
                                                    label="To"
                                                    value={toValue}
                                                    onChange={(newValue) => setToValue(newValue)}
                                                    format="HH:mm"
                                                    sx={{ gridColumn: "span 2" }}
                                                />
                                            </LocalizationProvider>
                                        )}
                                    </>
                                )}
                                <Box display="flex" justifyContent="start">
                                    <Button 
                                        color="primary" 
                                        variant="contained" 
                                        onClick={(e) => handleUpdateCourse(e)}
                                        sx={{ 
                                            width: "10rem",
                                            mt: 1,
                                            ":hover": {
                                                backgroundColor: colors.greenAccent[500],
                                                color: colors.primary[400],
                                            }
                                        }}
                                    >
                                        Update
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </div>
    )
}

export default UpdateCourseModal