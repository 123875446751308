import React from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Axios from 'axios';
import Alert from '../../../components/alert/Alert';
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";

const ModifyTab = ({ zoomRoom, setZoomRoomUpdated }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const gridItemStyle = {
        '& > :not(style)': { m: 1, width: '55.87ch' }
    };

    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = React.useState("");
    const [errorAlertMessage, setErrorAlertMessage] = React.useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const room_number = data.get('room_number');
        const meeting_id = data.get('meeting_id');
        const link = data.get('link');
        const password = data.get('password');
        const note = data.get('note');
        const remark = data.get('remark') || "";

        // if room_number is not a number, show error
        if (isNaN(room_number)) {
            setErrorAlertMessage("Room number must be a number");
            setErrorAlertOpen(true);
            return;
        }

        await Axios.put(`${process.env.REACT_APP_URL}/api/v1/zooms/${zoomRoom.id}`, {
            room_number: room_number,
            meeting_id: meeting_id,
            link: link,
            password: password,
            note: note,
            remark: remark,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setSuccessAlertMessage(response.data.message);
            setSuccessAlertOpen(true);
            setZoomRoomUpdated(true);
        }).catch((error) => {
            console.log(error);
            setErrorAlertMessage(error.response.data.message);
            setErrorAlertOpen(true);
        });
    };

    return (
        <>
        <Box component="form" onSubmit={handleSubmit} noValidate>
            <Grid container direction="row" alignItems="center">
                <Grid item sx={gridItemStyle}>
                    <TextField 
                        id="room_number"
                        name="room_number"
                        label="Room Number"
                        variant="filled"
                        defaultValue={zoomRoom.room_number}
                    />
                </Grid>
            </Grid>
            <Grid container direction="row" alignItems="center">
                <Grid item sx={gridItemStyle}>
                    <TextField 
                        id="meeting_id"
                        name="meeting_id"
                        label="Meeting ID"
                        variant="filled"
                        defaultValue={zoomRoom.meeting_id}
                    />
                </Grid>
                <Grid item sx={gridItemStyle}>
                    <TextField 
                        id="link"
                        name="link"
                        label="Link"
                        variant="filled"
                        defaultValue={zoomRoom.link}
                    />
                </Grid>
            </Grid>
            <Grid container direction="row" alignItems="center">
                <Grid item sx={gridItemStyle}>
                    <TextField 
                        id="password"
                        name="password"
                        label="Password"
                        variant="filled"
                        defaultValue={zoomRoom.password}
                    />
                </Grid>
                <Grid item sx={gridItemStyle}>
                    <TextField 
                        id="note"
                        name="note"
                        label="Note"
                        variant="filled"
                        defaultValue={zoomRoom.note}
                    />
                </Grid>
                <Grid item sx={gridItemStyle}>
                    <TextField 
                        id="remark"
                        name="remark"
                        label="Remark"
                        variant="filled"
                        defaultValue={zoomRoom.remark}
                    />
                </Grid>
            </Grid>
            <Button
                type="submit"
                variant="contained"
                style={{
                    "backgroundColor": colors.primary[300],
                    "color": "white",
                    "float": "center",
                    "marginTop": "10px",
                    "width": "100%",
                }}
            >
                Update
            </Button>
        </Box>
        <Alert
            successAlertOpen={successAlertOpen}
            setSuccessAlertOpen={setSuccessAlertOpen}
            errorAlertOpen={errorAlertOpen}
            setErrorAlertOpen={setErrorAlertOpen}
            successMsg={successAlertMessage}
            errorMsg={errorAlertMessage}
            vertical='top'
            horizontal='right'
        />
        </>
    )
}

export default ModifyTab