import { DateTime } from 'luxon';

export const generateWeekDates = (date) => {
    
    const arrayOfDate = [];

    // find the first day of the week
    let firstDayOfWeek = date;
    while (firstDayOfWeek.weekday !== 1) {
        firstDayOfWeek = firstDayOfWeek.minus({ days: 1 });
    }

    arrayOfDate.push({
        date: null,    
        hours: [
            "9am", "", "9:30am", "", "10am", "", "10:30am", "", "11am", "", "11:30am", "",
            "12pm", "", "12:30pm", "", "1pm", "", "1:30pm", "", "2pm", "", "2:30pm", "",
            "3pm", "", "3:30pm", "", "4pm", "", "4:30pm", "", "5pm", "", "5:30pm", "",
            "6pm", "", "6:30pm", "", "7pm", "", "7:30pm", "", "8pm", "", "8:30pm", "",
            "9pm", "", "9:30pm", "", "10pm", "", "10:30pm", "", "11pm", "", "11:30pm", ""
        ]
    });

    for (let i = 0; i < 7; i++) {
        const date = firstDayOfWeek.plus({ days: i });
        date.set({ hour: 9, minute: 0, second: 0 });
        const dateHourMap = [
            "", "", "", "", "", "", "", "", "", "", "", "", 
            "", "", "", "", "", "", "", "", "", "", "", "",
            "", "", "", "", "", "", "", "", "", "", "", "",
            "", "", "", "", "", "", "", "", "", "", "", "",
            "", "", "", "", "", "", "", "", "", "", "", "",
        ];
        arrayOfDate.push(
            {
                date: date,
                hours: dateHourMap
            }
        );
    }

    return arrayOfDate;
};

export const generateWeekDatesForTeacherScheduler = (date, duration) => {
    // Duration
    // 0.5: 30 minutes, 1: 1 hour, 2: 2 hours
    const arrayOfDate = [];
    let firstDayOfWeek = date;

    while (firstDayOfWeek.weekday !== 1) {
        firstDayOfWeek = firstDayOfWeek.minus({ days: 1 });
    };

    if (duration === 0.5) {

        const hours = Array.from({ length: 32 }, (_, i) => {
            const hour = Math.floor(i / 2) + 8; // 8:00 am is the starting time
            const minutes = (i % 2) * 30;
            return DateTime.fromObject({ hour, minute: minutes }).toLocaleString(DateTime.TIME_SIMPLE);
        });

        arrayOfDate.push({
            date: null,
            hours: hours,
        });

        for (let i = 0; i < 7; i++) {
            const date = firstDayOfWeek.plus({ days: i }).set({ hour: 0, minute: 0, second: 0 });
            arrayOfDate.push({
                date: date,
                hours: hours,
            });
        }

        return arrayOfDate;
        
    } else if (duration === 1) {

        const hours = Array.from({ length: 16 }, (_, i) => {
            const hour = i + 8; // 8:00 am is the starting time
            return DateTime.fromObject({ hour }).toLocaleString(DateTime.TIME_SIMPLE);
        });

        arrayOfDate.push({
            date: null,
            hours: hours,
        });

        for (let i = 0; i < 7; i++) {
            const date = firstDayOfWeek.plus({ days: i }).set({ hour: 0, minute: 0, second: 0 });
            arrayOfDate.push({
                date: date,
                hours: hours,
            });
        }

        return arrayOfDate;

    } else if (duration === 2) {

        const hours = Array.from({ length: 9 }, (_, i) => {
            const hour = i * 2 + 8; // 8:00 am is the starting time
            return DateTime.fromObject({ hour }).toLocaleString(DateTime.TIME_SIMPLE);
        });

        arrayOfDate.push({
            date: null,
            hours: hours,
        });

        for (let i = 0; i < 7; i++) {
            const date = firstDayOfWeek.plus({ days: i }).set({ hour: 0, minute: 0, second: 0 });
            arrayOfDate.push({
                date: date,
                hours: hours,
            });
        }

        return arrayOfDate;

    }
};

export const generateWeekDatesForAdmin = (date, duration, startTime, endTime) => {
    
    const arrayOfDate = [];
    let firstDayOfWeek = date;

    // Find the first day of the week (Monday)
    while (firstDayOfWeek.weekday !== 1) {
        firstDayOfWeek = firstDayOfWeek.minus({ days: 1 });
    };
    
    // Create start and end DateTime objects for the first day of the week
    const start = DateTime.fromISO(`${firstDayOfWeek.toISODate()}T${startTime}`);
    const end = DateTime.fromISO(`${firstDayOfWeek.toISODate()}T${endTime}`);

    // Calculate the number of intervals based on the duration
    const numOfIntervals = end.diff(start, 'hours').hours / duration;

    // Create the time slots for each day of the week
    const hours = Array.from({ length: numOfIntervals }, (_, index) => {
        return start.plus({ hours: index * duration }).toFormat('h:mm a');
    });

    arrayOfDate.push({
        date: null,
        hours: hours,
    });

    for (let i = 0; i < 7; i++) {
        const dayDate = firstDayOfWeek.plus({ days: i }).set({ hour: 0, minute: 0, second: 0 });
        
        arrayOfDate.push({
            date: dayDate,
            hours: hours,
        });
    };

    return arrayOfDate;
};

export const checkifDateIsInThisWeek = (date) => {
    if (date === null) return false;

    const today = DateTime.now();
    let firstDayOfWeek = today;
    while (firstDayOfWeek.weekday !== 1) {
        firstDayOfWeek = firstDayOfWeek.minus({ days: 1 });
    }
    let lastDayOfWeek = firstDayOfWeek.plus({ days: 6 });
    firstDayOfWeek = firstDayOfWeek.set({ hour: 9, minute: 0, second: 0 });
    lastDayOfWeek = lastDayOfWeek.set({ hour: 23, minute: 59, second: 59 });
    return date >= firstDayOfWeek && date <= lastDayOfWeek;
};