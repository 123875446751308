import React from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Axios from 'axios';
import { tokens } from "../../../theme";
import { useTheme, FormControlLabel, Checkbox, Autocomplete } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { countryPhoneCodes } from '../../weekly/studentButtonMenu/countryPhoneCode';

export default function ModifyTab({ 
    student, 
    setStudentUpdated,
    setSuccessAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertOpen,
    setErrorAlertMessage, 
    socket = null,
    socketConnected = false,
    from_weekly = false,
    getStudentName,
}) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const gridItemStyle = {
        '& > :not(style)': { m: 1, width: '26ch' }
    };
    const gridSmallItemStyle = {
        '& > :not(style)': { m: 1, width: '17ch' }
    };

    // for dialog
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const handleClickDialogOpen = () => {
        setDialogOpen(true);
    };
    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    // for student and parents country phone code
    const [studentCountryPhoneCode, setStudentCountryPhoneCode] = React.useState(countryPhoneCodes.find((option) => option.code + " " + option.name === student.country_phone_code));
    const [parentCountryPhoneCode, setParentCountryPhoneCode] = React.useState(countryPhoneCodes.find((option) => option.code + " " + option.name === student.parent_country_phone_code));
    const [parent2CountryPhoneCode, setParent2CountryPhoneCode] = React.useState(countryPhoneCodes.find((option) => option.code + " " + option.name === student.parent_country_phone_code_2));
    
    // for parent gender
    const [gender, setGender] = React.useState(student.parent_gender || '');
    const handleGenderChange = (event) => {
        setGender(event.target.value);
    };

    // for student type
    const [isPotentialStudent, setIsPotentialStudent] = React.useState(student.is_potential_student ? true : false);
    const handleStudentTypeChange = (event) => {
        setIsPotentialStudent(event.target.value);
    };

    function isNumeric(value) {
        return /^-?\d+$/.test(value);
    }

    const [blacklistChecked, setBlacklistChecked] = React.useState(student.is_blacklist_student ? true : false);

    // // for blacklist check        
    const handleBlacklistChange = (event) => {
        const isChecked = event.target.checked;
        setBlacklistChecked(isChecked);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let consultation_number = data.get('consultation_number');
        if (consultation_number === '') {
            consultation_number = null;
        }

        if (consultation_number && !isNumeric(consultation_number)) {
            setErrorAlertMessage("Consultation number must be a number!");
            setErrorAlertOpen(true);
            return;
        }

        if (isPotentialStudent === null) {
            setErrorAlertMessage('Please select a student type.');
            setErrorAlertOpen(true);
            return;
        }

        // check if country phone codes are selected
        if (data.get('phone') !== "" && data.get('phone') !== null && studentCountryPhoneCode === null) {
            setErrorAlertMessage("Please select the country phone code for student's phone number!");
            setErrorAlertOpen(true);
            return;
        }

        if (data.get('parent_phone') !== "" && data.get('parent_phone') !== null && parentCountryPhoneCode === null ) {
            setErrorAlertMessage("Please select the country phone code for parent's phone number!");
            setErrorAlertOpen(true);
            return;
        }

        if (data.get('parent_phone_2') !== "" && data.get('parent_phone_2') !== null && parent2CountryPhoneCode === null ) {
            setErrorAlertMessage("Please select the country phone code for parent's phone number!");
            setErrorAlertOpen(true);
            return;
        }

        let email = data.get('email') && data.get('email') !== "" ? data.get('email').toLowerCase().trim() : null;
        let parent_email = data.get('parent_email') && data.get('parent_email') !== "" ? data.get('parent_email').toLowerCase().trim() : null;
        let parent_email_2 = data.get('parent_email_2') && data.get('parent_email_2') !== "" ? data.get('parent_email_2').toLowerCase().trim() : null;
        let first_name = data.get('first_name') && data.get('first_name') !== "" ? data.get('first_name').trim() : null;
        let last_name = data.get('last_name') && data.get('last_name') !== "" ? data.get('last_name').trim() : null;
        let korean_name = data.get('korean_name') && data.get('korean_name') !== "" ? data.get('korean_name').trim() : null;
        let school = data.get('school') && data.get('school') !== "" ? data.get('school').trim() : null;

        if ((first_name && first_name.length > 255) || (last_name && last_name.length > 255) || (korean_name && korean_name.length > 255)) {
            setErrorAlertMessage("Name fields must be less than 255 characters.");
            setErrorAlertOpen(true);
            return;
        }

        await Axios.put(`${process.env.REACT_APP_URL}/api/v1/students/${student.uuid}`, {
            first_name: first_name,
            last_name: last_name,
            korean_name: korean_name,
            school: school,
            grade: data.get('grade'),
            remark: data.get('remark'),
            email: email,
            country_phone_code: studentCountryPhoneCode ? studentCountryPhoneCode.code + " " + studentCountryPhoneCode.name : "+1 United States",
            phone: data.get('phone'),
            consultation_number: consultation_number,
            is_potential_student: isPotentialStudent,
            is_blacklist_student: blacklistChecked,
            parent_email: parent_email,
            parent_email_2: parent_email_2,
            parent_country_phone_code: parentCountryPhoneCode ? parentCountryPhoneCode.code + " " + parentCountryPhoneCode.name : "+1 United States",
            parent_phone: data.get('parent_phone'),
            parent_country_phone_code_2: parent2CountryPhoneCode ? parent2CountryPhoneCode.code + " " + parent2CountryPhoneCode.name : "+1 United States",
            parent_phone_2: data.get('parent_phone_2'),
            parent_gender: gender,
            street: data.get('street'),
            city: data.get('city'),
            state: data.get('state'),
            zip: data.get('zip'),
            country: data.get('country'),
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            }
        }).then((response) => {
            if (from_weekly && socketConnected) {
                socket.emit("put.StudentUpdate", {
                    student_uuid: student.uuid,
                    message: " updated " + getStudentName() + "'s information",
                });
            } else {
                setSuccessAlertMessage(response.data.message);
                setSuccessAlertOpen(true);
                setStudentUpdated(true);
            }
        }).catch((error) => {
            setErrorAlertMessage(error.response.data.message);
            setErrorAlertOpen(true);
        });
    };

    const handleDelete = async () => {
        handleDialogClose();

        await Axios.delete(`${process.env.REACT_APP_URL}/api/v1/students/${student.uuid}`, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            }
        }).then((response) => {
            if (from_weekly && socketConnected) {
                socket.emit("delete.StudentDelete", {
                    student_uuid: student.uuid,
                    message: " deleted " + getStudentName(),
                });
            } else {
                setSuccessAlertOpen(true);
                setSuccessAlertMessage(response.data.message);
                window.location.reload();
            }
        }).catch((error) => {
            console.log(error.response.data.message);
            setErrorAlertOpen(true);
            setErrorAlertMessage(error.response.data.message);
        });
    };

    React.useEffect(() => {
        const first_name = document.getElementById('first_name');
        const last_name = document.getElementById('last_name');
        const korean_name = document.getElementById('korean_name');
        const school = document.getElementById('school');
        const grade = document.getElementById('grade');
        const remark = document.getElementById('remark');
        const email = document.getElementById('email');
        const phone = document.getElementById('phone');
        const consultation_number = document.getElementById('consultation_number');
        const parent_email = document.getElementById('parent_email');
        const parent_phone = document.getElementById('parent_phone');
        const parent_email_2 = document.getElementById('parent_email_2');
        const parent_phone_2 = document.getElementById('parent_phone_2');
        const street = document.getElementById('street');
        const city = document.getElementById('city');
        const state = document.getElementById('state');
        const zip = document.getElementById('zip');
        const country = document.getElementById('country');
        // is potential student
        // gender
        // blacklist

        if (student && from_weekly) {
            first_name.value = student.first_name ? student.first_name : '';
            last_name.value = student.last_name ? student.last_name : '';
            korean_name.value = student.korean_name ? student.korean_name : '';
            school.value = student.school ? student.school : '';
            grade.value = student.grade ? student.grade : '';
            remark.value = student.remark ? student.remark : '';
            email.value = student.email ? student.email : '';
            phone.value = student.phone ? student.phone : '';
            consultation_number.value = student.consultation_number ? student.consultation_number : '';
            parent_email.value = student.parent_email ? student.parent_email : '';
            parent_phone.value = student.parent_phone ? student.parent_phone : '';
            parent_email_2.value = student.parent_email_2 ? student.parent_email_2 : '';
            parent_phone_2.value = student.parent_phone_2 ? student.parent_phone_2 : '';
            street.value = student.address ? student.address.street : null;
            city.value = student.address ? student.address.city : null;
            state.value = student.address ? student.address.state : null;
            zip.value = student.address ? student.address.zip : null;
            country.value = student.address ? student.address.country : null;
            setIsPotentialStudent(student.is_potential_student ? true : false);
            setGender(student.parent_gender || '');
            setBlacklistChecked(student.is_blacklist_student ? true : false);
        }

    }, [student]);

    return (
        <>
            <Box component="form" onSubmit={handleSubmit} noValidate>
                <Grid container direction="row" alignItems="center">
                    <Grid item sx={gridItemStyle}>
                        <TextField 
                            id="first_name"
                            name="first_name"
                            label="First name"
                            variant="filled"
                            defaultValue={student.first_name}
                        />
                    </Grid>
                    <Grid item sx={gridItemStyle}>
                        <TextField 
                            id="last_name"
                            name="last_name"
                            label="Last name"
                            variant="filled"
                            defaultValue={student.last_name}
                        />
                    </Grid>
                    <Grid item sx={gridItemStyle}>
                        <TextField 
                            id="korean_name"
                            name="korean_name"
                            label="Korean name"
                            variant="filled"
                            defaultValue={student.korean_name}
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" alignItems="center">
                    <Grid item sx={gridItemStyle}>
                        <TextField 
                            id="school"
                            name="school"
                            label="School"
                            variant="filled"
                            defaultValue={student.school}
                        />
                    </Grid>
                    <Grid item sx={gridItemStyle}>
                        <TextField 
                            id="grade"
                            name="grade"
                            label="Grade"
                            variant="filled"
                            defaultValue={student.grade}
                        />
                    </Grid>
                    <Grid item sx={gridItemStyle}>
                        <TextField 
                            id="remark"
                            name="remark"
                            label="Remark"
                            variant="filled"
                            defaultValue={student.remark}
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" alignItems="center">
                    <Grid item sx={gridItemStyle}>
                        <TextField 
                            id="email"
                            name="email"
                            label="Email"
                            variant="filled"
                            defaultValue={student.email}
                        />
                    </Grid>
                    <Grid item sx={gridItemStyle}>
                        <Autocomplete
                            id="country-phone-code"
                            value={studentCountryPhoneCode}
                            options={countryPhoneCodes}
                            getOptionLabel={(option) => option.code + " " + option.name}
                            sx={{ width: "50%" }}
                            renderInput={(params) => <TextField {...params} label="Country Phone Code" />}
                            onChange={(e, value) => {
                                setStudentCountryPhoneCode(value);
                            }}
                            isOptionEqualToValue={(option, value) => option.code === value.code}
                        />
                    </Grid>
                    <Grid item sx={gridItemStyle}>
                        <TextField 
                            id="phone"
                            name="phone"
                            label="Phone"
                            variant="filled"
                            defaultValue={student.phone}
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" alignItems="center">
                    <Grid item sx={gridItemStyle}>
                        <TextField 
                            id="consultation_number"
                            name="consultation_number"
                            label="Consultation Number"
                            variant="filled"
                            defaultValue={student.consultation_number}
                        />
                    </Grid>
                    <Grid item sx={gridItemStyle}>
                        <FormControl variant="filled">
                            <InputLabel id="demo-simple-select-filled-label">Student Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                                value={isPotentialStudent}
                                onChange={handleStudentTypeChange}
                            >
                                <MenuItem value={false}>Enrolled Student</MenuItem>
                                <MenuItem value={true}>Potential Student</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sx={gridItemStyle}>
                        <FormControlLabel control={
                            <Checkbox 
                                checked={blacklistChecked}
                                onChange={handleBlacklistChange}
                            />
                        } label="Blacklist?" 
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" alignItems="center">
                    <Grid item sx={gridItemStyle}>
                        <TextField 
                            id="parent_email"
                            name="parent_email"
                            label="Parent email 1"
                            variant="filled"
                            defaultValue={student.parent_email}
                        />
                    </Grid>
                    <Grid item sx={gridItemStyle}>
                        <Autocomplete
                            id="country-phone-code"
                            value={parentCountryPhoneCode}
                            options={countryPhoneCodes}
                            getOptionLabel={(option) => option.code + " " + option.name}
                            sx={{ width: "50%" }}
                            renderInput={(params) => <TextField {...params} label="Country Phone Code" />}
                            onChange={(e, value) => {
                                setParentCountryPhoneCode(value);
                            }}
                            isOptionEqualToValue={(option, value) => option.code === value.code}
                        />
                    </Grid>
                    <Grid item sx={gridItemStyle}>
                        <TextField 
                            id="parent_phone"
                            name="parent_phone"
                            label="Parent phone 1"
                            variant="filled"
                            defaultValue={student.parent_phone}
                        />
                    </Grid>
                    <Grid item sx={gridItemStyle}>
                        <FormControl variant="filled">
                            <InputLabel id="demo-simple-select-filled-label">Parent Gender</InputLabel>
                            <Select
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                                value={gender}
                                onChange={handleGenderChange}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value={"Male"}>Male</MenuItem>
                                <MenuItem value={"Female"}>Female</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container direction="row" alignItems="center">
                    <Grid item sx={gridItemStyle}>
                        <TextField 
                            id="parent_email_2"
                            name="parent_email_2"
                            label="Parent email 2"
                            variant="filled"
                            defaultValue={student.parent_email_2}
                        />
                    </Grid>
                    <Grid item sx={gridItemStyle}>
                        <Autocomplete
                            id="country-phone-code"
                            value={parent2CountryPhoneCode}
                            options={countryPhoneCodes}
                            getOptionLabel={(option) => option.code + " " + option.name}
                            sx={{ width: "50%" }}
                            renderInput={(params) => <TextField {...params} label="Country Phone Code" />}
                            onChange={(e, value) => {
                                setParent2CountryPhoneCode(value);
                            }}
                            isOptionEqualToValue={(option, value) => option.code === value.code}
                        />
                    </Grid>
                    <Grid item sx={gridItemStyle}>
                        <TextField 
                            id="parent_phone_2"
                            name="parent_phone_2"
                            label="Parent phone 2"
                            variant="filled"
                            defaultValue={student.parent_phone_2}
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" alignItems="center">
                    <Grid item sx={gridItemStyle}>
                        <TextField 
                            id="street"
                            name="street"
                            label="Street"
                            variant="filled"
                            defaultValue={student.address ? student.address.street : null}
                        />
                    </Grid>
                    <Grid item sx={gridItemStyle}>
                        <TextField 
                            id="city"
                            name="city"
                            label="City"
                            variant="filled"
                            defaultValue={student.address ? student.address.city : null}
                        />
                    </Grid>
                    <Grid item sx={gridSmallItemStyle}>
                        <TextField 
                            id="state"
                            name="state"
                            label="State"
                            variant="filled"
                            defaultValue={student.address ? student.address.state : null}
                        />
                    </Grid>
                    <Grid item sx={gridSmallItemStyle}>
                        <TextField 
                            id="zip"
                            name="zip"
                            label="Zip"
                            variant="filled"
                            defaultValue={student.address ? student.address.zip : null}
                        />
                    </Grid>
                    <Grid item sx={gridSmallItemStyle}>
                        <TextField 
                            id="country"
                            name="country"
                            label="Country"
                            variant="filled"
                            defaultValue={student.address ? student.address.country : null}
                        />
                    </Grid>
                </Grid>
                {/* <Grid container direction="row" alignItems="center">
                    <Grid item sx={gridItemStyle}>
                        <TextField 
                            id="zip"
                            name="zip"
                            label="Zip"
                            variant="filled"
                            defaultValue={student.address ? student.address.zip : null}
                        />
                    </Grid>
                    <Grid item sx={gridItemStyle}>
                        <TextField 
                            id="country"
                            name="country"
                            label="Country"
                            variant="filled"
                            defaultValue={student.address ? student.address.country : null}
                        />
                    </Grid>
                </Grid> */}
                <Button
                    type="submit"
                    variant="contained"
                    style={{
                        "backgroundColor": colors.primary[300],
                        "color": "white",
                        "float": "center",
                        "marginTop": "10px",
                        "width": "100%",
                    }}
                >
                    Update
                </Button>
            </Box>
            <Button
                variant="contained"
                style={{
                    "backgroundColor": colors.redAccent[500],
                    "color": "white",
                    "float": "center",
                    "marginTop": "15px",
                    "width": "100%",
                }}
                onClick={handleClickDialogOpen}
            >
                Delete this student
            </Button>
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {"Are you sure you want to delete this student's data?"}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    This action cannot be undone.
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleDialogClose}>Cancel</Button>
                <Button onClick={handleDelete} color="error" autoFocus>
                    Delete
                </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
