import React from 'react'
import { tokens } from "../../theme";
import { Box, Typography, useTheme } from "@mui/material";
// import Button from '@mui/material/Button';
import Loading from '../../components/Loading';
import Header from "../../components/Header";
import Alert from '../../components/alert/Alert';
import BackdropComp from '../../components/Backdrop';
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import WavingHandIcon from '@mui/icons-material/WavingHand';

const Home = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // for 401 error
    const signOut = useSignOut();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut();
        localStorage.removeItem("userName");
        localStorage.removeItem("who");
        navigate("/signin");
    }

    const [loading, setLoading] = React.useState(true);

    // const season = {0: 'All', 1: 'Spring', 2: 'Summer', 3: 'Fall'};

    // for dropdown
    // const [year, setYear] = React.useState(null);
    // const [semester, setSemester] = React.useState('');

    // for backdrop
    const [backdropOpen, setBackdropOpen] = React.useState(false);
    const handleBackdropClose = () => {
        setBackdropOpen(false);
    };

    // for alert
    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = React.useState("");
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = React.useState("");

    React.useEffect(() => {
        localStorage.setItem('selectedMenu', 'Dashboard');

        setLoading(false);
    }, []);

    return (
        <Box marginX={3} marginY={1} minHeight="88%" borderBottom={`1px solid ${colors.grey[800]}`}>
            <Header title="DASHBOARD" />
            {
                loading ?
                    <Loading />
                    : <>
                        <Box
                            display="flex"
                            justifyContent="left"
                            gap={2}
                            p={0}
                            minHeight="60vh"
                            mt={-2}
                        >
                            <Box flex={1} display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap={1}>
                                <WavingHandIcon fontSize="large" color="primary" />
                                <Typography variant="h2" color="primary">
                                    No More Loading!
                                </Typography>
                            </Box>
                        </Box>
                        <Alert
                            successAlertOpen={successAlertOpen}
                            setSuccessAlertOpen={setSuccessAlertOpen}
                            errorAlertOpen={errorAlertOpen}
                            setErrorAlertOpen={setErrorAlertOpen}
                            successMsg={successAlertMessage}
                            errorMsg={errorAlertMessage}
                            vertical="bottom"
                            horizontal="left"
                        />
                        <BackdropComp backdropOpen={backdropOpen} handleBackdropClose={handleBackdropClose} />
                    </>
            }
        </Box >
    )
}

export default Home