import React from 'react';
import Button from '@mui/material/Button';
import { Box, TextField, Typography, useMediaQuery } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CloseIcon from '@mui/icons-material/Close';
import getStudentName from '../../utils/getStudentName';
import Axios from 'axios';
import Autocomplete from '@mui/material/Autocomplete';

function StudentSearchModal({
    setStudentUuidForSearch,
    setSemester,
    colors,
    handleLogout,
}) {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        maxHeight: '80%',
        overflowY: 'auto',
        bgcolor: colors.primary[600],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    // for media query
    const matches2 = useMediaQuery('(min-width:1200px)');
    const matches3 = useMediaQuery('(min-width:1000px)');    

    const [loading, setLoading] = React.useState(false);
    
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => { setOpen(false); };
    
    const [students, setStudents] = React.useState(null);
    const [selectedStudent, setSelectedStudent] = React.useState(null);

    // for autocomplete
    const [studentInputValue, setStudentInputValue] = React.useState('');

    React.useEffect(() => {

        async function fetchStudents() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/students`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                setStudents(response.data);
                setLoading(false);
            }).catch((error) => {
                if (error.response.status === 404) {
                    setStudents([]);
                    setLoading(false);
                    return;
                } else if (error.response.status === 401) {
                    handleLogout();
                    return;
                }
                alert(error.response.data.message);
            });
        }


        if (open) {

            // reinitialize the state
            setSelectedStudent(null);
            setStudentInputValue('');

            setLoading(true);
            fetchStudents();
        }
    }, [open]);

    function _getStudentName(student) {
        let student_name = getStudentName(student);
        if (student_name === "Unknown" && student.consultation_number) {
            return "(" + student.consultation_number + ")";
        } else if (student_name === "Unknown" && student.email) {
            return student.email;
        }

        if (student.consultation_number) {
            student_name += " (" + student.consultation_number + ")";
        } 

        return student_name;
    }

    return (
        <>
            <Button
                variant="contained"
                color="primary"
                onClick={() => {
                    handleOpen();
                }}
            >
                Search Student
            </Button>
            <Modal
                aria-labelledby="student-search-modal"
                aria-describedby="student-search-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography variant="h4" color={colors.primary[450]}>
                            Search Student
                        </Typography>
                        <CloseIcon sx={{ position: "absolute", right: 10, top: 10, cursor: "pointer" }} onClick={handleClose} />
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", mt: 2 }}>
                            {loading || students === null ? (
                                <Typography variant="h6" color={colors.primary[450]}>
                                    Loading...
                                </Typography>
                            ) : (
                                <Autocomplete
                                    id="controllable-states-demo"
                                    options={students !== null ? students : []}
                                    getOptionLabel={(student) => {
                                        return _getStudentName(student);
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Choose Student"/>}
                                    sx={{ 
                                        width: "18vw", 
                                        ...!matches2 && {width: "20vw"}, 
                                        ...!matches3 && {width: "34vw"},
                                        transition: "width 0.1s" 
                                    }}
                                    onChange={(event, value) => setSelectedStudent(value)}
                                    value={selectedStudent}
                                    inputValue={studentInputValue}
                                    onInputChange={(event, newInputValue) => {
                                        setStudentInputValue(newInputValue);
                                    }}
                                />
                            )}
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: 2,
                                mt: 2,
                            }}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{ width: 200, mt: 2 }}
                                onClick={() => {
                                    if (selectedStudent) {
                                        setStudentUuidForSearch(selectedStudent.uuid);
                                        setSemester("all");
                                    } else {
                                        setStudentUuidForSearch(null);
                                    }

                                    handleClose();
                                }}
                            >
                                Search Courses for This Student
                            </Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
}

export default StudentSearchModal;