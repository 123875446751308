import * as React from 'react';
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import ModalTabs from './ModalTabs';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import MenuBookIcon from '@mui/icons-material/MenuBook';

export default function MoreInfoModal({ 
    teacher, 
    setTeacherUpdated,
    socket = null, // from weekly
    socketConnected = false,
    from_weekly = false,
    setTeacherForMoreInfo = null,
}) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1000,
        height: 800,
        bgcolor: colors.primary[600],
        border: '2px solid grey',
        boxShadow: 24,
        p: 4,
    };

    React.useEffect(() => {
        if (from_weekly) {
            setOpen(true);
        }
    }, [teacher]);
    
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        if (from_weekly) {
            setTeacherForMoreInfo(null);
        }

        setOpen(false)
    };

    return (
        <div>
            {!from_weekly && <Button size="small" style={{"backgroundColor": colors.primary[800], "color": colors.primary[400]}} onClick={handleOpen}>More Info</Button>}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                  backdrop: {
                    timeout: 500,
                  },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h1" ml={1} borderBottom={1}>
                            {teacher.last_name}, {teacher.first_name}
                        </Typography>
                        <Typography id="modal-modal-description" variant="h6" mt={1} mb={1} ml={1} color={"grey"}>
                            <SchoolRoundedIcon style={{marginBottom: "-5px", marginRight: "5px"}} /> {teacher.school} ({teacher.degree} in {teacher.major})
                        </Typography>
                        <Typography id="modal-modal-description" variant="h6" mt={1} mb={1} ml={1} color={"grey"}>
                            <MenuBookIcon 
                                style={{
                                    marginBottom: "-5px", 
                                    marginRight: "5px"
                                }}
                            />
                            {teacher.subject === null || teacher.subject === ""
                                ? "N/A"
                                : teacher.subject.includes('|') ? 
                                ' ' + teacher.subject.split('|').join(', ') : ' ' + teacher.subject
                            }
                        </Typography>
                        <ModalTabs 
                            teacher={teacher} 
                            setTeacherUpdated={setTeacherUpdated} 
                            socket={socket}
                            socketConnected={socketConnected}
                            from_weekly={from_weekly}
                        />
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}