import React from 'react';
import { Box, Button, Modal, Typography, useTheme } from '@mui/material';
import './Guideline.css';
import Axios from 'axios';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

// Import your images
import cover from './guidelinePics/cover.jpg';
import accessScheduler from './guidelinePics/accessScheduler.png';
import mainScreen from './guidelinePics/mainScreen.png';
import noClass from './guidelinePics/noClass.png';
import timeSlot from './guidelinePics/timeSlot.png';
import colors from './guidelinePics/colors.png';
import deadline from './guidelinePics/deadline.png';
import deadline2 from './guidelinePics/deadline2.png';
import deadline3 from './guidelinePics/deadline3.png';
import firstpage from './guidelinePics/firstpage.png';
import firstpage2 from './guidelinePics/firstpage2.png';
import lastweek from './guidelinePics/lastweek.png';
import beforecopy from './guidelinePics/beforecopy.png';
import aftercopy from './guidelinePics/aftercopy.png';
import submit from './guidelinePics/submit.png';
import submit2 from './guidelinePics/submit2.png';
import close from './guidelinePics/close.png';


const GuidelineModal = ({ userRole, userId, open, handleClose }) => {

    const [name, setName] = React.useState("");
    const [currentPage, setCurrentPage] = React.useState(0);  // Track the current page

    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';  

    // Fetch teacher name
    React.useEffect(() => {
        async function getTeacherName() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/teachers/${userId}`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                setName(response.data.first_name);
            }).catch((error) => {
                console.log(error);
            });
        }

        if (userRole === "teacher")
            getTeacherName();

    }, [userId, userRole]);

    // Define each page's content as an array of objects
    const pages = [
        {
            content: (
                <Box>
                    <Typography variant='h2' sx={{mb: 2, textAlign: 'center'}}>Hello, {name}! 👋 </Typography>
                    <img src={cover} alt="first page" className="page-image" style={{width: '35%'}}/>
                    <Box sx={{display: 'flex', flexDirection: 'column', width: '70%', ml: '15%', textIndent: '1rem'}}>
                        <p>This is a guidebook on how to submit your availability.</p>
                        <p>In brief, all you need to do are two simple steps ✌️: <u>click the slots and click the submit button.</u></p>
                        <p>This guidebook will help you explore all the features related to availability so that you feel comfortable with the system. Feel free to browse through it whenever you have the time.</p>
                        <p>Thank you. 😊</p>
                    </Box>
                </Box>
            )
        },
        {
            content: (
                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center' }}>          
                    <Typography variant='h2'>Table of Contents </Typography>
                    <ul className="menu" style={{ padding: 20 }}>
                        <li style={{fontSize: '20px', marginBottom: '5px'}} onClick={() => setCurrentPage(2)}>🗓️ How to access the Scheduler</li>
                        <li style={{fontSize: '20px', marginBottom: '5px'}} onClick={() => setCurrentPage(3)}>🖥️ Main Screen UI</li>
                        <li style={{fontSize: '20px', marginBottom: '5px'}} onClick={() => setCurrentPage(4)}>🙅 No Class Slot</li>
                        <li style={{fontSize: '20px', marginBottom: '5px'}} onClick={() => setCurrentPage(5)}>⏰ Time Slot</li>
                        <li style={{fontSize: '20px', marginBottom: '5px'}} onClick={() => setCurrentPage(6)}>🎨 Colors on the slots</li>
                        <li style={{fontSize: '20px', marginBottom: '5px'}} onClick={() => setCurrentPage(7)}>⌛️ Deadline & Week Switch Buttons</li>
                        <li style={{fontSize: '20px', marginBottom: '5px'}} onClick={() => setCurrentPage(8)}>🔁 First Page Button</li>
                        <li style={{fontSize: '20px', marginBottom: '5px'}} onClick={() => setCurrentPage(9)}>📑 Copy Button</li>
                        <li style={{fontSize: '20px', marginBottom: '5px'}} onClick={() => setCurrentPage(10)}>✅ Submit Button</li>
                        <li style={{fontSize: '20px' }} onClick={() => setCurrentPage(11)}>🚫 Close Button</li>
                    </ul>
                </Box>
            )
        },
        {
            title: "🗓️ How to access the Scheduler",
            content: (
                <Box>
                    <img src={accessScheduler} alt="Scheduler Access" className="page-image" style={{width: '60%'}} />
                    <Box sx={{display: 'flex', flexDirection: 'column', width: '50%', margin: '0 auto', textIndent: '1rem'}}>
                        <p>This is the screen you see when you log in with your account.</p>
                        <p>To access the scheduler for availability, click the briefcase with the clock icon in the upper right corner.</p>
                    </Box>
                </Box>
            )
        },
        {
            title: "🖥️ Main Screen UI",
            content: (
                <Box>
                    <img src={mainScreen} alt="Main Screen UI" className="page-image" style={{width: '80%'}} />
                    <Box sx={{display: 'flex', flexDirection: 'column', width: '50%', margin: '0 auto', textIndent: '1rem'}}>
                        <p>This is the screen where everything happens.
                        We'll start with the main screen and then move on to the buttons in the upper right corner.</p>
                    </Box>
                </Box>
            )
        },
        {
            title: "🙅 No Class Slot",
            content: (
                <Box>
                    <img src={noClass} alt="No Class Slot" className="page-image" style={{width: '80%'}}/>
                    <Box sx={{display: 'flex', flexDirection: 'column', width: '50%', margin: '0 auto', textIndent: '1rem'}}>
                        <p>We currently don't have any classes from Monday to Friday before 4:00 PM and Sunday.
                            During these times, the slots are blocked and labeled 'No Class.'</p>
                    </Box>
                </Box>
            )
        },
        {
            title: "⏰ Time Slot",
            content: (
                <Box>
                    <Box sx={{display: 'flex', flexDirection: 'column', width: '70%', margin: '0 auto', textIndent: '1rem'}}>
                        <p>Each page covers one week, and you'll see the date and day for each column in the first row.</p>
                        <p>The first column shows time slots, with each slot lasting 30 minutes.</p>
                    </Box>

                    <img src={timeSlot} alt="Time Slot" className="page-image" style={{width: '70%'}} />
                    <Box sx={{display: 'flex', flexDirection: 'column', width: '70%', margin: '0 auto', textIndent: '1rem'}}>
                        <p>For example, the row labeled "6:00 PM" in the first column represents the time from 6:00 PM to 6:30 PM.
                        If you select Monday at 6:00 PM, that means you are available from 6:00 PM to 6:30 PM on Monday.</p>
                        <p>It may seem confusing at first, so we've added a feature that displays the exact time for each slot.
                        When you hover your cursor over any slot in the calendar, the slot will show the time it represents. This should help you select the correct slots.</p>
                    </Box>
                </Box>
            )
        },
        {
            title: "🎨 Colors on the Slots",
            content: (
                <Box>
                    <img src={colors} alt="colors" className="page-image" style={{width: '75%'}}/>
                    <Box sx={{display: 'flex', flexDirection: 'column', width: '60%', margin: '0 auto', textIndent: '1rem'}}>
                        <p><strong style={{ backgroundColor: 'skyblue', color: 'white' }}>Sky Blue</strong>: Class time. These slots are blocked.</p>
                        <p><strong style={{ backgroundColor: 'red', color: 'white' }}>Red</strong>: Canceled classes. You can select these slots and mark them as available.</p>
                        <p><strong style={{ backgroundColor: 'orange', color: 'white' }}>Orange</strong>: Your availability. Select time slots when you're available.</p>
                    </Box>
                </Box>
            )
        },
        {
            title: "⌛️ Deadline & Week Switch Buttons",
            content: (
                <Box>
                    <Box sx={{display: 'flex', flexDirection: 'column', width: '80%', margin: '0 auto', textIndent: '1rem'}}>
                        <p>You can find the deadline at the top of the scheduler, highlighted in red.</p>
                        <p>The deadlines are fixed in the same position, and as you move between weeks, the deadlines will adjust accordingly and always appear in the same spot.</p> 
                        <p>The deadline for submitting availability is every Wednesday, two weeks before the displayed week.</p>
                    </Box>
                    <img src={deadline} alt="Deadline" className="page-image" style={{width: '80%'}}/>
                    <img src={deadline2} alt="Deadline" className="page-image" style={{width: '80%'}}/>
                    <img src={deadline3} alt="Deadline" className="page-image" style={{width: '80%'}}/>
                    <Box sx={{display: 'flex', flexDirection: 'column', width: '80%', margin: '0 auto', textIndent: '1rem'}}>
                        <p>For example, as in the first example above, if the calendar shows the week of 10/7 ~ 10/13, then two weeks prior would be 9/23 ~ 9/29.
                        Since the Wednesday of the 9/23 ~ 9/29 week is 9/25, the deadline for the week of 10/7 ~ 10/13 is 9/25.
                        </p>
                        <p>
                        It may sound confusing, but think in this way: <u>just subtract two weeks from the week you're looking at now. The Wednesday of that week is the deadline for the scheduler you're currently viewing.</u>
                        </p>
                    
                        <p
                            style={{
                            display: 'flex',
                            alignItems: 'center',
                            }}
                        >
                            <KeyboardArrowLeftIcon/> <KeyboardArrowRightIcon/>: You can switch the week back and forward with these buttons.
                        </p>
                    </Box>
                </Box>
            )
        },
        {
            title: "🔁 First Page Button",
            content: (
                <Box>
                    <img src={firstpage} alt="First page" className="page-image" style={{width: '80%'}}/>
                    <img src={firstpage2} alt="First page" className="page-image" style={{width: '80%'}}/>
                    <Box sx={{display: 'flex', flexDirection: 'column', width: '80%', margin: '0 auto', textIndent: '1rem'}}>
                        <p>
                        The week you see when you first click the icon is always two weeks ahead by default. This way, you don’t need to navigate anywhere; you can select your availability for that week right away.</p>
                        <p>
                        Sometimes, though, you might be looking at a different week for various reasons: maybe you want to check past availability or submit your future availability in advance. In that case, once you’re done, you can click the 'First Page' button to return to the default week with one click.
                        </p>
                    </Box>
                </Box>
            )
        },
        {
            title: "📑 Copy Button",
            content: (
                <Box>
                    <Box sx={{display: 'flex', flexDirection: 'column', width: '80%', margin: '0 auto', textIndent: '1rem'}}>
                        <p>Suppose we are currently trying to enter the schedule for the week of 10/14 to 10/20. In the previous week, 10/7 to 10/13, Thursday from 6:00 to 8:00 PM was marked as available and let's say that your availability for next week is the same, and you need to choose the same time slot.</p>
                    </Box>
                    <img src={lastweek} alt="Copy Button" className="page-image" style={{width: '80%'}}/>
                    <Box sx={{display: 'flex', flexDirection: 'column', width: '80%', margin: '0 auto', textIndent: '1rem'}}>
                        <p>You may simply select each time slot, which works perfectly well! That's why we've added a feature that allows you not only to click on slots but also to drag and select multiple slots at once, making it faster to choose your availability.</p>
                    </Box>
                    <img src={beforecopy} alt="Copy Button" className="page-image" style={{width: '80%'}}/>
                    <Box sx={{display: 'flex', flexDirection: 'column', width: '80%', margin: '0 auto', textIndent: '1rem'}}>
                        <p>However, if you have a lot of available time and need to select a large number of slots every week, this could become a bit of a burden.
                        Copy button would be helpful for that case. This button is only available if you've already submitted availability for the previous week.
                        </p>
                    </Box>
                    <img src={aftercopy} alt="Copy Button" className="page-image" style={{width: '80%'}}/>
                    <Box sx={{display: 'flex', flexDirection: 'column', width: '80%', margin: '0 auto', textIndent: '1rem'}}>
                        <p>By simply clicking the copy button as described earlier, your availability from the previous week will be copied over. This means the same time slot—Thursday, October 17 from 6:00 to 8:00 PM—will be filled in this week as well.</p>
                        <p style={{color: 'red'}}><strong>Please note you still need to click 'SUBMIT' after copying to save your availability for the current week.</strong></p>
                    </Box>
                </Box>
            )
        },
        {
            title: "✅ Submit Button",
            content: (
                <Box>
                    <img src={submit} alt="Submit Button" className="page-image" style={{width: '80%'}}/>
                    <Box sx={{display: 'flex', flexDirection: 'column', width: '80%', margin: '0 auto', textIndent: '1rem'}}>
                        <p>To submit your availability, select or drag time slots you are available, then click the submit button to save it.</p>
                        <p>The 'SUBMIT' button is the most important one. <u>You must click it after selecting your time slots.</u> If you close the availability screen or switch weeks without submitting, your availability won't be saved. Please make sure to submit once you're done!</p>
                    </Box>
                    <img src={submit2} alt="Submit Button" className="page-image" style={{width: '80%'}}/>
                    <Box sx={{display: 'flex', flexDirection: 'column', width: '80%', margin: '0 auto', textIndent: '1rem'}}>
                        <p>After you submit your availability, a success message will appear in a green block at the bottom left.</p>
                    </Box>
                </Box>
            )
        },
        {
            title: "🚫 Close Button",
            content: (
                <Box>
                    <img src={close} alt="Close Button" className="page-image" style={{width: '80%'}}/>
                    <Box sx={{display: 'flex', flexDirection: 'column', width: '80%', margin: '0 auto', textIndent: '1rem'}}>
                        <p>To exit the availability screen, click the close button. You can also close the screen by clicking anywhere outside the box.</p>
                    </Box>
                </Box>
            )
        }
    ];

    // Handle navigation between pages
    const nextPage = () => {
        if (currentPage < pages.length - 1) setCurrentPage(currentPage + 1);
    };

    const prevPage = () => {
        if (currentPage > 0) setCurrentPage(currentPage - 1);
    };

    const guidelineModalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '60%',
        height: '80%',
        bgcolor: isDarkMode ? '#373737' : 'white',
        color: isDarkMode ? 'white' : 'black',
        border: '2px solid grey',
        boxShadow: 24,
        p: 4,
        overflow: 'auto',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column' 
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            closeAfterTransition
        >
            <Box sx={guidelineModalStyle}>
                <Box sx={{flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
          
                    {/* Title */}
                    <Box sx={{ mb: 2, textAlign: 'center', fontFamily: 'Source Sans 3', fontWeight: '400' }}>
                        <Typography variant="h4">{pages[currentPage].title}</Typography>
                    </Box>

                    {/* Image (if any) and Text Content */}
                    <Box
                        sx={{
                        flexGrow: 1,
                        mb: 2,
                        fontFamily: 'Source Sans 3',
                        fontSize: '1.2rem',
                        lineHeight: '1.6', 
                        }}
                    >
                        {pages[currentPage].content}
                    </Box>

                    {/* Fixed Position for Navigation Buttons */}
                    <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 3}}>
                        <Button 
                            variant='contained'
                            backgroundColor="white"
                            onClick={prevPage} 
                            disabled={currentPage === 0}
                            sx={{
                                '&:hover': {
                                backgroundColor: 'grey'
                                }
                            }}
                        >
                            Previous
                        </Button>

                        {/* "Go Back to Table of Contents" button if not on the Table of Contents page */}
                        {currentPage > 1 && (
                            <Button 
                                variant="contained"
                                backgroundColor="white"
                                onClick={() => setCurrentPage(1)}
                                sx={{
                                '&:hover': {
                                    backgroundColor: 'grey'
                                }
                                }}         
                            >
                                Go Back to Table of Contents
                            </Button>
                        )}
        
                        {/* "Next" button */}
                        <Button 
                            variant='contained'
                            backgroundColor="white"
                            onClick={nextPage} 
                            disabled={currentPage === pages.length - 1}
                            sx={{
                                '&:hover': {
                                backgroundColor: 'grey'
                                }
                            }}
                        >
                            Next
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

export default GuidelineModal;