import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';

const Loading = () => {
    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "55vh" }}>
            <CircularProgress color="primary" />
        </div> // change to secondary color when the light theme upgrade is done
    )
}

export default Loading