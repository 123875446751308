import React from 'react'
import Backdrop from '@mui/material/Backdrop';
import Popover from '@mui/material/Popover';
import MoreVertIcon from '../../../components/MoreVertIcon';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Axios from 'axios';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { tokens } from "../../../theme";
import { Box, useTheme } from "@mui/material";
import TextField from '@mui/material/TextField';
import AddMemoDatePicker from './AddMemoDatePicker';
import dayjs from 'dayjs';

const MemoVertIcon = ({
    memo,
    student_uuid,
    setStudentUpdated,
    setSuccessAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertOpen,
    setErrorAlertMessage,
    socket,
    socketConnected,
    from_weekly,
    getStudentName = null,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMoreVertIconClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopOverClose = () => {
        setAnchorEl(null);
    };

    const popoverOpen = Boolean(anchorEl);
    const popoverId = popoverOpen ? 'popover' : undefined;

    // for edit modal
    const [editModalOpen, setEditModalOpen] = React.useState(false);
    const handleEditModalOpen = () => setEditModalOpen(true);
    const handleEditModalClose = () => { setEditModalOpen(false); setAnchorEl(null); };
    
    // for reminder date when editing
    const [reminderDate, setReminderDate] = React.useState(dayjs(memo.reminder));
    const [title, setTitle] = React.useState(memo.title);
    const [content, setContent] = React.useState(memo.content);

    // for edit
    const handleMemoEdit = async (event) => {
        event.preventDefault();

        await Axios.put(`${process.env.REACT_APP_URL}/api/v1/students/${student_uuid}/memos/${memo.id}`, {
            title: title,
            content: content,
            reminder: reminderDate ? reminderDate.toDate() : null,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            if (from_weekly && socketConnected) {
                socket.emit("put.StudentUpdate", {
                    student_uuid: student_uuid,
                    message: getStudentName !== null ? " edited one of the memos of " + getStudentName() : " edited one of the memos of a student",
                });
            } else {
                setStudentUpdated(true);
                setSuccessAlertOpen(true);
                setSuccessAlertMessage(response.data.message);
            }
            handleEditModalClose();
        }).catch((error) => {
            console.log(error);
            setErrorAlertOpen(true);
            setErrorAlertMessage(error.response.data.message);
        });
    };

    // for delete dialog
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const handleClickDialogOpen = () => {
        setDialogOpen(true);
    };
    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const deleteMemo = async (e, memo_id) => {
        e.preventDefault();

        await Axios.delete(`${process.env.REACT_APP_URL}/api/v1/students/${student_uuid}/memos/${memo_id}`, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            if (from_weekly && socketConnected) {
                socket.emit("put.StudentUpdate", {
                    student_uuid: student_uuid,
                    message: getStudentName !== null ? " deleted one of the memos of " + getStudentName() : " deleted one of the memos of a student",
                });
            } else {
                setStudentUpdated(true);
                setSuccessAlertOpen(true);
                setSuccessAlertMessage(response.data.message);
            }
            handleDialogClose();
        }).catch((error) => {
            setErrorAlertOpen(true);
            setErrorAlertMessage(error.response.data.message);
        });
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: colors.primary[600],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    
    return (
        <><MoreVertIcon handleClick={handleMoreVertIconClick} iconColor={'primary'} />
        <Popover
            id={popoverId}
            open={popoverOpen}
            anchorEl={anchorEl}
            onClose={handlePopOverClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                {/* EDIT */}
                <Button
                    variant="text"
                    color="primary"
                    onClick={handleEditModalOpen}
                >
                    Edit
                </Button>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={editModalOpen}
                    onClose={handleEditModalClose}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                    }}
                >
                    <Fade in={editModalOpen}>
                        <Box sx={style}>
                            <Box sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "1rem",
                            }}>
                                <TextField
                                    id="filled-basic"
                                    label="Title"
                                    variant="filled"
                                    name='title'
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    sx={{
                                        flex: "1",
                                        maxWidth: "20rem",
                                    }}
                                />
                                <AddMemoDatePicker reminderDate={reminderDate} setReminderDate={setReminderDate} />
                            </Box>
                            <TextField
                                id="filled-basic"
                                label="Content"
                                variant="filled"
                                name='content'
                                value={content}
                                onChange={(e) => setContent(e.target.value)}
                                fullWidth={true}
                                multiline={true}
                                rows={5}
                                sx={{
                                    mt: "1rem",
                                    borderRadius: "5px",
                                }}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                    marginTop: "1rem",
                                    width: "10rem",
                                }}
                                onClick={handleMemoEdit}
                            >
                                Add Memo
                            </Button>
                        </Box>
                    </Fade>
                </Modal>

                {/* DELETE */}
                <Button
                    variant="text"
                    color="error"
                    onClick={handleClickDialogOpen}
                >
                    Delete
                </Button>
                <Dialog
                    open={dialogOpen}
                    onClose={handleDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                    {"Are you sure you want to delete this memo?"}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This action cannot be undone.
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleDialogClose}>Cancel</Button>
                    <Button onClick={(e) => deleteMemo(e, memo.id)} color="error" autoFocus>
                        Delete
                    </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Popover></>
    )
}

export default MemoVertIcon