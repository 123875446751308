import React from 'react'
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { Box, useTheme, Typography } from "@mui/material";
import { tokens } from "../../theme";
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import TransgenderOutlinedIcon from '@mui/icons-material/TransgenderOutlined';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import Axios from 'axios';

const EachMemoModal = ({ 
    open, 
    handleMemoModalClose, 
    reminder, 
    setNotificationRemoved, 
    getStudentNameWithConsultationNumberAndReminderTitle,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: colors.primary[750],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const removeReminder = async (event) => {
        event.preventDefault();
        const memo_id = reminder.id;

        await Axios.put(`${process.env.REACT_APP_URL}/api/v1/memos/${memo_id}/reminder`, {
            reminder: null,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setNotificationRemoved(true);
            handleMemoModalClose();
        }).catch((error) => {
            console.log(error.response.data.message);
        });
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleMemoModalClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                        {reminder.student.is_potential_student ? (
                             <Box sx={{ display: "flex", flexDirection: "column" }}>
                                <Typography variant="h4" color={colors.primary[450]}>
                                    {reminder.student.consultation_number} (Consultation number)
                                </Typography>
                                <Box sx={{ display: "flex", gap: 1, alignItems: "end" }}>
                                    <Box sx={{ display: "flex"}}>
                                        <Typography variant="h6" color={colors.grey[600]}>
                                            Parent:
                                        </Typography>
                                    </Box>
                                    {reminder.student.parent_email &&
                                        <Box sx={{ display: "flex", alignItems: "center", gap: "0.2rem" }}>
                                            <AlternateEmailOutlinedIcon fontSize="small" color="disabled" />
                                            <Typography variant="h6" color={colors.grey[600]}>
                                                {reminder.student.parent_email}
                                            </Typography>
                                        </Box>
                                    }
                                    {reminder.student.parent_phone &&
                                        <Box sx={{ display: "flex", alignItems: "center", gap: "0.2rem" }}>
                                            <PhoneIphoneOutlinedIcon fontSize="small" color='disabled' />
                                            <Typography variant="h6" color={colors.grey[600]}>
                                                {reminder.student.parent_phone}
                                            </Typography>
                                        </Box>
                                    }
                                    {reminder.student.parent_gender &&
                                        <Box sx={{ display: "flex", alignItems: "center", gap: "0.2rem" }}>
                                            <TransgenderOutlinedIcon fontSize="small" color='disabled' />
                                            <Typography variant="h6" color={colors.grey[600]}>
                                                {reminder.student.parent_gender}
                                            </Typography>
                                        </Box>
                                    }
                                </Box>
                            </Box>
                        ) : (
                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                <Typography variant="h4" color={colors.primary[450]} mb={1}>
                                    {getStudentNameWithConsultationNumberAndReminderTitle(reminder)}
                                </Typography>
                                <Box sx={{ display: "flex", gap: 1 }}>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <Typography variant="h6" color={colors.grey[600]}>
                                            Student:
                                        </Typography>
                                    </Box>
                                    {reminder.student.email &&
                                        <Box sx={{ display: "flex", alignItems: "center", gap: "0.2rem" }}>
                                            <AlternateEmailOutlinedIcon fontSize="small" color="disabled" />
                                            <Typography variant="h6" color={colors.grey[600]}>
                                                {reminder.student.email}
                                            </Typography>
                                        </Box>
                                    }
                                    {reminder.student.phone &&
                                        <Box sx={{ display: "flex", alignItems: "center", gap: "0.2rem" }}>
                                            <PhoneIphoneOutlinedIcon fontSize="small" color='disabled' />
                                            <Typography variant="h6" color={colors.grey[600]}>
                                                {reminder.student.phone}
                                            </Typography>
                                        </Box>
                                    }
                                </Box>
                                <Box sx={{ display: "flex", gap: 1, alignItems: "end" }}>
                                    <Box sx={{ display: "flex"}}>
                                        <Typography variant="h6" color={colors.grey[600]}>
                                            Parent:
                                        </Typography>
                                    </Box>
                                    {reminder.student.parent_email &&
                                        <Box sx={{ display: "flex", alignItems: "center", gap: "0.2rem" }}>
                                            <AlternateEmailOutlinedIcon fontSize="small" color="disabled" />
                                            <Typography variant="h6" color={colors.grey[600]}>
                                                {reminder.student.parent_email}
                                            </Typography>
                                        </Box>
                                    }
                                    {reminder.student.parent_phone &&
                                        <Box sx={{ display: "flex", alignItems: "center", gap: "0.2rem" }}>
                                            <PhoneIphoneOutlinedIcon fontSize="small" color='disabled' />
                                            <Typography variant="h6" color={colors.grey[600]}>
                                                {reminder.student.parent_phone}
                                            </Typography>
                                        </Box>
                                    }
                                    {reminder.student.parent_gender &&
                                        <Box sx={{ display: "flex", alignItems: "center", gap: "0.2rem" }}>
                                            <TransgenderOutlinedIcon fontSize="small" color='disabled' />
                                            <Typography variant="h6" color={colors.grey[600]}>
                                                {reminder.student.parent_gender}
                                            </Typography>
                                        </Box>
                                    }
                                </Box>
                            </Box>
                        )}
                    <Box display="flex" flexDirection="column" gap={1} width="100%" pt={2}>
                        <Box 
                            sx={{ 
                                width: "100%", 
                                minHeight: "5rem", 
                                p: 2, 
                                borderRadius: 
                                "5px", 
                                backgroundColor: colors.primary[600],
                                border: "1px solid",
                            }}
                        >
                            <Typography variant="body1" color={colors.grey[600]}>
                                Created on: {dayjs(reminder.createdAt).format('YYYY-MM-DD')}
                                <br />
                                Reminder Activation Date: {dayjs(reminder.reminder).format('YYYY-MM-DD')}
                                <br /><br />
                            </Typography>
                            <Typography variant="body1" fontWeight="bold">
                                Title: {reminder.title}
                            </Typography>
                            < br/>
                            <Typography variant="body1">
                                {reminder.content && reminder.content.split('\n').map((line, index) => {
                                    return (
                                        <span key={index}>
                                            {line}
                                            <br />
                                        </span>
                                    )
                                })}
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "end" }}>
                        <Button color="secondary" variant="contained" onClick={(e) => removeReminder(e)} sx={{ width: "14rem", mt: 3}} >
                            Remove this notification
                        </Button>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    )
}

export default EachMemoModal