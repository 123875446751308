import dayjs from 'dayjs';

const subjectCategories = [
    { value: 'All', label: 'All' },
    { value: 'Biology', label: 'Biology' },
    { value: 'Chemistry', label: 'Chemistry' },
    { value: 'Physics', label: 'Physics' },
    { value: 'Computer Science', label: 'Computer Science' },
    { value: 'Economics', label: 'Economics' },
    { value: 'English', label: 'English' },
    { value: 'History', label: 'History' },
    { value: 'Math', label: 'Math' },
    { value: 'Research', label: 'Research' },
    { value: 'Writing Competition', label: 'Writing Competition' },
]

const grades = [
    { value: 0, label: 'All' },
    { value: 12, label: '12' },
    { value: 11, label: '11' },
    { value: 10, label: '10' },
    { value: 9, label: '9' },
    { value: 8, label: '8' },
    { value: 7, label: '7' },
    { value: 6, label: '6' },
    { value: 5, label: '5' },
    { value: 4, label: '4' },
    { value: 3, label: '3' },
    { value: 2, label: '2' },
    { value: 1, label: '1' }
]

const states = [
    { value: 'ALL', label: 'All' },
    { value: 'AL', label: 'Alabama' },
    { value: 'AK', label: 'Alaska' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'AR', label: 'Arkansas' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'CT', label: 'Connecticut' },
    { value: 'DE', label: 'Delaware' },
    { value: 'FL', label: 'Florida' },
    { value: 'GA', label: 'Georgia' },
    { value: 'HI', label: 'Hawaii' },
    { value: 'ID', label: 'Idaho' },
    { value: 'IL', label: 'Illinois' },
    { value: 'IN', label: 'Indiana' },
    { value: 'IA', label: 'Iowa' },
    { value: 'KS', label: 'Kansas' },
    { value: 'KY', label: 'Kentucky' },
    { value: 'LA', label: 'Louisiana' },
    { value: 'ME', label: 'Maine' },
    { value: 'MD', label: 'Maryland' },
    { value: 'MA', label: 'Massachusetts' },
    { value: 'MI', label: 'Michigan' },
    { value: 'MN', label: 'Minnesota' },
    { value: 'MS', label: 'Mississippi' },
    { value: 'MO', label: 'Missouri' },
    { value: 'MT', label: 'Montana' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'NJ', label: 'New Jersey' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'NY', label: 'New York' },
    { value: 'NC', label: 'North Carolina' },
    { value: 'ND', label: 'North Dakota' },
    { value: 'OH', label: 'Ohio' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'PA', label: 'Pennsylvania' },
    { value: 'RI', label: 'Rhode Island' },
    { value: 'SC', label: 'South Carolina' },
    { value: 'SD', label: 'South Dakota' },
    { value: 'TN', label: 'Tennessee' },
    { value: 'TX', label: 'Texas' },
    { value: 'UT', label: 'Utah' },
    { value: 'VT', label: 'Vermont' },
    { value: 'VA', label: 'Virginia' },
    { value: 'WA', label: 'Washington' },
    { value: 'WV', label: 'West Virginia' },
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WY', label: 'Wyoming' },
    { value: 'DC', label: 'District of Columbia' },
    { value: 'AS', label: 'American Samoa' },
    { value: 'GU', label: 'Guam' },
    { value: 'MP', label: 'Northern Mariana Islands' },
    { value: 'PR', label: 'Puerto Rico' },
    { value: 'UM', label: 'United States Minor Outlying Islands' },
    { value: 'VI', label: 'Virgin Islands, U.S.' }
]

const registrationFilter = [
    { value: 'all', label: 'All' },
    { value: 'registered', label: 'Registered' },
    { value: 'not registered', label: 'Not Registered' },
    { value: 'blacklist', label: 'Blacklist' }
]

const emailStatusFilter = [
    { value: 'all', label: 'All' },
    { value: 'is_sent', label: '신규문의' },
    { value: 'is_requested', label: '재문의' }
]

const studentEmailStatusFilter = [
    { value: 'all', label: 'All' },
    { value: 'is_requested', label: '재문의' }
]

const potentialStudentsFilter = [
    { value: 'all', label: 'All' },
    { value: 'blacklist', label: 'Blacklist' }
]

const subjects = [
    { value: 'all', label: 'All' },
    { value: 'Math', label: 'Math' },
    { value: 'English', label: 'English' }
]

const semesters = [
    { value: 'all', label: 'All' },
    { value: 'Spring', label: 'Spring' },
    { value: 'Summer', label: 'Summer' },
    { value: 'Fall', label: 'Fall' },
    { value: 'Winter', label: 'Winter' }
]

const semestersForAdminHome = [
    { value: 'all', label: 'All' },
    { value: 'Spring', label: 'Spring' },
    { value: 'Summer', label: 'Summer' },
    { value: 'Fall', label: 'Fall' },
]

const years = [];
const currentYear = dayjs().year();
const startYear = currentYear - 8;
for (let i = 9; i >= 0; i--) {
    years.push({ value: startYear + i, label: startYear + i });
}

const generateYears = (minYear, maxYear) => {
    const years = [];
    years.push({ value: 0, label: 'All' });
    for (let i = maxYear; i >= minYear; i--) {
        years.push({ value: i, label: i });
    }
    return years;
}


export { subjectCategories, grades, states, registrationFilter, potentialStudentsFilter, subjects, semesters, years, semestersForAdminHome, generateYears, emailStatusFilter, studentEmailStatusFilter }