import * as React from 'react';
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import ModalTabs from '../students/moreInfo/ModalTabs';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';

const StudentCardModal = ({ 
    student, 
    setStudentUpdated, 
    modalOpen, 
    setModalOpen,
    setSuccessAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertOpen,
    setErrorAlertMessage
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1000,
        height: 800,
        bgcolor: colors.primary[600],
        border: '2px solid grey',
        boxShadow: 24,
        p: 4,
    };
    

    const handleModalClose = () => setModalOpen(false);

    return (
        <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                  backdrop: {
                    timeout: 500,
                  },
                }}
            >
                <Fade in={modalOpen}>
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h1" ml={1} borderBottom={1}>
                            {student.last_name}, {student.first_name}
                        </Typography>
                        <Typography id="modal-modal-description" variant="h6" mt={1} mb={1} ml={1} color={"grey"}>
                            <SchoolRoundedIcon style={{marginBottom: "-5px", marginRight: "5px"}} /> {student.school} (Grade: {student.grade})
                        </Typography>
                        <ModalTabs 
                            student={student} 
                            setStudentUpdated={setStudentUpdated} 
                            setErrorAlertMessage={setErrorAlertMessage}
                            setErrorAlertOpen={setErrorAlertOpen}
                            setSuccessAlertOpen={setSuccessAlertOpen}
                            setSuccessAlertMessage={setSuccessAlertMessage}
                        />
                    </Box>
                </Fade>
            </Modal>
    )
}

export default StudentCardModal