import React from 'react';
import Button from '@mui/material/Button';
import AreYouSureModal from '../../../components/AreYouSureModal';


function SubstituteTeacherButton({
    zoom_id,
    date,
    _time,
    event_idx,
    event,
    enqueueSnackbar,
    colors,
    handlePopOverClose,
    socketConnected,
    socket,
    teacher_course,
    getTeacherName,
}) {

    const [areYouSureModalOpen, setAreYouSureModalOpen] = React.useState(false);
    const [isSubstituteTeacher, setIsSubstituteTeacher] = React.useState(
        event.substitute_teacher_id && teacher_course && event.substitute_teacher_id === teacher_course.teacher_id ? true : false
    );

    async function handleSave() {

        if (!socketConnected) {
            enqueueSnackbar("Error: Connection to server lost. Please refresh the page.", { variant: "error" });
            return;
        }

        try {
            socket.emit("put.SubstituteTeacher", {
                zoom_id: zoom_id,
                date: date,
                _time: _time,
                event_idx: event_idx,
                event: event,
                substitute_teacher_id: teacher_course.teacher_id,
                is_substitute_teacher: !isSubstituteTeacher,
            });
        } catch (error) {
            console.error(error);
            enqueueSnackbar("Error: Unable to set or unset substitute teacher. Please refresh the page and try again.", { variant: "error" });
        }

        handlePopOverClose();
    };

    return (
        <>
            <Button 
                onClick={() => {
                    if (!isSubstituteTeacher && event.substitute_teacher_id) {
                        setAreYouSureModalOpen(true);
                        return;
                    }

                    handleSave();
                }}
                sx={{ 
                    p: 1, 
                    width: "100%",
                    bgcolor: colors.yellowAccent[800],
                    borderRadius: "0px",
                    '&:hover': {
                        bgcolor: colors.yellowAccent[900],
                    },
                }}
            >
                {isSubstituteTeacher ? "Unset Substitute Teacher" : "Set Substitute Teacher"}
            </Button>
            <AreYouSureModal
                colors={colors}
                open={areYouSureModalOpen}
                setOpen={setAreYouSureModalOpen}
                title="Replace Substitute Teacher"
                message={"Substitute teacher is already set for this class session. Are you sure you want to set " + getTeacherName(teacher_course) + " as the substitute teacher?"}
                onConfirm={handleSave}
            />
        </>
    )
}

export default SubstituteTeacherButton;