import React from 'react'
import Button from '@mui/material/Button';

const GoToOneDriveButton = ({ colors, course }) => {

    return (
        <Button 
            component='a'
            href={course.one_drive_url ? course.one_drive_url : '/'}
            target='_blank'
            rel='noopener noreferrer'
            color='primary'
            sx={{ 
                p: 1, 
                width: "100%",
                bgcolor: colors.blueAccent[800],
                borderRadius: "0px",
            }}
        >
            Go to One Drive Doc
        </Button>
    )
}

export default GoToOneDriveButton