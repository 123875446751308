import React from 'react';
import Button from '@mui/material/Button';
import { Box, TextField, Typography } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CloseIcon from '@mui/icons-material/Close';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
// import AreYouSureModal from '../../../components/AreYouSureModal';


function BulkAttendanceControl({
    userId,
    attendances,
    enqueueSnackbar,
    colors,
    socketConnected,
    socket,
    setBackdropOpen,
}) {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        maxHeight: '96%',
        overflowY: 'auto',
        bgcolor: colors.primary[600],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    
    const [status, setStatus] = React.useState(null);
    const [note, setNote] = React.useState("");
    const [needZoomRecording, setNeedZoomRecording] = React.useState(false);

    // const [areYouSureModalOpen, setAreYouSureModalOpen] = React.useState(false);

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        if (!attendances || attendances.length === 0) {
            enqueueSnackbar("Error: No student selected.", { variant: "error" });
            return;
        }
        setOpen(true)
    };
    const handleClose = () => { setOpen(false); };

    function handleChange(e, newStatus) {
        e.preventDefault();
        setStatus(newStatus);

        if (newStatus === 4) {
            setNeedZoomRecording(true);
        }
    };

    async function handleSave() {
        try {
            // emit to server
            attendances.forEach((attendance) => {
                socket.emit("put.AttendanceUpdate", {
                    id: attendance.id,
                    status: status,
                    note: note,
                    need_zoom_recording: needZoomRecording,
                    course_id: attendance.course_id,
                });
            });
        } catch (error) {
            console.error(error);
            enqueueSnackbar("Error: Cannot save the changes. Please try again.", { variant: "error" });
            return;
        }

        handleClose();
    };

    return (
        <>
            <Button 
                onClick={handleOpen} 
                sx={{ 
                    flex: 2,
                    width: "100%",
                    height: "100%",
                    bgcolor: colors.greenAccent[800],
                    borderRadius: "0px",
                    textTransform: "none",
                    '&:hover': {
                        bgcolor: colors.greenAccent[900],
                    },
                }}
            >
                Update {attendances.length} {attendances.length > 1 ? "Attendances" : "Attendance"}
            </Button>
            <Modal
                aria-labelledby="attendance-modal"
                aria-describedby="attendance-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography variant="h4" color={colors.primary[450]}>
                            Attendance ({attendances.length} {attendances.length > 1 ? "students selected" : "student selected"})
                        </Typography>
                        <CloseIcon sx={{ position: "absolute", right: 10, top: 10, cursor: "pointer" }} onClick={handleClose} />
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", mt: 2 }}>
                            <FormGroup row>
                                <FormControlLabel 
                                    sx={{
                                        color: colors.grey[600],
                                    }}
                                    control={
                                        <Checkbox
                                            checked={status === null}
                                            onChange={(e) => handleChange(e, null)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            sx={{
                                                color: colors.grey[600],
                                                '&.Mui-checked': {
                                                    color: colors.grey[600],
                                                },
                                            }}
                                        />
                                    } 
                                    label="Undecide" 
                                    labelPlacement="top"
                                />
                                <FormControlLabel 
                                    sx={{
                                        color: colors.greenAccent[300],
                                    }}
                                    control={
                                        <Checkbox
                                            checked={status === 1}
                                            onChange={(e) => handleChange(e, 1)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            sx={{
                                                color: colors.greenAccent[300],
                                                '&.Mui-checked': {
                                                    color: colors.greenAccent[300],
                                                },
                                            }}
                                        />
                                    } 
                                    label="Present"
                                    labelPlacement="top"
                                />
                                <FormControlLabel 
                                    sx={{
                                        color: "#ff8f00",
                                    }}
                                    control={
                                        <Checkbox
                                            checked={status === 2}
                                            onChange={(e) => handleChange(e, 2)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            sx={{
                                                color: "#ff8f00",
                                                '&.Mui-checked': {
                                                    color: "#ff8f00",
                                                },
                                            }}
                                        />
                                    } 
                                    label="Late"
                                    labelPlacement="top"
                                />
                                <FormControlLabel 
                                    sx={{
                                        color: colors.redAccent[500],
                                    }}
                                    control={
                                        <Checkbox
                                            checked={status === 4}
                                            onChange={(e) => handleChange(e, 4)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            sx={{
                                                color: colors.redAccent[500],
                                                '&.Mui-checked': {
                                                    color: colors.redAccent[500],
                                                },
                                            }}
                                        />
                                    } 
                                    label="Absent (Group)"
                                    labelPlacement="top"
                                />
                            </FormGroup>
                            <TextField
                                label="Note"
                                multiline
                                rows={5}
                                variant="outlined"
                                value={note}
                                onChange={(e) => setNote(e.target.value)}
                                sx={{ width: 400, mt: 2 }}
                            />
                            <FormControlLabel 
                                control={
                                    <Checkbox
                                        checked={needZoomRecording}
                                        onChange={(e) => setNeedZoomRecording(e.target.checked)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                } 
                                label="Please check if this student needs a Zoom recording."
                            />
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: 2,
                                mt: 2,
                            }}
                        >
                            <Button
                                variant="contained"
                                color="secondary"
                                sx={{ width: 200, mt: 2 }}
                                onClick={() => handleSave()}
                            >
                                Save
                            </Button>
                            {/* <AreYouSureModal
                                colors={colors}
                                open={areYouSureModalOpen}
                                setOpen={setAreYouSureModalOpen}
                                title="Are you sure?"
                                message="Are you sure you want to save the changes?"
                                onConfirm={handleSave}
                            /> */}
                            <Button
                                variant="contained"
                                color="error"
                                sx={{ width: 200, mt: 2 }}
                                onClick={handleClose}
                            >
                                Close
                            </Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </>
    )
}

export default BulkAttendanceControl