import React from 'react'
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import { Box, useTheme, Typography, List, ListItem } from "@mui/material";
import { tokens } from "../../theme";
import Axios from 'axios';
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "lightblue",

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    // background: isDraggingOver ? "lightgrey" : "lightgrey",
    border: isDraggingOver ? "2px solid #000" : "2px solid #000",
    borderRadius: 4,
    padding: grid,
});

const ZoomOrderModal = ({ 
    zooms, 
    setSuccessAlertOpen,
    setErrorAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertMessage,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [zoomList, setZoomList] = React.useState([]);
    const [zoomRoomMap, setZoomRoomMap] = React.useState({});


    const [loading, setLoading] = React.useState(false);

    // for modal
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => { setOpen(false); };

    const handleSave = async (e) => {
        e.preventDefault();

        await Axios.put(`${process.env.REACT_APP_URL}/api/v1/zooms/weekly/order`, {
            order_list: zoomList,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then(async (response) => {
            setSuccessAlertMessage("Zoom Order Updated Successfully");
            setSuccessAlertOpen(true);
            handleClose();
        }).catch((error) => {
            setErrorAlertMessage(error.response.data.message || "Error: Cannot update the zoom order. Please try again.");
            setErrorAlertOpen(true);
        });
    };

    React.useEffect(() => {
        if (!zooms || zooms.length === 0 || !open) return;

        setLoading(true);

        const zoomMap = {};
        for (const zoom of zooms) {
            zoomMap[zoom.id] = zoom;
        }

        const list = zooms.map((zoom) => {
            return zoom.id;
        });
        // sort by order
        list.sort((a, b) => zoomMap[a].order - zoomMap[b].order);
        
        setZoomList(list);
        setZoomRoomMap(zoomMap);

        setLoading(false);
    }, [zooms, open]);

    const handleOnDragEnd = (result) => {
        const { destination, source } = result;


        if (!destination) return;

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        const items = Array.from(zoomList);
        const [removed] = items.splice(source.index, 1);
        items.splice(destination.index, 0, removed);

        setZoomList(items);
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        maxHeight: "80vh",
        overflowY: "auto",
        bgcolor: colors.primary[600],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <Box display="flex" mb={2} ml={2}>
            <Button 
                color="primary" 
                variant="contained" 
                onClick={handleOpen}
                sx={{ 
                    ":hover": {
                        backgroundColor: colors.greenAccent[500],
                        color: colors.primary[400],
                    }
                }}
            >
                Zoom Order for Weekly
            </Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography variant="h4" color={colors.primary[450]}>
                            Zoom Order for Weekly
                        </Typography>
                        <Box display="flex" flexDirection="column" gap={3} width="100%" pt={2}>
                            <Box display="flex" flexDirection="column">
                                {loading 
                                ? 
                                    <Typography>Loading...</Typography>
                                : (
                                    <DragDropContext onDragEnd={handleOnDragEnd}>
                                        <Droppable 
                                            droppableId="droppable"
                                            renderClone={(provided, snapshot, rubric) => (
                                                <ListItem 
                                                    ref={provided.innerRef} 
                                                    {...provided.draggableProps} 
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}                                                    
                                                >
                                                    Zoom
                                                </ListItem>
                                            )}
                                        >
                                            {(provided, snapshot) => (
                                                <List
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                    style={getListStyle(snapshot.isDraggingOver)}
                                                >
                                                    {zoomList && zoomList.length > 0 && zoomList.map((zoomId, index) => {
                                                        const zoom = zoomRoomMap[zoomId];

                                                        return (
                                                            <Draggable 
                                                                key={zoomId} 
                                                                draggableId={zoomId.toString()} 
                                                                index={index}
                                                                style={(_isDragging, draggableStyle) => ({ ...draggableStyle, position: 'static' })}
                                                            >
                                                                {(provided, snapshot) => (
                                                                    <ListItem 
                                                                        ref={provided.innerRef} 
                                                                        {...provided.draggableProps} 
                                                                        {...provided.dragHandleProps}
                                                                        style={getItemStyle(
                                                                            snapshot.isDragging,
                                                                            provided.draggableProps.style
                                                                        )}                                                    
                                                                    >
                                                                        Zoom {zoom.room_number} {zoom.remarks}
                                                                    </ListItem>
                                                                )}
                                                            </Draggable>
                                                        );
                                                    })}
                                                    {provided.placeholder}
                                                </List>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                )}
                            </Box>
                            <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                sx={{ width: "7rem" }}
                                onClick={handleSave}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </Box>
    )
}

export default ZoomOrderModal