import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Box, Typography } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
// import AreYouSureModal from '../../../components/AreYouSureModal';


function ModuleZoomAssignButton({
    zooms,
    userId,
    zoom_id,
    date,
    _time,
    event_idx,
    event,
    colors, 
    handlePopOverClose,
    enqueueSnackbar,
    socketConnected,
    socket,
}) {
    React.useEffect(() => {
        if (event) {
            setZoom(event.zoom_id);
        }
    }, [event]);

    // for modal
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {setOpen(false); handlePopOverClose();};

    // const [areYouSureModalOpen, setAreYouSureModalOpen] = React.useState(false);

    // for zoom
    const [zoom, setZoom] = React.useState(zoom_id);

    const zoomsListWithNull = zooms !== null ? [{id: 0, room_number: "None"}, ...zooms] : [];

    // for bypass
    const [bypass, setBypass] = React.useState(false);
    const handleBypassChange = (event) => {
        setBypass(event.target.checked);
    };
    
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: colors.primary[600],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    
    const handleAssign = async () => {
        if (zoom === null) {
            enqueueSnackbar("Please select zoom", { variant: "error" });
            return;
        } else if (event.zoom_id === zoom) {
            handleClose();
            return;
        }

        if (!socketConnected) {
            enqueueSnackbar("Socket is not connected. Please refresh the page.", { variant: "error" });
            return;
        }

        try {            
            setTimeout(() => {
                // emit to server
                socket.emit("put.EventZoomAssign", {
                    zoom_room_number: zoom === 0 ? null : zooms.find(z => z.id === zoom).room_number,
                    zoom_id: zoom_id,
                    date: date,
                    _time: _time,
                    event_idx: event_idx,
                    event: event,
                    new_zoom_id: zoom === 0 ? null : zoom,
                    bypass: bypass,
                });
            }, 300);
        } catch (error) {
            console.error(error);
            enqueueSnackbar("Error: Cannot save the changes. Please try again.", { variant: "error" });
            return;
        }

        handleClose();
    };
    
    
    return (
        <div>
            <Button 
                onClick={handleOpen} 
                sx={{ 
                    p: 1, 
                    width: "100%",
                    borderRadius: "0px",
                    bgcolor: colors.purpleAccent[900],
                    '&:hover': {
                        bgcolor: colors.purpleAccent[800],
                    },
                }}
            >
                Assign Zoom
            </Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography variant="h4" color={colors.primary[450]} mb={2}>
                            Assign Zoom to Class Module {event.title? event.title.split(" ")[event.title.split(" ").length - 1] : ""}
                        </Typography>
                        <Box display="flex" flexDirection="column" gap={3} width="100%" pt={2}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo-1"
                                getOptionLabel={(option) => option.id === 0 
                                    ? option.room_number.toString() 
                                    : "Zoom " + option.room_number.toString()
                                }
                                options={zooms !== null ? zoomsListWithNull : []}
                                renderInput={(params) => <TextField {...params} label="Select Zoom" />}
                                onChange={(event, value) => value !== null ? setZoom(value.id) : setZoom(null)} 
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                sx={{ width: "100%" }}
                            />
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                }}
                            >
                                <Typography variant="body" color='error'>
                                    Check if you want to ignore schedule conflict and assign the zoom anyway
                                </Typography>
                                <Checkbox
                                    checked={bypass}
                                    onChange={handleBypassChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    color='error'
                                    sx={{
                                        padding: "0",
                                        mt: "0.5rem",
                                    }}
                                />
                            </Box>
                            <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                sx={{ width: "7rem" }}
                                onClick={() => handleAssign()}
                            >
                                Assign
                            </Button>
                            {/* <AreYouSureModal
                                colors={colors}
                                open={areYouSureModalOpen}
                                setOpen={setAreYouSureModalOpen}
                                title="Are you sure?"
                                message="Are you sure you want to assign the zoom to this class module?"
                                onConfirm={handleAssign}
                            /> */}
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </div>
    )
}

export default ModuleZoomAssignButton