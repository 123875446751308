export default (student) => {
    function getStudentName(student) {
        let student_name = "";
        if (student.first_name && student.first_name !== "") {
            student_name += student.first_name;
        }
        if (student.korean_name && student.korean_name !== "") {
            student_name += " " + student.korean_name;
        }
        if (student.last_name && student.last_name !== "") {
            student_name += " " + student.last_name;
        }
        if (student_name === "") {
            student_name = "Unknown";
        }

        return student_name;
    }

    return getStudentName(student);
}