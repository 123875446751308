import React from 'react';
import Button from '@mui/material/Button';
import { Box, TextField, Typography } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CloseIcon from '@mui/icons-material/Close';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
// import AreYouSureModal from '../../../components/AreYouSureModal';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DateTime, Settings } from 'luxon';
import getCountryPhoneCode from '../../../utils/getCountryPhoneCode';

Settings.defaultZone = "America/New_York";


function BulkSendMessageControl({
    admin_name,
    userId,
    attendances,
    teacher_courses,
    enqueueSnackbar,
    colors,
    socketConnected,
    socket,
    setBackdropOpen,
    mobileMessageTemplates,
}) {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1000,
        maxHeight: '96%',
        overflowY: "auto",
        bgcolor: colors.primary[600],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    
    const [message, setMessage] = React.useState("");
    const [messageLength, setMessageLength] = React.useState(0);
    const [selectedPhoneNumbers, setSelectedPhoneNumbers] = React.useState([]);
    const [messageTemplates, setMessageTemplates] = React.useState([]);
    const [activeTemplates, setActiveTemplates] = React.useState([]);
    const [hideAll, setHideAll] = React.useState(true);

    const [isScheduled, setIsScheduled] = React.useState(false);
    const [scheduledDate, setScheduledDate] = React.useState(DateTime.now());
    const [memo, setMemo] = React.useState("");

    // const [areYouSureModalOpen, setAreYouSureModalOpen] = React.useState(false);

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        if ((!attendances || attendances.length === 0) && (!teacher_courses || teacher_courses.length === 0)) {
            enqueueSnackbar("Error: No students or teachers selected.", { variant: "error" });
            return;
        }
        setOpen(true)
    };
    const handleClose = () => { 
        setMessage("");
        setSelectedPhoneNumbers([]);
        setOpen(false); 
    };

    React.useEffect(() => {
        if (mobileMessageTemplates) {
            setMessageTemplates(mobileMessageTemplates);
        }
    }, [mobileMessageTemplates]);

    function handleChange(e, obj) {
        e.preventDefault();

        obj.country_phone_code = getCountryPhoneCode(obj.country_phone_code).replace(/-/g, "").replace(/ /g, "").replace(/\(/g, "").replace(/\)/g, "");

        if (e.target.checked) {
            setSelectedPhoneNumbers([...selectedPhoneNumbers, obj]);
        } else {
            if (obj.attendance_id) {
                // if phone and attendance_id are the same, remove the object from the array
                setSelectedPhoneNumbers(selectedPhoneNumbers.filter((selectedPhoneNumber) => {
                    console.log(selectedPhoneNumber.phone, obj.phone, selectedPhoneNumber.attendance_id, obj.attendance_id);
                    return selectedPhoneNumber.phone !== obj.phone || selectedPhoneNumber.attendance_id !== obj.attendance_id || selectedPhoneNumber.phone_type !== obj.phone_type;
                }));
            } else if (obj.teacher_course_id) {
                setSelectedPhoneNumbers(selectedPhoneNumbers.filter((selectedPhoneNumber) => {
                    return selectedPhoneNumber.phone !== obj.phone || selectedPhoneNumber.teacher_course_id !== obj.teacher_course_id || selectedPhoneNumber.phone_type !== obj.phone_type;
                }));
            }
        }
    };

    async function handleSend() {
        if (!socketConnected) {
            enqueueSnackbar("Error: Server is not connected. Please try again later.", { variant: "error" });
            return;
        }

        if (!selectedPhoneNumbers || selectedPhoneNumbers.length === 0) {
            enqueueSnackbar("Error: No phone number selected.", { variant: "error" });
            return;
        }

        if (!message || message === "") {
            enqueueSnackbar("Error: Message is empty.", { variant: "error" });
            return;
        }

        if (message.length > 160) {     
            enqueueSnackbar("Error: Message is too long. Please keep it within 160 characters.", { variant: "error" });
            return;
        }

        if (isScheduled && scheduledDate < DateTime.now()) {
            enqueueSnackbar("Scheduled date cannot be in the past.", { variant: "error" });
            return;
        }

        // We restrict the scheduled date to be within 2 weeks
        // This is to prevent message queue from storing too many messages
        if (isScheduled && scheduledDate > DateTime.now().plus({ weeks: 2 })) {
            enqueueSnackbar("Scheduled date cannot be more than 2 weeks in the future.", { variant: "error" });
            return;
        }

        // set 0 seconds and 0 milliseconds
        const scheduled_date = scheduledDate.set({ second: 0, millisecond: 0 });

        const dict = {}; // for students
        const dict_teacher = {}; // for teachers
        try {
            selectedPhoneNumbers.forEach((selectedPhoneNumber) => {
                
                const attendance = attendances.find((attendance) => {
                    return attendance.id === selectedPhoneNumber.attendance_id;
                });
                const teacher_course = teacher_courses.find((teacher_course) => {
                    return teacher_course.id === selectedPhoneNumber.teacher_course_id;
                });

                if (attendance) {
                    if (!dict[attendance.id]) {
                        dict[attendance.id] = { attendance: attendance, phone_numbers: [] };
                    }
                    dict[attendance.id].phone_numbers.push(
                        selectedPhoneNumber.country_phone_code + selectedPhoneNumber.phone.replace(/-/g, "").replace(/ /g, "").replace(/\(/g, "").replace(/\)/g, "")
                    );
                } else if (teacher_course) {
                    if (!dict_teacher[teacher_course.id]) {
                        dict_teacher[teacher_course.id] = { teacher_course: teacher_course, phone_numbers: [] };
                    }
                    dict_teacher[teacher_course.id].phone_numbers.push(
                        selectedPhoneNumber.country_phone_code + selectedPhoneNumber.phone.replace(/-/g, "").replace(/ /g, "").replace(/\(/g, "").replace(/\)/g, "")
                    );
                }
            });
        } catch (error) {
            console.error(error);
            enqueueSnackbar("Error: Cannot send the message. Please try again.", { variant: "error" });
            return;
        }

        let sent_first_class_reminder = false;
        let sent_makeup_reminder = false;
        let sent_cancel_reminder = false;

        if (activeTemplates.length > 0) {
            activeTemplates.forEach((template) => {
                if (template.is_cancel_reminder_students || template.is_cancel_reminder_parents) {
                    sent_cancel_reminder = true;
                } else if (template.is_first_class_reminder_students || template.is_first_class_reminder_parents) {
                    sent_first_class_reminder = true;
                } else if (template.is_makeup_class_reminder_students || template.is_makeup_class_reminder_parents) {
                    sent_makeup_reminder = true;
                }
            });
        }

        Object.values(dict).forEach((value) => {
            try {
                // emit to server
                socket.emit("send.MobileMessageStudent", {
                    phone_numbers: value.phone_numbers,
                    message: message,
                    student: value.attendance.student,
                    attendance_id: value.attendance.id,
                    sent_cancel_reminder: sent_cancel_reminder,
                    sent_first_class_reminder: sent_first_class_reminder,
                    sent_makeup_reminder: sent_makeup_reminder,
                    is_scheduled: isScheduled,
                    scheduled_date: isScheduled ? scheduled_date.toISO() : null,
                    memo: memo,
                    admin_name: admin_name,
                });
            } catch (error) {
                console.error(error);
                enqueueSnackbar("Error: Cannot send the message to " + getStudentName(value.attendance) + ". Please try again.", { variant: "error" });
            }
        });

        Object.values(dict_teacher).forEach((value) => {
            try {
                // emit to server
                socket.emit("send.MobileMessageTeacher", {
                    phone_numbers: value.phone_numbers,
                    message: message,
                    teacher: value.teacher_course.teacher,
                    event_id: value.teacher_course.event_id,
                    is_scheduled: isScheduled,
                    scheduled_date: isScheduled ? scheduled_date.toISO() : null,
                    memo: memo,
                    admin_name: admin_name,
                });
            } catch (error) {
                console.error(error);
                enqueueSnackbar("Error: Cannot send the message to " + value.teacher_course.teacher.first_name + " " + value.teacher_course.teacher.last_name + ". Please try again.", { variant: "error" });
            }
        });

        handleClose();
    };

    function getStudentName(attendance) {
        let student_name = "";

        if (!attendance.student) {
            student_name += "Name Unknown";
        } else if (attendance.student.first_name && attendance.student.last_name) {
            student_name += attendance.student.first_name + " " + attendance.student.last_name;
        } else if (attendance.student.first_name) {
            student_name += attendance.student.first_name;
        } else if (attendance.student.last_name) {
            student_name += attendance.student.last_name;
        } else if (attendance.student.consultation_number) {
            student_name += attendance.student.consultation_number;
        }

        return student_name;
    }

    const handleMessageTemplateButtonClick = (template) => {
        const message = template.template;
        
        if (activeTemplates.includes(template)) {
            setActiveTemplates(activeTemplates.filter((item) => item !== template));
        } else {
            setActiveTemplates([...activeTemplates, template]);
            setMessage((prev) => {
                if (prev === "") {
                    setMessageLength(message.length);
                    return message;
                }
                setMessageLength(prev.length + message.length + 1);
                return prev + "\n" + message;
            });
        }
    };

    function getBgcolor(template) {

        if (!activeTemplates.includes(template)) {
            return colors.primary[800];
        }

        if ((template.is_cancel_reminder_students || template.is_cancel_reminder_parents)) {
            return colors.redAccent[600];
        } else if ((template.is_first_class_reminder_students || template.is_first_class_reminder_parents)) {
            return colors.blueAccent[600];
        } else if ((template.is_makeup_class_reminder_students || template.is_makeup_class_reminder_parents)) {
            return colors.greenAccent[600];
        } else if ((template.is_homework_reminder_students || template.is_homework_reminder_parents)) {
            return colors.purpleAccent[800];
        } else if ((template.is_zoom_recording_reminder_students || template.is_zoom_recording_reminder_parents)) {
            return colors.yellowAccent[600];
        } else {
            // late or others
            return colors.blueAccent[900];
        }
    }

    function notShowingUnneededTemplates(template) {
        if (teacher_courses.length > 0 && attendances.length === 0) {
            // teacher
            // so we don't need student and parents templates
            if (template.is_first_class_reminder_students || template.is_first_class_reminder_parents
                || template.is_cancel_reminder_students || template.is_cancel_reminder_parents
                || template.is_makeup_class_reminder_students || template.is_makeup_class_reminder_parents
                || template.is_homework_reminder_students || template.is_homework_reminder_parents
                || template.is_zoom_recording_reminder_students || template.is_zoom_recording_reminder_parents
                || template.is_late_attendance_students || template.is_late_attendance_parents) {

                return false;
            }
        } else if (attendances.length > 0 && teacher_courses.length === 0) {
            // student
            // so we don't need teacher templates
            if (template.is_first_class_reminder_teachers || template.is_first_class_reminder_teachers
                || template.is_makeup_class_reminder_teachers || template.is_makeup_class_reminder_teachers
                || template.is_cancel_reminder_teachers || template.is_cancel_reminder_teachers) {
                
                return false;
            }
        }

        return true;
    }

    // function getAreYouSureMessage() {
    //     let message = "";
    //     if (activeTemplates.length > 1) {
    //         message += "You have selected multiple templates. ";
    //     }

    //     message += "Are you sure you want to send the message?";

    //     if (isScheduled) {
    //         message += " (The message is scheduled for " + scheduledDate.toLocaleString(DateTime.DATETIME_FULL) + " America/New_York time.)";
    //     }

    //     return message;
    // }

    return (
        <>
            <Button 
                onClick={handleOpen} 
                sx={{ 
                    flex: 3,
                    width: "100%",
                    height: "100%",
                    bgcolor: colors.blueAccent[800],
                    borderRadius: "0px",
                    textTransform: 'none',
                    '&:hover': {
                        bgcolor: colors.blueAccent[900],
                    },
                }}
            >
                Send Msg to {attendances.length} {attendances.length > 1 ? "Students" : "Student"} and {teacher_courses.length} {teacher_courses.length > 1 ? "Teachers" : "Teacher"}
            </Button>
            <Modal
                aria-labelledby="attendance-modal"
                aria-describedby="attendance-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography variant="h4" color={colors.primary[450]}>
                            Send Message ({attendances.length} {attendances.length > 1 ? "students" : "student"} and {teacher_courses.length} {teacher_courses.length > 1 ? "teachers selected" : "teacher selected"})
                        </Typography>
                        <CloseIcon sx={{ position: "absolute", right: 10, top: 10, cursor: "pointer" }} onClick={handleClose} />
                        <Box 
                            sx={{ 
                                display: "flex", 
                                flexDirection: "row", 
                                alignItems: "center", 
                                justifyContent: "center", 
                                mt: 2,
                                flexWrap: "wrap",
                            }}
                        >
                            {attendances && attendances.map((attendance, index) => {
                                const student = attendance.student;

                                return (
                                    <Box 
                                        key={attendance.id + "-" + index}
                                        sx={{ 
                                            display: "flex", 
                                            flexDirection: "row", 
                                            alignItems: "center", 
                                            ml: 2,
                                            mb: 2,
                                            border: "1px solid " + colors.grey[800],
                                            borderRadius: 2,
                                            p: 2,
                                            width: 400,
                                        }}
                                    >
                                        <FormGroup>
                                            {student && student.phone && student.phone !== "" && (
                                                <FormControlLabel 
                                                    control={
                                                        <Checkbox
                                                            onChange={(e) => 
                                                                handleChange(
                                                                    e, 
                                                                    {
                                                                        attendance_id: attendance.id, 
                                                                        phone: student.phone,
                                                                        phone_type: "student",
                                                                        country_phone_code: student.country_phone_code,
                                                                    }
                                                                )
                                                            }
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                        />
                                                    } 
                                                    label={`${getStudentName(attendance)}: ` + student.country_phone_code + " " + student.phone}
                                                    labelPlacement="end"
                                                />
                                            )}
                                            {student && student.parent_phone && student.parent_phone !== "" && (
                                                <FormControlLabel 
                                                    control={
                                                        <Checkbox
                                                        onChange={(e) => 
                                                            handleChange(
                                                                e, 
                                                                {
                                                                    attendance_id: attendance.id,
                                                                    phone: student.parent_phone,
                                                                    phone_type: "parent",
                                                                    country_phone_code: student.parent_country_phone_code,
                                                                }
                                                            )
                                                        }
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                        />
                                                    } 
                                                    label={"Parent 1: " + student.parent_country_phone_code + " " + student.parent_phone}
                                                    labelPlacement="end"
                                                />
                                            )}
                                            {student && student.parent_phone_2 && student.parent_phone_2 !== "" && (
                                                <FormControlLabel 
                                                    control={
                                                        <Checkbox
                                                            onChange={(e) => 
                                                                handleChange(
                                                                    e, 
                                                                    {
                                                                        attendance_id: attendance.id,
                                                                        phone: student.parent_phone_2,
                                                                        phone_type: "parent_2",
                                                                        country_phone_code: student.parent_country_phone_code_2,
                                                                    }
                                                                )
                                                            }
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                        />
                                                    } 
                                                    label={"Parent 2: " + student.parent_country_phone_code_2 + " " + student.parent_phone_2}
                                                    labelPlacement="end"
                                                />
                                            )}
                                        </FormGroup>
                                    </Box>
                                );
                            })}
                            {teacher_courses && teacher_courses.map((teacher_course, index) => {
                                const teacher = teacher_course.teacher;

                                return (
                                    <Box 
                                        key={teacher_course.id + "-" + index}
                                        sx={{ 
                                            display: "flex", 
                                            flexDirection: "row", 
                                            alignItems: "center", 
                                            ml: 2,
                                            mb: 2,
                                            border: "1px solid " + colors.grey[800],
                                            borderRadius: 2,
                                            p: 2,
                                            width: 400,
                                        }}
                                    >
                                        <FormGroup>
                                            {teacher && teacher.phone && teacher.phone !== "" && (
                                                <FormControlLabel 
                                                    control={
                                                        <Checkbox
                                                            onChange={(e) => 
                                                                handleChange(
                                                                    e, 
                                                                    {
                                                                        teacher_course_id: teacher_course.id, 
                                                                        phone: teacher.phone,
                                                                        phone_type: "teacher",
                                                                        country_phone_code: teacher.country_phone_code,
                                                                    }
                                                                )
                                                            }
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                        />
                                                    } 
                                                    label={`${teacher.first_name} ${teacher.last_name}: ` + teacher.country_phone_code + " " + teacher.phone}
                                                    labelPlacement="end"
                                                />
                                            )}
                                        </FormGroup>
                                    </Box>
                                );
                            })}
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: 2,
                                mt: 2,
                            }}
                        >
                            <Typography variant="h6" color={colors.primary[450]}>
                                Selected Phone Numbers ({selectedPhoneNumbers.length})
                            </Typography>
                            <Box
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                    gap: 2,
                                }}
                            >
                                {selectedPhoneNumbers && selectedPhoneNumbers.map((selectedPhoneNumber, index) => {
                                    return (
                                        <Box
                                            key={selectedPhoneNumber.phone + "-" + index}
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "baseline",
                                                justifyContent: "center",
                                                gap: 2,
                                            }}
                                        >
                                            <Typography variant="h5" color={colors.primary[450]}>
                                                {selectedPhoneNumber.country_phone_code + selectedPhoneNumber.phone.replace(/-/g, "").replace(/ /g, "").replace(/\(/g, "").replace(/\)/g, "")}
                                            </Typography>
                                        </Box>
                                    );
                                })}
                            </Box>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", mt: 2 }}>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 1,
                                    width: "100%",
                                }}
                            >
                                <Box
                                    sx={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Typography variant="h7" color={colors.primary[450]}>
                                        {activeTemplates.length} Template(s) Selected
                                    </Typography>
                                    <Button
                                        variant="text"
                                        sx={{
                                            fontSize: 12,
                                            color: colors.primary[100],
                                            textTransform: "none",
                                            textDecoration: "underline",
                                            '&:hover': {
                                                textDecoration: "underline",
                                            },
                                        }}
                                        onClick={() => {setHideAll(!hideAll)}}
                                    >
                                        {hideAll ? "Show All Templates" : "Hide All Templates"}
                                    </Button>
                                </Box>
                                {!hideAll && messageTemplates && messageTemplates.map((template, index) => {
                                    
                                    if (!notShowingUnneededTemplates(template)) {
                                        return;
                                    }

                                    return (
                                        <Button 
                                            key={index}
                                            variant="outlined"
                                            sx={{
                                                fontSize: 12,
                                                bgcolor: getBgcolor(template),
                                                color: colors.primary[100],
                                                textTransform: "none",
                                            }}
                                            onClick={() => {
                                                handleMessageTemplateButtonClick(template);
                                            }}
                                        >
                                            {template.name}
                                        </Button>
                                    );
                                })}
                            </Box>
                            <TextField
                                id="message"
                                label="Message"
                                multiline
                                rows={8}
                                fullWidth
                                value={message}
                                onChange={(e) => {
                                    setMessageLength(e.target.value.length);
                                    setMessage(e.target.value);
                                }}
                                sx={{ mt: 2 }}
                            />
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "baseline",
                                    justifyContent: "flex-end",
                                    mr: 2,
                                    gap: 2,
                                    width: "100%",
                                }}
                            >
                                <Typography 
                                    variant="h7" 
                                    color={messageLength < 161 ? 'primary' : colors.redAccent[500]}
                                >
                                    ({messageLength} / 160)
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: 2,
                                mt: 4,
                            }}
                        >
                            <FormControlLabel 
                                control={
                                    <Checkbox
                                        checked={isScheduled}
                                        onChange={(e) => setIsScheduled(e.target.checked)}
                                    />
                                }
                                label={"Scheduled"}
                                labelPlacement="end"
                            />
                        </Box>
                        {isScheduled && (<>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        gap: 2,
                                        mt: 2,
                                        width: "100%",
                                        height: "60px",
                                    }}
                                >
                                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                                        <DateTimePicker
                                            disabled={!isScheduled}
                                            timezone="America/New_York"
                                            label="Scheduled Date (America/New_York)"
                                            value={scheduledDate}
                                            onChange={(newValue) => setScheduledDate(newValue)}
                                        />
                                    </LocalizationProvider>
                                    <TextField
                                        id="admin_name"
                                        label="Admin Name"
                                        disabled
                                        type="text"
                                        value={admin_name}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        gap: 2,
                                        mt: 2,
                                        width: "90%",
                                    }}
                                >
                                    <TextField
                                        id='memo'
                                        label='Memo for record purposes'
                                        type='text'
                                        multiline
                                        fullWidth
                                        rows={6}
                                        value={memo}
                                        onChange={(e) => setMemo(e.target.value)}
                                        placeholder='Memo for the scheduled message'
                                        sx={{ mt: 2 }}
                                    />
                                </Box>
                            </>)}
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: 2,
                                mt: 4,
                            }}
                        >
                            <Button
                                variant="contained"
                                color="secondary"
                                sx={{ width: 200, mt: 2 }}
                                onClick={() => handleSend()}
                            >
                                Send
                            </Button>
                            {/* <AreYouSureModal
                                colors={colors}
                                open={areYouSureModalOpen}
                                setOpen={setAreYouSureModalOpen}
                                title="Are you sure?"
                                message={getAreYouSureMessage()}
                                onConfirm={handleSend}
                            /> */}
                            <Button
                                variant="contained"
                                color="error"
                                sx={{ width: 200, mt: 2 }}
                                onClick={handleClose}
                            >
                                Close
                            </Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </>
    )
}

export default BulkSendMessageControl