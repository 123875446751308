import React from 'react';
import Header from '../../components/Header';
import { Box, useTheme, Typography, Button, Stack, TextField, Tooltip, IconButton } from "@mui/material";
import Loading from '../../components/Loading';
import Axios from 'axios';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { tokens } from "../../theme";
import dayjs from 'dayjs';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableFooter from '@mui/material/TableFooter';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import JsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import Alert from '../../components/alert/Alert';


function PaycheckCalculator() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const pageRef = React.useRef(null);

    const [loading, setLoading] = React.useState(false);
    const [teacherWithCourses, setTeacherWithCourses] = React.useState([]);
    const [fromDate, setFromDate] = React.useState(dayjs().startOf('day').locale('en'));
    const [toDate, setToDate] = React.useState(dayjs().endOf('day').locale('en'));

    const [allView, setAllView] = React.useState(false);
    const [allDetailedView, setAllDetailedView] = React.useState(false);
    const [confirmedTotals, setConfirmedTotals] = React.useState({});

    const [selectedTeacher, setSelectedTeacher] = React.useState(null);
    const [totalDollars, setTotalDollars] = React.useState({});
    const [courseHourlyRates, setCourseHourlyRates] = React.useState({});

    // const [editTotal, setEditTotal] = React.useState(false);
    const [editMemo, setEditMemo] = React.useState(false);

    // for alert
    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = React.useState("");
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = React.useState("");

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: colors.primary[300],
            color: theme.palette.common.white,
            fontSize: 14,
            fontWeight: "bold",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
        border: '1px solid rgba(224, 224, 224, 1)',
    }));
      
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: colors.primary[750],
        },
        '&:nth-of-type(even)': {
            backgroundColor: colors.primary[750],
        },
    }));

    React.useEffect(() => {
        if (teacherWithCourses.length > 0) {
            /**
             * Initialize confirmed totals
             * teacher_id: {
             *     name: string,
             *     total: number,
             * }
             */
            const confirmedTotals = {};
            const totalDollars = {};
            const courseHourlyRates = {};
            teacherWithCourses.forEach((teacher) => {
                confirmedTotals[teacher.id] = {
                    name: teacher.first_name + " " + teacher.last_name,
                    total: null,
                };

                teacher.teacher_courses.forEach((teacher_course) => {
                    if (!totalDollars[teacher.id]) {
                        totalDollars[teacher.id] = {};
                    }
                    if (!courseHourlyRates[teacher.id]) {
                        courseHourlyRates[teacher.id] = {};
                    }

                    totalDollars[teacher.id][teacher_course.course.id] = null;
                    courseHourlyRates[teacher.id][teacher_course.course.id] = null;
                });
            });

            setConfirmedTotals(confirmedTotals);
            setTotalDollars(totalDollars);
            setCourseHourlyRates(courseHourlyRates);
        }
    }, [teacherWithCourses]);
    
    async function fetchTeacherWithCourses() {
        setLoading(true);

        // fetch teacher with courses
        await Axios.get(`${process.env.REACT_APP_URL}/api/v1/admins/paycheck/teacher-with-courses?fromDate=${fromDate.toISOString()}&toDate=${toDate.toISOString()}`, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then(async (response) => {
            setSelectedTeacher(null);
            setTeacherWithCourses(response.data);
            const teacher_ids = response.data.map((teacher) => teacher.id);
            // fetch additional teachers
            await Axios.post(`${process.env.REACT_APP_URL}/api/v1/admins/paycheck/additional-teachers-with-courses?fromDate=${fromDate.toISOString()}&toDate=${toDate.toISOString()}`, {
                teacher_ids: teacher_ids,
            }, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                setTeacherWithCourses((prev) => {
                    return [...prev, ...response.data];
                });
            });
        }).catch((error) => {
            console.error(error);
        });

        setLoading(false);
    }

    async function saveMemoForPayroll(e, teacher_uuid, memo) {
        e.preventDefault();

        if (memo === undefined || memo === null) {
            setErrorAlertMessage("Please enter a memo for payroll.");
            setErrorAlertOpen(true);
            return;
        }
        
        await Axios.put(`${process.env.REACT_APP_URL}/api/v1/teachers/${teacher_uuid}/memo-for-payroll`, {
            memo_for_payroll: memo,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {

            setSuccessAlertMessage("Memo for Payroll has been saved successfully.");
            setSuccessAlertOpen(true);

            setTeacherWithCourses((prev) => {
                return prev.map((teacher) => {
                    if (teacher.uuid === teacher_uuid) {
                        return {
                            ...teacher,
                            memo_for_payroll: memo,
                        };
                    }

                    return teacher;
                });
            });

            setSelectedTeacher((prev) => {
                return {
                    ...prev,
                    memo_for_payroll: memo,
                };
            });

            setEditMemo(false);
        }).catch((error) => {
            console.error(error);
            setErrorAlertMessage("Failed to save Memo for Payroll.");
            setErrorAlertOpen(true);
        });
    }

    function getCourseName(course) {
        return "(" + course.code + ") " + course.course_title;
    }

    function getDuration(start, end) {
        const duration = dayjs(end).diff(dayjs(start), 'minute');

        // hours + minutes
        return Math.floor(duration / 60) + "h " + duration % 60 + "m";
    }

    function getTotalHours(course, teacher) {
        let totalHours = 0;
        let totalMinutes = 0;

        course.events.forEach((event) => {
            if (!event.is_canceled && event.is_canceled_int === 0 && event.teacher_id === teacher.id) {
                const duration = dayjs(event.end).diff(dayjs(event.start), 'minute');
                totalHours += Math.floor(duration / 60);
                totalMinutes += duration % 60;
            }
        });

        totalHours += Math.floor(totalMinutes / 60);
        totalMinutes = totalMinutes % 60;

        return totalHours + "h " + totalMinutes + "m";
    }

    function getTotalDollar(teacher, course, hourlyRate) {
        let totalDollar = 0;
        try {
            const totalHours = getTotalHours(course, teacher).split(' ')[0].replace('h', '');
            const totalMinutes = getTotalHours(course, teacher).split(' ')[1].replace('m', '');
            const totalHoursInMinutes = parseInt(totalHours) * 60 + parseInt(totalMinutes);
            totalDollar = hourlyRate * totalHoursInMinutes / 60;        
        } catch (error) {
            alert("Please enter a valid number for hourly rate.");
            return;
        }

        setCourseHourlyRates({
            ...courseHourlyRates,
            [teacher.id]: {
                ...courseHourlyRates[teacher.id],
                [course.id]: hourlyRate,
            },
        });
        setTotalDollars({
            ...totalDollars,
            [teacher.id]: {
                ...totalDollars[teacher.id],
                [course.id]: totalDollar,
            },
        });
    }

    function getAllTotalDollars(teacher_courses, additional_teacher_courses, hourlyRateAllInt) {

        const _totalDollars = {};
        const _courseHourlyRates = {};
        _courseHourlyRates[selectedTeacher.id] = {};
        _totalDollars[selectedTeacher.id] = {};

        teacher_courses.forEach((teacher_course) => {
            teacher_course.course.events.forEach((event) => {
                if (!event.is_canceled && event.is_canceled_int === 0 && event.teacher_id === selectedTeacher.id) {
                    const duration = dayjs(event.end).diff(dayjs(event.start), 'minute');
                    const totalHours = Math.floor(duration / 60);
                    const totalMinutes = duration % 60;
                    const totalHoursInMinutes = totalHours * 60 + totalMinutes;
                    const totalDollar = hourlyRateAllInt * totalHoursInMinutes / 60;
                    
                    if (_totalDollars[selectedTeacher.id][teacher_course.course.id]) {
                        _totalDollars[selectedTeacher.id][teacher_course.course.id] += totalDollar;
                    } else {
                        _totalDollars[selectedTeacher.id][teacher_course.course.id] = totalDollar;
                    }
                }
            });

            _courseHourlyRates[selectedTeacher.id][teacher_course.course.id] = hourlyRateAllInt;
        });

        console.log(additional_teacher_courses);

        if (additional_teacher_courses && additional_teacher_courses.length > 0) {
            additional_teacher_courses.forEach((teacher_course) => {
                console.log(teacher_course);
                teacher_course.course.events.forEach((event) => {
                    console.log(event);
                    if (!event.is_canceled && event.is_canceled_int === 0 && event.teacher_id === selectedTeacher.id) {
                        const duration = dayjs(event.end).diff(dayjs(event.start), 'minute');
                        const totalHours = Math.floor(duration / 60);
                        const totalMinutes = duration % 60;
                        const totalHoursInMinutes = totalHours * 60 + totalMinutes;
                        const totalDollar = hourlyRateAllInt * totalHoursInMinutes / 60;
                        
                        if (_totalDollars[selectedTeacher.id][teacher_course.course.id]) {
                            _totalDollars[selectedTeacher.id][teacher_course.course.id] += totalDollar;
                        } else {
                            _totalDollars[selectedTeacher.id][teacher_course.course.id] = totalDollar;
                        }
                    }
                });

                _courseHourlyRates[selectedTeacher.id][teacher_course.course.id] = hourlyRateAllInt;
            });
        }

        setCourseHourlyRates(
            { ...courseHourlyRates, ..._courseHourlyRates }
        );
        setTotalDollars(
            { ...totalDollars, ..._totalDollars }
        );
    }

    function getAllTotal() {
        let total = 0;
        Object.values(confirmedTotals).forEach((obj) => {
            try {
                const _total = obj.total;
                if (_total && !isNaN(_total)) {
                    total += parseFloat(_total);
                }
            } catch (error) {
                console.error(error);
            }
        });

        return total.toFixed(2);
    }

    function getSortedKeys(keys) {
        return keys.sort((a, b) => {
            if (confirmedTotals[a].name < confirmedTotals[b].name) {
                return -1;
            } else if (confirmedTotals[a].name > confirmedTotals[b].name) {
                return 1;
            }

            return 0;
        });
    }

    function getRowSpan(teacher) {
        if (!teacher.teacher_courses && !teacher.additional_teacher_courses) {
            return 0;
        }

        if (!teacher.additional_teacher_courses) {
            return teacher.teacher_courses.length;
        }

        if (!teacher.teacher_courses) {
            return teacher.additional_teacher_courses.length;
        }

        return teacher.teacher_courses.length + teacher.additional_teacher_courses.length;
    }

    const generatePDF = async () => {

        const Component = pageRef.current;

        await html2canvas(Component).then((canvas) => {
            const componentWidth = Component.offsetWidth;
            const componentHeight = Component.offsetHeight;
        
            const orientation = componentWidth >= componentHeight ? 'l' : 'p';
        
            const imgData = canvas.toDataURL('image/png');
            const pdf = new JsPDF({
                orientation,
                unit: 'px'
            });
        
            pdf.internal.pageSize.width = componentWidth;
            pdf.internal.pageSize.height = componentHeight;
        
            const fileName = "(" + fromDate.format('YYYY-MM-DD') + " - " + toDate.format('YYYY-MM-DD') + ") Teacher Paychecks.pdf";
            pdf.addImage(imgData, 'PNG', 0, 0, componentWidth, componentHeight);
            pdf.save(fileName);
        })
    }

    const getBorderBottom = (index, length, has_additional_teacher_courses) => {
        if (index === length - 1 && !has_additional_teacher_courses) {
            return "1px solid black";
        }

        return "unset";
    };

    return (
        <Box m="20px" marginX={3} marginY={3} ref={pageRef}>
            { loading ? 
                <Loading />
                : 
                <Box 
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Header title="Paycheck Calculator" subtitle="A ONE Institute" />
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "baseline",
                        }}
                    >
                        <Typography 
                            variant="h5"
                            sx={{
                                color: colors.primary[100],
                                marginBottom: 2,
                                fontWeight: "bold",
                            }}
                        >
                            Select Date Range
                        </Typography>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: 2,
                            }}
                        >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="From"
                                    value={fromDate}
                                    onChange={(newValue) => setFromDate(newValue)}
                                    sx={{ width: "100%" }}
                                />
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="To"
                                    value={toDate}
                                    onChange={(newValue) => setToDate(newValue)}
                                    sx={{ width: "100%" }}
                                />
                            </LocalizationProvider>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={fetchTeacherWithCourses}
                            >
                                OK
                            </Button>
                        </Box>
                        <Box
                            sx={{
                                mt: 4,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "baseline",
                            }}
                        >
                            <Stack direction="row" sx={{ flexWrap: "wrap", gap: 1 }}>
                                {teacherWithCourses.length > 0 && (<>
                                    <Chip
                                        label={"All"}
                                        onClick={() => {
                                            setSelectedTeacher(null);
                                            setAllDetailedView(false);
                                            setAllView(true);
                                        }}
                                        sx={{
                                            bgcolor: allView && colors.greenAccent[500],
                                        }}
                                    />
                                    <Chip
                                        label={"All Detailed View"}
                                        onClick={() => {
                                            setSelectedTeacher(null);
                                            setAllView(false);
                                            setAllDetailedView(true);
                                        }}
                                        sx={{
                                            bgcolor: allDetailedView && colors.greenAccent[500],
                                        }}
                                    />
                                </>)}
                                {teacherWithCourses.map((teacher, index) => {
                                    return (
                                        <Chip
                                            key={index}
                                            label={teacher.first_name + " " + teacher.last_name}
                                            onClick={() => {
                                                setSelectedTeacher(teacher);
                                                setAllView(false);
                                                setAllDetailedView(false);
                                            }}
                                            sx={{
                                                bgcolor: selectedTeacher?.uuid === teacher.uuid && colors.greenAccent[500],
                                            }}
                                        />
                                    )
                                })}
                            </Stack>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "baseline",
                                mt: 6,
                                mb: 6,
                                width: "100%",
                            }}
                        >
                            <TableContainer component={Paper} sx={{ width: "100%" }}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        {allView ? (
                                            <TableRow>
                                                <StyledTableCell style={{ width: "90%" }}>Name</StyledTableCell>
                                                <StyledTableCell style={{ width: "10%" }}>Total (Dollar)</StyledTableCell>
                                            </TableRow>
                                        ) : (
                                            <TableRow>
                                                <StyledTableCell style={{ width: "10%" }}>Name</StyledTableCell>
                                                <StyledTableCell style={{ width: "10%" }}>Course</StyledTableCell>
                                                <StyledTableCell style={{ width: "50%" }}>Modules</StyledTableCell>
                                                <StyledTableCell style={{ width: "10%" }}>Total (Hours)</StyledTableCell>
                                                <StyledTableCell style={{ width: "10%" }}>Hourly Rate</StyledTableCell>
                                                <StyledTableCell style={{ width: "10%" }}>Total (Dollar)</StyledTableCell>
                                            </TableRow>
                                        )}
                                    </TableHead>
                                </Table>
                            </TableContainer>
                            <TableContainer component={Paper} sx={{ width: "100%" }}>
                                <Table aria-label="simple table">
                                    {allView ? (
                                        <TableBody>
                                            {confirmedTotals && getSortedKeys(Object.keys(confirmedTotals)).map((teacher_id, index) => {
                                                return (
                                                    <StyledTableRow key={index}>
                                                        <StyledTableCell style={{ width: "90%" }}>
                                                            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
                                                                {confirmedTotals[teacher_id].name}
                                                            </Typography>
                                                        </StyledTableCell>
                                                        <StyledTableCell style={{ width: "10%" }}>
                                                            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
                                                                {confirmedTotals[teacher_id].total ? "$" + confirmedTotals[teacher_id].total : ""}
                                                            </Typography>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                )
                                            })}
                                        </TableBody>
                                    ) : allDetailedView ? (
                                        <TableBody>
                                            {teacherWithCourses.map((teacher, index) => {
                                                return (

                                                <>
                                                    {teacher.teacher_courses && teacher.teacher_courses.map((teacher_course, index) => {
                                                        const course = teacher_course.course;

                                                        return (
                                                            <StyledTableRow key={index}>
                                                                {index === 0 ? (
                                                                    <StyledTableCell rowSpan={getRowSpan(teacher)} style={{ width: "10%", borderBottom: "1px solid black" }}>
                                                                        <Typography variant='h6' sx={{ fontWeight: 'bold', mb: 2 }}>
                                                                            {teacher.first_name + " " + teacher.last_name}
                                                                        </Typography>
                                                                        <Typography variant='h7'>
                                                                            {teacher.memo_for_payroll && teacher.memo_for_payroll.split('\n').map((line, index) => {
                                                                                return (
                                                                                    <span key={index}>
                                                                                        {line}
                                                                                        <br />
                                                                                    </span>
                                                                                );
                                                                            })}
                                                                    </Typography>
                                                                    </StyledTableCell>
                                                                ) : null}
                                                                <StyledTableCell style={{ width: "10%", borderBottom: getBorderBottom(index, teacher.teacher_courses.length, teacher.additional_teacher_courses && teacher.additional_teacher_courses.length > 0) }}>{getCourseName(course)}</StyledTableCell>
                                                                <StyledTableCell 
                                                                    sx={{ 
                                                                        width: "50%",
                                                                        borderBottom: getBorderBottom(index, teacher.teacher_courses.length, teacher.additional_teacher_courses && teacher.additional_teacher_courses.length > 0)
                                                                    }}
                                                                >
                                                                    <Stack direction="row" sx={{ flexWrap: "wrap", gap: "20px" }}>
                                                                        {course.events.map((event, i) => {
        
                                                                            // if (event.teacher_id !== teacher.id) {
                                                                            //     return null;
                                                                            // }
        
                                                                            return (
                                                                                <Box key={i}>
                                                                                    <Typography variant='h6' sx={{ fontWeight: 'bold' }}>{event.title}</Typography>
                                                                                    {event.is_canceled || event.is_canceled_int > 0 ? (
                                                                                        <Typography style={{ color: colors.redAccent[500] }}>Canceled</Typography>
                                                                                    ) : event.teacher_id !== teacher.id ? (
                                                                                        <Typography style={{ color: colors.redAccent[800] }}>Not Present</Typography>
                                                                                    ) : (
                                                                                        <>
                                                                                            <Typography>{dayjs(event.start).format('YYYY-MM-DD HH:mm')}</Typography>
                                                                                            <Typography>{dayjs(event.end).format('YYYY-MM-DD HH:mm')}</Typography>
                                                                                            <Typography>{event.teacher_id === teacher.id ? getDuration(event.start, event.end) : "0h 0m"}</Typography>
                                                                                            {event.is_make_up_event && (
                                                                                                <Typography style={{ color: colors.blueAccent[500] }}>Make-up</Typography>
                                                                                            )}
                                                                                            {event.substitute_teacher_id && event.substitute_teacher_id === teacher.id && (
                                                                                                <Typography style={{ color: colors.greenAccent[400] }}>Substitute</Typography>
                                                                                            )}
                                                                                        </>
                                                                                    )}
                                                                                </Box>
                                                                            )
                                                                        })}
                                                                    </Stack>
                                                                </StyledTableCell>
                                                                <StyledTableCell style={{ width: "10%", borderBottom: getBorderBottom(index, teacher.teacher_courses.length, teacher.additional_teacher_courses && teacher.additional_teacher_courses.length > 0) }}>{getTotalHours(course, teacher)}</StyledTableCell>
                                                                <StyledTableCell style={{ width: "10%", borderBottom: getBorderBottom(index, teacher.teacher_courses.length, teacher.additional_teacher_courses && teacher.additional_teacher_courses.length > 0) }}></StyledTableCell>
                                                                <StyledTableCell style={{ width: "10%", borderBottom: getBorderBottom(index, teacher.teacher_courses.length, teacher.additional_teacher_courses && teacher.additional_teacher_courses.length > 0) }}>
                                                                    {confirmedTotals[teacher.id] && confirmedTotals[teacher.id].total !== null ? (
                                                                        <>
                                                                            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
                                                                                {totalDollars[teacher.id] && totalDollars[teacher.id][course.id] ? "$" + totalDollars[teacher.id][course.id].toFixed(2) : "0"}
                                                                            </Typography>
                                                                            {courseHourlyRates[teacher.id] && courseHourlyRates[teacher.id][course.id] && (
                                                                                <Typography>
                                                                                    ({courseHourlyRates[teacher.id][course.id] ? "$" + courseHourlyRates[teacher.id][course.id].toFixed(2) : ""} / hour)
                                                                                </Typography>
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <Typography variant='h6' color='red'>
                                                                            Not Confirmed
                                                                        </Typography>
                                                                    )}
                                                                </StyledTableCell>
                                                            </StyledTableRow>
                                                        )
                                                    })}

                                                    {teacher.additional_teacher_courses && teacher.additional_teacher_courses.map((teacher_course, index) => {
                                                        const course = teacher_course.course;

                                                        return (
                                                            <StyledTableRow key={index}>
                                                                <StyledTableCell style={{ width: "10%", borderBottom: getBorderBottom(index, teacher.additional_teacher_courses.length, false) }}>{getCourseName(course)}</StyledTableCell>
                                                                <StyledTableCell 
                                                                    sx={{ 
                                                                        width: "50%",
                                                                        borderBottom: getBorderBottom(index, teacher.additional_teacher_courses.length, false),
                                                                    }}
                                                                >
                                                                    <Stack direction="row" sx={{ flexWrap: "wrap", gap: "20px" }}>
                                                                        {course.events.map((event, i) => {
        
                                                                            // if (event.teacher_id !== teacher.id) {
                                                                            //     return null;
                                                                            // }
        
                                                                            return (
                                                                                <Box key={i}>
                                                                                    <Typography variant='h6' sx={{ fontWeight: 'bold' }}>{event.title}</Typography>
                                                                                    {event.is_canceled || event.is_canceled_int > 0 ? (
                                                                                        <Typography style={{ color: colors.redAccent[500] }}>Canceled</Typography>
                                                                                    ) : event.teacher_id !== teacher.id ? (
                                                                                        <Typography style={{ color: colors.redAccent[800] }}>Not Present</Typography>
                                                                                    ) : (
                                                                                        <>
                                                                                            <Typography>{dayjs(event.start).format('YYYY-MM-DD HH:mm')}</Typography>
                                                                                            <Typography>{dayjs(event.end).format('YYYY-MM-DD HH:mm')}</Typography>
                                                                                            <Typography>{event.teacher_id === teacher.id ? getDuration(event.start, event.end) : "0h 0m"}</Typography>
                                                                                            {event.is_make_up_event && (
                                                                                                <Typography style={{ color: colors.blueAccent[500] }}>Make-up</Typography>
                                                                                            )}
                                                                                            {event.substitute_teacher_id && event.substitute_teacher_id === teacher.id && (
                                                                                                <Typography style={{ color: colors.greenAccent[400] }}>Substitute</Typography>
                                                                                            )}
                                                                                        </>
                                                                                    )}
                                                                                </Box>
                                                                            )
                                                                        })}
                                                                    </Stack>
                                                                </StyledTableCell>
                                                                <StyledTableCell style={{ width: "10%", borderBottom: getBorderBottom(index, teacher.additional_teacher_courses.length, false) }}>{getTotalHours(course, teacher)}</StyledTableCell>
                                                                <StyledTableCell style={{ width: "10%", borderBottom: getBorderBottom(index, teacher.additional_teacher_courses.length, false) }}></StyledTableCell>
                                                                <StyledTableCell style={{ width: "10%", borderBottom: getBorderBottom(index, teacher.additional_teacher_courses.length, false) }}>
                                                                    {confirmedTotals[teacher.id] && confirmedTotals[teacher.id].total !== null ? (
                                                                        <>
                                                                            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
                                                                                {totalDollars[teacher.id] && totalDollars[teacher.id][course.id] ? "$" + totalDollars[teacher.id][course.id].toFixed(2) : ""}
                                                                            </Typography>
                                                                            {courseHourlyRates[teacher.id] && courseHourlyRates[teacher.id][course.id] && (
                                                                                <Typography>
                                                                                    ({courseHourlyRates[teacher.id][course.id] ? "$" + courseHourlyRates[teacher.id][course.id].toFixed(2) : ""} / hour)
                                                                                </Typography>
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <Typography variant='h6' color='red'>
                                                                            Not Confirmed
                                                                        </Typography>
                                                                    )}
                                                                </StyledTableCell>
                                                            </StyledTableRow>
                                                        )
                                                    })}
                                                </>)
                                            })}
                                        </TableBody>
                                    ) : (
                                        <TableBody>
                                            {selectedTeacher && selectedTeacher.teacher_courses.map((teacher_course, index) => {
                                                const course = teacher_course.course;

                                                return (
                                                    <StyledTableRow key={index}>
                                                        {index === 0 ? (
                                                            <StyledTableCell rowSpan={getRowSpan(selectedTeacher)} style={{ width: "10%", position: "relative", }}>
                                                                <Box
                                                                    sx={{
                                                                        position: "absolute",
                                                                        top: "0",
                                                                        right: "0",
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        cursor: "pointer",
                                                                    }}
                                                                >
                                                                    <Tooltip title="Edit Memo for Payroll" arrow placement="top">
                                                                        <IconButton onClick={() => setEditMemo(!editMemo)}>
                                                                            <NoteAltOutlinedIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Box>
                                                                <Typography variant='h6' sx={{ fontWeight: 'bold', mb: 2 }}>
                                                                    {selectedTeacher.first_name + " " + selectedTeacher.last_name}
                                                                </Typography>
                                                                {editMemo ? (
                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            flexDirection: "column",
                                                                        }}
                                                                    >
                                                                        <TextField
                                                                            name={'memo-for-payroll-' + selectedTeacher.id}
                                                                            label="Memo for Payroll"
                                                                            variant="outlined"
                                                                            defaultValue={selectedTeacher.memo_for_payroll}
                                                                            multiline
                                                                            rows={4}
                                                                            sx={{
                                                                                mb: 2,
                                                                                mx: "auto",
                                                                            }}
                                                                        />
                                                                        <Button
                                                                            variant="contained"
                                                                            color="primary"
                                                                            size='small'
                                                                            onClick={(e) => saveMemoForPayroll(e, selectedTeacher.uuid, document.getElementsByName('memo-for-payroll-' + selectedTeacher.id)[0].value)}
                                                                        >
                                                                            Save
                                                                        </Button>
                                                                    </Box>
                                                                ) : (
                                                                    <Typography variant='h7'>
                                                                        {selectedTeacher.memo_for_payroll && selectedTeacher.memo_for_payroll.split('\n').map((line, index) => {
                                                                            return (
                                                                                <span key={index}>
                                                                                    {line}
                                                                                    <br />
                                                                                </span>
                                                                            );
                                                                        })}
                                                                    </Typography>
                                                                )}
                                                                <TextField
                                                                    disabled={selectedTeacher && confirmedTotals[selectedTeacher.id] && confirmedTotals[selectedTeacher.id].total !== null}
                                                                    name='hourly-rate-all'
                                                                    label="Hourly Rate (All)"
                                                                    variant="outlined"
                                                                    size="small"
                                                                    sx={{
                                                                        mt: 6,
                                                                        mb: 2,
                                                                        mx: "auto",
                                                                    }}
                                                                />
                                                                <Button
                                                                    disabled={selectedTeacher && confirmedTotals[selectedTeacher.id] && confirmedTotals[selectedTeacher.id].total !== null}
                                                                    variant="contained"
                                                                    color="primary"
                                                                    size='small'
                                                                    onClick={() => {
                                                                        const hourlyRateAll = document.getElementsByName('hourly-rate-all')[0].value;
                                                                        let hourlyRateAllInt = 0;
                                                                        try {
                                                                            hourlyRateAllInt = parseFloat(hourlyRateAll);
                                                                        } catch (error) {
                                                                            alert("Please enter a valid number for hourly rate.");
                                                                            return;
                                                                        }

                                                                        getAllTotalDollars(selectedTeacher.teacher_courses, selectedTeacher.additional_teacher_courses, hourlyRateAllInt);
                                                                    }}
                                                                >
                                                                    Calculate All
                                                                </Button>
                                                            </StyledTableCell>
                                                        ) : null}
                                                        <StyledTableCell style={{ width: "10%" }}>{getCourseName(course)}</StyledTableCell>
                                                        <StyledTableCell 
                                                            sx={{ 
                                                                width: "50%",
                                                            }}
                                                        >
                                                            <Stack direction="row" sx={{ flexWrap: "wrap", gap: "20px" }}>
                                                                {course.events.map((event, i) => {

                                                                    // if (event.teacher_id !== selectedTeacher.id) {
                                                                    //     return null;
                                                                    // }

                                                                    return (
                                                                        <Box key={i}>
                                                                            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>{event.title}</Typography>
                                                                            {event.is_canceled || event.is_canceled_int > 0 ? (
                                                                                <Typography style={{ color: colors.redAccent[500] }}>Canceled</Typography>
                                                                            ) : event.teacher_id !== selectedTeacher.id ? (
                                                                                <Typography style={{ color: colors.redAccent[800] }}>Not Present</Typography>
                                                                            ) : (
                                                                                <>
                                                                                    <Typography>{dayjs(event.start).format('YYYY-MM-DD HH:mm')}</Typography>
                                                                                    <Typography>{dayjs(event.end).format('YYYY-MM-DD HH:mm')}</Typography>
                                                                                    <Typography>{event.teacher_id === selectedTeacher.id ? getDuration(event.start, event.end) : "0h 0m"}</Typography>
                                                                                    {event.is_make_up_event && (
                                                                                        <Typography style={{ color: colors.blueAccent[500] }}>Make-up</Typography>
                                                                                    )}
                                                                                    {event.substitute_teacher_id && event.substitute_teacher_id === selectedTeacher.id && (
                                                                                        <Typography style={{ color: colors.greenAccent[400] }}>Substitute</Typography>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </Box>
                                                                    )
                                                                })}
                                                            </Stack>
                                                        </StyledTableCell>
                                                        <StyledTableCell style={{ width: "10%" }}>{getTotalHours(course, selectedTeacher)}</StyledTableCell>
                                                        <StyledTableCell style={{ width: "10%" }}>
                                                            <TextField
                                                                disabled={selectedTeacher && confirmedTotals[selectedTeacher.id] && confirmedTotals[selectedTeacher.id].total !== null}
                                                                name={`hourly-rate-${course.id}`}
                                                                id={`hourly-rate-${course.id}`}
                                                                label="Hourly Rate"
                                                                size="small"
                                                                sx={{
                                                                    mb: 1,
                                                                    mx: "auto",
                                                                }}
                                                            />
                                                            <Button
                                                                disabled={selectedTeacher && confirmedTotals[selectedTeacher.id] && confirmedTotals[selectedTeacher.id].total !== null}
                                                                variant="contained"
                                                                color="primary"
                                                                size='small'
                                                                sx={{
                                                                    width: "100%",
                                                                }}
                                                                onClick={() => {
                                                                    const hourlyRate = document.getElementsByName('hourly-rate-' + course.id)[0].value;
                                                                    document.getElementsByName('hourly-rate-' + course.id)[0].value = hourlyRate;
                                                                    let hourlyRateAllInt = 0;
                                                                    try {
                                                                        hourlyRateAllInt = parseFloat(hourlyRate);
                                                                    } catch (error) {
                                                                        alert("Please enter a valid number for hourly rate.");
                                                                        return;
                                                                    }

                                                                    getTotalDollar(selectedTeacher, course, hourlyRateAllInt);
                                                                }}
                                                            >
                                                                Calculate
                                                            </Button>

                                                        </StyledTableCell>
                                                        <StyledTableCell style={{ width: "10%" }}>
                                                            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
                                                                {totalDollars[selectedTeacher.id] && totalDollars[selectedTeacher.id][course.id] ? "$" + totalDollars[selectedTeacher.id][course.id].toFixed(2) : "0"}
                                                            </Typography>
                                                            {courseHourlyRates[selectedTeacher.id] && courseHourlyRates[selectedTeacher.id][course.id] && (
                                                                <Typography>
                                                                    ({courseHourlyRates[selectedTeacher.id][course.id] ? "$" + courseHourlyRates[selectedTeacher.id][course.id].toFixed(2) : ""} / hour)
                                                                </Typography>
                                                            )}
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                )
                                            })}

                                            {selectedTeacher && selectedTeacher.additional_teacher_courses && selectedTeacher.additional_teacher_courses.map((teacher_course, index) => {
                                                const course = teacher_course.course;

                                                return (
                                                    <StyledTableRow key={index}>
                                                        <StyledTableCell style={{ width: "10%" }}>{getCourseName(course)}</StyledTableCell>
                                                        <StyledTableCell 
                                                            sx={{ 
                                                                width: "50%",
                                                            }}
                                                        >
                                                            <Stack direction="row" sx={{ flexWrap: "wrap", gap: "20px" }}>
                                                                {course.events.map((event, i) => {

                                                                    if (event.teacher_id !== selectedTeacher.id) {
                                                                        return null;
                                                                    }

                                                                    return (
                                                                        <Box key={i}>
                                                                            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>{event.title}</Typography>
                                                                            {event.is_canceled || event.is_canceled_int > 0 ? (
                                                                                <Typography style={{ color: colors.redAccent[500] }}>Canceled</Typography>
                                                                            ) : event.teacher_id !== selectedTeacher.id ? (
                                                                                <Typography style={{ color: colors.redAccent[800] }}>Not Present</Typography>
                                                                            ) : (
                                                                                <>
                                                                                    <Typography>{dayjs(event.start).format('YYYY-MM-DD HH:mm')}</Typography>
                                                                                    <Typography>{dayjs(event.end).format('YYYY-MM-DD HH:mm')}</Typography>
                                                                                    <Typography>{event.teacher_id === selectedTeacher.id ? getDuration(event.start, event.end) : "0h 0m"}</Typography>
                                                                                    {event.is_make_up_event && (
                                                                                        <Typography style={{ color: colors.blueAccent[500] }}>Make-up</Typography>
                                                                                    )}
                                                                                    {event.substitute_teacher_id && event.substitute_teacher_id === selectedTeacher.id && (
                                                                                        <Typography style={{ color: colors.greenAccent[400] }}>Substitute</Typography>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </Box>
                                                                    )
                                                                })}
                                                            </Stack>
                                                        </StyledTableCell>
                                                        <StyledTableCell style={{ width: "10%" }}>{getTotalHours(course, selectedTeacher)}</StyledTableCell>
                                                        <StyledTableCell style={{ width: "10%" }}>
                                                            <TextField
                                                                disabled={selectedTeacher && confirmedTotals[selectedTeacher.id] && confirmedTotals[selectedTeacher.id].total !== null}
                                                                name={`hourly-rate-${course.id}`}
                                                                id={`hourly-rate-${course.id}`}
                                                                label="Hourly Rate"
                                                                size="small"
                                                                sx={{
                                                                    mb: 1,
                                                                    mx: "auto",
                                                                }}
                                                            />
                                                            <Button
                                                                disabled={selectedTeacher && confirmedTotals[selectedTeacher.id] && confirmedTotals[selectedTeacher.id].total !== null}
                                                                variant="contained"
                                                                color="primary"
                                                                size='small'
                                                                sx={{
                                                                    width: "100%",
                                                                }}
                                                                onClick={() => {
                                                                    const hourlyRate = document.getElementsByName('hourly-rate-' + course.id)[0].value;
                                                                    document.getElementsByName('hourly-rate-' + course.id)[0].value = hourlyRate;
                                                                    let hourlyRateAllInt = 0;
                                                                    try {
                                                                        hourlyRateAllInt = parseFloat(hourlyRate);
                                                                    } catch (error) {
                                                                        alert("Please enter a valid number for hourly rate.");
                                                                        return;
                                                                    }

                                                                    getTotalDollar(selectedTeacher, course, hourlyRateAllInt);
                                                                }}
                                                            >
                                                                Calculate
                                                            </Button>

                                                        </StyledTableCell>
                                                        <StyledTableCell style={{ width: "10%" }}>
                                                            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
                                                                {totalDollars[selectedTeacher.id] && totalDollars[selectedTeacher.id][course.id] ? "$" + totalDollars[selectedTeacher.id][course.id].toFixed(2) : ""}
                                                            </Typography>
                                                            {courseHourlyRates[selectedTeacher.id] && courseHourlyRates[selectedTeacher.id][course.id] && (
                                                                <Typography>
                                                                    ({courseHourlyRates[selectedTeacher.id][course.id] ? "$" + courseHourlyRates[selectedTeacher.id][course.id].toFixed(2) : ""} / hour)
                                                                </Typography>
                                                            )}
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                )
                                            })}
                                        </TableBody>
                                    )}
                                </Table>
                            </TableContainer>
                            <TableContainer component={Paper} sx={{ width: "100%", minHeight: "160px", }}>
                                <Table aria-label="simple table" sx={{ minHeight: "160px", }}>
                                    <TableFooter>
                                        {allView || allDetailedView ? (
                                            <TableRow>
                                                <StyledTableCell style={{ width: "90%" }}></StyledTableCell>
                                                <StyledTableCell style={{ width: "10%" }}>
                                                    <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
                                                        Total: ${getAllTotal()}
                                                    </Typography>
                                                </StyledTableCell>
                                            </TableRow>
                                        ) : selectedTeacher ? (
                                            <TableRow>
                                                <StyledTableCell style={{ width: "10%" }}></StyledTableCell>
                                                <StyledTableCell style={{ width: "10%" }}></StyledTableCell>
                                                <StyledTableCell style={{ width: "50%" }}></StyledTableCell>
                                                <StyledTableCell style={{ width: "10%" }}></StyledTableCell>
                                                <StyledTableCell style={{ width: "10%" }}></StyledTableCell>
                                                <StyledTableCell style={{ width: "10%" }}>
                                                    <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
                                                        Total: ${Object.values(totalDollars[selectedTeacher.id]).reduce((a, b) => a + b, 0).toFixed(2)}
                                                    </Typography>
                                                    {selectedTeacher && confirmedTotals[selectedTeacher.id] && confirmedTotals[selectedTeacher.id].total !== null ? (
                                                        <Button
                                                            variant="contained"
                                                            color="error"
                                                            size='small'
                                                            sx={{
                                                                mt: 2,
                                                                width: "100%",
                                                            }}
                                                            onClick={() => {
                                                                setConfirmedTotals({
                                                                    ...confirmedTotals,
                                                                    [selectedTeacher.id]: {
                                                                        ...confirmedTotals[selectedTeacher.id],
                                                                        total: null,
                                                                    },
                                                                });
                                                            }}
                                                        >
                                                            Cancel Confirm
                                                        </Button>
                                                    ) : selectedTeacher && confirmedTotals[selectedTeacher.id] && confirmedTotals[selectedTeacher.id].total === null ? (
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            size='small'
                                                            sx={{
                                                                mt: 2,
                                                            }}
                                                            onClick={() => {
                                                                const total = Object.values(totalDollars[selectedTeacher.id]).reduce((a, b) => a + b, 0).toFixed(2);
                                                                setConfirmedTotals({
                                                                    ...confirmedTotals,
                                                                    [selectedTeacher.id]: {
                                                                        ...confirmedTotals[selectedTeacher.id],
                                                                        total: total,
                                                                    },
                                                                });
                                                            }}
                                                        >
                                                            Confirm
                                                        </Button>
                                                    ) : null}
                                                </StyledTableCell>
                                            </TableRow>
                                        ) : null}
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                    {allDetailedView && (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={generatePDF}
                            sx={{
                                mt: 4,
                                width: "100%",
                                maxWidth: "140px",
                                ml: "auto",
                            }}
                        >
                            Generate PDF
                        </Button>
                    )}
                </Box>
            }
            <Alert
                successAlertOpen={successAlertOpen}
                setSuccessAlertOpen={setSuccessAlertOpen}
                errorAlertOpen={errorAlertOpen}
                setErrorAlertOpen={setErrorAlertOpen}
                successMsg={successAlertMessage}
                errorMsg={errorAlertMessage}
                vertical='bottom'
                horizontal='left'
            />
        </Box>
    )
}

export default PaycheckCalculator