import * as React from 'react';
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import VideocamIcon from '@mui/icons-material/Videocam';
import ModalTabs from './ModalTabs';

export default function MoreInfoModal({ zoomRoom, setZoomRoomUpdated }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1000,
        height: 800,
        bgcolor: colors.primary[600],
        border: '2px solid grey',
        boxShadow: 24,
        p: 4,
    };
    
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div>
            <Button size="small" style={{"backgroundColor": colors.primary[800], "color": colors.primary[400]}} onClick={handleOpen}>
                More Info
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                  backdrop: {
                    timeout: 500,
                  },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Box display="flex" gap={1} alignItems="center" borderBottom={1}>
                            <VideocamIcon fontSize="large" />
                            <Typography id="modal-modal-title" variant="h2" ml={1}>
                                Zoom {zoomRoom.room_number}
                            </Typography>
                        </Box>
                        <Typography id="modal-modal-description" variant="h6" mt={1} ml={1} color={"grey"}>
                            Meeting ID: {zoomRoom.meeting_id}
                        </Typography>
                        <Typography id="modal-modal-description" variant="h6" ml={1} color={"grey"}>
                            Link: {zoomRoom.link}
                        </Typography>
                        <Typography id="modal-modal-description" variant="h6" ml={1} color={"grey"}>
                            Password: {zoomRoom.password}
                        </Typography>
                        <Typography id="modal-modal-description" variant="h6" ml={1} color={"grey"}>
                            Note: {zoomRoom.note}
                        </Typography>
                        <Typography id="modal-modal-description" variant="h6" mb={1} ml={1} color={"grey"}>
                            Remark: {zoomRoom.remark}
                        </Typography>
                        <ModalTabs zoomRoom={zoomRoom} setZoomRoomUpdated={setZoomRoomUpdated} />
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}