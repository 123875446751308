import React from "react";
import { Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Axios from "axios";
import TextEditor from "./TextEditor";

const MainMemoComp = ({
    userId,
    socket,
    colors,
    mainMemo,
    setMainMemo,
    enqueueSnackbar,
    handleLogout,
//   mainMemoUpdated,
//   setMainMemoUpdated,
}) => {

    React.useEffect(() => {
        async function fetchMainMemos() {
            try {
                const res = await Axios.get(`${process.env.REACT_APP_URL}/api/v1/weekly/main-memos`,{
                    headers: {
                        Authorization: `Bearer ${
                            document.cookie?.split("=")[1].split(";")[0]
                        }`,
                    },
                });

                setMainMemo(res.data);
                // setMainMemoUpdated(false);
            } catch (err) {
                if (err.response?.status === 401) {
                    handleLogout();
                } else {
                    const error_msg =
                        err.response?.data?.message ||
                        err.response?.data?.error ||
                        "Error fetching main memos";
                    enqueueSnackbar(error_msg, { variant: "error" });
                }
            }
        }

        fetchMainMemos();
    }, []);

    // Handle adding a new memo
    const handleAddMainMemo = async () => {
        try {
            socket.emit("post.WeeklyMainMemos", {
                index: mainMemo.length + 1,
            });
        } catch (err) {
            console.log(err);
        }
    };


  return (
        <>
            {mainMemo.map((memoBox, idx) => {

                return (
                    <Box
                        key={idx}
                        sx={{
                            display: "flex",
                            position: "relative",
                        }}
                    >
                        <TextEditor
                            memo={memoBox.memo}
                            memoId={memoBox.id}
                            socket={socket}
                            userId={userId}
                            activeUsersNum={memoBox.active_users_num}
                            colors={colors}
                        />

                        {/* Show add button only for the latest text editor */}
                        {idx === mainMemo.length - 1 && (
                            <Box
                                sx={{
                                    position: "absolute",
                                    right: -20,
                                    top: "50%",
                                    transform: "translateY(-50%)",          
                                    zIndex: 0,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    cursor: "pointer",
                                    backgroundColor: "lightgray",
                                    borderRadius: "50%",
                                    padding: 1,
                                }}
                                onClick={handleAddMainMemo}
                            >
                                <AddIcon fontSize="large" sx={{ color: "black" }} />
                            </Box>
                        )}
                    </Box>
                );
            })}
            {mainMemo.length === 0 && (
                <Box
                    sx={{
                        position: "absolute",
                        left: "100px",
                        top: "300px",
                        zIndex: 0,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        backgroundColor: "lightgray",
                        borderRadius: "50%",
                        padding: 1,
                    }}
                    onClick={handleAddMainMemo}
                >
                    <AddIcon fontSize="large" sx={{ color: "black" }} />
                </Box>
            )}
        </>
    );
};

export default MainMemoComp;
