import React from 'react'
import { Box, IconButton, Button, Typography, useTheme, TextField, Divider, Autocomplete } from "@mui/material";
import { tokens } from "../../theme";
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import Grid from '@mui/material/Grid';
import AddMemoDatePicker from "../students/moreInfo/AddMemoDatePicker";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Popover from '@mui/material/Popover';
import Axios from "axios";
import Alert from '../../components/alert/Alert';
import FormControlLabel from '@mui/material/FormControlLabel';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Checkbox from '@mui/material/Checkbox';
import dayjs from 'dayjs';
import { countryPhoneCodes } from '../weekly/studentButtonMenu/countryPhoneCode';

const QuickAdd = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const gridItemStyle2 = {
        '& > :not(style)': { m: 1, width: '20rem' }
    };

    const [potentialStudentCreated, setPotentialStudentCreated] = React.useState(false);

    // for alert
    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [successMsg, setSuccessMsg] = React.useState('');
    const [errorMsg, setErrorMsg] = React.useState('');

    /*
     * popover for quick add
     */ 
    const [quickAddAnchorEl, setQuickAddAnchorEl] = React.useState(null);
    const handleQuickAddClick = (event) => { setQuickAddAnchorEl(event.currentTarget) };
    const handleQuickAddClose = () => { setQuickAddAnchorEl(null) };
    const quickAddOpen = Boolean(quickAddAnchorEl);
    const quickAddId = quickAddOpen ? 'simple-popover' : undefined;

    // for parent country phone code
    const [parentCountryPhoneCode, setParentCountryPhoneCode] = React.useState(countryPhoneCodes.find((countryPhoneCode) => countryPhoneCode.name === "United States"));

    function isNumeric(value) {
        return /^-?\d+$/.test(value);
    }

    const handleQuickAdd = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let student_uuid = null;

        // should it be not null and forced to put in the form?
        let consultation_number = data.get('consultation_number');
        if (consultation_number && consultation_number === "") {
            consultation_number = null;
        }

        if (consultation_number && !isNumeric(consultation_number)) {
            setErrorMsg("Consultation number should be numeric!");
            setErrorAlertOpen(true);
            return;
        }

        if (data.get('parent_phone') !== "" && parentCountryPhoneCode === null) {
            setErrorMsg("Please select a country phone code!");
            setErrorAlertOpen(true);
            return;
        }

        await Axios.get(`${process.env.REACT_APP_URL}/api/v1/students/consultation-number/check/${consultation_number}`, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then(async (response) => {
            if (response.data.exists) {
                if (!window.confirm("Consultation number already exists. Do you want to continue?")) {
                    return;
                }
            }

            await Axios.post(`${process.env.REACT_APP_URL}/api/v1/students/quick-add`, {
                consultation_number: consultation_number,
                parent_phone: data.get('parent_phone'),
                parent_email: data.get('parent_email'),
                parent_gender: gender,
                parent_country_phone_code: parentCountryPhoneCode ? parentCountryPhoneCode.code : "+1",
            }, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then(async (response) => {
                student_uuid = response.data.student_uuid;
                if (data.get('title') !== "" || data.get('content') === "") {
                    await Axios.post(`${process.env.REACT_APP_URL}/api/v1/students/${student_uuid}/memos`, {
                        title: data.get('title'),
                        content: data.get('content'),
                        reminder: reminderDate !== null ? reminderDate.toDate() : null,
                    }, {
                        headers: {
                            Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                        },
                    }).then(async (response) => {
                        await Axios.post(`${process.env.REACT_APP_URL}/api/v1/students/${student_uuid}/info-mails`, {
                            date: checked ? dayjs(infoMailDate) : null,
                            is_sent: checked ? true : false,
                        }, {
                            headers: {
                                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                            },
                        }).then((response) => {
                            setSuccessMsg("Successfully added a student with memo!");
                            setSuccessAlertOpen(true);
                            setPotentialStudentCreated(true);
                            reinitialize();
                        }).catch((error) => {
                            setErrorMsg(error.response.data.message || error.response.data.error);
                            setErrorAlertOpen(true);
                        });
                    }).catch((error) => {
                        setErrorMsg(error.response.data.message || error.response.data.error);
                        setErrorAlertOpen(true);
                    });
                } else {
                    setSuccessMsg("Successfully added a student!");
                    setSuccessAlertOpen(true);
                    setPotentialStudentCreated(true);
                    reinitialize();
                }
            }).catch((error) => {
                console.log(error.response.data.message || error.response.data.error);
                setErrorMsg(error.response.data.message || error.response.data.error);
                setErrorAlertOpen(true);
            });    
        }).catch((error) => {
            console.log(error.response.data.message || error.response.data.error);
            setErrorMsg(error.response.data.message || error.response.data.error);
            setErrorAlertOpen(true);
            return;
        });
    };

    // for reminder
    const [reminderDate, setReminderDate] = React.useState(null);

    // for info mail
    const [checked, setChecked] = React.useState(false);
    const handleCheckBoxChange = (event) => {
        setChecked(event.target.checked);
    };
    const [infoMailDate, SetInfoMailDate] = React.useState(null);

    // for parent gender
    const [gender, setGender] = React.useState('');
    const handleGenderChange = (event) => {
        setGender(event.target.value);
    };

    const reinitialize = () => {
        setReminderDate(null);
        setGender('');
        document.getElementById("quick-add-form").reset();
    }

    // for consultation number
    const [newConsultationNumber, setNewConsultationNumber] = React.useState(null);

    React.useEffect(() => {
        async function fetchLastNumberOfConsNum() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/students/consultation-number/last-number`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                let lastNumber = response.data;
                if (lastNumber === null) {
                    lastNumber = 0;
                }
                setNewConsultationNumber(lastNumber + 1);
            }).catch((error) => {
                console.log(error.response.data.message || error.response.data.error);
                
                if (error.response.status === 404) {
                    // ignore because it means there is no consultation number
                }
            });
        }

        fetchLastNumberOfConsNum();
        setPotentialStudentCreated(false);
    }, [potentialStudentCreated]);


    return (<>
        <IconButton onClick={handleQuickAddClick}>
            <PersonAddAltOutlinedIcon />
        </IconButton>
        <Popover
            id={quickAddId}
            open={quickAddOpen}
            anchorEl={quickAddAnchorEl}
            onClose={handleQuickAddClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            sx={{
                '& .MuiPopover-paper': {
                    border: '1px solid',
                    borderRadius: '20px',
                    backgroundColor: colors.primary[750],

                },
            }}
        >
            <Box 
                component="form" 
                id='quick-add-form'
                onSubmit={handleQuickAdd} 
                noValidate
                sx={{ 
                    p: 3, 
                    width: 1000,
                    display: "flex", 
                    flexDirection: "column", 
                }}
            >
                <Typography variant="h4" fontWeight="bold" sx={{ mx: "auto", mb: 1 }}>
                    Quick Add for Potential Students
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <Box 
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <Grid container direction="column" alignItems="center" flex={6}>
                        <Typography variant="h6" fontWeight="bold">
                            Memo
                        </Typography>
                        <Grid item sx={{ display: "flex", gap: "1rem", my: "0.5rem" }}>
                            <TextField
                                id="filled-basic"
                                label="Title"
                                variant="filled"
                                name='title'
                                sx={{ width: "20rem" }}
                            />
                            <AddMemoDatePicker reminderDate={reminderDate} setReminderDate={setReminderDate} />
                        </Grid>
                        <Grid item sx={{display: "flex", gap: "1rem", my: "0.5rem"}}>
                            <TextField
                                id="filled-basic"
                                label="Memo"
                                variant="filled"
                                name='content'
                                multiline={true}
                                rows={8}
                                sx={{
                                    borderRadius: "5px",
                                    width: "20rem",
                                }}
                            />
                            <Box sx={{ display: "flex", flexDirection: "column", mt: 1 }}>
                                <FormControlLabel
                                    control={<Checkbox checked={checked} onChange={handleCheckBoxChange} />}
                                    label="Did you send a class information email?"
                                    sx={{ width: "100%" }}
                                />
                                {checked && (
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="Date for the information email to be sent"
                                            value={infoMailDate}
                                            onChange={(newValue) => SetInfoMailDate(newValue)}
                                            sx={{
                                                width: "100%"
                                            }}
                                        />
                                    </LocalizationProvider>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container direction="column" alignItems="center" flex={4}>
                        <Typography variant="h6" fontWeight="bold">
                            Infomation
                        </Typography>
                        <Grid item sx={gridItemStyle2}>
                            <TextField
                                id="consultation_number"
                                name="consultation_number"
                                label="Consultation Number"
                                variant="filled"
                                value={newConsultationNumber}
                                onChange={(e) => setNewConsultationNumber(e.target.value)}
                            />
                        </Grid>
                        <Grid item sx={gridItemStyle2}>
                            <Autocomplete
                                id="country-phone-code"
                                value={parentCountryPhoneCode}
                                options={countryPhoneCodes}
                                getOptionLabel={(option) => option.code + " " + option.name}
                                sx={{ width: "50%" }}
                                renderInput={(params) => <TextField {...params} label="Country Phone Code" />}
                                onChange={(e, value) => {
                                    setParentCountryPhoneCode(value);
                                }}
                                isOptionEqualToValue={(option, value) => option.code === value.code}
                            />
                            <TextField
                                id="parent_phone"
                                name="parent_phone"
                                label="Parent's Phone"
                                variant="filled"
                            />
                        </Grid>
                        <Grid item sx={gridItemStyle2}>
                            <TextField
                                id="parent_email"
                                name="parent_email"
                                label="Parent's Email"
                                variant="filled"
                            />
                        </Grid>
                        <Grid item sx={gridItemStyle2}>
                            <FormControl variant="filled" sx={{ gridColumn: "span 1" }}>
                                <InputLabel id="demo-simple-select-filled-label">Parent's Gender</InputLabel>
                                <Select
                                    labelId="demo-simple-select-filled-label"
                                    id="demo-simple-select-filled"
                                    value={gender}
                                    onChange={handleGenderChange}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={"Male"}>Male</MenuItem>
                                    <MenuItem value={"Female"}>Female</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
                <Button type='submit' color="secondary" variant="contained" sx={{ width: "20rem", mx: "auto", mt: 3 }} >
                    Add Student with Memo
                </Button>
            </Box>
            <Alert 
                successAlertOpen={successAlertOpen} 
                setSuccessAlertOpen={setSuccessAlertOpen}
                errorAlertOpen={errorAlertOpen}
                setErrorAlertOpen={setErrorAlertOpen}
                successMsg={successMsg}
                errorMsg={errorMsg}
                vert='top'
                horiz='right'
            />
        </Popover>
    </>)
}

export default QuickAdd