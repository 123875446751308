function isNumeric(value) {
    return /^-?\d+$/.test(value);
}

function isValidCountryCode(code) {
    const regex = /^\+\d+$/;
    return regex.test(code);
}

function getCountryPhoneCode(countryPhoneCode) {
    try {

        const splitedStrings = countryPhoneCode.split(" ");
        let code = "";
        for (let i = 0; i < splitedStrings.length - 1; i++) {

            // do not add if the string is not a number or "+" sign with the number
            if (!isNumeric(splitedStrings[i]) && !isValidCountryCode(splitedStrings[i])) {
                continue;
            }

            if (i !== 0) {
                code += " ";
            }

            code += splitedStrings[i];
        }

        return code;

    } catch (error) {
        console.error("Error in getCountryPhoneCode: ", error);
    }

    return "";
}

export default getCountryPhoneCode;