import React from 'react'
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Box, useTheme, Typography } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Autocomplete from '@mui/material/Autocomplete';
import { tokens } from "../../../theme";
import dayjs from 'dayjs';
import Axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';

const AddStudentModal = ({ 
    setAnchorEl, 
    course, 
    setInfoUpdated,
    setBackdropOpen,
    setSuccessAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertOpen,
    setErrorAlertMessage
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [firstRender, setFirstRender] = React.useState(true);

    // for modal
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => { setOpen(false); setAnchorEl(null); };

    // for adding and removing a student
    const [addStudent, setAddStudent] = React.useState(null);
    // const [removeStudent, setRemoveStudent] = React.useState(null);

    // for students not in course for autocomplete options
    const [studentsNotInCourse, setStudentsNotInCourse] = React.useState(null);

    // for start and end date to add a student
    const [startDate, setStartDate] = React.useState(dayjs().startOf('day').locale('en'));
    const [endDate, setEndDate] = React.useState(dayjs().endOf('day').locale('en'));

    const handleAddStudent = async () => {
        if (addStudent === null) {
            setErrorAlertMessage("Please select a student to add.");
            setErrorAlertOpen(true);
            return;
        }

        if (startDate === null) {
            setErrorAlertMessage("Please select a start date.");
            setErrorAlertOpen(true);
            return;
        }

        if (endDate === null) {
            setErrorAlertMessage("Please select an end date.");
            setErrorAlertOpen(true);
            return;
        }

        if (startDate.isAfter(endDate)) {
            setErrorAlertMessage("Start date cannot be after end date.");
            setErrorAlertOpen(true);
            return;
        }

        if (startDate.isBefore(dayjs(course.start_date).startOf('day')) || endDate.startOf('day').isAfter(dayjs(course.end_date).endOf('day'))) {
            setErrorAlertMessage("Start and end date must be within course's start and end date.");
            setErrorAlertOpen(true);
            return;
        }

        setBackdropOpen(true);
        await Axios.post(`${process.env.REACT_APP_URL}/api/v1/courses/${course.uuid}/students/${addStudent.uuid}`, {
            start_date: startDate,
            end_date: endDate
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setSuccessAlertMessage(response.data.message);
            setSuccessAlertOpen(true);
            setBackdropOpen(false);
            handleClose();

            setTimeout(() => {
                setInfoUpdated(true);
            }, 1000);
        }).catch((error) => {
            setErrorAlertMessage(error.response.data.message);
            setErrorAlertOpen(true);
            setBackdropOpen(false);
        });
    };

    async function fetchStudentsNotInCourse() {
        await Axios.get(`${process.env.REACT_APP_URL}/api/v1/courses/${course.uuid}/other-students`, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setStudentsNotInCourse(response.data.map((student) => {
                return {
                    id: student.id,
                    uuid: student.uuid,
                    name: student.korean_name !== null ? student.first_name + " " + student.korean_name + " " + student.last_name : student.first_name + " " + student.last_name,
                    consultation_number: student.consultation_number,
                }
            }));
            setFirstRender(false);
        }).catch((error) => {
            setErrorAlertMessage(error.response.data.message);
            setErrorAlertOpen(true);
            setFirstRender(false);
        });
    }

    if (firstRender)
        fetchStudentsNotInCourse();

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: colors.primary[600],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <div>
            <Button onClick={handleOpen} sx={{ p: 1, width: "100%" }}>Add Student</Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography variant="h4" color={colors.primary[450]}>
                            Add a student to {course.course_title}
                        </Typography>
                        <CloseIcon sx={{ position: "absolute", right: 10, top: 10, cursor: "pointer" }} onClick={handleClose} />
                        <Box display="flex" flexDirection="column" gap={3} width="100%" pt={2}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo-1"
                                    getOptionLabel={(option) => option.consultation_number ? 
                                        `${option.name} (Consult #: ${option.consultation_number})`:`${option.name}`}
                                    renderOption={(props, option) => (
                                        <li {...props}>
                                            {option.name} 
                                            {option.consultation_number && (
                                                <>
                                                    &nbsp;(<span style={{ color: "green" }}> Consult #: {option.consultation_number}</span>)
                                                </>
                                            )}
                                        </li>
                                    )}
                                    options={studentsNotInCourse !== null ? studentsNotInCourse : []}
                                    renderInput={(params) => <TextField {...params} label="Add Student" />}
                                    onChange={(event, value) => setAddStudent(value)} 
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    sx={{ width: "100%", pr: "5rem" }}
                                />
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="From"
                                        value={startDate}
                                        onChange={(newValue) => setStartDate(newValue)}
                                        sx={{ width: "100%", pr: "5rem" }}
                                    />
                                </LocalizationProvider>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="To"
                                        value={endDate}
                                        onChange={(newValue) => setEndDate(newValue)}
                                        sx={{ width: "100%", pr: "5rem" }}
                                    />
                                </LocalizationProvider>
                                <Button
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    sx={{ mt: 2, width: "7rem" }}
                                    onClick={handleAddStudent}
                                >
                                    Add
                                </Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </div>
    )
}

export default AddStudentModal