import React from 'react'
import Button from '@mui/material/Button';
// import AreYouSureModal from '../../../components/AreYouSureModal';

const ModuleRecordedButton = ({ 
    userId,
    zoom_id,
    date,
    _time,
    event_idx,
    event,
    colors, 
    handlePopOverClose,
    enqueueSnackbar,
    socketConnected,
    socket,
}) => {
    // const [areYouSureModalOpen, setAreYouSureModalOpen] = React.useState(false);
    const [isRecorded, setIsRecorded] = React.useState(event.is_recorded || false);

    React.useEffect(() => {
        if (event) {
            setIsRecorded(event.is_recorded || false);
        }
    }, [event]);

    const handleSave = async () => {
        if (!event) {
            enqueueSnackbar("Error: unexpected error occurred. Please refresh the page.", { variant: "error" });
            return;
        }

        if (!socketConnected) {
            enqueueSnackbar("Socket is not connected. Please refresh the page.", { variant: "error" });
            return;
        }

        const is_recorded = !isRecorded; // toggle

        try {
            setTimeout(() => {
                // emit to server
                socket.emit("put.EventRecorded", {
                    admin_uuid: userId,
                    zoom_id: zoom_id,
                    date: date,
                    _time: _time,
                    event_idx: event_idx,
                    event: event,
                    is_recorded: is_recorded,
                });
            }, 300);
        } catch (error) {
            console.error(error);
            enqueueSnackbar("Error: Cannot save the changes. Please try again.", { variant: "error" });
            return;
        }

        handlePopOverClose();
    };

    return (
        <>
            <Button 
                onClick={() => handleSave()}
                sx={{ 
                    p: 1, 
                    width: "100%",
                    bgcolor: colors.yellowAccent[800],
                    borderRadius: "0px",
                    '&:hover': {
                        bgcolor: colors.yellowAccent[700],
                    },
                }}
            >
                {isRecorded === false ? "Set as Recording Uploaded on Drive" : "Set as Not Recording Uploaded on Drive"}
            </Button>
            {/* <AreYouSureModal
                colors={colors}
                open={areYouSureModalOpen}
                setOpen={setAreYouSureModalOpen}
                title={isRecorded === false ? "Do you want to set this as Recording Uploaded on Drive? (Not on Classroom)" : "Do you want to set this as Not Recording Uploaded on Drive?"}
                message="Are you sure you want to proceed?"
                onConfirm={handleSave}
            /> */}
        </>
    )
}

export default ModuleRecordedButton