// service/socket.js
import socketio from "socket.io-client";
import React from "react";

export const socket = socketio(process.env.REACT_APP_URL, {
    autoConnect: false,
    extraHeaders: {
        Authorization: "Bearer " + document?.cookie?.split("=")[1]?.split(";")[0] ?? null,
    },
});
export const SocketContext = React.createContext();