import React from 'react';
import Button from '@mui/material/Button';
import { Box, TextField, Typography } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CloseIcon from '@mui/icons-material/Close';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
// import AreYouSureModal from '../../../components/AreYouSureModal';
import getCountryPhoneCode from '../../../utils/getCountryPhoneCode';


function AttendanceButtonModal({
    admin_name,
    userId,
    zoom_id,
    date,
    _time,
    event_idx,
    event,
    attendance,
    enqueueSnackbar,
    colors,
    handlePopOverClose,
    socketConnected,
    socket,
    mobileMessageTemplates,
}) {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        maxHeight: '80%',
        overflowY: 'auto',
        bgcolor: colors.primary[600],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    // const [areYouSureModalOpen, setAreYouSureModalOpen] = React.useState(false);
    
    const [status, setStatus] = React.useState(attendance.status);
    const [note, setNote] = React.useState(attendance.note ? attendance.note : "");
    const [needZoomRecording, setNeedZoomRecording] = React.useState(attendance.need_zoom_recording ? attendance.need_zoom_recording : false);
    const [autoTextMessage, setAutoTextMessage] = React.useState(false);
    const [textMessage, setTextMessage] = React.useState(
        mobileMessageTemplates && mobileMessageTemplates.length > 0 && mobileMessageTemplates.find((item) => item.is_late_attendance_students === true) ? mobileMessageTemplates.find((item) => item.is_late_attendance_students === true)?.template : ""
    );
    const [textMessageToParents, setTextMessageToParents] = React.useState(
        mobileMessageTemplates && mobileMessageTemplates.length > 0 && mobileMessageTemplates.find((item) => item.is_late_attendance_students === true) ? mobileMessageTemplates.find((item) => item.is_late_attendance_parents === true)?.template : ""
    );
    const [student, setStudent] = React.useState(attendance.student);
    const [selectedPhoneNumbers, setSelectedPhoneNumbers] = React.useState(
        // only student
        attendance.student && attendance.student.phone && attendance.student.phone !== "" ? [(getCountryPhoneCode(attendance.student.country_phone_code) + attendance.student.phone).replace(/-/g, "").replace(/ /g, "").replace(/\(/g, "").replace(/\)/g, "")] : []
    );
    const [selectedParentPhoneNumbers, setSelectedParentPhoneNumbers] = React.useState(
        getParentPhoneNumbersAndType(attendance.student)
    );

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => { setOpen(false); handlePopOverClose(); };

    React.useEffect(() => {
        if (attendance) {
            setStatus(attendance.status);
            setNote(attendance.note ? attendance.note : "");
            setNeedZoomRecording(attendance.need_zoom_recording ? attendance.need_zoom_recording : false);
            setAutoTextMessage(false);
            setTextMessage(
                mobileMessageTemplates && mobileMessageTemplates.length > 0 && mobileMessageTemplates.find((item) => item.is_late_attendance_students === true) ? mobileMessageTemplates.find((item) => item.is_late_attendance_students === true)?.template : ""
            );
            setTextMessageToParents(
                mobileMessageTemplates && mobileMessageTemplates.length > 0 && mobileMessageTemplates.find((item) => item.is_late_attendance_students === true) ? mobileMessageTemplates.find((item) => item.is_late_attendance_parents === true)?.template : ""
            );
            setStudent(attendance.student);
        }
    }, [attendance]);

    function handleChange(e, newStatus) {
        e.preventDefault();

        /**
         * when status 1 (present) or 2 (late) and newStatus is 1 (present) or 2 (late)
         * then set status to 5, which is "late but present"
         */

        if (status === newStatus) {
            setStatus(null);
        } else if ((status === 1 && newStatus === 2) || (status === 2 && newStatus === 1)) {
            setStatus(5);
        } else if (status === 5 && (newStatus === 1 || newStatus === 2)) {
            setStatus(newStatus === 1 ? 2 : 1);
        } else {
            setStatus(newStatus);
        }

        // not late
        if (newStatus !== 2) {
            setAutoTextMessage(false);
            setSelectedPhoneNumbers([]);
            setSelectedParentPhoneNumbers([]);
            setTextMessage(
                mobileMessageTemplates && mobileMessageTemplates.length > 0 && mobileMessageTemplates.find((item) => item.is_late_attendance_students === true) ? mobileMessageTemplates.find((item) => item.is_late_attendance_students === true)?.template : ""
            );
            setTextMessageToParents(
                mobileMessageTemplates && mobileMessageTemplates.length > 0 && mobileMessageTemplates.find((item) => item.is_late_attendance_students === true) ? mobileMessageTemplates.find((item) => item.is_late_attendance_parents === true)?.template : ""
            );
        }

        if (newStatus === 4) {
            setNeedZoomRecording(true);
        }
    };

    const handleSelectPhone = (e, phone) => {
        const _phone = phone.replace(/-/g, "").replace(/ /g, "").replace(/\(/g, "").replace(/\)/g, "");

        if (e.target.checked) {
            setSelectedPhoneNumbers([...selectedPhoneNumbers, _phone]);
        } else {
            setSelectedPhoneNumbers(selectedPhoneNumbers.filter((item) => item !== _phone));
        }
    };

    const handleParentSelectPhone = (e, phone, type) => {
        const _phone = phone.replace(/-/g, "").replace(/ /g, "").replace(/\(/g, "").replace(/\)/g, "");
        const obj = {
            phone: _phone,
            type: type,
        };

        if (e.target.checked) {
            setSelectedParentPhoneNumbers([...selectedParentPhoneNumbers, obj]);
        } else {
            setSelectedParentPhoneNumbers(selectedParentPhoneNumbers.filter((item) => {
                console.log(item.phone, _phone, item.type, type);
                return item.phone !== _phone || item.type !== type;
            }));
        }
    };

    async function handleSave() {
        if (!socketConnected) {
            enqueueSnackbar("Error: Cannot save the changes. Please try again.", { variant: "error" });
            return;
        }

        if (autoTextMessage === true && selectedPhoneNumbers.length === 0 && selectedParentPhoneNumbers.length === 0) {
            enqueueSnackbar("Please select at least one phone number.", { variant: "error" });
            return;
        }

        if (autoTextMessage === true && (selectedPhoneNumbers.length > 0 && textMessage === "")) {
            enqueueSnackbar("Please enter a message for the student.", { variant: "error" });
            return;
        }

        if (autoTextMessage === true && (selectedParentPhoneNumbers.length > 0 && textMessageToParents === "")) {
            enqueueSnackbar("Please enter a message for the parent(s).", { variant: "error" });
            return;
        }

        if (autoTextMessage === true && textMessage.length > 160) {
            enqueueSnackbar("Message is too long. Please keep it under 160 characters.", { variant: "error" });
            return;
        }

        if (autoTextMessage === true && textMessageToParents.length > 160) {
            enqueueSnackbar("Message is too long. Please keep it under 160 characters.", { variant: "error" });
            return;
        }

        try {
            // emit to server
            socket.emit("put.AttendanceUpdate", {
                id: attendance.id,
                date: date,
                status: status,
                note: note,
                need_zoom_recording: needZoomRecording,
                course_id: event.course_id,
                auto_text_message: autoTextMessage ? true : false,
                text_message: autoTextMessage ? textMessage : null,
                phone_numbers: autoTextMessage ? selectedPhoneNumbers : null,
                text_message_to_parents: autoTextMessage ? textMessageToParents : null,
                parent_phone_numbers: autoTextMessage ? selectedParentPhoneNumbers.map((obj) => {
                    return obj.phone;
                }) : null,
            });
        } catch (error) {
            console.error(error);
            enqueueSnackbar("Error: Cannot save the changes. Please try again.", { variant: "error" });
            return;
        }

        if (autoTextMessage) {
            try {
                // emit to server
                socket.emit("send.MobileMessage", {
                    phone_numbers: selectedPhoneNumbers,
                    message: textMessage,
                    student: student,
                    attendance_id: attendance.id,
                    admin_name: admin_name,
                });
            } catch (error) {
                console.error(error);
                enqueueSnackbar("Error: Cannot send text message. Please try again.", { variant: "error" });
                return;
            }
        }

        handleClose();
    };

    function getParentPhoneNumbersAndType(student) {
        const phoneNumbers = [];
        if (student && student.parent_phone && student.parent_phone !== "") {
            const number = getCountryPhoneCode(student.parent_country_phone_code) + student.parent_phone;

            const obj = {
                phone: number.replace(/-/g, "").replace(/ /g, "").replace(/\(/g, "").replace(/\)/g, ""),
                type: "parent_1",
            };
            phoneNumbers.push(obj);
        }
        if (student && student.parent_phone_2 && student.parent_phone_2 !== "") {
            const number = getCountryPhoneCode(student.parent_country_phone_code_2) + student.parent_phone_2;
            const obj = {
                phone: number.replace(/-/g, "").replace(/ /g, "").replace(/\(/g, "").replace(/\)/g, ""),
                type: "parent_2",
            };
            phoneNumbers.push(obj);
        }
        return phoneNumbers;
    }

    function seeIfParentPhoneIsSelected(phone, type) {
        const _phone = phone.replace(/-/g, "").replace(/ /g, "").replace(/\(/g, "").replace(/\)/g, "");
        const found = selectedParentPhoneNumbers.find((item) => item.phone === _phone && item.type === type);
        return found ? true : false;
    }

    return (
        <>
            <Button 
                onClick={handleOpen} 
                sx={{ 
                    p: 1, 
                    width: "100%",
                    bgcolor: colors.greenAccent[800],
                    borderRadius: "0px",
                    '&:hover': {
                        bgcolor: colors.greenAccent[900],
                    },
                }}
            >
                Attendance
            </Button>
            <Modal
                aria-labelledby="attendance-modal"
                aria-describedby="attendance-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography variant="h4" color={colors.primary[450]}>
                            Attendance
                        </Typography>
                        <CloseIcon sx={{ position: "absolute", right: 10, top: 10, cursor: "pointer" }} onClick={handleClose} />
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", mt: 2 }}>
                            <FormGroup row>
                                <FormControlLabel 
                                    sx={{
                                        color: colors.grey[600],
                                    }}
                                    control={
                                        <Checkbox
                                            checked={status === null}
                                            onChange={(e) => handleChange(e, null)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            sx={{
                                                color: colors.grey[600],
                                                '&.Mui-checked': {
                                                    color: colors.grey[600],
                                                },
                                            }}
                                        />
                                    } 
                                    label="Undecide" 
                                    labelPlacement="top"
                                />
                                <FormControlLabel 
                                    sx={{
                                        color: colors.greenAccent[300],
                                    }}
                                    control={
                                        <Checkbox
                                            checked={status === 1 || status === 5}
                                            onChange={(e) => handleChange(e, 1)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            sx={{
                                                color: colors.greenAccent[300],
                                                '&.Mui-checked': {
                                                    color: colors.greenAccent[300],
                                                },
                                            }}
                                        />
                                    } 
                                    label="Present"
                                    labelPlacement="top"
                                />
                                <FormControlLabel 
                                    sx={{
                                        color: "#ff8f00",
                                    }}
                                    control={
                                        <Checkbox
                                            checked={status === 2 || status === 5}
                                            onChange={(e) => handleChange(e, 2)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            sx={{
                                                color: "#ff8f00",
                                                '&.Mui-checked': {
                                                    color: "#ff8f00",
                                                },
                                            }}
                                        />
                                    } 
                                    label="Late"
                                    labelPlacement="top"
                                />
                                <FormControlLabel 
                                    sx={{
                                        color: colors.redAccent[500],
                                    }}
                                    control={
                                        <Checkbox
                                            checked={status === 4}
                                            onChange={(e) => handleChange(e, 4)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            sx={{
                                                color: colors.redAccent[500],
                                                '&.Mui-checked': {
                                                    color: colors.redAccent[500],
                                                },
                                            }}
                                        />
                                    } 
                                    label="Absent (Group)"
                                    labelPlacement="top"
                                />
                            </FormGroup>
                            <TextField
                                label="Note"
                                multiline
                                rows={5}
                                variant="outlined"
                                value={note}
                                onChange={(e) => setNote(e.target.value)}
                                sx={{ width: 400, mt: 2 }}
                            />
                            <FormControlLabel 
                                control={
                                    <Checkbox
                                        checked={needZoomRecording}
                                        onChange={(e) => setNeedZoomRecording(e.target.checked)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                } 
                                label="Please check if this student needs a Zoom recording."
                            />
                            {status === 2 &&
                                <>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={autoTextMessage}
                                                onChange={(e) => {setAutoTextMessage(e.target.checked);}}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        }
                                        label="Auto send text message"
                                    />
                                    {autoTextMessage &&
                                        <>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "flex-start",
                                                    gap: 2,
                                                    mt: 2,
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        gap: 2,
                                                    }}
                                                >
                                                    <TextField
                                                        label="To Student"
                                                        multiline
                                                        rows={6}
                                                        variant="outlined"
                                                        value={textMessage}
                                                        onChange={(e) => setTextMessage(e.target.value)}
                                                        sx={{ width: 300, mt: 2 }}
                                                    />
                                                    {student && student.phone && student.phone !== "" && (
                                                        <FormControlLabel 
                                                            control={
                                                                <Checkbox
                                                                    checked={selectedPhoneNumbers.includes((getCountryPhoneCode(student.country_phone_code) + student.phone).replace(/-/g, "").replace(/ /g, "").replace(/\(/g, "").replace(/\)/g, ""))}
                                                                    onChange={(e) => handleSelectPhone(e, getCountryPhoneCode(student.country_phone_code) + student.phone)}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                />
                                                            } 
                                                            label={"Student: " + student.country_phone_code + " " + student.phone}
                                                            labelPlacement="end"
                                                        />
                                                    )}
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        gap: 2,
                                                    }}
                                                >
                                                    <TextField
                                                        label="To Parent(s)"
                                                        multiline
                                                        rows={6}
                                                        variant="outlined"
                                                        value={textMessageToParents}
                                                        onChange={(e) => setTextMessageToParents(e.target.value)}
                                                        sx={{ width: 300, mt: 2 }}
                                                    />
                                                    {student && student.parent_phone && student.parent_phone !== "" && (
                                                        <FormControlLabel 
                                                            control={
                                                                <Checkbox
                                                                    checked={seeIfParentPhoneIsSelected(getCountryPhoneCode(student.parent_country_phone_code) + student.parent_phone, "parent_1")}
                                                                    onChange={(e) => handleParentSelectPhone(e, getCountryPhoneCode(student.parent_country_phone_code) + student.parent_phone, "parent_1")}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                />
                                                            } 
                                                            label={"Parent 1: " + student.parent_country_phone_code + " " + student.parent_phone}
                                                            labelPlacement="end"
                                                        />
                                                    )}
                                                    {student && student.parent_phone_2 && student.parent_phone_2 !== "" && (
                                                        <FormControlLabel 
                                                            control={
                                                                <Checkbox
                                                                    checked={seeIfParentPhoneIsSelected(getCountryPhoneCode(student.parent_country_phone_code_2) + student.parent_phone_2, "parent_2")}
                                                                    onChange={(e) => handleParentSelectPhone(e, getCountryPhoneCode(student.parent_country_phone_code_2) + student.parent_phone_2, "parent_2")}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                />
                                                            } 
                                                            label={"Parent 2: " + student.parent_country_phone_code_2 + " " + student.parent_phone_2}
                                                            labelPlacement="end"
                                                        />
                                                    )}
                                                </Box>
                                            </Box>
                                        </>
                                    }
                                </>
                            }
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: 2,
                                mt: 2,
                            }}
                        >
                            <Button
                                variant="contained"
                                color="secondary"
                                sx={{ width: 200, mt: 2 }}
                                onClick={() => handleSave()}
                            >
                                Save
                            </Button>
                            {/* <AreYouSureModal
                                colors={colors}
                                open={areYouSureModalOpen}
                                setOpen={setAreYouSureModalOpen}
                                title="Are you sure?"
                                message="Are you sure you want to save the changes?"
                                onConfirm={handleSave}
                            /> */}
                            <Button
                                variant="contained"
                                color="error"
                                sx={{ width: 200, mt: 2 }}
                                onClick={handleClose}
                            >
                                Close
                            </Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </>
    )
}

export default AttendanceButtonModal;