import React from "react";
import TableBody from '@mui/material/TableBody';
import Axios from "axios";
import MemoTableBodyContent from "./MemoTableBodyContent";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";


const MemoTableBody = ({
    memos,
    student_uuid,
    setStudentUpdated,
    setSuccessAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertOpen,
    setErrorAlertMessage,
    socket = null,
    socketConnected = false,
    from_weekly = false,
    getStudentName,
}) => {
    const [items, setItems] = React.useState(memos);

    React.useEffect(() => {
         setItems(memos);
    }, [memos]);

    const handleDragEnd = async (result) => {

        const { destination, source } = result;

        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        const currentItems = Array.from(items);
        const [removed] = currentItems.splice(source.index, 1);
        currentItems.splice(destination.index, 0, removed);

        const reorderedItems = currentItems.map((item, index) => ({
            ...item,
            order: index
        }));

        setItems(reorderedItems);

        try {
            await Axios.put(
                `${process.env.REACT_APP_URL}/api/v1/students/${student_uuid}/memo/order`,
                { newMemos: reorderedItems },
                {
                    headers: {
                        Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                    },
                }
            ).then((response) => {
                if (!from_weekly) {
                    setStudentUpdated(true);
                } else if (from_weekly && socketConnected) {
                    // from weekly, so we need to emit to server (using socketio)
                    socket.emit("put.StudentUpdate", {
                        student_uuid: student_uuid,
                        message: " updated the order of memos of " + getStudentName(),
                    });
                }
            });
        } catch (error) {
            console.error(error);
            setErrorAlertMessage(error.response?.data?.message || "An error occurred");
            setErrorAlertOpen(true);
            // Revert the items in case of error
            setItems(items);
        }
    };

    
    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
          
        // change background colour if dragging
        // background: isDragging ? "lightgreen" : "",
      
        // styles we need to apply on draggables
        ...draggableStyle,
        // position: "static",
        top: `auto !important`,
        left: `auto !important`,
    });
      
    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="memos">
                {(provided, snapshot) => (
                    <TableBody
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        // style={getListStyle(snapshot.isDraggingOver)}
                    >
                        {Array.isArray(items) && items.length > 0 &&
                            items.sort(
                                (a, b) => {
                                    if (a.order === null && b.order === null) {
                                        return a.createdAt > b.createdAt ? -1 : 1;
                                    }
                                    if (a.order === null) {
                                        return -1;
                                    }
                                    if (b.order === null) {
                                        return 1;
                                    }
                                    return a.order < b.order ? -1 : 1;
                                }
                            ).map((memo, index) => (
                                <Draggable key={memo.id} draggableId={memo.id.toString()} index={index}>
                                    {(provided, snapshot) => (
                                        <MemoTableBodyContent
                                            key={memo.id}
                                            memo={memo}
                                            student_uuid={student_uuid}
                                            setStudentUpdated={setStudentUpdated}
                                            setSuccessAlertOpen={setSuccessAlertOpen}
                                            setSuccessAlertMessage={setSuccessAlertMessage}
                                            setErrorAlertOpen={setErrorAlertOpen}
                                            setErrorAlertMessage={setErrorAlertMessage}
                                            socket={socket}
                                            socketConnected={socketConnected}
                                            from_weekly={from_weekly}
                                            getStudentName={getStudentName}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style,
                                            )}
                                        />
                                    )}
                                </Draggable>
                            ))
                        }
                        {provided.placeholder}
                    </TableBody>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default MemoTableBody;