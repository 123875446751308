import React from 'react'
import { tokens } from "../../theme";
import { Box, useTheme, Button, Typography } from "@mui/material";
import Loading from '../../components/Loading';
import Header from "../../components/Header";
import Axios from 'axios';
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import Alert from '../../components/alert/Alert';
import MuiAlert from '@mui/material/Alert';
import Chip from '@mui/material/Chip';
import AddTimeModal from './AddTimeModal';
import dayjs from 'dayjs';
import RemoveTimeModal from './RemoveTimeModal';
import AddPotentialCourseModal from './AddPotentialCourseModal';
import EachPotentialCourse from './EachPotentialCourse';
import CombineCoursesModal from './CombineCoursesModal';
import AddPrivatePotentialCourseOrOther from './AddPrivatePotentialCourseOrOther';
import Snackbar from '@mui/material/Snackbar';
import CombinedSchedule from './CombinedSchedule';
import { useOutletContext } from 'react-router-dom';
import PopoverControl from './PopoverControl';

var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone');

dayjs.extend(utc)
dayjs.extend(timezone)

// 15 colors
const colors_for_combined_courses = [
    "#9568a5",
    "#55c68e",
    "#b5a1b4",
    "#533cc4",
    "#aa9e8c",
    "#6f728e",
    "#ba6c5d",
    "#c1ccbf",
    "#aaabdd",
    "#3a7d8e",
    "#6fce7f",
    "#756e24",
    "#5b722d",
    "#4a4ebf",
    "#6e889e",
]


const subjects = [
    "SAT & ACT",
    "AP & Competition",
    "High",
    "Middle",
]

const days = [
    "월",
    "화",
    "수",
    "목",
    "금",
    "토",
]

const eng_days_to_kor_days = {
    "monday": "월",
    "tuesday": "화",
    "wednesday": "수",
    "thursday": "목",
    "friday": "금",
    "saturday": "토",
}

// potential courses data structure
/**
 * {
 *    time: {
 *       day: {
 *          subject: [ courses ]
 *       }
 *    }  
 * }
 */

const season = {0: 'All', 1: 'Spring', 2: 'Summer', 3: 'Fall'};

const PotentialCourses = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // for 401 error
    const signOut = useSignOut();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut();
        localStorage.removeItem("userName");
        localStorage.removeItem("who");
        navigate("/signin");
    }

    // for group or private course button chip
    const [potentialCourseType, setPotentialCourseType] = React.useState(0);

    // for combine courses modal
    const [combineCoursesModalOpen, setCombineCoursesModalOpen] = React.useState(false);
    const handleCombineCoursesCoursesModalClose = () => setCombineCoursesModalOpen(false);

    // for add and remove time modal
    const [addTimeModalOpen, setAddTimeModalOpen] = React.useState(false);
    const handleClose = () => setAddTimeModalOpen(false);
    const [removeTimeModalOpen, setRemoveTimeModalOpen] = React.useState(false);
    const handleRemoveTimeModalClose = () => setRemoveTimeModalOpen(false);

    // for alert
    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = React.useState("");
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = React.useState("");

    // for scoll down alert
    const [scrollDownAlertOpen, setScrollDownAlertOpen] = React.useState(false);
    const handleCloseScollDownAlert = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setScrollDownAlertOpen(false);
    };

    const scroll = useOutletContext().scrollPosition;
    // for scroll detection
    const [timeLen, setTimeLen] = React.useState(0);
    const [boxOpen, setBoxOpen] = React.useState("");

    const [loading, setLoading] = React.useState(true);
    const [potentialSchedules, setPotentialSchedules] = React.useState([]); // used for updating potential courses
    const [semesters, setSemesters] = React.useState([]);
    const [selectedSemester, setSelectedSemester] = React.useState(null);
    const [potentialCourses, setPotentialCourses] = React.useState([]);
    const [combinedScheduleMap, setCombinedScheduleMap] = React.useState({}); // for combined schedule
    const [maxCourseLen, setMaxCourseLen] = React.useState(0); // for combined schedule
    const [occupationMap, setOccupationMap] = React.useState({}); // for combined schedule
    const [courseIdStudentCoursesMap, setCourseIdStudentCoursesMap] = React.useState({});
    const [times, setTimes] = React.useState([]);
    const [scheduleMap, setScheduleMap] = React.useState({});
    const [timeToIdMap, setTimeToIdMap] = React.useState({});
    const [beingModified, setBeingModified] = React.useState(-1); // for course memo

    const scrollToParam = window.localStorage.getItem("pc_potentialCourseUpdated") || null;
    const [firstRender, setFirstRender] = React.useState(true);

    const [color_map, setColorMap] = React.useState({});

    React.useEffect(() => {
        localStorage.setItem('selectedMenu', '다음 학기 예상 스케줄');
        const prevSelectedCourseType = localStorage.getItem("pc_selected_course_type");
        if (prevSelectedCourseType !== null && !isNaN(prevSelectedCourseType)) {
            setPotentialCourseType(parseInt(prevSelectedCourseType));
        }

        fetchSemesters();
        setLoading(false);
    }, []);

    React.useEffect(() => {
        if (selectedSemester !== null && potentialCourseType !== null) {
            setLoading(true);
            localStorage.setItem("pc_selected_course_type", potentialCourseType);
            fetchPotentialCourses();
            setTimeout(() => {
                setLoading(false);
            }, 500);
        }
    }, [selectedSemester, potentialCourseType]);

    function useOuterClick(callback) {
        const innerRef = React.useRef();
        const callbackRef = React.useRef();
    
        // set current callback in ref, before second useEffect uses it
        React.useEffect(() => { // useEffect wrapper to be safe for concurrent mode
            callbackRef.current = callback;
        });
    
        React.useEffect(() => {
            document.addEventListener("click", handleClick);
            return () => document.removeEventListener("click", handleClick);
        
            // read most recent callback and innerRef dom node from refs
            function handleClick(e) {
                if (
                    innerRef.current && 
                    callbackRef.current &&
                    !innerRef.current.contains(e.target)
                ) {
                    callbackRef.current(e);
                }
            }
        }, []); // no need for callback + innerRef dep
        
        return innerRef; // return ref; client can omit `useRef`
    }

    function buildPotentialCourses(potentiel_schedule) {
        const potential_courses = [];
        for (let i = 0; i < potentiel_schedule.length; i++) {
            const potential_schedule_with_no_course_potential_schedules = {
                id: potentiel_schedule[i].id,
                start_time: potentiel_schedule[i].start_time,
                end_time: potentiel_schedule[i].end_time,
                semester_id: potentiel_schedule[i].semester_id,
                memo: potentiel_schedule[i].memo,
            }
            const course_potential_schedules = potentiel_schedule[i].course_potential_schedules;
            for (let j = 0; j < course_potential_schedules.length; j++) {
                const course = course_potential_schedules[j].course;

                const course_potential_schedule = {
                    id: course_potential_schedules[j].id,
                    potential_schedule_id: potentiel_schedule[i].id,
                    days: course_potential_schedules[j].days,
                    email_template: course_potential_schedules[j].email_template,
                    subject: course_potential_schedules[j].subject,
                    category: course_potential_schedules[j].category,
                    order: course_potential_schedules[j].order,
                    potential_schedule: potential_schedule_with_no_course_potential_schedules,
                    combined_with: course_potential_schedules[j].combined_with,
                }

                if (!potential_courses.includes(course)) {
                    course.course_potential_schedule = course_potential_schedule;
                    potential_courses.push(course);
                }
            }
        }

        // sort potential courses by course title ascending
        potential_courses.sort((a, b) => {
            if (a.course_title < b.course_title) {
                return -1;
            } else if (a.course_title > b.course_title) {
                return 1;
            }
            return 0;
        });

        setPotentialCourses(potential_courses);
    }

    function buildScheduleMap(potentiel_schedule) {
        // potential courses data structure
        /**
         * {
         *    time: {
         *       day: {
         *          subject: [ courses ]
         *       }
         *    }  
         * }
         */

        const scheduleMap = {};
        const times = [];
        const timeToIdMap = {};
        const potential_courses = [];

        let color_index = 0;
        const color_map = {};

        for (let i = 0; i < potentiel_schedule.length; i++) {
            const start_time = dayjs(potentiel_schedule[i].start_time).tz("America/New_York").format("h:mm a");
            const end_time = dayjs(potentiel_schedule[i].end_time).tz("America/New_York").format("h:mm a");
            const time = start_time.toString() + " - " + end_time.toString();

            if (!times.includes(time)) {
                times.push(time);
            }

            if (!scheduleMap[time]) {
                timeToIdMap[time] = potentiel_schedule[i].id;
                scheduleMap[time] = {
                    "월": {
                        "SAT & ACT": [],
                        "AP & Competition": [],
                        "High": [],
                        "Middle": [],
                    },
                    "화": {
                        "SAT & ACT": [],
                        "AP & Competition": [],
                        "High": [],
                        "Middle": [],
                    },
                    "수": {
                        "SAT & ACT": [],
                        "AP & Competition": [],
                        "High": [],
                        "Middle": [],
                    },
                    "목": {
                        "SAT & ACT": [],
                        "AP & Competition": [],
                        "High": [],
                        "Middle": [],
                    },
                    "금": {
                        "SAT & ACT": [],
                        "AP & Competition": [],
                        "High": [],
                        "Middle": [],
                    },
                    "토": {
                        "SAT & ACT": [],
                        "AP & Competition": [],
                        "High": [],
                        "Middle": [],
                    },
                };
            }

            const potential_schedule_with_no_course_potential_schedules = {
                id: potentiel_schedule[i].id,
                start_time: potentiel_schedule[i].start_time,
                end_time: potentiel_schedule[i].end_time,
                semester_id: potentiel_schedule[i].semester_id,
                memo: potentiel_schedule[i].memo,
            }
            const course_potential_schedules = potentiel_schedule[i].course_potential_schedules;

            for (let j = 0; j < course_potential_schedules.length; j++) {
                const days = course_potential_schedules[j].days?.split("|");
                const course_subject = course_potential_schedules[j].subject;
                const course = course_potential_schedules[j].course;
                const combined_with = course_potential_schedules[j].combined_with;

                if (combined_with && combined_with.length > 0) {
                    for (let k = 0; k < combined_with.length; k++) {
                        if (!color_map[combined_with[k]]) {
                            color_map[combined_with[k]] = colors_for_combined_courses[color_index];
                        }
                    }

                    color_map[course.id] = colors_for_combined_courses[color_index];
                    color_index += 1;
                    color_index = color_index % colors_for_combined_courses.length;
                }

                const course_potential_schedule = {
                    id: course_potential_schedules[j].id,
                    potential_schedule_id: potentiel_schedule[i].id,
                    days: course_potential_schedules[j].days,
                    time: time,
                    email_template: course_potential_schedules[j].email_template,
                    subject: course_potential_schedules[j].subject,
                    category: course_potential_schedules[j].category,
                    order: course_potential_schedules[j].order,
                    potential_schedule: potential_schedule_with_no_course_potential_schedules,
                    combined_with: course_potential_schedules[j].combined_with,
                    teacher_name: course_potential_schedules[j].course.teacher_name,
                }

                if (!potential_courses.includes(course)) {
                    course.course_potential_schedule = course_potential_schedule;
                    potential_courses.push(course);
                }

                for (let k = 0; k < days.length; k++) {
                    scheduleMap[time][days[k]][course_subject].push(course);
                }
            }
        }

        // sort potential courses by course title ascending
        potential_courses.sort((a, b) => {
            if (a.course_title < b.course_title) {
                return -1;
            } else if (a.course_title > b.course_title) {
                return 1;
            }
            return 0;
        });

        setColorMap(color_map);
        setPotentialCourses(potential_courses);
        setTimes(times);
        setScheduleMap(scheduleMap);
        setTimeToIdMap(timeToIdMap);
        setTimeLen(times.length + 1);
    }

    function buildCombinedSchedule(combined_schedule) {
        const combined_schedule_map = {};
        let max_course_len = 0;
        let combined_schedule_id = 0;
        for (let i = 0; i < combined_schedule.length; i++) {
            const obj = combined_schedule[i];
            if (obj.course_potential_schedules !== undefined && obj.course_potential_schedules.length > 0) {
                // for group potential schedule
                const start_time = dayjs(obj.start_time).tz('America/New_York').format("h:mm a");
                const end_time = dayjs(obj.end_time).tz('America/New_York').format("h:mm a");

                const time_diff = dayjs(obj.end_time).diff(dayjs(obj.start_time), 'minute') / 30;
                for (let j = 0; j < obj.course_potential_schedules.length; j++) {
                    const days = obj.course_potential_schedules[j].days?.split("|") || [];
                    const subject = obj.course_potential_schedules[j].subject || "No Subject";
                    const course_title = obj.course_potential_schedules[j].course.course_title || "No Title";
                    const teacher_name = obj.course_potential_schedules[j].course.teacher_name || "No Teacher Assigned";
                    const course_id = obj.course_potential_schedules[j].course.id;
                    const is_private = false;

                    for (let k = 0; k < days.length; k++) {
                        if (!combined_schedule_map[days[k]]) {
                            combined_schedule_map[days[k]] = {};
                        }

                        if (!combined_schedule_map[days[k]][start_time]) {
                            combined_schedule_map[days[k]][start_time] = [];
                        }

                        combined_schedule_map[days[k]][start_time].push({
                            course_title,
                            subject,
                            teacher_name,
                            start_time,
                            end_time,
                            time_diff,
                            student_name: null,
                            course_id,
                            fake_id: combined_schedule_id,
                            is_private,
                        });
                        combined_schedule_id += 1;
                        max_course_len = Math.max(max_course_len, combined_schedule_map[days[k]][start_time].length);
                    }
                }
            } else {
                // for private potential schedule
                const start_time = dayjs(obj.start_time).tz('America/New_York').format("h:mm a");
                const end_time = dayjs(obj.end_time).tz('America/New_York').format("h:mm a");
                const time_diff = dayjs(obj.end_time).diff(dayjs(obj.start_time), 'minute') / 30;
                const days = obj.days?.split("|") || [];
                const subject = obj.subject || "No Subject";
                const course_title = obj.course_title || "Private Potential Schedule";
                const teacher_name = obj.teacher_name || "No Teacher Assigned";
                const student_name = obj.student_name || "No Student Name";
                const student_uuid = obj.student_uuid ?? null;
                const student_course_id = obj.student_course_id ?? null;
                const course_uuid = obj.course_uuid ?? null;
                const course_id = obj.course_id;
                const is_private = true;

                for (let j = 0; j < days.length; j++) {
                    const day = eng_days_to_kor_days[days[j]];
                    if (!combined_schedule_map[day]) {
                        combined_schedule_map[day] = {};
                    }

                    if (!combined_schedule_map[day][start_time]) {
                        combined_schedule_map[day][start_time] = [];
                    }

                    combined_schedule_map[day][start_time].push({
                        id: obj.id,
                        course_title,
                        subject,
                        teacher_name,
                        start_time,
                        end_time,
                        time_diff,
                        student_name,
                        course_id,
                        fake_id: combined_schedule_id,
                        is_private,
                        student_uuid,
                        course_uuid,
                        student_course_id,
                        days: obj.days,
                        start_time_iso: obj.start_time,
                        end_time_iso: obj.end_time,
                    });
                    combined_schedule_id += 1;
                    max_course_len = Math.max(max_course_len, combined_schedule_map[day][start_time].length);
                }
            }
        }

        const occupation_map = {
            "월": {},
            "화": {},
            "수": {},
            "목": {},
            "금": {},
            "토": {},
        };

        Array.from(Object.keys(occupation_map)).forEach((day) => {
            // initialize occupation map
            occupation_map[day] = {
                "9:00 am": [],
                "9:30 am": [],
                "10:00 am": [],
                "10:30 am": [],
                "11:00 am": [],
                "11:30 am": [],
                "12:00 pm": [],
                "12:30 pm": [],
                "1:00 pm": [],
                "1:30 pm": [],
                "2:00 pm": [],
                "2:30 pm": [],
                "3:00 pm": [],
                "3:30 pm": [],
                "4:00 pm": [],
                "4:30 pm": [],
                "5:00 pm": [],
                "5:30 pm": [],
                "6:00 pm": [],
                "6:30 pm": [],
                "7:00 pm": [],
                "7:30 pm": [],
                "8:00 pm": [],
                "8:30 pm": [],
                "9:00 pm": [],
                "9:30 pm": [],
                "10:00 pm": [],
                "10:30 pm": [],
                "11:00 pm": [],
                "11:30 pm": [],
                "12:00 am": [],                
            };

            Array.from(Object.keys(occupation_map[day])).forEach((time) => {
                // initialize occupation map using max_course_len
                occupation_map[day][time] = Array(max_course_len * 2).fill(false);
            });
        });

        setOccupationMap(occupation_map);
        setCombinedScheduleMap(combined_schedule_map);
        setMaxCourseLen(max_course_len);
    }

    async function fetchPotentialCourses() {
        if (potentialCourseType !== 3) {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/potential-courses/semesters/${selectedSemester}?q=${potentialCourseType}`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                if (potentialCourseType === 0) {
                    // for group potential courses
                    buildScheduleMap(response.data.potentialSchedule);
                } else {
                    // type 1 (private) or 2 (other)
                    buildPotentialCourses(response.data.potentialSchedule);
                }
                setPotentialSchedules(response.data.potentialSchedule);
                setCourseIdStudentCoursesMap(response.data.course_id_student_courses_map);

                if (scrollToParam && firstRender) {
                    setScrollDownAlertOpen(true);
                    setFirstRender(false);
                    setTimeout(() => {
                        window.localStorage.removeItem("pc_potentialCourseUpdated");
                    }, 10000);
                }
            }).catch((error) => {
                console.log("error: ", error);
                if (error.response.status === 404) {
                    setTimes([]);
                    setPotentialCourses([]);
                    return;
                } else if (error.response.status === 401) {
                    handleLogout();
                    return;
                }
                setErrorAlertMessage(error.response.data.message || error.response.data.error || "Something went wrong!");
                setErrorAlertOpen(true);
            });
        } else {
            // when potentialCourseType is 3
            // for group and private potential courses schedule
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/potential-courses/semesters/${selectedSemester}/combined-schedule`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                // do not need to set times and potential courses
                setTimes([]);
                setPotentialCourses([]);
                buildCombinedSchedule(response.data);
            }).catch((error) => {
                console.log("error: ", error);
                if (error.response.status === 404) {
                    setTimes([]);
                    setPotentialCourses([]);
                    setOccupationMap({});
                    setCombinedScheduleMap({});
                    setMaxCourseLen(0);
                    return;
                } else if (error.response.status === 401) {
                    handleLogout();
                    return;
                }
                setErrorAlertMessage(error.response.data.message || error.response.data.error || "Something went wrong!");
                setErrorAlertOpen(true);
            });
        }
    }

    async function fetchSemesters() {
        await Axios.get(`${process.env.REACT_APP_URL}/api/v1/semesters`, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setSemesters(response.data);
            setSelectedSemester(response.data[0].id);
        }).catch((error) => {
            if (error.response.status === 404) {
                setSemesters([]);
                return;
            } else if (error.response.status === 401) {
                handleLogout();
                return;
            }
            setErrorAlertMessage(error.response.data.message || error.response.data.error || "Something went wrong!");
            setErrorAlertOpen(true);
        });
    }

    // for semester chip
    const handleChipClick = (semester_id) => {
        setSelectedSemester(semester_id);
    };

    // for potential course type chip
    const handleTypeChipClick = (type) => {
        setPotentialCourseType(type);
    };

    async function handleConfirmStudent(e, course_uuid, student_uuid, is_confirmed, fromValue, toValue) {
        e.preventDefault();

        await Axios.put(`${process.env.REACT_APP_URL}/api/v1/courses/${course_uuid}/students/${student_uuid}`, {
            is_confirmed,
            start_date: fromValue || null,
            end_date: toValue || null,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            fetchPotentialCourses();
            setSuccessAlertOpen(true);
            setSuccessAlertMessage(response.data.message);
        }).catch((error) => {
            setErrorAlertOpen(true);
            setErrorAlertMessage(error.response.data.error);
        });
    }

    async function handlePrivateScheduleConfirm(e, potentialCourse_uuid, student_uuid, fromValue, toValue, days, subject, teacher_name, private_potential_schedule_id = null, is_delete_request = false) {
        e.preventDefault();

        if (is_delete_request === false && (!fromValue || !toValue || !selectedSemester || !student_uuid || !potentialCourse_uuid || !days || days.length === 0)) {
            setErrorAlertOpen(true);
            setErrorAlertMessage("Please fill all required fields!");
            return;
        } else if (is_delete_request === true && private_potential_schedule_id === null) {
            setErrorAlertOpen(true);
            setErrorAlertMessage("Is delete request but private potential schedule id is not provided!");
            return;
        }

        if (private_potential_schedule_id === null) {
            // create private potential schedule
            await Axios.post(`${process.env.REACT_APP_URL}/api/v1/private-potential-schedules`, {
                from_time: fromValue,
                to_time: toValue,
                days: days.join("|"),
                semester_id: selectedSemester,
                student_uuid: student_uuid,
                course_uuid: potentialCourse_uuid,
                teacher_name: teacher_name || null,
                subject,
            }, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                fetchPotentialCourses();
                setSuccessAlertOpen(true);
                setSuccessAlertMessage(response.data.message);
            }).catch((error) => {
                setErrorAlertOpen(true);
                setErrorAlertMessage(error.response.data.error);
            });
        } else if (private_potential_schedule_id !== null && is_delete_request === false) {
            // update private potential schedule
            await Axios.put(`${process.env.REACT_APP_URL}/api/v1/private-potential-schedules/${private_potential_schedule_id}`, {
                from_time: fromValue,
                to_time: toValue,
                days: days.join("|"),
                teacher_name: teacher_name || null,
                subject,
            }, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                fetchPotentialCourses();
                setSuccessAlertOpen(true);
                setSuccessAlertMessage(response.data.message);
            }).catch((error) => {
                setErrorAlertOpen(true);
                setErrorAlertMessage(error.response.data.error);
            });
        } else if (private_potential_schedule_id !== null && is_delete_request === true) {
            // delete private potential schedule
            await Axios.delete(`${process.env.REACT_APP_URL}/api/v1/private-potential-schedules/${private_potential_schedule_id}`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                fetchPotentialCourses();
                setSuccessAlertOpen(true);
                setSuccessAlertMessage(response.data.message);
            }).catch((error) => {
                setErrorAlertOpen(true);
                setErrorAlertMessage(error.response.data.error);
            });
        }
    }

    async function saveTeacherNameForPrivateSchedule(classInfo, teacherName) {
        if (!classInfo.id) {
            setErrorAlertOpen(true);
            setErrorAlertMessage("PrivateSchedule id is not provided!");
            return;
        }

        if (!teacherName) {
            setErrorAlertOpen(true);
            setErrorAlertMessage("Teacher name is not provided!");
            return;
        }

        await Axios.put(`${process.env.REACT_APP_URL}/api/v1/private-potential-schedules/${classInfo.id}/teacher-name`, {
            teacher_name: teacherName,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            }
        }).then((res) => {
            setSuccessAlertOpen(true);
            setSuccessAlertMessage(res.data.message || "Teacher name saved successfully!");
            fetchPotentialCourses();
        }).catch((err) => {
            setErrorAlertOpen(true);
            setErrorAlertMessage(err.response.data.error || "Error occurs while saving teacher name!");
        });
    }

    async function saveTeacherName(course_id, teacher_name) {
        if (!course_id) {
            setErrorAlertOpen(true);
            setErrorAlertMessage("Course id is not provided!");
            return;
        }

        if (!teacher_name) {
            setErrorAlertOpen(true);
            setErrorAlertMessage("Teacher name is not provided!");
            return;
        }

        await Axios.put(`${process.env.REACT_APP_URL}/api/v1/courses/${course_id}/save-teacher-name`, {
            teacher_name,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setSuccessAlertOpen(true);
            setSuccessAlertMessage(response.data.message);
            fetchPotentialCourses();
        }).catch((error) => {
            setErrorAlertOpen(true);
            setErrorAlertMessage(error.response.data.error);
        });
    }

    async function saveCourseMemo(course_uuid, course_memo) {
        await Axios.put(`${process.env.REACT_APP_URL}/api/v1/courses/${course_uuid}/save-course-memo`, {
            course_memo,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setSuccessAlertOpen(true);
            setSuccessAlertMessage(response.data.message);
        }).catch((error) => {
            setErrorAlertOpen(true);
            setErrorAlertMessage(error.response.data.error);
        })
    }

    function scrollDownToCourseDetail(uuid = null) {
        let courseDiv = null;

        if (!uuid)
            courseDiv = document.getElementById(scrollToParam);
        else
            courseDiv = document.getElementById(uuid);
            

        if (courseDiv) {
            courseDiv.scrollIntoView({ behavior: "smooth" });
            handleCloseScollDownAlert();
        }
    }

    React.useEffect(() => {        
        const potentialCoursesHeader = document.getElementById("potential-courses-header");

        const parent_box = document.getElementById("parent-box");

        const box_1_start = document.getElementById(`day-0-time-0`);
        const box_1_end = document.getElementById(`day-0-time-${timeLen - 1}`);

        const box_2_start = document.getElementById(`day-1-time-0`);
        const box_2_end = document.getElementById(`day-1-time-${timeLen - 1}`);

        const box_3_start = document.getElementById(`day-2-time-0`);
        const box_3_end = document.getElementById(`day-2-time-${timeLen - 1}`);

        if (scroll < parent_box?.offsetTop + box_1_start?.offsetTop + box_1_start?.clientHeight) {
            potentialCoursesHeader.style.display = "none";
            potentialCoursesHeader.style.zIndex = -1;
        }
        else if (scroll > parent_box?.offsetTop + box_1_start?.offsetTop + box_1_start?.clientHeight 
            && scroll < parent_box?.offsetTop + box_1_end?.offsetTop + box_1_end?.clientHeight) {
            potentialCoursesHeader.style.display = "block";
            potentialCoursesHeader.style.zIndex = 3;
            setBoxOpen("월|목");
        }
        else if (scroll > parent_box?.offsetTop + box_1_end?.offsetTop + box_1_end?.clientHeight 
            && scroll < parent_box?.offsetTop + box_2_start?.offsetTop + box_2_start?.clientHeight) {
            potentialCoursesHeader.style.display = "none";
            potentialCoursesHeader.style.zIndex = -1;
        }
        else if (scroll > parent_box?.offsetTop + box_2_start?.offsetTop + box_2_start?.clientHeight 
            && scroll < parent_box?.offsetTop + box_2_end?.offsetTop + box_2_end?.clientHeight) {
            potentialCoursesHeader.style.display = "block";
            potentialCoursesHeader.style.zIndex = 3;
            setBoxOpen("화|금");
        }
        else if (scroll > parent_box?.offsetTop + box_2_end?.offsetTop + box_2_end?.clientHeight 
            && scroll < parent_box?.offsetTop + box_3_start?.offsetTop + box_3_start?.clientHeight) {
            potentialCoursesHeader.style.display = "none";
            potentialCoursesHeader.style.zIndex = -1;
        }
        else if (scroll > parent_box?.offsetTop + box_3_start?.offsetTop + box_3_start?.clientHeight 
            && scroll < parent_box?.offsetTop + box_3_end?.offsetTop + box_3_end?.clientHeight) {
            potentialCoursesHeader.style.display = "block";
            potentialCoursesHeader.style.zIndex = 3;
            setBoxOpen("수|토");
        }
        if (scroll > parent_box?.offsetTop + box_3_end?.offsetTop + box_3_end?.clientHeight) {
            potentialCoursesHeader.style.display = "none";
            potentialCoursesHeader.style.zIndex = -1;
        }     
    }, [scroll]);

    return (
        <Box 
            m="20px"
            marginX={3}
            marginY={3}
            sx={{
                minHeight: "88%",
                position: "relative",
            }}
        >
            <Header title="다음 학기 예상 스케줄" subtitle="A ONE Institute" />
            { loading ? 
                <Loading />
            : <>
                <Box
                    id="parent-box"
                    position="relative"
                    display="flex" 
                    flexDirection="column" 
                    justifyContent="left"
                    gap={2}
                    p={0}
                    minHeight={70}
                    mb={2}
                >
                    <Box
                        id="potential-courses-header"
                        sx={{
                            position: "sticky",
                            top: "0",
                            left: "0",
                            width: "calc(100% - 153px)",
                            height: "64px",
                            marginLeft: "150px",
                            marginRight: "3px",
                            // backgroundColor: colors.primary[400], // for testing
                            backgroundColor: colors.primary[100],
                            color: "white",
                            display: "none",
                            zIndex: "-1",
                        }}
                    >
                        <Box sx={{display: "flex", flexDirection: "row"}}>
                            <Box
                                sx={{
                                    flex: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                    borderTop: "1px solid lightgrey",
                                    borderBottom: "1px solid lightgrey",
                                    borderRight: "1px solid lightgrey",
                                    borderLeft: "1px solid lightgrey"
                                }}
                            >
                                <Box
                                    sx={{
                                        width: "100%",
                                        borderBottom: "1px solid lightgrey",
                                        textAlign: "center",
                                        p: 1,
                                        fontWeight: "bold",
                                        fontSize: 16,
                                    }}
                                >
                                    {boxOpen? boxOpen.split("|")[0] : ""}
                                </Box>
                                <Box
                                    sx={{
                                        width: "100%",
                                        display: "flex",
                                    }}
                                >
                                    {subjects.map((subject, j) => {
                                        return (
                                            <Box
                                                key={j}
                                                sx={{
                                                    flex: 1,
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    textAlign: "center",
                                                    borderRight: j === subjects.length - 1 ? 0 : "1px solid lightgrey",
                                                }}
                                            >
                                                {subject}
                                            </Box>
                                        )
                                    })}
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    flex: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                    borderTop: "1px solid lightgrey",
                                    borderBottom: "1px solid lightgrey",
                                    borderRight: "1px solid lightgrey",
                                    borderLeft: "1px solid lightgrey"
                                }}
                            >
                                <Box
                                    sx={{
                                        width: "100%",
                                        borderBottom: "1px solid lightgrey",
                                        textAlign: "center",
                                        p: 1,
                                        fontWeight: "bold",
                                        fontSize: 16,
                                    }}
                                >
                                    {boxOpen? boxOpen.split("|")[1] : ""}
                                </Box>
                                <Box
                                    sx={{
                                        width: "100%",
                                        display: "flex",
                                    }}
                                >
                                    {subjects.map((subject, j) => {
                                        return (
                                            <Box
                                                key={j}
                                                sx={{
                                                    flex: 1,
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    textAlign: "center",
                                                    borderRight: j === subjects.length - 1 ? 0 : "1px solid lightgrey",
                                                }}
                                            >
                                                {subject}
                                            </Box>
                                        )
                                    })}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 2,
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                gap: 2,
                            }}
                        >
                            {semesters.map((semester, index) => {
                                return (
                                    <Chip 
                                        key={index} 
                                        label={semester.year + " " + season[semester.season]} 
                                        sx={{
                                            padding: 3,
                                            bgcolor: selectedSemester === semester.id ? colors.greenAccent[500] : null,
                                            border: selectedSemester === semester.id ? "1px solid " + colors.primary[100] : "1px solid transparent",
                                            fontSize: 13,
                                        }}
                                        onClick={() => handleChipClick(semester.id)} 
                                    />
                                )
                            })}
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 2,
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                gap: 2,
                            }}
                        >
                            <Chip 
                                label={"Group"}
                                sx={{
                                    padding: 3,
                                    bgcolor: potentialCourseType === 0 ? colors.greenAccent[500] : null,
                                    border: potentialCourseType === 0 ? "1px solid " + colors.primary[100] : "1px solid transparent",
                                    fontSize: 13,
                                }}
                                onClick={() => handleTypeChipClick(0)} 
                            />
                            <Chip 
                                label={"Private"}
                                sx={{
                                    padding: 3,
                                    bgcolor: potentialCourseType === 1 ? colors.greenAccent[500] : null,
                                    border: potentialCourseType === 1 ? "1px solid " + colors.primary[100] : "1px solid transparent",
                                    fontSize: 13,
                                }}
                                onClick={() => handleTypeChipClick(1)} 
                            />
                            <Chip 
                                label={"Other"}
                                sx={{
                                    padding: 3,
                                    bgcolor: potentialCourseType === 2 ? colors.greenAccent[500] : null,
                                    border: potentialCourseType === 2 ? "1px solid " + colors.primary[100] : "1px solid transparent",
                                    fontSize: 13,
                                }}
                                onClick={() => handleTypeChipClick(2)} 
                            />
                            <Chip 
                                label={"Schedule"}
                                sx={{
                                    padding: 3,
                                    bgcolor: potentialCourseType === 3 ? colors.greenAccent[500] : null,
                                    border: potentialCourseType === 3 ? "1px solid " + colors.primary[100] : "1px solid transparent",
                                    fontSize: 13,
                                }}
                                onClick={() => handleTypeChipClick(3)} 
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 2,
                        }}
                    >
                        {potentialCourseType === 0 ? (
                            <>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                        width: 152,
                                    }}
                                    onClick={() => setAddTimeModalOpen(true)}
                                >
                                    Add Time
                                </Button>
                                <AddTimeModal 
                                    open={addTimeModalOpen} 
                                    handleClose={handleClose} 
                                    selected_semester_id={selectedSemester} 
                                    setSuccessAlertOpen={setSuccessAlertOpen}
                                    setSuccessAlertMessage={setSuccessAlertMessage}
                                    setErrorAlertOpen={setErrorAlertOpen}
                                    setErrorAlertMessage={setErrorAlertMessage}
                                />
                                <Button
                                    variant="contained"
                                    color="error"
                                    sx={{
                                        width: 152,
                                    }}
                                    onClick={() => setRemoveTimeModalOpen(true)}
                                >
                                    Remove Time
                                </Button>
                                <RemoveTimeModal
                                    open={removeTimeModalOpen}
                                    handleClose={handleRemoveTimeModalClose}
                                    timeToIdMap={timeToIdMap}
                                    times={times}
                                    setSuccessAlertOpen={setSuccessAlertOpen}
                                    setSuccessAlertMessage={setSuccessAlertMessage}
                                    setErrorAlertOpen={setErrorAlertOpen}
                                    setErrorAlertMessage={setErrorAlertMessage}
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                        width: 240,
                                    }}
                                    onClick={() => { setCombineCoursesModalOpen(true) }}
                                >
                                    Combine Courses' Templates
                                </Button>
                                <CombineCoursesModal
                                    combineCoursesModalOpen={combineCoursesModalOpen}
                                    handleCombineCoursesCoursesModalClose={handleCombineCoursesCoursesModalClose}
                                    potentialCourses={potentialCourses}
                                    setSuccessAlertOpen={setSuccessAlertOpen}
                                    setSuccessAlertMessage={setSuccessAlertMessage}
                                    setErrorAlertOpen={setErrorAlertOpen}
                                    setErrorAlertMessage={setErrorAlertMessage}
                                />
                            </>
                        ) : potentialCourseType === 1 ? (
                            <AddPrivatePotentialCourseOrOther
                                setSuccessAlertOpen={setSuccessAlertOpen}
                                setSuccessAlertMessage={setSuccessAlertMessage}
                                setErrorAlertOpen={setErrorAlertOpen}
                                setErrorAlertMessage={setErrorAlertMessage}
                                selectedSemester={selectedSemester}
                                potentialCourseType={potentialCourseType}
                                buttonName={"Add Private Potential Course"}
                            />
                        ) : potentialCourseType === 2 ? (
                            <AddPrivatePotentialCourseOrOther
                                setSuccessAlertOpen={setSuccessAlertOpen}
                                setSuccessAlertMessage={setSuccessAlertMessage}
                                setErrorAlertOpen={setErrorAlertOpen}
                                setErrorAlertMessage={setErrorAlertMessage}
                                selectedSemester={selectedSemester}
                                potentialCourseType={potentialCourseType}
                                buttonName={"Add Other (컨설팅, 원장님 상담 등)"}
                            />
                        ) : null}
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            minWidth: 900,
                        }}
                    >
                        {potentialCourseType === 0 ? (
                            Array.from({ length: (days.length / 2) }).map((_, days_index) => {
                                return Array.from({ length: times.length + 1 }).map((_, index) => {
                                    return (
                                        <Box
                                            id={`day-${days_index}-time-${index}`}
                                            key={index}
                                            sx={{
                                                minHeight: 50,
                                                display: "flex",
                                                flexDirection: "row",
                                                width: "100%",
                                                minWidth: 900,
                                                mb: index === times.length ? 10 : 0,
                                                bgcolor: colors.primary[600],
                                            }}
                                        >
                                            {/* first column for time */}
                                            <Box
                                                sx={{
                                                    width: 150,
                                                    borderBottom: "1px solid lightgrey",
                                                    borderLeft: index === 0 ? null : "1px solid lightgrey",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    pt: 1,
                                                }}
                                            >
                                                {index > 0 ? times[index - 1] : null}
                                            </Box>
                                            {days.map((day, i) => {
                                                if (i % (days.length / 2) === days_index) {
                                                    if (index === 0) {
                                                        return (
                                                            <Box
                                                                key={i}
                                                                sx={{
                                                                    flex: 1,
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    borderTop: "1px solid lightgrey",
                                                                    borderBottom: "1px solid lightgrey",
                                                                    borderRight: "1px solid lightgrey",
                                                                    borderLeft: i < 3 ? "1px solid lightgrey" : 0,
                                                                }}
                                                            >
                                                                <Box
                                                                    id={day}
                                                                    sx={{
                                                                        width: "100%",
                                                                        borderBottom: "1px solid lightgrey",
                                                                        textAlign: "center",
                                                                        p: 1,
                                                                        fontWeight: "bold",
                                                                        fontSize: 16,
                                                                    }}
                                                                >
                                                                    {day}
                                                                </Box>
                                                                <Box
                                                                    sx={{
                                                                        width: "100%",
                                                                        display: "flex",
                                                                    }}
                                                                >
                                                                    {subjects.map((subject, j) => {
                                                                        return (
                                                                            <Box
                                                                                key={j}
                                                                                sx={{
                                                                                    flex: 1,
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    textAlign: "center",
                                                                                    borderRight: j === subjects.length - 1 ? 0 : "1px solid lightgrey",
                                                                                }}
                                                                            >
                                                                                {subject}
                                                                            </Box>
                                                                        )
                                                                    })}
                                                                </Box>
                                                            </Box>
                                                        )
                                                    }
        
                                                    return (
                                                        <Box
                                                            key={i}
                                                            sx={{
                                                                flex: 1,
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                borderLeft: i < 3 ? "1px solid lightgrey" : 0,
                                                                borderRight: "1px solid lightgrey",
                                                                borderBottom: "1px solid lightgrey",
                                                            }}
                                                        >
                                                            {subjects.map((subject, j) => {
                                                                return (
                                                                    <Box
                                                                        key={j}
                                                                        sx={{
                                                                            flex: 1,
                                                                            display: "flex",
                                                                            flexDirection: "column",
                                                                        }}
                                                                    >
                                                                        <AddPotentialCourseModal
                                                                            setSuccessAlertOpen={setSuccessAlertOpen}
                                                                            setSuccessAlertMessage={setSuccessAlertMessage}
                                                                            setErrorAlertOpen={setErrorAlertOpen}
                                                                            setErrorAlertMessage={setErrorAlertMessage}
                                                                            potential_schedule_id={timeToIdMap[times[index - 1]]}
                                                                            time={times[index - 1]}
                                                                            subject={subject}
                                                                            order={scheduleMap[times[index - 1]][day][subject].length}
                                                                            day={day}
                                                                            daysData={days}
                                                                            selectedSemester={selectedSemester}
                                                                        />
                                                                        {scheduleMap[times[index - 1]][day][subject].map((course, k) => {
                                                                            return (
                                                                                <PopoverControl
                                                                                    key={course.uuid}
                                                                                    index={k}
                                                                                    course={course}
                                                                                    courseIdStudentCoursesMap={courseIdStudentCoursesMap}
                                                                                    color_map={color_map}
                                                                                    setErrorAlertOpen={setErrorAlertOpen}
                                                                                    setErrorAlertMessage={setErrorAlertMessage}
                                                                                    setSuccessAlertOpen={setSuccessAlertOpen}
                                                                                    setSuccessAlertMessage={setSuccessAlertMessage}
                                                                                    fetchPotentialCourses={fetchPotentialCourses}
                                                                                />
                                                                            );
                                                                        })}
                                                                    </Box>
                                                                )
                                                            })}
                                                        </Box>
                                                    )
                                                }
                                            })}
                                        </Box>
                                    )
                                })
                            })
                        ) : potentialCourseType === 1 ? (
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 1,
                                    padding: 2,
                                    marginTop: 2,
                                    border: "1px solid lightgrey",
                                }}
                            >
                                {/* private potential courses */}
                                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                                    Click to scroll down to the course detail
                                </Typography>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: 1,
                                        flexWrap: "wrap",
                                    }}
                                >
                                    {potentialCourses.map((potentialCourse, index) => {
                                        return (
                                            <Button
                                                key={index}
                                                variant="outlined"
                                                color="primary"
                                                sx={{
                                                    minWidth: 100,
                                                    margin: 1,
                                                    padding: 1,
                                                }}
                                                onClick={() => {
                                                    scrollDownToCourseDetail(potentialCourse.uuid);
                                                }}
                                            >
                                                {potentialCourse.course_title}
                                            </Button>
                                        )
                                    })}
                                </Box>
                            </Box>
                        ) : potentialCourseType === 2 ? (
                            <Box>
                                {/* other */}
                            </Box>
                        ) : (
                            <Box>
                                {/* combined schedule */}
                                <CombinedSchedule 
                                    combinedScheduleMap={combinedScheduleMap} 
                                    maxCourseLen={maxCourseLen} 
                                    occupationMap={occupationMap}
                                    saveTeacherName={saveTeacherName}
                                    saveTeacherNameForPrivateSchedule={saveTeacherNameForPrivateSchedule}
                                    handlePrivateScheduleConfirm={handlePrivateScheduleConfirm}
                                />
                            </Box>
                        )}
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                            mt: 2,
                            mb: potentialCourseType !== 3 ? "20vh" : 0,
                            width: "100%",
                            height: "100%",
                        }}
                    >
                        {potentialCourses.map((potentialCourse, index) => {
                            return (
                                <Box key={index} id={potentialCourse.uuid}>
                                    <EachPotentialCourse
                                        potentialSchedules={potentialSchedules}
                                        potentialCourseType={potentialCourseType}
                                        potentialCourse={potentialCourse}
                                        courseIdStudentCoursesMap={courseIdStudentCoursesMap}
                                        fetchPotentialCourses={fetchPotentialCourses}
                                        handleConfirmStudent={handleConfirmStudent}
                                        handlePrivateScheduleConfirm={handlePrivateScheduleConfirm}
                                        useOuterClick={useOuterClick}
                                        setBeingModified={setBeingModified}
                                        beingModified={beingModified}
                                        saveCourseMemo={saveCourseMemo}
                                        setSuccessAlertMessage={setSuccessAlertMessage}
                                        setSuccessAlertOpen={setSuccessAlertOpen}
                                        setErrorAlertMessage={setErrorAlertMessage}
                                        setErrorAlertOpen={setErrorAlertOpen}
                                    />
                                </Box>
                            )
                        })}
                    </Box>
                </Box>
            </>}
            <Snackbar open={scrollDownAlertOpen} autoHideDuration={10000} onClose={handleCloseScollDownAlert}>
                <MuiAlert
                    severity="info"
                    variant="filled"
                    action={<>
                        <Button 
                            variant='outlined'
                            color='inherit'
                            size="small"
                            onClick={() => scrollDownToCourseDetail(null)}
                        >
                            Scroll Down
                        </Button>
                        <Button
                            variant='outlined'
                            color='inherit'
                            size="small"
                            sx={{ marginLeft: 2 }}
                            onClick={handleCloseScollDownAlert}
                        >
                            Close
                        </Button>
                    </>}
                    sx={{
                        position: 'fixed',
                        top: "100px",
                        right: "20px",
                        maxWidth: 600,
                    }}
                >
                    Scroll down to the course you were working on.
                </MuiAlert>
            </Snackbar>
            <Alert
                successAlertOpen={successAlertOpen}
                setSuccessAlertOpen={setSuccessAlertOpen}
                errorAlertOpen={errorAlertOpen}
                setErrorAlertOpen={setErrorAlertOpen}
                successMsg={successAlertMessage}
                errorMsg={errorAlertMessage}
                vertical='bottom'
                horizontal='left'
            />
        </Box>
    )
}

export default PotentialCourses;