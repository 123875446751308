import React from 'react'
import { Box, useTheme, Typography } from "@mui/material";
import { tokens } from "../../../theme";
import AssignmentIcon from '@mui/icons-material/Assignment';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Popover from '@mui/material/Popover';
import MoreVertIcon from "../../../components/MoreVertIcon"
import UpdateModal from './UpdateModal';
import Chip from '@mui/material/Chip';
import DeleteDialog from '../../../components/DeleteDialog';
import CircleIcon from '@mui/icons-material/Circle';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import Axios from 'axios';
import dayjs from 'dayjs';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import getStudentName from '../../../utils/getStudentName';
import Tooltip from '@mui/material/Tooltip';

var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone');

dayjs.extend(utc)
dayjs.extend(timezone)

var options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: '2-digit',
    minute: '2-digit'
};

var options_without_time = {
    year: "numeric",
    month: "short",
    day: "numeric"
};

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: 10,
      top: 3,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 2px',
    },
}));

const AssignmentBox = ({ 
    courseId,
    students,
    studentsStartEndDateMap,
    homework, 
    authorDisabledForAdminActions, 
    setBackdropOpen, 
    setSuccessAlertOpen, 
    setErrorAlertOpen,
    userRole,
    userId,
    setSuccessAlertMessage,
    setErrorAlertMessage
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [doesStudentHaveSubmission] = React.useState(
        homework.studentsWhoSubmitted?.filter(student => student.uuid === userId).length > 0 ||
        homework.studentsWhoSubmittedLate?.filter(student => student.uuid === userId).length > 0
    );

    // for popover
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleMoreVertIconClick = (event) => {
        if (userRole === "student")
            return;
        setAnchorEl(event.currentTarget);
    };
    const handlePopOverClose = () => {
        setAnchorEl(null);
    };
    const popoverOpen = Boolean(anchorEl);
    const popoverId = popoverOpen ? 'popover' : undefined;

    const handleDeleteHomework = async () => {
        setAnchorEl(null);
        setBackdropOpen(true);
        await Axios.delete(`${process.env.REACT_APP_URL}/api/v1/courses/${courseId}/events/${homework.event_id}/homeworks/${homework.id}`, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((res) => {
            setBackdropOpen(false);
            setSuccessAlertMessage("Homework deleted successfully!");
            setSuccessAlertOpen(true);
            window.location.reload();
        }).catch((err) => {
            setBackdropOpen(false);
            setErrorAlertMessage(err.response.data.message);
            setErrorAlertOpen(true);
            console.log(err);
        });
    };

    // for due date logic
    const dueDateRender = () => {
        if (homework.due_date === null) {
            return (
                <Box></Box>
            );
        }

        if (new Date().getTime() > new Date(homework.due_date).getTime() && !homework.hasSubmission) {
            // no submission and due date passed
            return (
                <Typography variant="h7" color="error" sx={{my: "auto", ml: "auto"}}>
                    <Tooltip 
                        title={
                            dayjs(homework.due_date).tz('America/New_York').format('YYYY-MM-DD HH:mm') + " (NY Time)"
                        }
                        arrow
                        placement="top"
                    >
                        <span style={{ fontWeight: "bold" }}>Due</span> {new Date(homework.due_date).toLocaleDateString("en", options)}
                    </Tooltip>
                </Typography>
            );
        } else if (new Date().getTime() > new Date(homework.due_date).getTime() && homework.hasSubmission && homework.lateSubmission) {
            // late submission and due date passed
            return (
                <><AssignmentTurnedInIcon size='small' color='warning' sx={{ ml: "auto", mr: "0.3rem" }} />
                <Typography variant="h7" color={colors.grey[700]} sx={{ my: "auto" }}>
                    <Tooltip 
                        title={
                            dayjs(homework.due_date).tz('America/New_York').format('YYYY-MM-DD HH:mm') + " (NY Time)"
                        }
                        arrow
                        placement="top"
                    >
                        <span style={{ fontWeight: "bold" }}>Due</span> {new Date(homework.due_date).toLocaleDateString("en", options)}
                    </Tooltip>
                </Typography></>
            );
        } else if (homework.hasSubmission && !homework.lateSubmission) {
            // submitted on time
            return (
                <><AssignmentTurnedInIcon size='small' color='secondary' sx={{ ml: "auto", mr: "0.3rem" }} />
                <Typography variant="h7" color={colors.grey[700]} sx={{ my: "auto" }}>
                    <Tooltip 
                        title={
                            dayjs(homework.due_date).tz('America/New_York').format('YYYY-MM-DD HH:mm') + " (NY Time)"
                        }
                        arrow
                        placement="top"
                    >
                        <span style={{ fontWeight: "bold" }}>Due</span> {new Date(homework.due_date).toLocaleDateString("en", options)}
                    </Tooltip>
                </Typography></>
            );
        } else {
            // due date not passed and not submitted
            return (
                <Typography variant="h7" color={colors.grey[700]} sx={{my: "auto", ml: "auto"}}>
                    <Tooltip 
                        title={
                            dayjs(homework.due_date).tz('America/New_York').format('YYYY-MM-DD HH:mm') + " (NY Time)"
                        }
                        arrow
                        placement="top"
                    >
                        <span style={{ fontWeight: "bold" }}>Due</span> {new Date(homework.due_date).toLocaleDateString("en", options)}
                    </Tooltip>
                </Typography>
            );
        }
    };

    // for submission indicator logic
    const studentsSubmissionRender = () => {
        if (students === null || students === undefined || students.length === 0)
            return <Box></Box>;
        let studentsWhoDidNotSubmit = [];
        
        // make list of students who did not submit
        for (let i = 0; i < students.length; i++) {
            const id = students[i].id
            const name = getStudentName(students[i]);
            const end_date = dayjs(studentsStartEndDateMap[students[i].id].end_date);
            const start_date = dayjs(studentsStartEndDateMap[students[i].id].start_date);

            // if a student is not in both exclude_student and unexclude_student,
            // then continue to the next student
            if (!homework.exclude_student?.split('|').includes(id.toString()) && !homework.unexclude_student?.split('|').includes(id.toString())) {
                continue;
            }
                
            // student was not in the class at the time of the module
            if (dayjs(homework.event.start.split('T')[0]).isAfter(end_date) || dayjs(homework.event.start.split('T')[0]).isBefore(start_date)) {
                continue;
            }
            
            let submitted = false;

            homework.studentsWhoSubmitted?.forEach(element => {
                if (element["id"] === id)
                    submitted = true;
            });

            homework.studentsWhoSubmittedLate?.forEach(element => {
                if (element["id"] === id)
                    submitted = true;
            })

            let exclude_students = [];

            if (homework.exclude_student !== null) {
                const exclude_student = homework.exclude_student.split('|');
                exclude_students = exclude_student.map(Number);
            }
            if (exclude_students.includes(id))
                continue;

            if (!submitted) {
                studentsWhoDidNotSubmit.push({ id: id, name: name });
            }
        }        

        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    flexWrap: "wrap",
                    width: "100%",
                    py: "0.5rem",
                    px: "1rem",
                    gap: "0.5rem",
                }}
            >
                {homework.studentsWhoSubmitted !== null && 
                    homework.studentsWhoSubmitted.map((student, index) => {
                        let exclude_students = [];

                        if (homework.exclude_student !== null) {
                            const exclude_student = homework.exclude_student.split('|');
                            exclude_students = exclude_student.map(Number);           
                        }
                        if (exclude_students.includes(student.id))
                            return null;

                        if (homework.feedback_map[student.id] !== undefined) {
                            return (
                                <StyledBadge key={index} color='primary' variant={homework.feedback_map[student.id].is_opened ? 'standard' : 'dot'}>
                                    {homework.feedback_map[student.id].is_opened &&
                                        <CheckCircleIcon sx={{ position: "absolute", top: "-0.5rem", right: "0", backgroundColor: colors.primary[600], borderRadius: '50%' }} />
                                    }                                    
                                    <Chip color='secondary' label={student.name} sx={{ mr: "0.3rem", mb: "0.3rem" }} />
                                </StyledBadge>
                            )
                        } else {
                            return (
                                <Chip key={index} color='secondary' label={student.name} sx={{ mr: "0.3rem", mb: "0.3rem" }} />
                            )
                        }
                    })
                }
                {homework.studentsWhoSubmittedLate !== null &&
                    homework.studentsWhoSubmittedLate.map((student, index) => {
                        let exclude_students = [];

                        if (homework.exclude_student !== null) {
                            const exclude_student = homework.exclude_student.split('|');
                            exclude_students = exclude_student.map(Number);           
                        }
                        if (exclude_students.includes(student.id))
                            return null;
            
                        if (homework.feedback_map[student.id] !== undefined) {
                            return (
                                <StyledBadge key={index} color='primary' variant={homework.feedback_map[student.id].is_opened ? 'standard' : 'dot'}>
                                    {homework.feedback_map[student.id].is_opened &&
                                        <CheckCircleIcon sx={{ position: "absolute", top: "-0.5rem", right: "0", backgroundColor: colors.primary[600], borderRadius: '50%' }} />
                                    }
                                    <Chip color='warning' label={student.name} sx={{ mr: "0.3rem", mb: "0.3rem" }} />
                                </StyledBadge>
                            )
                        } else {
                            return (
                                <Chip key={index} color='warning' label={student.name} sx={{ mr: "0.3rem", mb: "0.3rem" }} />
                            )
                        }    
                    })
                }
                {studentsWhoDidNotSubmit !== null &&
                    studentsWhoDidNotSubmit.map((student, index) => {
                        return <Chip key={index} label={student.name} />
                    })
                }
            </Box>
        );
    };

    const handleFeedbackClick = () => {
        window.location.href = `/classrooms/${courseId}/homework/${homework.id}/grading`;
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: colors.primary[600],
                borderRadius: "5px",
                border: "0.1px solid #a3a3a3",
                mb: "1.8rem",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    borderBottom: "0.1px solid #a3a3a3",
                    py: "0.5rem",
                    px: "1rem",
                    alignItems: "center",
                }}
            >
                <Avatar sx={{ width: 30, height: 30 }} >
                    <AssignmentIcon />
                </Avatar>
                <Typography variant="h5" sx={{my: "auto", ml: "1rem"}}>
                    {homework.title}
                </Typography>
                {dueDateRender()}
                {userRole !== "student" &&
                    <>
                        <MoreVertIcon handleClick={handleMoreVertIconClick} />
                        <Popover
                            id={popoverId}
                            open={popoverOpen}
                            anchorEl={anchorEl}
                            onClose={handlePopOverClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <UpdateModal
                                courseId={courseId} 
                                homework={homework}
                                students={students}
                                userRole={userRole}
                                studentsStartEndDateMap={studentsStartEndDateMap} 
                                authorDisabledForAdminActions={authorDisabledForAdminActions}
                                setBackdropOpen={setBackdropOpen}
                                setSuccessAlertOpen={setSuccessAlertOpen}
                                setErrorAlertOpen={setErrorAlertOpen}
                                setSuccessAlertMessage={setSuccessAlertMessage}
                                setErrorAlertMessage={setErrorAlertMessage}
                            />
                            <DeleteDialog
                                handleDelete={handleDeleteHomework}
                                setAnchorEl={setAnchorEl}
                                dialogTitle={"Are you sure you want to delete the homework?"} 
                            />
                            <Button onClick={handleFeedbackClick} sx={{ p: 1, width: "100%" }}>
                                Grading
                            </Button>
                        </Popover>
                    </>
                }
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    borderBottom: "0.1px solid #a3a3a3",
                    py: "1rem",
                    px: "0.3rem",
                }}
            >
                <Typography variant="h7" color={colors.grey[700]} sx={{my: "auto", ml: "1rem"}}>
                    Posted on: {new Date(homework.createdAt).toLocaleDateString("en", options_without_time)}
                </Typography>
                <Typography variant='h7' sx={{mt: "1rem", ml: "1rem"}}>
                    {homework.content && homework.content.split("\n").map((line, index) => {
                        return (
                            <span key={index}>
                                {line}
                                <br />
                            </span>
                        )
                    })}
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        mt: "1rem",
                        mx: "1rem"
                    }}
                >
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography variant="h7" color={colors.grey[700]}>
                            {homework.hw_files.length === 1 ? "Attached 1 file" : `Attached ${homework.hw_files.length} files`}
                        </Typography>
                        {homework.hw_files.map((file) => {
                            return (
                                <a key={file.id} href={file.file_url} style={{ color: colors.grey[700] }}>
                                    {file.name}
                                </a>
                            )
                        })}
                    </Box>
                    <Box mt="auto" display="flex" flexDirection="column">
                        {userRole !== "teacher" &&
                            <Button 
                                type="button"
                                variant="contained"
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.location.href = `/classrooms/${courseId}/homework/${homework.id}/submissions`
                                }}
                                style={{
                                    backgroundColor: doesStudentHaveSubmission ? colors.blueAccent[600] : colors.primary[300],
                                    width: "11rem",
                                    fontSize: "9px",
                                    color: "white",
                                    textDecoration: "none"
                                }}
                            >
                                {doesStudentHaveSubmission ? "View Submission and Grade" : "Make a Submission"}
                            </Button>
                        }
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    width: "100%",
                    py: "0.5rem",
                    px: "1rem",
                }}
            >
                <CircleIcon fontSize='10px' color='secondary' />
                <Typography variant='h9' sx={{ml: "0.3rem"}}>
                    On-time submission
                </Typography>
                <CircleIcon fontSize='10px' color='warning' sx={{ml: "1rem"}} />
                <Typography variant='h9' sx={{ml: "0.3rem"}}>
                    Late submission
                </Typography>
                <CircleIcon fontSize='10px' sx={{ml: "1rem", color: colors.grey[950]}} />
                <Typography variant='h9' sx={{ml: "0.3rem"}}>
                    No submission
                </Typography>
            </Box>
            {studentsSubmissionRender()}
        </Box>
    )
}

export default AssignmentBox