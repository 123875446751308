import React from 'react';
import { Box, Typography, useTheme, Divider } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { tokens } from "../../theme";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
// import AreYouSureModal from '../../components/AreYouSureModal';

const NotesModal = ({
    socket,
    socketConnected,
    notesModalOpen,
    setNotesModalOpen,
    notesModalData,
    setNotesModalData,
    dateForRequest,
    enqueueSnackbar,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // const [areYouSureModalOpen, setAreYouSureModalOpen] = React.useState(false);

    const handleClose = () => {
        setNotesModalData(null);
        setNotesModalOpen(false);
    }

    // React.useEffect(() => {
    //     if (!areYouSureModalOpen) {
    //         setSelectedNote(null);
    //     }
    // }, [areYouSureModalOpen]);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        maxHeight: '80%',
        overflowY: 'auto',
        bgcolor: colors.primary[600],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const noteColor = (status) => {
        if (status === 1) {
            // present
            return "success.main";
        } else if (status === 2) {
            // late
            return "warning.main";
        } else if (status === 3 || status === 4) {
            // absent
            return "error";
        } else {
            return null;
        }
    };

    const deleteNote = (note) => {
        if (!socketConnected) {
            enqueueSnackbar('Socket not connected. Please refresh the page.', { variant: 'error' });
            return;
        }

        try {
            socket.emit('put.AttendanceUpdate', {
                ...dateForRequest, // course_id in it
                note: null,
                status: note.status,
                id: note.attendance_id,
                is_note_deleted: true,
            });
        } catch (error) {
            console.error('Error deleting note: ', error);
            enqueueSnackbar('Error deleting note.', { variant : 'error' });
        }

        setNotesModalData(null);
        setNotesModalOpen(false);
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={notesModalOpen}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={notesModalOpen}>
                <Box sx={style}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            fontSize: 20,
                        }}
                    >
                        <Typography variant='h5' color={colors.primary[100]} fontWeight="bold" sx={{ mb: 2 }}>
                            Notes - {dateForRequest.student_name}
                        </Typography>
                        {notesModalData && notesModalData.length > 0 && notesModalData.map((note, idx) => {

                            return (
                                <Box 
                                    key={idx}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        width: '100%',
                                    }}
                                >
                                    {idx !== 0 && <Divider sx={{ width: '100%', my: 1 }} />}
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            width: '100%',
                                        }}
                                    >
                                        <Typography variant='body1' color={noteColor(note.status)} fontWeight="bold">
                                            {note.date}: 
                                        </Typography>
                                        <DeleteForeverIcon 
                                            color='error'
                                            fontSize='small' 
                                            sx={{ 
                                                cursor: 'pointer',
                                            }} 
                                            onClick={() => {
                                                deleteNote(note);
                                                // setAreYouSureModalOpen(true);
                                            }}
                                        />
                                    </Box>
                                    <Typography variant='body1' color={noteColor(note.status)}>
                                        {note.note?.split('\n').map((item, index) => {
                                            // return <span key={index}>{index + 1}. {item}<br /></span>
                                            return <span key={index}>{item}<br /></span>
                                        })}
                                    </Typography>
                                </Box>
                            )
                        })}
                    </Box>
                    {/* <AreYouSureModal
                        colors={colors}
                        open={areYouSureModalOpen}
                        setOpen={setAreYouSureModalOpen}
                        title="This action cannot be undone!"
                        message={`Are you sure you want to delete this note?`}
                        onConfirm={deleteNote}
                    /> */}
                </Box>
            </Fade>
        </Modal>
    );
}

export default NotesModal;