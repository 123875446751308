import React from 'react';
import Button from '@mui/material/Button';
// import AreYouSureModal from '../../../components/AreYouSureModal';


function TeacherPresentButton({
    zoom_id,
    date,
    _time,
    event_idx,
    event,
    enqueueSnackbar,
    colors,
    handlePopOverClose,
    socketConnected,
    socket,
    teacher_course,
}) {

    // const [areYouSureModalOpen, setAreYouSureModalOpen] = React.useState(false);
    const [isPresent, setIsPresent] = React.useState(
        event.teacher_id && teacher_course && event.teacher_id === teacher_course.teacher_id ? true : false
    );

    async function handleSave() {

        if (!socketConnected) {
            enqueueSnackbar("Error: Connection to server lost. Please refresh the page.", { variant: "error" });
            return;
        }

        try {
            socket.emit("put.TeacherPresent", {
                zoom_id: zoom_id,
                date: date,
                _time: _time,
                event_idx: event_idx,
                event: event,
                teacher_id: teacher_course.teacher_id,
                is_present: !isPresent,
            });
        } catch (error) {
            console.error(error);
            enqueueSnackbar("Error: Unable to set or unser teacher as present. Please refresh the page and try again.", { variant: "error" });
        }

        handlePopOverClose();
    };

    return (
        <>
            <Button 
                onClick={() => handleSave()}
                sx={{ 
                    p: 1, 
                    width: "100%",
                    bgcolor: colors.greenAccent[800],
                    borderRadius: "0px",
                    '&:hover': {
                        bgcolor: colors.greenAccent[900],
                    },
                }}
            >
                {isPresent ? "Unset Present" : "Set Present"}
            </Button>
            {/* <AreYouSureModal
                colors={colors}
                open={areYouSureModalOpen}
                setOpen={setAreYouSureModalOpen}
                title={isPresent ? "Unset Present" : "Set Present"}
                message={isPresent ? "Are you sure you want to unset this teacher as present?" : "Are you sure you want to set this teacher as present?"}
                onConfirm={handleSave}
            /> */}
        </>
    )
}

export default TeacherPresentButton;