import React from 'react'
import Popover from '@mui/material/Popover';
import { Button, Box, Typography } from '@mui/material';
import ModuleCancelButton from './ModuleCancelButton';
import ModuleScheduleUpdateButton from './ModuleScheduleUpdateButton';
import SetMakeUpEventButton from './SetMakeUpEventButton';
import ModuleZoomAssignButton from './ModuleZoomAssignButton';
import ModuleRecordedButton from './ModuleRecordedButton';
import ModuleTotalNumButton from './ModuleTotalNumButton';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import ModeCommentIcon from '@mui/icons-material/ModeComment';


const ModuleButtonMenu = ({
    userId,
    zoom_id,
    date,
    _time,
    event_idx,
    event,
    enqueueSnackbar,
    colors,
    socketConnected,
    socket,
    zoomRooms,
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const LightTooltip = styled(({ className, ...props }) => (
            <Tooltip {...props} classes={{ popper: className }} />
        )
    )(
        ({ theme }) => ({
            [`& .${tooltipClasses.tooltip}`]: {
                backgroundColor: colors.grey[900],
                color: 'rgba(0, 0, 0, 0.87)',
                boxShadow: theme.shadows[5],
                fontSize: 14,
                border: `1px solid ${colors.grey[700]}`,
            },
        })
    );

    const handleButtonClick = (e) => {
        setAnchorEl(e.currentTarget);

        // need to emit to server to let other users know that this user is viewing the course
        socket.emit("put.activeUsers", {
            admin_uuid: userId,
            zoom_id: zoom_id,
            date: date,
            _time: _time,
            event_idx: event_idx,
            active: true,
        });
    };

    const handlePopOverClose = () => {
        setAnchorEl(null);

        // need to emit to server to let other users know that this user is no longer viewing the course
        socket.emit("put.activeUsers", {
            admin_uuid: userId,
            zoom_id: zoom_id,
            date: date,
            _time: _time,
            event_idx: event_idx,
            active: false,
        });
    };

    const popoverOpen = Boolean(anchorEl);
    const popoverId = popoverOpen ? 'popover' : undefined;

    function getFontSize(start_end_diff) {
        if (!start_end_diff || start_end_diff === "1.00") {
            return "8px";
        } else {
            return "12px";
        }
    }

    function getEventTitle() {
        const title = event.title;

        if (!title) {
            return "n/a";
        }

        // remove "Class" from the title
        if (title && title.includes("Class ")) {
            return title.replace("Class ", "");
        }
        
        return title;
    }

    function getTotalSessionNum() {
        if (event.course && event.course.private_total_classes) {
            return event.course.private_total_classes;
        }
        if (event.total_event_num) {
            return event.total_event_num;
        }

        return "n/a";
    }

    function getCurrentEventNum() {
        if (event.curr_event_num && event.is_canceled) {
            return parseInt(event.curr_event_num) + 1;
        }

        if (event.curr_event_num) {
            return event.curr_event_num;
        }

        return "n/a";
    }

    return (
        <>
        <Box
            sx={{
                position: "absolute",
                top: 0,
                right: 0,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1,
            }}
        >
            {event.course && (event.course.private_total_classes === null || event.course.private_total_classes === 0) && (
                <Tooltip title="Total number of class sessions are not set" placement="top" arrow>
                    <PriorityHighIcon fontSize='medium' color='warning' />
                </Tooltip>
            )}
            {event.course && event.course.course_memo_2 && event.course.course_memo_2 !== "" && (
                <LightTooltip 
                    title={
                        event.course.course_memo_2.split("\n").map((item, key) => {
                            return <span key={key}>{item}<br/></span>
                        })
                    } 
                    placement="right-start"
                    arrow
                >
                    <ModeCommentIcon fontSize='small' color='info' />
                </LightTooltip>
            )}
        </Box>
        <Button
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: 800,
                fontSize: getFontSize(event.start_end_diff),
                color: colors.blueAccent[500],
                textAlign: "center",
                width: "100%",
                height: "100%",
                color: event.is_canceled_int && "red !important",
                padding: (!event.start_end_diff || event.start_end_diff === "1.00") ? "0px" : "6px 8px",
                textTransform: "none",
            }}
            onClick={handleButtonClick}
        >
            {event.title && (
                <Typography sx={{ fontSize: getFontSize(event.start_end_diff) }}>
                    {getEventTitle()}
                </Typography>
            )}
            <Typography sx={{ fontSize: getFontSize(event.start_end_diff) }}>
                {getCurrentEventNum()} / {getTotalSessionNum()}
            </Typography>
        </Button>
        <Popover
            id={popoverId}
            open={popoverOpen}
            anchorEl={anchorEl}
            onClose={handlePopOverClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    bgcolor: "background.paper",
                    width: "200px",
                    boxShadow: 4,
                }}
            >
                <ModuleScheduleUpdateButton
                    handlePopOverClose={handlePopOverClose}
                    userId={userId}
                    zoom_id={zoom_id}
                    date={date}
                    _time={_time}
                    event_idx={event_idx}
                    event={event}
                    enqueueSnackbar={enqueueSnackbar}
                    colors={colors}
                    socketConnected={socketConnected}
                    socket={socket}
                />
                <SetMakeUpEventButton
                    handlePopOverClose={handlePopOverClose}
                    userId={userId}
                    zoom_id={zoom_id}
                    date={date}
                    _time={_time}
                    event_idx={event_idx}
                    event={event}
                    enqueueSnackbar={enqueueSnackbar}
                    colors={colors}
                    socketConnected={socketConnected}
                    socket={socket}
                />
                <ModuleCancelButton
                    handlePopOverClose={handlePopOverClose}
                    userId={userId}
                    zoom_id={zoom_id}
                    date={date}
                    _time={_time}
                    event_idx={event_idx}
                    event={event}
                    enqueueSnackbar={enqueueSnackbar}
                    colors={colors}
                    socketConnected={socketConnected}
                    socket={socket}
                />
                <ModuleRecordedButton
                    handlePopOverClose={handlePopOverClose}
                    userId={userId}
                    zoom_id={zoom_id}
                    date={date}
                    _time={_time}
                    event_idx={event_idx}
                    event={event}
                    enqueueSnackbar={enqueueSnackbar}
                    colors={colors}
                    socketConnected={socketConnected}
                    socket={socket}
                />
                <ModuleZoomAssignButton
                    handlePopOverClose={handlePopOverClose}
                    userId={userId}
                    zoom_id={zoom_id}
                    date={date}
                    _time={_time}
                    event_idx={event_idx}
                    event={event}
                    enqueueSnackbar={enqueueSnackbar}
                    colors={colors}
                    socketConnected={socketConnected}
                    socket={socket}
                    zooms={zoomRooms}
                />
                <ModuleTotalNumButton
                    course={event.course}
                    colors={colors}
                    handlePopOverClose={handlePopOverClose}
                    enqueueSnackbar={enqueueSnackbar}
                    socketConnected={socketConnected}
                    socket={socket}
                />
            </Box>
        </Popover></>
    )
}
export default ModuleButtonMenu