import React from 'react'
import { tokens } from "../../../theme";
import { Box, useTheme, Typography } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '../../../components/alert/Alert';
import Header from "../../../components/Header";
import AdminActionAccordion from './AdminActionAccordion';
import Axios from 'axios';
import AssignmentBox from './AssignmentBox';
import { useOutletContext } from 'react-router-dom';
import Loading from '../../../components/Loading';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import getCookies from '../../../utils/getCookies';


const Homework = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // for 401 error
    const signOut = useSignOut();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut();
        localStorage.removeItem("userName");
        localStorage.removeItem("who");
        navigate("/signin");
    }

    const matches = useMediaQuery('(min-width:1500px)');
    const matches2 = useMediaQuery('(min-width:1200px)');

    const [loading, setLoading] = React.useState(true);
    const [courseId, setCourseId] = React.useState(null);
    const courseData = useOutletContext().courseData;
    const lectureModules = useOutletContext().lectureModules;
    const [students, setStudents] = React.useState(null);
    const [studentsStartEndDateMap, setStudentsStartEndDateMap] = React.useState(null);
    const [homeworks, setHomeworks] = React.useState(null);
    const [authorDisabledForAdminActions, setAuthorDisabledForAdminActions] = React.useState(false);
    const currentUser = getCookies("_auth_state");
    const userId = currentUser ? currentUser.split(',')[0]?.split(':')[1]?.split('"')[1] : null;
    const userRole = currentUser ? currentUser.split(',')[2]?.split(':')[1]?.split('"')[1] : null;
    const homeworkIdFromUrl = window.location.pathname.split('/')[4] === undefined ? null : window.location.pathname.split('/')[4];
    const homeworkIdFromUrlRef = React.useRef(null);
    const [classMaterials, setClassMaterials] = React.useState([]);
    const [studentId, setStudentId] = React.useState(null);


    // for backdrop
    const [backdropOpen, setBackdropOpen] = React.useState(false);
    const handleBackdropClose = () => {
        setBackdropOpen(false);
    };

    // for alert
    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = React.useState("");
    const [errorAlertMessage, setErrorAlertMessage] = React.useState("");

    // useEffect
    React.useEffect(() => {
        localStorage.setItem('selectedMenu', 'Homework');
        setCourseId(window.location.pathname.split('/')[2]);

        if (userRole === "student" || userRole === "teacher")
            setAuthorDisabledForAdminActions(true);

        async function getHomework() {
            await Axios.post(`${process.env.REACT_APP_URL}/api/v1/courses/${window.location.pathname.split('/')[2]}/homeworks`, {
                is_student: userRole === "student" ? true : false,
                student_uuid: userRole === "student" ? userId : null
            }, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                }
            }).then((res) => {
                setHomeworks(res.data.all_homework);
            }).catch((err) => {
                if (err.response.status !== 401) {
                    handleLogout();
                    return;
                }
                console.log(err);
            });
        }

        async function getStudents() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/courses/${window.location.pathname.split('/')[2]}/students`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((res) => {
                const all_students = [];
                for (let i = 0; i < res.data.length; i++) {
                    all_students.push(res.data[i].student);
                    setStudentsStartEndDateMap((prev) => ({
                        ...prev,
                        [res.data[i].student.id]: {
                            start_date: res.data[i].start_date,
                            end_date: res.data[i].end_date,
                        }
                    }));
                }

                setStudents(all_students);
            }).catch((err) => {
                if (err.response.status !== 404)
                    console.log(err);
                else if (err.response.status === 401)
                    handleLogout();
            });
        }
        
        async function getStudentId() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/students/${userId}`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((res) => {
                setStudentId(res.data.id);
            }).catch((err) => {
                if (err.response.status !== 404)
                    console.log(err);
                else if (err.response.status === 401)
                    handleLogout();
            });
        }
            
        async function getClassMaterials() {
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/courses/${window.location.pathname.split('/')[2]}/class-materials`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((res) => {
                setClassMaterials(res.data);
            }).catch((err) => {
                if (err.response.status !== 404)
                    console.log(err);
                else if (err.response.status === 401)
                    handleLogout();
            });
        }

        if (userRole === "student")
            getStudentId();

        getHomework();
        getStudents();
        getClassMaterials();
        setLoading(false);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // scroll to homeworkIdFromUrl
    React.useEffect(() => {
        const interval = setInterval(() => {
            if (homeworkIdFromUrl !== null && homeworkIdFromUrlRef.current !== null) {
                const element = document.getElementById(homeworkIdFromUrl);
                if (element)
                    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                clearInterval(interval);
            }

            if (homeworkIdFromUrl === null)
                clearInterval(interval);
        }, 500);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    
    return (
        <Box m="20px" marginX={3} marginY={3} minHeight="90%">
            { loading || courseData === null || lectureModules === null || homeworks === null ? 
                <Loading />
                : <>
                    <Box 
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            px: "25%",
                            ...!matches && {
                                px: "15%",
                            },
                            ...!matches2 && {
                                px: "10%",
                            },
                        }}
                    >
                        <Header 
                            title={
                                courseData.code !== null 
                                ? courseData.code + ". " + courseData.course_title 
                                : courseData.course_title
                            }
                            subtitle="A ONE Institute"
                        />
                        {/* For Admin */}
                        {userRole !== "student" &&
                            <Box
                                minWidth="20rem"
                                width="100%"
                                minHeight="10rem"
                                borderRadius="3px"
                                padding={3}
                                boxShadow="0px 0px 10px 10px rgba(0, 0, 0, 0.10)"
                                backgroundColor={colors.primary[600]}
                                display="flex"
                                flexDirection="column"
                                alignItems="left"
                                gap={3}
                                mb={3}
                            >
                                <Typography variant="h4" color={colors.primary[450]}>
                                    Admin Actions
                                </Typography>
                                <AdminActionAccordion
                                    courseId={courseId}
                                    students={students}
                                    userRole={userRole}
                                    studentsStartEndDateMap={studentsStartEndDateMap}
                                    authorDisabledForAdminActions={authorDisabledForAdminActions}
                                    lectureModules={lectureModules}
                                    setBackdropOpen={setBackdropOpen}
                                    setSuccessAlertOpen={setSuccessAlertOpen}
                                    setErrorAlertOpen={setErrorAlertOpen}
                                    setSuccessAlertMessage={setSuccessAlertMessage}
                                    setErrorAlertMessage={setErrorAlertMessage}
                                    classMaterials={classMaterials}
                                />
                            </Box>
                        }

                        {/* For Students and teachers */}
                        <Box
                            minWidth="20rem"
                            width="100%"
                            minHeight="100%"
                            borderRadius="3px"
                            padding={8}
                            boxShadow="0px 0px 10px 10px rgba(0, 0, 0, 0.10)"
                            backgroundColor={colors.primary[600]}
                            alignItems="center"
                        >
                            {homeworks !== null && homeworks.length !== 0 ?
                                homeworks.map((homework) => {                                
                                    let exclude_students = homework.exclude_student ? homework.exclude_student.split('|') : [];
                                    let unexclude_students = homework.unexclude_student ? homework.unexclude_student.split('|') : [];
                                        
                                    if (userRole === "student" 
                                        && ((exclude_students.includes(studentId.toString())) 
                                            || (!exclude_students.includes(studentId.toString()) 
                                                && !unexclude_students.includes(studentId.toString()))
                                            )
                                        )
                                        return null;
                                    if (homeworkIdFromUrl === null || homeworkIdFromUrl !== homework.id.toString())
                                        return <AssignmentBox
                                                    key={homework.id}
                                                    courseId={courseId}
                                                    students={students}
                                                    studentsStartEndDateMap={studentsStartEndDateMap}
                                                    homework={homework}
                                                    authorDisabledForAdminActions={authorDisabledForAdminActions}
                                                    setBackdropOpen={setBackdropOpen}
                                                    setSuccessAlertOpen={setSuccessAlertOpen}
                                                    setErrorAlertOpen={setErrorAlertOpen}
                                                    userRole={userRole}
                                                    userId={userId}
                                                    setSuccessAlertMessage={setSuccessAlertMessage}
                                                    setErrorAlertMessage={setErrorAlertMessage}
                                                />
                                    else if (homeworkIdFromUrl === homework.id.toString())
                                        return <div key={homework.id} id={homework.id} ref={homeworkIdFromUrlRef}>
                                                    <AssignmentBox
                                                        key={homework.id}
                                                        courseId={courseId}
                                                        students={students}
                                                        studentsStartEndDateMap={studentsStartEndDateMap}
                                                        homework={homework}
                                                        authorDisabledForAdminActions={authorDisabledForAdminActions}
                                                        setBackdropOpen={setBackdropOpen}
                                                        setSuccessAlertOpen={setSuccessAlertOpen}
                                                        setErrorAlertOpen={setErrorAlertOpen}
                                                        userRole={userRole}
                                                        userId={userId}
                                                        setSuccessAlertMessage={setSuccessAlertMessage}
                                                        setErrorAlertMessage={setErrorAlertMessage}
                                                    />
                                                </div>
                                })
                                :
                                <Box sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    minWidth: "20rem",
                                    minHeight: "32rem",
                                    gap: "0.4rem",
                                }}>
                                    <InfoOutlinedIcon sx={{ my: "auto" }} />
                                    <Typography variant="h4" color={colors.primary[450]} my="auto">
                                        No homework yet!
                                    </Typography>
                                </Box>
                            }
                        </Box>
                    </Box>
                    <Alert
                        successAlertOpen={successAlertOpen}
                        setSuccessAlertOpen={setSuccessAlertOpen}
                        errorAlertOpen={errorAlertOpen}
                        setErrorAlertOpen={setErrorAlertOpen}
                        successMsg={successAlertMessage}
                        errorMsg={errorAlertMessage}
                        vertical="bottom"
                        horizontal="left"
                    />
                    <Backdrop
                        sx={{ color: colors.primary[400], zIndex: 2147483646 }}
                        open={backdropOpen}
                        onClick={handleBackdropClose}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </>
            }
        </Box>
    )
}

export default Homework