import * as React from 'react';
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import ModalTabs from './ModalTabs';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import Alert from '../../../components/alert/Alert';

export default function MoreInfoModal({ 
    student, 
    setStudentUpdated,
    socket = null,
    socketConnected = false,
    from_weekly = false,
    setStudentForMoreInfo,
}) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1000,
        height: 800,
        bgcolor: colors.primary[600],
        border: '2px solid grey',
        boxShadow: 24,
        p: 4,
    };

    React.useEffect(() => {
        if (from_weekly) {
            setOpen(true);
        }

        const url_pathname = window.location.pathname;
        const searchParams = new URLSearchParams(window.location.search);
        const consultation_number = searchParams.get('consultation_number');

        if (student && student.consultation_number && url_pathname === "/admin/consultationProgress" && consultation_number === student.consultation_number.toString()) {
            setOpen(true);
        }
    }, [student]);
    
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        if (from_weekly) {
            setStudentForMoreInfo(null);
        }

        setOpen(false)
    };

    // for alert
    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = React.useState('');
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = React.useState('');

    const getStudentName = () => {
        if (!student) {
            return '';
        }

        if (student.is_potential_student && student.consultation_number) {
            return student.consultation_number;
        } else if (student.is_potential_student && !student.consultation_number && student.first_name && student.korean_name && student.last_name) {
            return `${student.first_name} ${student.korean_name} ${student.last_name}`;
        } else if (student.is_potential_student && !student.consultation_number && student.first_name && student.korean_name) {
            return student.first_name + " " + student.korean_name;
        } else if (student.is_potential_student && !student.consultation_number && student.first_name && student.last_name) {
            return student.first_name + " " + student.last_name;
        } else if (student.is_potential_student && !student.consultation_number && student.first_name) {
            return student.first_name;
        } else if (student.is_potential_student && !student.consultation_number && student.last_name) {
            return student.last_name;
        } else if (student.is_potential_student && student.email) {
            return student.email;
        } else if (student.is_potential_student) {
            return 'Unknown Potential Student';
        }

        if (student.first_name && student.korean_name && student.last_name && student.consultation_number) {
            return `${student.first_name} ${student.korean_name} ${student.last_name} (${student.consultation_number})`;
        } else if (student.first_name && student.korean_name && student.last_name) {
            return `${student.first_name} ${student.korean_name} ${student.last_name}`;
        } else if (student.first_name && student.korean_name && student.consultation_number) {
            return `${student.first_name} ${student.korean_name} (${student.consultation_number})`;
        } else if (student.first_name && student.korean_name) {
            return `${student.first_name} ${student.korean_name}`;
        } else if (student.first_name && student.last_name && student.consultation_number) {
            return `${student.first_name} ${student.last_name} (${student.consultation_number})`;
        } else if (student.first_name && student.last_name) {
            return `${student.first_name} ${student.last_name}`;
        } else if (student.first_name && student.consultation_number) {
            return `${student.first_name} (${student.consultation_number})`;
        } else if (student.last_name && student.consultation_number) {
            return `${student.last_name} (${student.consultation_number})`;
        } else if (student.consultation_number) {
            return student.consultation_number;
        } else if (student.first_name && student.first_name !== "") {
            return student.first_name;
        } else if (student.last_name && student.last_name !== "") {
            return student.last_name;
        } else {
            return 'Unknown Student';
        }
    };

    return (
        student &&
        <div>
            {!from_weekly && <Button size="small" style={{"backgroundColor": colors.primary[800], "color": colors.primary[400]}} onClick={handleOpen}>More Info</Button>}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                  backdrop: {
                    timeout: 500,
                  },
                }}
            >
                <Fade in={open}>
                    {!student.is_potential_student ? (
                        <Box sx={style}>
                            <Typography id="modal-modal-title" variant="h1" ml={1} borderBottom={1}>
                                {student.last_name}, {student.first_name} {student.korean_name} {student.consultation_number && `(${student.consultation_number})`}
                            </Typography>
                            <Typography id="modal-modal-description" variant="h6" mt={1} mb={1} ml={1} color={"grey"}>
                                <SchoolRoundedIcon style={{marginBottom: "-5px", marginRight: "5px"}} /> {student.school} (Grade: {student.grade})
                            </Typography>
                            <ModalTabs 
                                student={student} 
                                setStudentUpdated={setStudentUpdated} 
                                setSuccessAlertOpen={setSuccessAlertOpen}
                                setSuccessAlertMessage={setSuccessAlertMessage}
                                setErrorAlertOpen={setErrorAlertOpen}
                                setErrorAlertMessage={setErrorAlertMessage}
                                socket={socket}
                                socketConnected={socketConnected}
                                from_weekly={from_weekly}
                                getStudentName={getStudentName}
                            />
                            <Alert
                                successAlertOpen={successAlertOpen}
                                setSuccessAlertOpen={setSuccessAlertOpen}
                                errorAlertOpen={errorAlertOpen}
                                setErrorAlertOpen={setErrorAlertOpen}
                                successMsg={successAlertMessage}
                                errorMsg={errorAlertMessage}
                                vertical='bottom'
                                horizontal='left'
                            />
                        </Box>
                    ) : (
                        <Box sx={style}>
                            <Typography id="modal-modal-title" variant="h1" ml={1} borderBottom={1}>
                                {student.consultation_number} (Consultation Number)
                            </Typography>
                            <Typography id="modal-modal-description" variant="h6" mt={1} mb={1} ml={1} color={"grey"}>
                                <SchoolRoundedIcon style={{marginBottom: "-5px", marginRight: "5px"}} /> 
                                {student.school ? student.school : "N/A"} (Grade: {student.grade ? student.grade : "N/A"})
                            </Typography>
                            <ModalTabs 
                                student={student} 
                                setStudentUpdated={setStudentUpdated}
                                setSuccessAlertOpen={setSuccessAlertOpen}
                                setSuccessAlertMessage={setSuccessAlertMessage}
                                setErrorAlertOpen={setErrorAlertOpen}
                                setErrorAlertMessage={setErrorAlertMessage}
                                socket={socket}
                                socketConnected={socketConnected}
                                from_weekly={from_weekly}
                                getStudentName={getStudentName}
                            />
                            <Alert
                                successAlertOpen={successAlertOpen}
                                setSuccessAlertOpen={setSuccessAlertOpen}
                                errorAlertOpen={errorAlertOpen}
                                setErrorAlertOpen={setErrorAlertOpen}
                                successMsg={successAlertMessage}
                                errorMsg={errorAlertMessage}
                                vertical='bottom'
                                horizontal='left'
                            />
                        </Box>
                    )}
                </Fade>
            </Modal>
        </div>
    );
}