import React from 'react';
import './Calendar.css';
import Header from "../../components/Header";
import CalendarSidebar from "./CalendarSidebar";
import { Box, Button, Typography, Popover } from "@mui/material";
import Axios from 'axios';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CalendarEventPopup from "./CalendarEventPopup";
import VacationModal from "./VacationModal";
import Alert from '../../components/alert/Alert';
import CircleIcon from '@mui/icons-material/Circle';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      fontSize: theme.typography.pxToRem(12),
    },
}));
  
const Calendar = () => {
    const today = new Date();
    const [activeDay, setActiveDay] = React.useState(today.getDate());
    const [month, setMonth] = React.useState(today.getMonth());
    const [year, setYear] = React.useState(today.getFullYear());
    const [eventsArr, setEventsArr] = React.useState([]);
    const [updated, setUpdated] = React.useState(false);

    // For event popover
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [updateAnchorEl, setUpdateAnchorEl] = React.useState(null);
    const [selectedEvent, setSelectedEvent] = React.useState(null);

    // For more events popover
    const [moreAnchorEl, setMoreAnchorEl] = React.useState(null);

    // For blinking effect
    const [blinkDay, setBlinkDay] = React.useState(null);

    // for alert
    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = React.useState("");
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = React.useState("");    

    // For holiday modal
    const [vacations, setVacations] = React.useState([]);
    const [openVacationModal, setOpenVacationModal] = React.useState(false);

    const handleOpenVacation = () => {
        setOpenVacationModal(true);
    };

    const handleCloseVacation = () => {
        setOpenVacationModal(false);
    };
    

    React.useEffect(() => {
        localStorage.setItem('selectedMenu', 'Calendar');
    }, []);

    React.useEffect(() => {
        initCalendar();
        fetchEvents();
        fetchVacations();
    }, [month, year, updated]);

    const months = [
        "January", "February", "March", "April", "May", "June", 
        "July", "August", "September", "October", "November", "December"
    ];

    const initCalendar = () => {
        const firstDay = new Date(year, month, 1);
        const lastDay = new Date(year, month + 1, 0);
        const prevLastDay = new Date(year, month, 0);
        const prevDays = prevLastDay.getDate();
        const lastDate = lastDay.getDate();
        const day = firstDay.getDay();
        const nextDays = 7 - lastDay.getDay() - 1;

        let days = [];

        // Previous month's days
        for (let x = day; x > 0; x--) {
            days.push(
                <Box className="day prev-date" key={`prev-${x}`}>
                    <Box className="date">{prevDays - x + 1}</Box>
                </Box>
            );
        }

        // Current month's days
        for (let i = 1; i <= lastDate; i++) {
            const isBlinking = blinkDay === i;

            const dayEvents = eventsArr.filter(eventObj => {
                const eventDate = new Date(eventObj.date);
                    return (
                        eventDate.getDate() === i &&
                        eventDate.getMonth() === month &&
                        eventDate.getFullYear() === year
                    );
                });

            const isToday = i === today.getDate() && year === today.getFullYear() && month === today.getMonth();

            days.push(
                <Box
                    className={`day ${isToday ? 'today active' : ''} ${dayEvents.length > 0 ? 'event' : ''} ${isBlinking ? 'blink_me' : ''}`}
                    key={`current-${i}`}
                    onClick={(e) => handleDayClick(i, e)}
                >
                    <Box className="date">{i}</Box>
                    {/* Display up to 3 events */}
                    <Box className="events-list" sx={{ width: '100%', marginTop: '20px' }}>
                        {dayEvents.slice(0, 3).map((event, index) => (
                            <Box key={index} className="event-item">
                                <HtmlTooltip title={event.name} arrow placement='top'>
                                    <Button 
                                        sx={{
                                            bgcolor: event.color, 
                                            color: 'white', 
                                            width: '100%', 
                                            height: '18px',
                                            fontSize: '14px',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            textTransform: 'none',
                                            whiteSpace: 'nowrap',
                                            ":hover": { 
                                                backgroundColor: '#eaa54b' 
                                            }
                                        }}
                                        onClick={(e) => handleEventClick(event, e)}
                                    >
                                        {event.competition && event.competition.name ? event.competition.name : event.name}
                                    </Button>
                                </HtmlTooltip>
                            </Box>
                        ))}
                        {dayEvents.length > 3 && (
                        <>
                        <Box sx={{ display: 'flex', justifyContent: 'center', height: '10px' }}>
                            <Button
                                className="more-events"
                                variant="text"
                                sx={{ 
                                    color: '#080C11', 
                                    cursor: 'pointer',
                                    ":hover": { 
                                        backgroundColor: '#eaa54b' 
                                    }
                                }}
                                onClick={handleMoreClick}
                            >
                                + {dayEvents.length - 3} more
                            </Button>
                        </Box>
                        <Popover
                            id={moreId}
                            open={morePopover}
                            anchorEl={moreAnchorEl}
                            onClose={handleMorePopoverClose}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                            PaperProps={{
                                sx: {
                                    height: 'auto',
                                    maxHeight: '300px',
                                    overflowY: 'auto',
                                    width: 'auto',
                                    maxWidth: '200px',
                                    padding: 1,
                                },
                            }}
                        >
                            {dayEvents.map((event) => (
                                <Box 
                                    key={event.id} 
                                    sx={{ 
                                        display: 'flex', 
                                        justifyContent: 'space-between',                                        
                                        mb: 1,
                                        ":hover": {
                                            cursor: 'pointer',
                                            backgroundColor: '#eaa54b',
                                            width: '100%',
                                        },
                                    }} 
                                    onClick={(e) => handleEventClick(event, e)}                                    
                                >
                                    <Typography variant="body1">{event.name}</Typography>
                                    <Box
                                        sx={{
                                            width: 20,
                                            height: 20,
                                            backgroundColor: event.color,
                                            borderRadius: '50%',
                                        }}
                                    />
                                </Box>
                            ))}
                        </Popover>
                        </>
                        )}
                    </Box>
                </Box>
            );
        }

        // Next month's days
        for (let j = 1; j <= nextDays; j++) {
            days.push(
                <Box className="day next-date" key={`next-${j}`}>
                    <Box className="date">{j}</Box>
                </Box>
            );
        }

        return days;
    };

    const fetchEvents = async () => {
        await Axios.get(`${process.env.REACT_APP_URL}/api/v1/calendar-events`, {
            params: {
                month: month + 1,
                year: year,
            },
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setUpdated(false);
            setEventsArr(response.data);
        }).catch((error) => {
            if (error.response.status === 404) {
                console.log("No events found");
            }
        });
    };

    const fetchVacations = async () => {
        await Axios.get(`${process.env.REACT_APP_URL}/api/v1/calendar-events/vacations`, {        
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setVacations(response.data);
            setUpdated(false);
        }).catch((error) => {
            if (error.response.status === 404) {
                console.log("No Vacations found");
            }
        });
    };

    const handleDayClick = (day, event) => {
        setActiveDay(day);
        setSelectedEvent(null);
        setAnchorEl(event.currentTarget);
    };

    const handleMoreClick = (e) => {
        e.stopPropagation();
        setMoreAnchorEl(e.currentTarget);
    };

    // Popover close handler
    const handleMorePopoverClose = (e) => {
        e.stopPropagation();
        setMoreAnchorEl(null);
    };

    // Check if the popover should be open
    const morePopover = Boolean(moreAnchorEl);
    const moreId = morePopover ? 'more-popover' : undefined;

    const handleEventClick = (event, e) => {
        e.stopPropagation();
        setSelectedEvent(event);
        setUpdateAnchorEl(e.currentTarget);
    };
    
    const prevMonth = () => {
        setMonth(month - 1 < 0 ? 11 : month - 1);
        if (month === 0) setYear(year - 1);
    };

    const nextMonth = () => {
        setMonth(month + 1 > 11 ? 0 : month + 1);
        if (month === 11) setYear(year + 1);
    };

    const handleToday = () => {
        setMonth(today.getMonth());
        setYear(today.getFullYear());
    };

    // Function to handle sidebar event click
    const handleEventClickFromSidebar = (eventDate) => {
        const date = new Date(eventDate);
        setYear(date.getFullYear());
        setMonth(date.getMonth());
        setActiveDay(date.getDate());

        setBlinkDay(null);

        setTimeout(() => {
            setBlinkDay(date.getDate());
        }, 50);
    };

    return (
        <Box className="calendar-container" marginX={3} marginY={3}>
        <Header title="Calendar" subtitle="Tests and Competitions" />

            <Box display="flex" justifyContent="space-between">
                {/* CALENDAR SIDEBAR */}
                <CalendarSidebar 
                    updated={updated}
                    onEventClick={handleEventClickFromSidebar}
                    setSuccessAlertMessage={setSuccessAlertMessage}
                    setSuccessAlertOpen={setSuccessAlertOpen}
                    setErrorAlertMessage={setErrorAlertMessage}
                    setErrorAlertOpen={setErrorAlertOpen}
                />

                {/* CALENDAR */}
                <Box flex="1 1 100%" ml="15px">
                    <Box className="calendar-main">
                        <Box className="event-color-indicators">
                            <Box className="event-color-indicator-wrapper">
                                <CircleIcon sx={{ color: '#06B050' }} fontSize='small' />
                                <Typography variant="body2">Open</Typography>
                            </Box>
                            <Box className="event-color-indicator-wrapper">
                                <CircleIcon sx={{ color: '#E97130' }} fontSize='small' />
                                <Typography variant="body2">Registration Deadline</Typography>
                            </Box>
                            <Box className="event-color-indicator-wrapper">
                                <CircleIcon sx={{ color: '#FF0000' }} fontSize='small' />
                                <Typography variant="body2">Submission Deadline</Typography>
                            </Box>
                            <Box className="event-color-indicator-wrapper">
                                <CircleIcon sx={{ color: '#5E9BD3' }} fontSize='small' />
                                <Typography variant="body2">Test Date</Typography>
                            </Box>
                            <Box className="event-color-indicator-wrapper">
                                <CircleIcon sx={{ color: '#A02C92' }} fontSize='small' />
                                <Typography variant="body2">Winner Announcement</Typography>
                            </Box>
                        </Box>
                        <Box className="calendar">
                            <Box className="month">
                                <Box>
                                    <Button className="prev" onClick={prevMonth}><ArrowBackIosNewIcon/></Button>
                                    <Button className="today" onClick={handleToday}>Today</Button>
                                    <Button className="next" onClick={nextMonth}><ArrowForwardIosIcon/></Button>
                                </Box>
                                <Box className="date">
                                    {months[month]} {year}
                                </Box>
                                {/* <Box/> */}
                                {/* Vacation */}
                                <Box>
                                    <Button className="vacation" onClick={handleOpenVacation}>Vacation</Button>
                                </Box>
                            </Box>
                            <Box sx={{ overflow: 'auto'}}>
                                <Box className="weekdays">
                                    <Box className="day">Sun</Box>
                                    <Box className="day">Mon</Box>
                                    <Box className="day">Tue</Box>
                                    <Box className="day">Wed</Box>
                                    <Box className="day">Thu</Box>
                                    <Box className="day">Fri</Box>
                                    <Box className="day">Sat</Box>
                                </Box>
                                <Box className="days">{initCalendar()}</Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>

            {/* VACATION MODAL */}
            <VacationModal
                vacations={vacations}
                open={openVacationModal}
                onClose={handleCloseVacation}
                setUpdated={setUpdated}
                setSuccessAlertMessage={setSuccessAlertMessage}            
                setSuccessAlertOpen={setSuccessAlertOpen}
                setErrorAlertMessage={setErrorAlertMessage}
                setErrorAlertOpen={setErrorAlertOpen}
            />
            
            {/* EVENT POPUP */}
            <CalendarEventPopup
                vacations={vacations}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                updateAnchorEl={updateAnchorEl}
                setUpdateAnchorEl={setUpdateAnchorEl}
                selectedEvent={selectedEvent}
                updated={updated}
                setUpdated={setUpdated}
                year={year}
                month={month}
                activeDay={activeDay}
                setSuccessAlertMessage={setSuccessAlertMessage}
                setSuccessAlertOpen={setSuccessAlertOpen}
                setErrorAlertMessage={setErrorAlertMessage}
                setErrorAlertOpen={setErrorAlertOpen}
            />
            <Alert
                successAlertOpen={successAlertOpen}
                setSuccessAlertOpen={setSuccessAlertOpen}
                errorAlertOpen={errorAlertOpen}
                setErrorAlertOpen={setErrorAlertOpen}
                successMsg={successAlertMessage}
                errorMsg={errorAlertMessage}
                vertical="bottom"
                horizontal="left"
            />
        </Box>
    );
};

export default Calendar;