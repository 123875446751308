import React from 'react';
import { Box, TextField, Typography ,Button, useTheme } from '@mui/material';
import { tokens } from '../theme';
import BackdropComp from '../components/Backdrop';
import Axios from 'axios';


function Unsubscription() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [email, setEmail] = React.useState('');
    const [unsubscribed, setUnsubscribed] = React.useState(false);
    const [error, setError] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    const focusInput = React.useRef(null);

    const handleUnsubscribe = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        if (!email) {
            setError("Email is required!");
            setLoading(false);
            focusInput.current.focus();
            return;
        }

        // check if email is valid using regex
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!emailPattern.test(email)) {
            setError("Invalid email address!");
            setLoading(false);
            focusInput.current.focus();
            return;
        }

        const _email = email.trim().toLowerCase();
        
        await Axios.post(`${process.env.REACT_APP_URL}/api/v1/news-letter-emails/unsubscription`, {
            email: _email,
        }, {
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            setUnsubscribed(true);
            setEmail('');
            setLoading(false);
        }).catch((error) => {
            console.log(error);

            const status = error.response?.status;
            if (status && status === 404) {
                setError("Email not found!");
            } else {
                setError("Error occurred while unsubscribing email! Please try again later.");
            }

            focusInput.current.focus();
            setLoading(false);
        });
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                width: '100vw',
                fontSize: '2rem',
                fontWeight: 'bold',
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '20px',
                    left: '20px',
                }}
            >
                <a href="https://www.aoneinstitutesat.com" target="_blank" rel="noreferrer">
                    <img src="/imgs/aone-logo.png" alt="A ONE INSTITUTE" width="200px" />
                </a>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 2,
                    padding: "10px",
                    width: "50%",
                    maxWidth: "500px",
                }}
            >
                {unsubscribed ? (
                    <Typography variant="h3" sx={{ fontWeight: 500, textAlign: 'center', lineHeight: 1.5 }}>
                        You have successfully unsubscribed from our news letters!
                    </Typography>
                ) : (
                    <>
                        <Typography variant="h3" color="primary" sx={{ fontWeight: 500 }}>
                            Unsubscribe from <span style={{ color: colors.blueAccent[600] }}>AONE</span> News Letters
                        </Typography>
                        <Typography variant="h4" color="primary" sx={{ fontWeight: 500, textAlign: 'center', mt: 1 }}>
                            If you would like to unsubscribe from our news letters, enter your email address below and click the <span style={{ color: colors.redAccent[500] }}>"Unsubscribe"</span> button.
                        </Typography>
                        <TextField
                            inputRef={focusInput}
                            disabled={unsubscribed}
                            id="outlined-basic"
                            label="Email"
                            variant="outlined"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            color={error && 'error'}
                            sx={{ 
                                width: '100%', 
                                mt: 2,
                            }}
                        />
                        {error && (
                            <Typography variant="h5" color="error" sx={{ fontWeight: 500 }}>
                                {error}
                            </Typography>
                        )}
                        <Box sx={{ mt: 2 }}>
                            <Button disabled={unsubscribed} variant="contained" color="error" onClick={handleUnsubscribe}>
                                Unsubscribe
                            </Button>
                        </Box>
                    </>
                )}
            </Box>
            <BackdropComp backdropOpen={loading} handleBackdropClose={() => setLoading(false)} />
        </Box>
    );
}

export default Unsubscription